import AltInstance from 'common-ui/src/alt/AltInstance';

class StepUpAuthenticationActions {
    constructor() {
        this.generateActions(
            'toggleLinkAsShow',
            'setEmail',
            'setValidEmail',
            'submitEmail',
            'setToken',
            'setValidToken',
            'submitToken',
            'setAuthenticator',
            'setValidAuthenticator',
            'submitAuthenticator',
            'setPhoneCountry',
            'setPhoneNumber',
            'setNoPhoneSubmitted',
            'setValidPhone',
            'submitPhoneCall',
            'submitPhoneText',
            'changeContactLinkClicked',
            'setMismatch',
            'setShowOtp',
            'setOtp',
            'setValidOtp',
            'submitOtp',
            'setRedirectUrl',
            'setCSRFToken',
            'setIsMobile',
            'setSuccessHandler',
            'setAppCode',
            'setFeatureCode',
            'setIsRepeatableChallenge',
            'setReasonForStepUp',
        );
    }
}

export default AltInstance.createActions(StepUpAuthenticationActions);
