/* eslint-disable react/sort-comp,react/destructuring-assignment,react/prop-types */
// 'use strict';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AnalyticsLoggerHelper } from 'dbk-analytics';
import { startTracker } from './AnalyticTracker';

@inject('appConfigStore')
@inject('navigationStore')
@inject('authenticationStore')
@observer
export default class Analytics extends Component {
    render() {
        const {
            appConfigStore,
            navigationStore,
            authenticationStore,
        } = this.props;

        if (
            appConfigStore &&
            appConfigStore.appConfig &&
            authenticationStore.user &&
            !this.loggerInitialized
        ) {
            AnalyticsLoggerHelper.initialize({
                navigationStore,
                appConfigStore,
            });
            startTracker({ appConfigStore });
            this.loggerInitialized = true;
        }

        return <div style={{ display: 'none' }} />;
    }
}
