/* eslint-disable spaced-comment,no-underscore-dangle,react/no-unused-prop-types,react/boolean-prop-naming,prefer-const,react/destructuring-assignment,react/no-unused-state,react/sort-comp,react/prop-types,react/no-string-refs,padding-line-between-statements,prefer-template,jsx-a11y/no-redundant-roles,jsx-a11y/anchor-is-valid,no-script-url,class-methods-use-this,no-plusplus,lines-between-class-members,no-lonely-if,no-var,react/no-access-state-in-setstate,prefer-destructuring,one-var,operator-assignment,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-noninteractive-tabindex,default-case,no-else-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SVG from '../images/SVG';
import '../../styles/Almanac.scss';
//reference : https://github.com/code0wl/isomorphic-react-calendar-tutorial

const _i18nDefaultStrings = {
    'label.nextMonth': 'Next Month',
    'label.prevMonth': 'Previous Month',
    'label.helpTextForDisabledDate': 'Disable',
};

export default class Almanac extends Component {
    static propTypes = {
        /**
         * Initial focused value on the almanac.
         */
        defaultValue: PropTypes.object,
        /**
         * Internaitionalization strings for using the almanac in different languages.
         */
        i18nStrings: PropTypes.object,
        /**
         * A string that represents the format for date and year at the top of the almanac component.
         */
        headerMonthFormat: PropTypes.string,
        /**
         * An array of holidays to make unselectable.
         */
        holidays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
        /**
         * A boolean to make weekends unselectable.
         */
        disableWeekends: PropTypes.bool,
        /**
         * An object representing the selectable date range that the calendar will show.
         */
        selectRange: PropTypes.object,
        /**
         * A function to be called when a date is selected.
         */
        onSelect: PropTypes.func,
        //onHoverDate: PropTypes.func,
        /**
         * A function to be called when the user changes the month.
         */
        onMonthChange: PropTypes.func,
        /**
         * A footer element for the almanac.
         */
        footer: PropTypes.element,
        /**
         * A date object that represents the first day your almanac will go to.
         */
        maxStartDate: PropTypes.object,
        /**
         * A date object that represents the last day your almanac will go to.
         */
        maxEndDate: PropTypes.object,
        /**
         * A custom function for filtering the dates.
         */
        dateFilter: PropTypes.func,
        /**
         * A function to be executed with the focus is reset.
         */
        onResetFocus: PropTypes.func,
        /**
         * A date object that represents the first element you were on when you entered the almanac.
         */
        initialValue: PropTypes.object,
        /**
         * A bool value that only exists in mobile view rendering.
         */
        isXSView: PropTypes.bool,
        /**
         * sent from AlmanacPicker if Manual Entry is enabled on the almanac picker - used to get focus management within the datepicker to behave correctly.
         */
        manualEntryEnabled: PropTypes.bool,
        /**
         * Function to pass that will focus the calendar icon when using shift tab to navigate almanac picker.
         */
        focusManagementForAlmanacPreviousMonthKeyDown: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this._buildWeekdaysHeaderItems = this._buildWeekdaysHeaderItems.bind(
            this,
        );
        this._getPreviousMonth = this._getPreviousMonth.bind(this);
        this._getNextMonth = this._getNextMonth.bind(this);
        this._getString = this._getString.bind(this);
        this._arrowKeyNavigationHandler = this._arrowKeyNavigationHandler.bind(
            this,
        );
        this._isSelectedDayInCurrentMonth = this._isSelectedDayInCurrentMonth.bind(
            this,
        );
        this._convertDateToCalendarPosition = this._convertDateToCalendarPosition.bind(
            this,
        );
        let defaultValue = this.props.defaultValue
            ? moment(this.props.defaultValue)
            : moment();
        this.currentLocale = defaultValue.locale();
        this.state = {
            focusedDate: defaultValue.clone(),
            selectedValue: defaultValue.clone(),
            currentMonth: defaultValue.startOf('month'),
            maxStartDate: this.props.maxStartDate,
            maxEndDate: this.props.maxEndDate,
            initialFocus: defaultValue.clone(),
        };
    }

    componentDidMount() {
        if (this.props.focusSelectedDateOnMount) {
            let selectedDate = this.state.selectedValue;
            let dayPosition = this._convertDateToCalendarPosition(selectedDate);

            if (dayPosition && this.refs && this.refs[dayPosition]) {
                this.refs[dayPosition].focus();
            }
        }
        if (this.props.isXSView) {
            this.refs.previousMonthLink.focus();
        }
    }

    UNSAFE_componentWillUpdate() {
        let locale = moment.locale();
        if (locale !== this.currentLocale) {
            this.state.focusedDate.locale(locale);
            this.state.selectedValue.locale(locale);
            this.state.currentMonth.locale(locale);
            this.currentLocale = locale;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.defaultValue !== nextProps.defaultValue) {
            let defaultValue = nextProps.defaultValue
                ? moment(nextProps.defaultValue)
                : moment();
            this.setState({
                selectedValue: defaultValue.clone(),
                currentMonth: defaultValue.startOf('month'),
            });
        }
        if (nextProps.currentMonth) {
            this.setState({
                currentMonth: nextProps.currentMonth.clone(),
            });
        }
        if (this.props.maxStartDate !== nextProps.maxStartDate) {
            this.setState({
                maxStartDate: nextProps.maxStartDate,
            });
        }
        if (this.props.maxEndDate !== nextProps.maxEndDate) {
            this.setState({
                maxEndDate: nextProps.maxEndDate,
            });
        }
    }

    render() {
        let monthClass =
            ' month-label ' +
            (this.state.currentMonth.month() === moment().month()
                ? 'current-month'
                : '');
        let headerMonthFormat = this.props.headerMonthFormat
            ? this.props.headerMonthFormat
            : 'MMMM YYYY';
        let prevMonth = this.state.currentMonth
            .clone()
            .subtract(1, 'month')
            .format(headerMonthFormat);
        let nextMonth = this.state.currentMonth
            .clone()
            .add(1, 'month')
            .format(headerMonthFormat);
        let loadingContent = (
            <div className="loader-container">
                <SVG name="SmallLoader" size="lg" />
                <div className="loader-text">{this.props.loadingText}</div>
            </div>
        );
        let showLoading = this.props.loading
            ? loadingContent
            : this._getWeeks();
        return (
            <div className="di-almanac" ref="diAlmanac">
                <div className="almanac-header">
                    <span
                        className="sr-only"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        {this.state.currentMonth.format(headerMonthFormat)}
                    </span>
                    <span className="section">
                        <a
                            href="javascript:void(0)"
                            ref="previousMonthLink"
                            aria-label={
                                this._getString('label.prevMonth') +
                                ' ' +
                                prevMonth
                            }
                            className="icon left"
                            role="link"
                            onKeyDown={this._prevKeyDown.bind(this)}
                            onClick={this._getPreviousMonth}
                        >
                            <SVG name="Back" />
                        </a>
                    </span>
                    <span className={'section' + monthClass}>
                        {this.state.currentMonth.format(headerMonthFormat)}
                    </span>
                    <span className="section">
                        <a
                            href="javascript:void(0)"
                            aria-label={
                                this._getString('label.nextMonth') +
                                ' ' +
                                nextMonth
                            }
                            className="icon right"
                            role="link"
                            onKeyDown={this._nextKeyDown.bind(this)}
                            onClick={this._getNextMonth}
                        >
                            <SVG name="Forward" />
                        </a>
                    </span>
                </div>
                <div>
                    <table role="table" className="days">
                        <thead className="list">
                            <tr role="row">
                                {this._buildWeekdaysHeaderItems()}
                            </tr>
                        </thead>
                        <tbody className="weeks">{showLoading}</tbody>
                    </table>
                </div>
                {this.props.loading ? null : (
                    <div
                        className={
                            'footer' + (this.props.footer ? '' : ' empty')
                        }
                    >
                        {this.props.footer}
                    </div>
                )}
            </div>
        );
    }

    _buildWeekdaysHeaderItems() {
        let headerItems = [];

        for (let i = 0; i < 7; i++) {
            headerItems.push(
                <th key={i} className="day-label" role="columnheader">
                    <span className="sr-only">{moment.weekdays(i)}</span>
                    <span aria-hidden="true">
                        {moment.weekdaysMin(true, i)}
                    </span>
                </th>,
            );
        }

        return headerItems;
    }

    _prevKeyDown(event) {
        event.stopPropagation();
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            this._getPreviousMonth();
        } else if (event.key === 'Tab' && event.shiftKey) {
            if (this.props.manualEntryEnabled) {
                //focus calendar icon when shift tabbing out of the calendar
                this.props.focusManagementForAlmanacPreviousMonthKeyDown();
            }
        }
    }

    _nextKeyDown(event) {
        event.stopPropagation();
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            this._getNextMonth();
        }
    }
    _selectDay(date) {
        this.setState({ selectedValue: date, focusedDate: date });
        if (this.props.onSelect) {
            this.props.onSelect(date);
        }

        if (!this.props.iconFocussable) {
            if (this.props.onDateFocus) {
                setTimeout(() => {
                    this.props.onDateFocus(date);
                });
            }
        } else {
            if (this.props.onDateIconFocussable) {
                this.props.onDateIconFocussable();
            }
        }
    }

    _getString(key) {
        // TODO Add legitimate localization
        return _i18nDefaultStrings[key] || '';
    }

    _getPreviousMonth() {
        var month = this.state.currentMonth;
        month.subtract(1, 'month');
        this._setMonth(month);

        if (this._isSelectedDayInCurrentMonth()) {
            this.setState({ focusedDate: this.state.selectedValue });
        } else {
            this.setState({ focusedDate: month.startOf('month') });
        }
    }

    _getNextMonth() {
        var month = this.state.currentMonth;
        month.add(1, 'month');
        this._setMonth(month);

        if (this._isSelectedDayInCurrentMonth()) {
            this.setState({ focusedDate: this.state.selectedValue });
        } else {
            this.setState({ focusedDate: month.startOf('month') });
        }
    }

    _setMonth(month) {
        this.setState({ currentMonth: month });
        if (this.props.onMonthChange) {
            setTimeout(() => {
                this.props.onMonthChange(month, this.state.selectedValue);
            });
        }
    }

    _isHoliday(date) {
        let holidays = this.props.holidays;
        let isHoliday = false;
        if (holidays) {
            for (let i = 0; i < holidays.length; i++) {
                isHoliday = this._isDateEqual(moment(holidays[i]), date);
                if (isHoliday) {
                    break;
                }
            }
        }
        return isHoliday;
    }

    _getWeeks() {
        var weeks = [],
            complete = false,
            date = this.state.currentMonth
                .clone()
                .add('w' - 1)
                .weekday(0),
            monthIndex = date.month(),
            weekNumber = 0,
            today = moment(),
            rangeDates = this._prepareRange();

        while (!complete) {
            weeks.push(
                <tr role="row" key={weekNumber} className="week">
                    {this._getWeekDays(
                        today,
                        date.clone(),
                        rangeDates,
                        weekNumber,
                    )}
                </tr>,
            );
            date.add(1, 'w');
            complete = weekNumber++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();
        }
        return weeks;
    }

    _getWeekDays(today, date, rangeDates, weekNumber) {
        const daysPerWeek = 7;
        let disableWeekends = this.props.disableWeekends;
        let key = 0;
        let days = [];

        for (let dayNumber = 0; dayNumber < daysPerWeek; dayNumber++) {
            let isToday = this._isDateEqual(today, date);
            let isSelected = this._isDateEqual(this.state.selectedValue, date);
            let isFocused = this._isDateEqual(this.state.focusedDate, date);
            let isAfterMaxEndDate = false;
            let isBeforeMaxStartDate = false;
            let isFiltered = false;

            if (this.state.maxStartDate) {
                let maxSDate = moment(this.state.maxStartDate);
                isBeforeMaxStartDate =
                    !this._isDateEqual(maxSDate, date) &&
                    maxSDate.valueOf() > date.valueOf();
            }
            if (this.state.maxEndDate) {
                let maxEDate = moment(this.state.maxEndDate);
                isAfterMaxEndDate =
                    !this._isDateEqual(maxEDate, date) &&
                    maxEDate.valueOf() < date.valueOf();
            }
            if (this.props.dateFilter) {
                isFiltered = !this.props.dateFilter(date);
            }

            let isHoliday = this._isHoliday(date);
            let isWeekend = date.day() === 0 || date.day() === 6;

            let className = 'day wd' + date.day() + ' d' + date.date();
            className = className + (isSelected ? ' selected' : '');
            className =
                className + (isBeforeMaxStartDate ? ' before-max-start' : '');
            className = className + (isAfterMaxEndDate ? ' after-max-end' : '');
            className =
                className +
                (disableWeekends && isWeekend ? ' weekend' : ' weekday');
            className = className + (isHoliday ? ' holiday' : '');

            if (rangeDates) {
                className = className + this._getRangeClasses(date, rangeDates);
            }

            let onClickDate;
            let isSelectable;
            if (
                !isBeforeMaxStartDate &&
                !isAfterMaxEndDate &&
                !(disableWeekends && isWeekend) &&
                !isHoliday &&
                !isFiltered
            ) {
                className = className + ' selectable';
                onClickDate = this._selectDay.bind(this, date.clone());
                isSelectable = true;
            } else {
                className = className + ' unselectable';
                isSelectable = false;
            }

            let tabIndex = isFocused ? '0' : '-1';

            let dayPosition = weekNumber.toString() + dayNumber.toString();
            let monthFormat = this.props.headerMonthFormat
                ? this.props.headerMonthFormat
                : 'MMMM YYYY';
            let weekDaysString =
                date.format(monthFormat) +
                ' ' +
                moment.weekdays(dayNumber) +
                ' ' +
                date.date();
            days.push(
                <td
                    ref={dayPosition}
                    role="cell"
                    tabIndex={isSelected ? 0 : tabIndex}
                    aria-label={
                        isSelectable
                            ? weekDaysString
                            : this._getString('label.helpTextForDisabledDate') +
                              ' ' +
                              weekDaysString
                    }
                    key={key++}
                    className={
                        className + ' no' + (isToday ? ' current-day' : '')
                    }
                    onClick={onClickDate}
                    onKeyDown={this._arrowKeyNavigationHandler.bind(
                        this,
                        date.clone(),
                        isSelectable,
                    )}
                >
                    {date.date()}
                </td>,
            );

            date.add(1, 'd');
        }

        return days;
    }

    _arrowKeyNavigationHandler(date, isSelectable, event) {
        let nextDate = null;
        let nextPosition = null;
        event.stopPropagation();
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                nextDate = date.clone().subtract(1, 'week');
                break;
            case 'ArrowDown':
                event.preventDefault();
                nextDate = date.clone().add(1, 'week');
                break;
            case 'ArrowLeft':
                event.preventDefault();
                nextDate = date.clone().subtract(1, 'day');
                break;
            case 'ArrowRight':
                event.preventDefault();
                nextDate = date.clone().add(1, 'day');
                break;
            case 'Escape':
                if (this.props.onResetFocus && this.props.initialValue) {
                    event.preventDefault();
                    this.props.onResetFocus();
                    this.setState({ focusedDate: this.props.initialValue });
                }
                break;
            case 'Enter':
            case ' ':
                event.preventDefault();
                if (isSelectable) {
                    this._selectDay(date);
                }
                break;
        }

        if (nextDate) {
            this.setState({ focusedDate: nextDate });
            nextPosition = this._convertDateToCalendarPosition(nextDate);
            if (
                nextPosition &&
                nextDate.isSame(this.state.currentMonth, 'month')
            ) {
                if (this.refs[nextPosition]) {
                    this.refs[nextPosition].focus();
                }
            } else {
                this._setMonth(nextDate.clone().startOf('month'));
                setTimeout(() => {
                    nextPosition = this._convertDateToCalendarPosition(
                        nextDate,
                    );
                    if (this.refs[nextPosition]) {
                        this.refs[nextPosition].focus();
                    }
                });
            }

            if (this.props.onDateFocus) {
                setTimeout(() => {
                    this.props.onDateFocus(nextDate);
                });
            }
        }
    }

    _isSelectedDayInCurrentMonth() {
        return this.state.selectedValue.isSame(
            this.state.currentMonth,
            'month',
        );
    }

    _isDateFirstDayOfCurrentMonth(date) {
        return this.state.currentMonth
            .clone()
            .startOf('month')
            .isSame(date, 'day');
    }

    _convertDateToCalendarPosition(date) {
        let startOfCurrentMonth = this.state.currentMonth
            .clone()
            .startOf('month');
        let endOfCurrentMonth = startOfCurrentMonth.clone().endOf('month');
        let currentMonthStartColumn = startOfCurrentMonth.weekday();
        let currentMonthEndColumn = endOfCurrentMonth.weekday();
        let firstCalendarDate = startOfCurrentMonth
            .clone()
            .subtract(currentMonthStartColumn, 'days');
        let lastCalendarDate = endOfCurrentMonth
            .clone()
            .add(7 - 1 - currentMonthEndColumn, 'days');

        if (date.isBetween(firstCalendarDate, lastCalendarDate, null, '[]')) {
            let difference = date.diff(firstCalendarDate, 'days');
            let row = Math.floor(difference / 7);
            let column = difference % 7;
            return row.toString() + column.toString();
        } else {
            return null;
        }
    }

    _getRangeClasses(date, range) {
        let inRange = false;
        let rangeNames = ' range';
        let index = 0;

        for (; index < range.length; index++) {
            inRange = this._isDateEqual(range[index], date);
            if (inRange) {
                break;
            }
        }
        rangeNames = rangeNames + ' rn' + index;

        if (index === range.length - 1) {
            rangeNames = rangeNames + ' rnl';
        }

        return inRange ? rangeNames : '';
    }
    _prepareRange() {
        if (!this.props.selectRange) {
            return undefined;
        }
        let daysToSelect = this.props.selectRange
            ? this.props.selectRange.count
            : undefined;
        let startFrom = this.props.selectRange
            ? moment(this.props.selectRange.start)
            : undefined;
        let endTo = this.props.selectRange
            ? moment(this.props.selectRange.end)
            : undefined;
        let range = [];

        if (startFrom && (daysToSelect || endTo)) {
            range.push(startFrom.clone());
            if (daysToSelect) {
                let limit = daysToSelect > 0 ? daysToSelect : daysToSelect * -1;
                for (let i = 1; i < limit; i++) {
                    if (daysToSelect > 0) {
                        startFrom.add(1, 'd');
                    } else {
                        startFrom.subtract(1, 'd');
                    }

                    range.push(startFrom.clone());
                }

                if (daysToSelect < 0) {
                    // Compare dates to sort
                    let compare = function compareMilli(a, b) {
                        if (a.valueOf() < b.valueOf()) {
                            return -1;
                        }
                        if (a.valueOf() > b.valueOf()) {
                            return 1;
                        }
                        return 0;
                    };
                    range.sort(compare);
                }
            } else if (!this._isDateEqual(startFrom, endTo)) {
                do {
                    startFrom.add(1, 'd');
                    range.push(startFrom.clone());
                } while (endTo.valueOf() > startFrom.valueOf());
            }
        }

        return range;
    }

    _isDateEqual(d1, d2) {
        return d1.format('YYYY MM DD') === d2.format('YYYY MM DD');
    }
}
