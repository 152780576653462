import axios from 'axios';
import Constants from '../constants/Constants';

export default class LoggingService {
    /**
     * Logs a failed ID check
     * @param context   Context to use for the logging URL
     * @param idOriginalLog OriginalID to be verified
     * @param idResponseLog ID received in the response
     * @param loggingURL        Optional logging URL that can accept a POST and these parameters
     * @returns {*|axios.Promise}
     */
    static logVerifyIDError(context, idOriginalLog, idResponseLog, loggingURL) {
        return axios({
            url: `${context}${loggingURL || Constants.VERIFY_ID_LOGGING_URL}`,
            params: {
                idOriginal: idOriginalLog,
                idResponse: idResponseLog,
            },
            method: 'POST',
        });
    }
}
