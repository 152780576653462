/* eslint-disable no-param-reassign,no-var,vars-on-top,padding-line-between-statements,no-plusplus,consistent-return,prefer-const,no-useless-escape,one-var,prefer-template,prefer-destructuring,eqeqeq,no-unused-vars,no-empty */
import LoggingService from '../services/LoggingService';
import Constants from '../constants/Constants';

/* global di:false */

export default class HelperFunctions {
    /**
     * Retrieves a JSON object defined by JSON string dot notation.
     */
    static getObjectByDotNotation(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    /**
     * Attaches a link element to the DOM to load the specified resource URL.
     */
    static loadCSSResource(resourceUrl) {
        if (resourceUrl) {
            // Check if we've already loaded the CSS URL on the page.
            let isAlreadyPresent = false;
            for (var i = 0; i < document.styleSheets.length; i++) {
                let stylesheet = document.styleSheets[i];
                if (resourceUrl === stylesheet.href) {
                    isAlreadyPresent = true;
                }
            }

            // Only add the CSS link to the page if it's not there already.
            if (!isAlreadyPresent) {
                var link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = resourceUrl;

                var head = document.getElementsByTagName('head')[0];
                head.appendChild(link);
            }
        }
    }

    static loadCSSResources(resourceUrls) {
        if (Array.isArray(resourceUrls)) {
            resourceUrls.forEach((resourceUrl) => {
                this.loadCSSResource(resourceUrl);
            });
        } else {
            console.error(
                'Parameter to HelperFunctions.loadCSSResources needs to be an Array.',
            );
        }
    }

    /**
     * Helper function to parse URL parameters and retrieve the value from the specified
     * parameter name in the URL.
     * Solution copied from the following stackoverflow post:
     * http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
     */
    static getUrlParameterValueByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i'),
            results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /*
     * Helper function to calculate the width of a string in pixels
     * Solution copied from the following stackoverflow post:
     * http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
     */
    static getWidthOfText(txt, fontname, fontsize) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.font = fontsize + 'px ' + fontname;
        var width = ctx.measureText(txt).width;
        return width;
    }

    /**
     * Parses the document cookies looking for the passed in cookie name
     * @since CommonUI 1.5
     * @param cookieName    Name of the cookie to look for in the document cookies
     * @returns {*} Value of the cookie if it exists, undefined otherwise
     */
    static readCookie(cookieName) {
        cookieName += '=';
        for (
            var cookies = document.cookie.split(/;\s*/), i = cookies.length - 1;
            i >= 0;
            i--
        ) {
            if (!cookies[i].indexOf(cookieName)) {
                return cookies[i].replace(cookieName, '');
            }
        }
    }

    /**
     * Helper function to verify the provided ID against the value present in the cookie.
     * @since CommonUI 1.5
     * @param idFromResponse    ID to verify (usually obtained from AJAX response header)
     * @param loggingURL        Optional logging URL that can accept a POST and these parameters
     * @returns {boolean}       true if the ID matches the one in cookie, false otherwise
     *
     */
    static verifyId(idFromResponse, loggingURL) {
        if (idFromResponse) {
            try {
                var originalId = HelperFunctions.readCookie('USPIBID');
                if (originalId) {
                    if (idFromResponse.indexOf(originalId) == -1) {
                        if (di && di.env && di.env.context) {
                            LoggingService.logVerifyIDError(
                                di.env.context,
                                originalId,
                                idFromResponse,
                                loggingURL,
                            )
                                .then((response) => {})
                                .catch((ex) => {});
                            window.top.location.href = `${di.env.context}${Constants.LOGOUT_URL}?reason=logout`;
                        } else {
                            return false;
                        }
                    }
                }
            } catch (e) {}
        }
        return true;
    }
}
