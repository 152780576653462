/* eslint-disable react/prop-types,no-plusplus,jsx-a11y/no-redundant-roles,react/prefer-stateless-function */
import React, { Component } from 'react';
import MfaDropdown from '../../mfa/components/MfaDropdown';

export default class MfaNoCodeDropdown extends Component {
    render() {
        const possibleReasons = [];
        let i;

        for (i = 0; i < this.props.reasons.length; i++) {
            possibleReasons.push(
                <li key={i}>
                    <div>{this.props.reasons[i]}</div>
                </li>,
            );
        }

        return (
            <div className="mfa-no-code-dropdown mfa-indent">
                {this.props.reasons.length ? (
                    <MfaDropdown
                        title={this.props.labels.didntGetCode}
                        id="didntGetCode"
                    >
                        <div
                            className="mfa-dropdown-content"
                            id="didntGetCodeContent"
                        >
                            <div>{this.props.labels.ensure}</div>
                            <ul>{possibleReasons}</ul>
                            <div className="mfa-dropdown-link">
                                {this.props.showDifferentMethodLink ? (
                                    <a
                                        href={
                                            './step-up?forAppCode=' +
                                            this.props.forAppCode +
                                            '&actionId=' +
                                            this.props.actionId +
                                            '&reason=' +
                                            this.props.reason
                                        }
                                        role="link"
                                        aria-label={
                                            this.props.labels.tryDifferentMethod
                                        }
                                    >
                                        {this.props.labels.tryDifferentMethod}
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </MfaDropdown>
                ) : (
                    <div className="di-accordion-selector">
                        {this.props.showDifferentMethodLink ? (
                            <a
                                href={
                                    './step-up?forAppCode=' +
                                    this.props.forAppCode +
                                    '&actionId=' +
                                    this.props.actionId +
                                    '&reason=' +
                                    this.props.reason
                                }
                                role="link"
                                aria-label={
                                    this.props.labels.tryDifferentMethodAlt
                                }
                            >
                                {this.props.labels.tryDifferentMethodAlt}
                            </a>
                        ) : null}
                    </div>
                )}
            </div>
        );
    }
}
