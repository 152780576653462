/* eslint-disable react/prefer-stateless-function,react/destructuring-assignment,react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/SlideTransitionGroup.scss';

import SlideTransition from './SlideTransition';

export default class SlideTransitionGroup extends Component {
    render() {
        return (
            <div>
                <SlideTransition
                    depth={this.props.depth}
                    className={styles['slider-items-container']}
                >
                    {this.props.children}
                </SlideTransition>
            </div>
        );
    }
}

SlideTransitionGroup.propTypes = {
    depth: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};
