import Fetch from 'fetch';

export default {
    getAppConfig() {
        return Fetch.get('/site-olb/v1/config/app').then((appConfig) => {
            const { env = {}, css = {} } = appConfig;

            return {
                staticResourcesContext: process.env.REACT_APP_CONTEXT,
                helpLocationHref: `${process.env.REACT_APP_CONTEXT}static/html/login_help.html`,

                version: env.buildNumber,
                ...env,

                ...css,

                uiPerformanceLogEnabled: env.uiperformanceEnabled,
                cdiFingerprintEnabled: true,
                analyticsEnabled: appConfig.analyticsEnabled,
                quickLinkEnabled: appConfig.quickLinkEnabled,
                mobileFlow: appConfig.mobileFlow,
                bioCatchConfig: appConfig.bioCatchConfig,
                modernBrandingEnabled: appConfig.modernBrandingEnabled,
                nextGenBaseUrl: appConfig.nextGenBaseUrl,
                nextGenUIPlatformBaseUrl: appConfig.nextGenUIPlatformBaseUrl,
                logoutUrl: '/app/logout',
                defaultLoginUrl: '/app/login',
                consumerLoginUrl: '/app/login/consumer',
                businessLoginUrl: '/app/login/business',
                whiteListDomains: appConfig.whiteListDomains,
                callSignConfig: appConfig.callSignConfig,
                noIndexMeta: appConfig.noIndexMeta,
            };
        });
    },
};
