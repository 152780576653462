export default function Paper() {
    return {
        MuiPaper: {
            defaultProps: {
                variant: 'elevation',
                elevation: 0,
            },

            styleOverrides: {
                root: ({ elevation }) => ({
                    ...(elevation === 0 && {
                        boxShadow: '0px 4px 20px rgb(27 53 93 / 5%)',
                    }),
                }),
            },
        },
    };
}
