/* eslint-disable import/prefer-default-export */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

export const VStack = forwardRef(
    ({ justify = 'center', align = 'center', children, ...props }, ref) => {
        return (
            <Stack
                ref={ref}
                justifyContent={justify}
                alignItems={align}
                {...props}
                direction="column"
            >
                {children}
            </Stack>
        );
    },
);

VStack.propTypes = {
    justify: PropTypes.string,
    align: PropTypes.string,
    children: PropTypes.node,
};

export const HStack = forwardRef(
    ({ justify = 'center', align = 'center', children, ...props }, ref) => {
        return (
            <Stack
                ref={ref}
                justifyContent={justify}
                alignItems={align}
                {...props}
                direction="row"
            >
                {children}
            </Stack>
        );
    },
);

HStack.propTypes = {
    justify: PropTypes.string,
    align: PropTypes.string,
    children: PropTypes.node,
};
