/**
 * # Flex - MUI Box with display property set to flex
 */
/* eslint-disable import/prefer-default-export */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

export const Flex = forwardRef(
    (
        {
            direction,
            align = 'center',
            justify = 'center',
            wrap,
            basis,
            grow,
            shrink,
            ...props
        },
        ref,
    ) => {
        return (
            <Box
                ref={ref}
                flexDirection={direction}
                alignItems={align}
                justifyContent={justify}
                flexWrap={wrap}
                flexBasis={basis}
                flexGrow={grow}
                flexShrink={shrink}
                {...props}
                display="flex"
            />
        );
    },
);

Flex.propTypes = {
    direction: PropTypes.string,
    align: PropTypes.string,
    justify: PropTypes.string,
    wrap: PropTypes.string,
    basis: PropTypes.string,
    grow: PropTypes.string,
    shrink: PropTypes.string,
};
