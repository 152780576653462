/* eslint-disable filenames/match-exported,import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment,react/no-unknown-property */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Accounts extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 77 52"
                enableBackground="new 0 0 20 20"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>

                <g
                    id="Card-management-zero-state"
                    stroke="none"
                    stroke-width="1"
                    fill="currentColor"
                    fill-rule="evenodd"
                    transform="translate(-697.000000, -366.000000)"
                >
                    <path
                        d="M770.815935,372.913975 L696.236906,383.395374 L694.79318,373.122732 C694.716477,372.576961
                    695.092271,372.072973 695.652173,371.994283 L768.235449,361.793369 C768.786561,361.715916 769.294812,
                    362.090629 769.372209,362.641333 L770.815935,372.913975 Z M771.938942,380.904586 L776.20682,
                    411.272119 C776.283523,411.81789 775.907729,412.321878 775.347827,412.400568 L702.764551,422.601482
                    C702.213439,422.678935 701.705188,422.304222 701.627791,421.753518 L697.359913,391.385985
                    L771.938942,380.904586 Z"
                        id="card-control-icon"
                        transform="translate(735.500000, 392.197426) rotate(8.000000) translate(-735.500000, -392.197426) "
                    />
                </g>
            </svg>
        );
    }
}
