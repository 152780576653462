import { useLayoutEffect } from 'react';

export const useWindowResize = (callback) => {
    useLayoutEffect(() => {
        // Add event listener
        window.addEventListener('resize', callback);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', callback);
    }, []);
};
