export default class BioCatchSDKManager {
    static cdApi;

    static config;

    static sessionId;

    static currentContext;

    static start(sessionId, config) {
        this.config = config;
        this.cdApi = window.cdApi;
        this.updateSession(sessionId);
    }

    static updateSession(sessionId) {
        if (this.cdApi) {
            this.sessionId = sessionId;
            this.cdApi.setCustomerSessionId(this.sessionId);
        }
    }

    static changeContext(contextName) {
        if (this.cdApi && contextName !== this.currentContext) {
            this.cdApi.changeContext(contextName);
            this.currentContext = contextName;
        }
    }

    static getSSOContext(ssoUrl) {
        const { ssoContext } = this.config;

        if (ssoContext && ssoUrl) {
            const matchedKey = Object.keys(ssoContext).find((matchKey) =>
                ssoUrl.toLowerCase().includes(matchKey.toLowerCase()),
            );

            return ssoContext[matchedKey];
        }

        return undefined;
    }
}
