/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme, bgcolor }) => ({
    backgroundColor: bgcolor ?? theme.palette.grey[100],
    position: 'relative',
    borderRadius: '5px',
    margin: '8px',
    padding: '4%',
}));

export function IconBox({ bgcolor, ...rest }) {
    return (
        <StyledBox
            display="flex"
            justify="center"
            bgcolor={bgcolor}
            {...rest}
        />
    );
}

IconBox.propTypes = {
    bgcolor: PropTypes.string,
};
