import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Fetch from 'fetch';

class AppLoaderErrorBoundary extends Component {
    static defaultProps = {
        customerSupportContact: undefined,
        errorCode: undefined,
        serviceUnAvailableMessage:
            'We are temporarily experiencing technical difficulties; try again later. If the problem persists contact us at ',
        defaultMessage:
            'This page is temporarily unavailable. Please try again later. If the problem persists, contact customer support.',
        errorCodeMessageStart: '(Error Code: ',
        errorCodeMessageEnd: ')',
    };

    static propTypes = {
        customerSupportContact: PropTypes.string,
        errorCode: PropTypes.string,
        serviceUnAvailableMessage: PropTypes.string,
        defaultMessage: PropTypes.string,
        errorCodeMessageStart: PropTypes.string,
        errorCodeMessageEnd: PropTypes.string,
    };

    state = {};

    static getDerivedStateFromError(e) {
        console.log('Error loading App', e);

        let stack = '<undefined>';
        let message = '<undefined>';
        let errString = '<undefined>';

        try {
            if (e) {
                stack = e.stack || '<no_error>';
                message = e.message || '<no_message>';

                if (e.toString) {
                    errString = e.toString();
                }
            }
            const logURL = `/site-olb/v1/logForMAISNavigationAppNotFound?epoxy-ui-error=true`;
            Fetch.postFormData(logURL, {
                stack,
                message,
                errString,
            }).catch((ex) => {
                console.log('Error in logMarketingErrorMessage', ex);
            });
        } catch (ex) {} // eslint-disable-line

        return { hasError: true };
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <React.Fragment>
                    <Helmet>
                        <link
                            rel="stylesheet"
                            href={`${process.env.REACT_APP_CONTEXT}loading/main.css`}
                        />
                        <title>Error</title>
                    </Helmet>
                    <div id="container" className="app-loading-error">
                        <div id="main" className="wrapper">
                            <div className="formLayout">
                                <h1>Something Went Wrong</h1>
                                <p id="errorInfo">
                                    {this.renderErrorMessage()}
                                </p>
                                <img
                                    src={`${process.env.REACT_APP_CONTEXT}static/images/class_errors_1000.svg`}
                                    alt="Error"
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return children;
    }

    renderErrorMessage() {
        const {
            customerSupportContact,
            serviceUnAvailableMessage,
            errorCode,
            defaultMessage,
            errorCodeMessageStart,
            errorCodeMessageEnd,
        } = this.props;

        if (customerSupportContact) {
            return `${serviceUnAvailableMessage}${customerSupportContact}`;
        }
        const errorMessage = errorCode
            ? `${errorCodeMessageStart}${errorCode}${errorCodeMessageEnd}`
            : '';

        return `${defaultMessage}${errorMessage}`;
    }
}

export default AppLoaderErrorBoundary;
