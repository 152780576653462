/* eslint-disable padding-line-between-statements,react/destructuring-assignment,prefer-template,prefer-const,react/prop-types,jsx-a11y/click-events-have-key-events,no-underscore-dangle,jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control,dot-notation,react/button-has-type,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from '../images/SVG';
import styles from '../../styles/SlideTransitionGroup.scss';

export default class SlideTransitionItem extends Component {
    constructor(props) {
        super(props);
        this.focusSlideTransitionButton = this.focusSlideTransitionButton.bind(
            this,
        );
    }

    render() {
        let slideTransitionItemErrorClass = '';
        if (this.props.showError) {
            slideTransitionItemErrorClass =
                ' ' + styles['slide-transition-item-error'];
        }

        let disabledStr = this.props.disabled ? 'disabled' : '';
        let id = this.props.id ? this.props.id : null;

        return (
            <div
                className={'form-group ' + styles['slider-transition-item']}
                onClick={
                    this.props.showLoading
                        ? null
                        : this._handleOnClick.bind(this)
                }
            >
                <div className={'input-group ' + slideTransitionItemErrorClass}>
                    <label
                        className={
                            styles['input-label'] +
                            (this.props.labelClassName || '') +
                            ' input-label'
                        }
                    >
                        {this.props.label}
                    </label>

                    <div className={styles['input']}>
                        {this.props.showLoading ? (
                            <div
                                className={
                                    styles['slide-transition-item-loading-div']
                                }
                            >
                                <span>
                                    <SVG name="SmallLoader" />
                                </span>
                                &nbsp;
                                {this.props.loadingMessage}
                            </div>
                        ) : (
                            <span>
                                {this.props.children ? (
                                    <div
                                        id={id}
                                        className={
                                            styles[
                                                'slide-transition-item-component-div'
                                            ]
                                        }
                                        role="button"
                                        aria-haspopup="listbox"
                                        tabIndex="0"
                                        ref={(selectedItem) => {
                                            this.focusElement = selectedItem;
                                        }}
                                    >
                                        {this.props.children}
                                    </div>
                                ) : (
                                    <button
                                        readOnly
                                        disabled={disabledStr}
                                        className={
                                            'form-control ' +
                                            styles['form-control']
                                        }
                                        value={this.props.value}
                                        ref={(selectedItem) => {
                                            this.focusElement = selectedItem;
                                        }}
                                    >
                                        {this.props.value}
                                    </button>
                                )}
                            </span>
                        )}
                    </div>
                </div>
                {this.props.showError && this.props.errorMessage ? (
                    <div
                        className={
                            'text-danger ' +
                            styles['slide-transiton-item-error-div']
                        }
                    >
                        <SVG name="AlertError" /> {this.props.errorMessage}
                    </div>
                ) : null}
            </div>
        );
    }

    _handleOnClick() {
        if (this.props.navDown && !this.props.disabled) {
            this.props.navDown();
        }
    }

    focusSlideTransitionButton() {
        if (this.focusElement) {
            this.focusElement.focus();
        }
    }
}

SlideTransitionItem.propTypes = {
    label: PropTypes.string,
    showError: PropTypes.bool,
    showLoading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    navDown: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    labelClassName: PropTypes.string,
};
