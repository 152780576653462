import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

import { green, grey } from '@mui/material/colors';

import BrowserRefreshHandler from '../../handlers/browserRefreshHandler';

import { useModernBranding } from '../../contexts';

export const LookSwitch = ({ label }) => {
    const { modernBrandingEnabled, toggleMuiBranding } = useModernBranding();

    const toggleLook = () => {
        toggleMuiBranding();
        BrowserRefreshHandler.refresh();
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                pr: '5px',
                whiteSpace: 'nowrap',
            }}
        >
            <Switch
                id="look-switcher"
                checked={modernBrandingEnabled}
                onChange={toggleLook}
                onColor={green[500]}
                offColor={grey[400]}
                handleDiameter={12}
                uncheckedIcon={false}
                checkedIcon={false}
                height={16}
                width={30}
                ariaLabel={label}
            />
            <span style={{ paddingLeft: '10px' }}>{label}</span>
        </div>
    );
};

LookSwitch.propTypes = {
    label: PropTypes.string.isRequired,
};

export default {};
