/* eslint-disable no-unused-vars,import/no-useless-path-segments,import/order,import/newline-after-import,no-var,react/destructuring-assignment,react/prop-types,jsx-a11y/no-redundant-roles,jsx-a11y/anchor-is-valid,no-underscore-dangle,padding-line-between-statements */
import React, { Component } from 'react';
import MfaActions from './../actions/MfaActions';
import { BrowserDetect } from 'common-ui/src/util/module';
export default class MfaTextWithLink extends Component {
    render() {
        var textArray = this.props.text.split('{0}');

        return (
            <span>
                <span>{textArray[0]}</span>
                <a
                    href="#"
                    onClick={this._handleClick.bind(this)}
                    role="link"
                    aria-label={this.props.link}
                >
                    {this.props.link}
                </a>
                <span>{textArray[1]}</span>
            </span>
        );
    }

    _handleClick(event) {
        event.preventDefault();
        if (document.getElementById('mfaCodeInputField')) {
            document.getElementById('mfaCodeInputField').focus();
        }
        this.props.onClick.call();
    }
}
