/* eslint-disable import/order,react/destructuring-assignment,react/sort-comp,react/no-string-refs,prefer-template,react/static-property-placement */
import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styles from '../../styles/SlideTransitionGroup.scss';
import PropTypes from 'prop-types';

export default class SlideTransition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: 'right',
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const direction = newProps.depth > this.props.depth ? 'right' : 'left';
        this.setState({ direction });
    }

    componentDidUpdate(prevProps) {
        if (
            this.refs.groupContainer &&
            prevProps.depth != null &&
            prevProps.depth !== this.props.depth
        ) {
            this.refs.groupContainer.scrollIntoView();
        }
    }

    render() {
        // Remove depth from props since React 15.x complains about any invalid props on HTML components
        const { depth, ...copyProps } = this.props;
        const outerProps = {
            className: styles['slider-outer-wrapper'],
        };
        const transProps = {
            component: 'div',
            transitionName: {
                enter: styles['slider-' + this.state.direction + '-enter'],
                leave: styles['slider-' + this.state.direction + '-leave'],
            },
        };
        const innerProps = {
            ref: 'inner',
            key: depth,
            className: styles['slider-inner-wrapper'],
        };

        return (
            <div ref="groupContainer" {...copyProps} {...outerProps}>
                <ReactCSSTransitionGroup
                    {...transProps}
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={300}
                >
                    <div {...innerProps}>{this.props.children}</div>
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

SlideTransition.propTypes = {
    depth: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};
