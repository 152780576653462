/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

export const ArrowDropDown = ({ color, ...rest }) => {
    return (
        <SvgIcon
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M0.710051 1.71L3.30005 4.3C3.69005 4.69 4.32005 4.69 4.71005 4.3L7.30005 1.71C7.93005 1.08 7.48005 0 6.59005 0H1.41005C0.520051 0 0.0800515 1.08 0.710051 1.71Z"
                fill={color}
            />
        </SvgIcon>
    );
};

ArrowDropDown.propTypes = {
    color: PropTypes.string,
};
