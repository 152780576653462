/* eslint-disable no-underscore-dangle,camelcase,prefer-const,react/destructuring-assignment,react/prop-types,prefer-template,padding-line-between-statements,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-noninteractive-tabindex,react/no-string-refs,object-shorthand,eqeqeq,lines-between-class-members,class-methods-use-this,spaced-comment,no-unneeded-ternary,react/static-property-placement,react/boolean-prop-naming,react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const _defaultBundle_en_US = {
    'comp.toggleSwitch.label.on': 'ON',
    'comp.toggleSwitch.label.off': 'OFF',
};
export default class ToggleSwitch extends Component {
    constructor(props) {
        super(props);

        let defaultConfig = {
            isActive: props.defaultActive ? props.defaultActive : false,
        };

        this.state = defaultConfig;

        // Bind this to internal functions
        this._onClick = this._onClick.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
    }

    render() {
        let small = this.props.isSmall ? ' sm' : '';
        let active = this.state.isActive ? ' active' : '';
        let disabled = this.props.disabled ? ' disabled' : '';
        let className = this.props.className ? this.props.className + ' ' : '';
        let labelOn = this._getString(this.props, 'label.on');
        let labelOff = this._getString(this.props, 'label.off');
        let labelId = this.state.isActive ? 'labelOn' : 'labelOff';
        let buttonActive = this.state.isActive ? ' active' : 'inactive';
        let labelActive = this.state.isActive ? 'Active' : '';
        let labelInActive = this.state.isActive ? '' : 'InActive';
        if (this.props.columnName && this.state.isActive) {
            labelActive = this.props.columnName + labelActive;
        } else {
            labelInActive = this.props.columnName + labelInActive;
        }
        let labelOnSpanId = 'labelOn';
        let labelOffSpanId = 'labelOff';
        if (this.props.id) {
            labelOnSpanId += this.props.id;
            labelOffSpanId += this.props.id;
            labelId += this.props.id;
        }

        // DO NOT Remove the "&nbsp;" before & after the 'label-slider' span element.
        // This is due to to support describes the number of characters (glyphs) that
        // were produced from the given font. React rendering remove the indenting for
        // glyphs by rendering markup in a single line.

        return (
            <div className={className + 'di-toggle-switch-container' + small}>
                <div
                    className={'di-toggle-switch' + active + disabled}
                    role="application"
                    aria-labelledby={
                        this.props.ariaLabelledby
                            ? this.props.ariaLabelledby + ' ' + labelId
                            : labelId
                    }
                    onClick={this._onClick}
                    onKeyDown={this._onKeyDown}
                    tabIndex="0"
                >
                    <span
                        className="label-on "
                        id={labelOnSpanId}
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <span className="sr-only">{labelActive}</span>
                        {labelOn}
                    </span>
                    &nbsp;
                    <span
                        className="label-slider"
                        ref="toggleSlider"
                        role="button"
                        aria-label={buttonActive}
                    >
                        {' '}
                    </span>
                    &nbsp;
                    <span
                        className="label-off "
                        id={labelOffSpanId}
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <span className="sr-only">{labelInActive}</span>
                        {labelOff}
                    </span>
                    {!this.state.isActive && this.props.changed ? (
                        <span
                            className="sr-only"
                            aria-live="assertive"
                            role="alert"
                        >
                            {labelOff}
                        </span>
                    ) : null}
                    {this.state.isActive && this.props.changed ? (
                        <span
                            className="sr-only"
                            aria-live="assertive"
                            role="alert"
                        >
                            {labelOn}
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }

    _onClick(e) {
        let isActive = !this.state.isActive;
        if (!this.props.preventAutoToggling) {
            this.setState({ isActive: isActive });
        }
        if (this.props.onChange) {
            this.props.onChange(isActive, e);
        }
    }

    _onKeyDown(e) {
        let isActive = !this.state.isActive;
        if (e.keyCode == 32) {
            if (!this.props.preventAutoToggling) {
                this.setState({ isActive: isActive });
            }
            if (this.props.onChange) {
                this.props.onChange(isActive, e);
            }
        }
    }
    _getString(data, key) {
        //let lang = data.lang;
        let i18nStrings = data.i18nStrings || _defaultBundle_en_US;
        let value = i18nStrings['comp.toggleSwitch.' + key];

        // If value for Key is not found, true the complete key.
        return value ? value : 'comp.toggleSwitch.' + key;
    }
}

ToggleSwitch.propTypes = {
    /**
     * If true defaults the toggle switch to the active state.
     */
    defaultActive: PropTypes.bool,
    /**
     * Internationalization strings for displaying component in other languages.
     */
    i18nStrings: PropTypes.object,
    /**
     * if true, the toggle switch is small.
     */
    isSmall: PropTypes.bool,
    /**
     * If true, the toggle switch is disabled.
     */
    disabled: PropTypes.bool,
    /**
     * Function to be executed when toggle switch changes.
     */
    onChange: PropTypes.func,
    /**
     * If true, this will prevent the toggle from being called onClick and onKeyDown. This means that the developer has to manage the components props for toggling.
     */
    preventAutoToggling: PropTypes.bool,
    /**
     * Unique ID to be used for the for the labelOff and labelOn IDs used by the component to prevent WCAG issues.
     */
    id: PropTypes.string,
};
