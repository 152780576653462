import Firebase from '../../analytics/vendors/Firebase';
import Adobe from '../../analytics/vendors/Adobe';

let trackerStarted = false;
const analyticsVendors = [Firebase, Adobe];

function initAnalyticVendors(config) {
    analyticsVendors.forEach((vendor) => vendor.init(config)); // Initialize each analytic vendor
}

export function startTracker(config) {
    if (!trackerStarted) {
        trackerStarted = true;
        initAnalyticVendors(config);
    }
}

export function trackAnalyticEvent(eventObject, analyticsData) {
    if (eventObject && eventObject.action && trackerStarted) {
        analyticsVendors.forEach((vendor) =>
            vendor.handleEvent(eventObject, analyticsData),
        );
    }
}
