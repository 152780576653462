/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Delete extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M0,9c0,5,4,9,9,9s9-4,9-9s-4-9-9-9S0,4,0,9z M6.7,4.8L9,7.1l2.3-2.3c0.5-0.5,1.3-0.5,1.8,0.1c0.5,0.5,0.6,1.4,0.1,1.8
                        L10.9,9l2.3,2.3c0.5,0.5,0.5,1.3-0.1,1.8c-0.5,0.5-1.4,0.6-1.8,0.1L9,10.9l-2.3,2.3c-0.5,0.5-1.3,0.4-1.8-0.1s-0.6-1.4-0.1-1.8
                        L7.1,9L4.8,6.7C4.3,6.2,4.3,5.4,4.9,4.9C5.4,4.3,6.2,4.3,6.7,4.8z"
                    />
                </g>
            </svg>
        );
    }
}
