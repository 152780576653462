/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Typography } from '@mui/material';

import { Flex } from 'dbk-common-ui';
import { palette } from 'dbk-dynamic-branding';

export const Loader = ({ label, size = 60, sx, ...props }) => (
    <Flex direction="column" sx={{ ...sx }} {...props}>
        <CircularProgress
            size={size}
            sx={{
                color: `${palette?.palette?.primary?.main}`,
            }}
        />
        {label && (
            <Typography
                variant="subtitle2"
                color="primary.main"
                sx={{ pt: (theme) => theme.spacing(1) }}
            >
                {label}
            </Typography>
        )}
    </Flex>
);

Loader.propTypes = {
    label: PropTypes.string,
    size: PropTypes.number,
    sx: PropTypes.object,
};
