/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment,react/prop-types,react/no-unknown-property */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Order extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                role={this.props.svgRole}
                aria-label={this.props.svgName}
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g stroke="none" stroke-width="1" fill-rule="evenodd">
                    <g transform="translate(-86.000000, -238.000000)">
                        <g transform="translate(86.000000, 238.000000)">
                            <g>
                                <g transform="translate(0.000000, 7.000000)">
                                    <rect x="0" y="0" width="18" height="2" />
                                    <rect x="0" y="4" width="18" height="2" />
                                    <rect x="0" y="8" width="18" height="2" />
                                </g>
                                <polygon
                                    transform="translate(9.000000, 4.000000) rotate(90.000000) translate(-9.000000, -4.000000) "
                                    points="5.5 4.225 10.2022472 7 10.2022472 5.27425 12.5 5.27425 12.5 2.725 10.2022472 2.725 10.2022472 1"
                                />
                                <polygon
                                    transform="translate(9.000000, 20.000000) scale(1, -1) rotate(90.000000) translate(-9.000000, -20.000000) "
                                    points="5.5 20.225 10.2022472 23 10.2022472 21.27425 12.5 21.27425 12.5 18.725 10.2022472 18.725 10.2022472 17"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
