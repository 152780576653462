/* eslint-disable strict,lines-around-directive,prefer-const,padding-line-between-statements,consistent-return */
'use strict';
/**
 * General purpose event manager that is framework agnostic and allows components
 * to listen to events and dispatch events. This manager will also potentially allow for
 * non-React components to communicate with React components.
 */
export default class EventManager {
    /**
     * Dispatch an event to trigger any attached listeners.
     */
    static dispatch(event, data) {
        let mappedEvent = this.eventMap[event];
        if (typeof mappedEvent === 'undefined') {
            return false;
        }

        // Execute each attached callback with the given data.
        mappedEvent.forEach((callback) => {
            callback(data);
        });

        return true;
    }

    /**
     * Attach a listener callback function for the given event.
     * IMPORTANT: unlisten must be explicitly called before the owning component is removed
     * from the DOM, otherwise a memory leak will occur.
     */
    static listen(event, callback) {
        if (typeof this.eventMap[event] === 'undefined') {
            this.eventMap[event] = [];
        }

        // Remove the callback first if it's already been added to ensure we only have 1
        // instance of the callback at a time.
        this.unlisten(event, callback);
        this.eventMap[event].push(callback);
    }

    /**
     * Remove the listener callback function for the given event.
     */
    static unlisten(event, callback) {
        if (typeof this.eventMap[event] === 'undefined') {
            return true;
        }

        // Remove all instances of the unsubscribed callback function for this event.
        this.eventMap[event] = this.eventMap[event].filter((cb) => {
            return cb !== callback;
        });
    }
}

EventManager.eventMap = {};
