import { action, observable } from 'mobx';
import LocaleService from './LocaleService';

class LocaleStore {
    @observable locale = '';

    @action
    loadLocale() {
        return LocaleService.getLocale().then((res) => {
            this.locale = res.locale;

            return res;
        });
    }
}
export default new LocaleStore();
