/* eslint-disable prefer-const,padding-line-between-statements,prefer-destructuring,operator-assignment,prefer-template,no-else-return,no-param-reassign,no-useless-concat */
export default class FormattedMoney {
    // The argument to this function should be a number that satisfies (typeof number === 'number')

    static convertNumberToUSD(numberAsString, showCurrencySymbol) {
        let matches = /(-?)(\d+)(?:\.(\d{1,2}))?/.exec(numberAsString);
        let negativeSign = '';
        let dollars = '0';
        let cents = '00';
        if (matches !== null) {
            // 0 is the whole match
            // 1 is the the negative sign (if present)
            // 2 is dollars
            // 3 is cents
            negativeSign = matches[1];
            dollars = matches[2] || dollars;
            cents = matches[3] || cents;

            // Cents will only have length 1 or 2 at this point
            // If it's 1, another trailing 0 needs to be added
            if (cents.length === 1) {
                cents = cents + '0';
            }
        }

        // Add commas to the dollars
        dollars = FormattedMoney.addCommasToInteger(dollars);
        if (showCurrencySymbol) {
            return negativeSign + '$' + dollars + '.' + cents;
        } else {
            return negativeSign + dollars + '.' + cents;
        }
    }

    static addCommasToInteger(number) {
        number = number.toString();
        let regex = /(\d+)(\d{3})/; // One or more digits, followed by exactly 3 digits
        while (regex.test(number)) {
            number = number.replace(regex, '$1' + ',' + '$2');
        }
        return number;
    }
}
