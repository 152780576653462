/* eslint-disable import/order,no-underscore-dangle,prefer-const,react/destructuring-assignment,prefer-template,spaced-comment,prefer-destructuring,jsx-a11y/no-redundant-roles,react/no-string-refs,class-methods-use-this,no-unneeded-ternary,react/no-access-state-in-setstate,padding-line-between-statements,object-shorthand,jsx-a11y/anchor-is-valid,no-script-url,react/no-danger,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,react/no-array-index-key,no-unused-vars,react/static-property-placement,react/no-unused-prop-types,react/boolean-prop-naming */
import React, { Component } from 'react';
import SVG from '../images/SVG';
import PropTypes from 'prop-types';

import ClickOutHandler from '../../util/clickout/ClickOutHandler';

const _i18nStringsDefault = {
    'comp.dropdown.label.default': 'Select One...',
};

/**
 * @deprecated Since 1.8.0; Use SelectionDropdown instead
 */
export default class Combobox extends Component {
    constructor(props) {
        super(props);

        let Wrapper = this.props.itemWrapper;

        let defaultConfig = {
            clicked: false,
            selectedItem: this.props.defaultSelectedItem
                ? new Wrapper(this.props.defaultSelectedItem)
                : undefined,
            isOpen: false,
        };

        this.state = defaultConfig;

        this._onClickOutSide = this._onClickOutSide.bind(this);
        this._dropdownToggleButtonClick = this._dropdownToggleButtonClick.bind(
            this,
        );
    }

    render() {
        let variationType = this.props.variationType
            ? ' ' + this.props.variationType
            : ''; //{on-gray | link | fi link}
        let size = this.props.isSmall ? ' sm' : ''; //{sm}
        let id = this.props.id || new Date().getTime();
        let defaultLabel = this._getString(this.props, 'label.default');
        let disabled = this.props.disabled ? ' disabled' : '';
        let selectedItem = this.state.selectedItem;
        let list;
        let optionsStyle = {};

        if (this.props.items) {
            list = this._renderListItem(this.props);
        }

        if (selectedItem) {
            defaultLabel = selectedItem.getTitle(true);
        }

        if (this.props.listHeight && this.props.listHeight > 0) {
            optionsStyle = {
                maxHeight: this.props.listHeight + 'px',
                overflowY: 'scroll',
                overflowX: 'hidden',
            };
        }

        return (
            <ClickOutHandler onClickOut={this._onClickOutSide}>
                <div
                    className={
                        'di dropdown' +
                        size +
                        variationType +
                        disabled +
                        (this.state.isOpen ? ' open' : '')
                    }
                    id={'dd-' + id}
                >
                    <button
                        className={'toggle' + disabled}
                        ref="dropdownToggle"
                        id={'dLabel-' + id}
                        type="button"
                        aria-haspopup="true"
                        aria-expanded={this.state.isOpen}
                        title={defaultLabel}
                        onClick={this._dropdownToggleButtonClick}
                        role="button"
                        disabled={this.props.disabled}
                    >
                        <span className="ddlabel">{defaultLabel}</span>
                        <SVG name="Sort" width="16px" height="16px" />
                    </button>
                    <ul
                        className="dropdown-menu"
                        role="menu"
                        style={optionsStyle}
                    >
                        {list}
                    </ul>
                </div>
            </ClickOutHandler>
        );
    }

    _getString(data, key) {
        //let lang = data.lang;
        let i18nStrings = data.i18nStrings || _i18nStringsDefault;
        let _key = 'comp.dropdown.' + key;
        let value = i18nStrings[_key];

        // If value for Key is not found, true the complete key.
        return value ? value : _key;
    }

    _listItemClick(item, e) {
        let Wrapper = this.props.itemWrapper;
        this.setState({
            selectedItem: new Wrapper(item),
            isOpen: !this.state.isOpen,
        });
        if (this.props.onSelect) {
            this.props.onSelect(item, e);
        }
    }

    _renderListItem(data) {
        let listItems = [];

        let Wrapper = this.props.itemWrapper;
        let selectedItem = this.state.selectedItem;
        let trimValueOn = data.trimValueOn ? data.trimValueOn : -1;
        let minWidth = data.minWidth ? data.minWidth + 'px' : 100 + '%';

        if (Wrapper && data.items && !data.disabled) {
            data.items.forEach((item, i) => {
                let itemWrapped = new Wrapper(item);
                let title = itemWrapped.getTitle();

                let displayTitle = itemWrapped.getTitle();
                let labelStyle = { minWidth: minWidth };
                let active = '';

                if (trimValueOn > 0 && title.length >= trimValueOn) {
                    displayTitle = title.substring(0, title.length - 4) + '...';
                }

                if (selectedItem) {
                    active =
                        selectedItem.toString() === itemWrapped.toString()
                            ? 'active'
                            : '';
                }

                let cellLayout = (
                    <a style={labelStyle} href="javascript:void(0);">
                        {' '}
                        {displayTitle}
                    </a>
                );

                if (item.getLayout) {
                    //console.info('>>>> Here ...' + _customeCellLayout(item));
                    cellLayout = (
                        <a
                            style={labelStyle}
                            href="javascript:void(0);"
                            dangerouslySetInnerHTML={item.getLayout(item)}
                        />
                    );
                }

                listItems.push(
                    <li
                        key={i}
                        className={active}
                        onClick={this._listItemClick.bind(this, item)}
                        title={title}
                    >
                        {cellLayout}
                    </li>,
                );
            });
        }

        return listItems;
    }

    _dropdownToggleButtonClick(event) {
        if (!this.props.disabled) {
            this.setState({ isOpen: !this.state.isOpen });
        }
    }

    _onClickOutSide(event) {
        this.setState({ isOpen: false });
    }
}

Combobox.propTypes = {
    i18nStrings: PropTypes.object,
    trimValueOn: PropTypes.number,
    minWidth: PropTypes.number,
    variationType: PropTypes.oneOf(['on-gray', 'link', 'fi link']),
    isSmall: PropTypes.bool,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
    listHeight: PropTypes.number,
    defaultSelectedItem: PropTypes.object,
    itemWrapper: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
};
