import AnalyticsLogger from 'common-ui/src/util/AnalyticsLogger';
import AnalyticsLoggerHelper from './AnalyticsLoggerHelper';

export default {
    log() {
        if (!AnalyticsLoggerHelper.config) {
            return;
        }
        const { navigationStore } = AnalyticsLoggerHelper.config;
        const globalDI = AnalyticsLoggerHelper.dIEnvConfig;
        const { currentLocation: location } = navigationStore;

        if (location && location.pathname !== '/app/login/consumer') {
            const { search } = window.location;
            const { diId, guid } = globalDI;
            const { csrf } = navigationStore.navigationData ?? {};
            const { pathname } = location;
            const analyticsData = {
                diId,
                guid,
                csrf,
                timeStamp: new Date().toISOString(),
                host: window.location.host,
                search,
                pathname,
            };
            const eventObject = {
                props: {
                    prop12: 'view_route',
                    eVar12: 'view_route',
                    events: 'event22',
                    pageName: pathname,
                },
                site: 'sdp',
                action: 'view_route',
                category: pathname,
            };

            AnalyticsLogger.trackEvent(eventObject, analyticsData);
        }
    },

    logHomeRoute() {
        const {
            navigationStore,
            appConfigStore,
        } = AnalyticsLoggerHelper.config;

        const globalDI = AnalyticsLoggerHelper.dIEnvConfig;

        const { currentLocation: location } = navigationStore;

        if (
            location.pathname === '/app/home' ||
            location.pathname === '/app/home/olb/accounts'
        ) {
            const { cookie } = document;
            const { en_US: prevLoginDate } =
                navigationStore.navigationData.lastLoginDate || {};
            const { width: screenWidth, height: screenHeight } = window.screen;

            const {
                userGuid,
                userFullName,
                isBBUser,
            } = appConfigStore.dbkSession;

            const { diId } = globalDI;

            const analyticsDataHome = {
                diId,
                userGuid,
                userFullName,
                isBBUser,
                cookie,
                prevLoginDate,
                screenWidth,
                screenHeight,
            };

            const eventObjectHome = {
                props: {
                    prop12: 'init_session',
                    eVar12: 'init_session',
                    events: 'event22',
                    pageName: location.pathname,
                },
                site: 'sdp',
                action: 'init_session',
                category: location.pathname,
            };

            AnalyticsLogger.trackEvent(eventObjectHome, analyticsDataHome);
        }
    },
};
