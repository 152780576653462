/* eslint-disable filenames/match-exported,import/order,import/no-named-as-default,react/forbid-foreign-prop-types,react/forbid-prop-types,no-underscore-dangle,no-unneeded-ternary,prefer-template,react/destructuring-assignment,no-else-return,padding-line-between-statements,no-restricted-syntax,no-plusplus,react/static-property-placement */
import React, { cloneElement } from 'react';
import { uncontrollable } from 'uncontrollable';

import SelectionDropdown from './SelectionDropdown';
import splitComponentProps from 'react-bootstrap/lib/utils/splitComponentProps';
import ValidComponentChildren from 'react-bootstrap/lib/utils/ValidComponentChildren';
import PropTypes from 'prop-types';

const propTypes = {
    ...SelectionDropdown.propTypes,

    // Toggle props.
    /**
     * Component visual or contextual style variants.
     */
    bsStyle: PropTypes.string,
    /**
     * Component size variations.
     */
    bsSize: PropTypes.string,
    /**
     * The title that's shown on the toggle.
     */
    title: PropTypes.node,
    /**
     * The initial title that's shown on the toggle before an item is selected.
     */
    defaultTitle: PropTypes.node,
    /**
     * An identifier that corresponds to an event key on one of the menu items; determines which item is currently
     * selected. Use defaultSelectedItemKey to only set the initially selected item.
     */
    selectedItemKey: PropTypes.any,
    /**
     * Hides the caret on the toggle when set to true.
     */
    noCaret: PropTypes.bool,
    /**
     * MenuItems or an array of MenuItems
     */
    children: PropTypes.node,
    /**
     * Boolean to prevent issue when jquery is present on a page
     */
    preventDoubleJump: PropTypes.node,
};

class SelectionDropdownButton extends React.Component {
    constructor(props) {
        super(props);

        this._getChildByKey = this._getChildByKey.bind(this);
        this.state = {
            selectedIndex: 0,
        };
    }

    render() {
        const {
            bsSize,
            bsStyle,
            title,
            defaultTitle,
            selectedItemKey,
            ariaLabel,
            children,
            ...props
        } = this.props;

        const [dropdownProps, childProps] = splitComponentProps(
            props,
            SelectionDropdown.ControlledComponent,
        );
        const [toggleProps, menuProps] = splitComponentProps(
            childProps,
            SelectionDropdown.Toggle,
        );

        let selectedChild = null;
        let ariaLabelValue = null;
        let ariaLabelValueAccountType = null;

        if (selectedItemKey != null) {
            selectedChild = this._getChildByKey(selectedItemKey);
        }
        ariaLabelValue = ariaLabel ? ariaLabel : '';
        ariaLabelValueAccountType =
            title ||
            (selectedChild && selectedChild.props.children) ||
            defaultTitle;

        return (
            <SelectionDropdown
                {...dropdownProps}
                bsSize={bsSize}
                bsStyle={bsStyle}
            >
                <SelectionDropdown.Toggle
                    {...toggleProps}
                    bsSize={bsSize}
                    bsStyle={bsStyle}
                    ariaLabel={ariaLabel}
                    ariaLabelValueAccountType={ariaLabelValueAccountType}
                    aria-label={
                        title ||
                        (selectedChild && selectedChild.props.children) ||
                        defaultTitle
                            ? ariaLabelValue +
                              ' ' +
                              (title ||
                                  (selectedChild &&
                                      selectedChild.props.children) ||
                                  defaultTitle)
                            : null
                    }
                >
                    {title ||
                        (selectedChild && selectedChild.props.children) ||
                        defaultTitle}
                </SelectionDropdown.Toggle>

                <SelectionDropdown.Menu
                    {...menuProps}
                    preventDoubleJump={
                        this.props.preventDoubleJump ? true : false
                    }
                    selectedIndex={this.state.selectedIndex}
                >
                    {ValidComponentChildren.map(children, (child) => {
                        if (
                            selectedItemKey != null &&
                            child.props.eventKey === selectedItemKey
                        ) {
                            return cloneElement(child, { active: true });
                        } else {
                            return child;
                        }
                    })}
                </SelectionDropdown.Menu>
            </SelectionDropdown>
        );
    }

    _getChildByKey(key) {
        const childrenArray = React.Children.toArray(this.props.children);
        let index = 0;
        for (const child of childrenArray) {
            if (child.props.eventKey === key) {
                this.state.selectedIndex = index;
                return child;
            }
            index++;
        }

        return null;
    }
}

SelectionDropdownButton.propTypes = propTypes;

const UncontrolledSelectionDropdownButton = uncontrollable(
    SelectionDropdownButton,
    { selectedItemKey: 'onSelect' },
);

// react-docgen needs this export to read component information properly
export { SelectionDropdownButton };

export default UncontrolledSelectionDropdownButton;
