/* eslint-disable no-var,eqeqeq,class-methods-use-this,prefer-template,lines-between-class-members,prefer-const,padding-line-between-statements,spaced-comment,radix,vars-on-top,one-var,prefer-destructuring,no-param-reassign,no-unneeded-ternary,operator-assignment */
class CommonUtils {
    areCookiesEnabled() {
        // Set a test cookie
        var expires = new Date();
        expires.setTime(expires.getTime() + 1800000);
        this.setCookie('testCookie', 'cookie', expires, false, false, false);

        if (document.cookie.indexOf('testCookie') == -1) {
            return false;
        }

        // Remove the test cookie
        expires = new Date();
        expires.setTime(expires.getTime() - 1800000);
        this.setCookie('testCookie', 'cookie', expires, false, false, false);

        return true;
    }

    setCookie(name, value, expires, path, domain, secure) {
        document.cookie =
            name +
            '=' +
            value +
            (expires ? '; expires=' + expires.toGMTString() : '') +
            (path ? '; path=' + path : '') +
            (domain ? '; domain=' + domain : '') +
            (secure ? '; secure' : '');
    }
    /*
     * Convert String Date to MM, dd format.
     *
     * #Parameters;
     * - date (required, Type: String): "02/26/2017
     *
     * #Example;
     * - toDateMMdd("02/26/2017");
     * */
    toDateMMdd(_date, checkforToday) {
        let format = 'MM dd';
        let ipDate = this.stringToDate(_date, 'mm/dd/yyyy', '/');

        if (
            (checkforToday && ipDate && this.isTodaysDate(ipDate)) ||
            _date === 'Today'
        ) {
            return 'Today';
        }

        return this.formattedDate(format, ipDate);
    }

    /**
     * Check if Date is of today;
     *
     */
    isTodaysDate(date) {
        let todaysDate = new Date();
        return date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
    }
    /*
     * Convert String Date to Date Object.
     *
     * # Parameters;
     * - date (required, type: string) : "17/9/2014"
     * - format (required, type: string) : "dd/MM/yyyy"
     * - delimiter (required, type: string) : "/"
     *
     *
     * Example :
     * - stringToDate("17/9/2014","dd/MM/yyyy","/");
     * - stringToDate("9/17/2014","mm/dd/yyyy","/")
     * - stringToDate("9-17-2014","mm-dd-yyyy","-")
     */
    stringToDate(_date, _format, _delimiter) {
        //console.log(">> _stringToDate : " + _date);
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf('mm');
        var dayIndex = formatItems.indexOf('dd');
        var yearIndex = formatItems.indexOf('yyyy');
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var date = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);

        return date.toString() === 'Invalid Date' ? undefined : date;
    }
    /*
     * Get Breakpont as per the document width.
     */
    getBreakpoint() {
        var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width =
                w.innerWidth || documentElement.clientWidth || body.clientWidth;
        //height = w.innerHeight || documentElement.clientHeight || body.clientHeight;

        var breakpoint;
        if (width >= 1200) {
            breakpoint = 'lg';
        } else if (width < 1200 && width >= 992) {
            breakpoint = 'md';
        } else if (width < 992 && width >= 768) {
            breakpoint = 'sm';
        } else {
            breakpoint = 'xs';
        }
        return breakpoint;
    }
    /*
     * Map the date into provided format, not currently have support of Time.
     *
     * #Parameters;
     * - pattern (required) :
     *  -- yy -> Short Year Name
     *  -- yyyy -> Full Year Name
     *  -- MM -> Short Month Name
     *  -- MMM -> Full Month Name
     *  -- dd -> Day Numeric
     * - date (optional, default Today) : JS Date Object
     * - locale (optional, default 'en')
     *
     * Example:
     * - formattedDate ('MM, dd', new Date(), 'en')
     * - formattedDate ('MMM, dd')
     * */
    formattedDate(pattern, date, locale) {
        let localeMonName = {
            en: {
                month_names: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                month_names_short: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
            },
        };
        locale = locale ? locale : 'en';

        date = date ? date : new Date();
        let formattedDate = '';

        if (pattern.indexOf('yyyy') > -1) {
            formattedDate = pattern.replace(
                'yyyy',
                date.getFullYear().toString(),
            );
        } else if (pattern.indexOf('yy') > -1) {
            formattedDate = pattern.replace(
                'yy',
                date.getFullYear().toString(),
            );
        } else {
            formattedDate = pattern;
        }

        let mm = date.getMonth().toString(); // getMonth() is zero-based
        if (formattedDate.indexOf('mm') > -1) {
            mm = mm + 1;
            mm = mm.length > 1 ? mm : '0' + mm;
            formattedDate = formattedDate.replace('mm', mm);
        } else if (formattedDate.indexOf('MMM') > -1) {
            formattedDate = formattedDate.replace(
                'MMM',
                localeMonName[locale].month_names[mm],
            );
        } else if (formattedDate.indexOf('MM') > -1) {
            formattedDate = formattedDate.replace(
                'MM',
                localeMonName[locale].month_names_short[mm],
            );
        }

        let dd = date.getDate().toString();
        dd = dd.length > 1 ? dd : '0' + dd;
        formattedDate = formattedDate.replace('dd', dd);
        return formattedDate;
    }
}

export default new CommonUtils();
