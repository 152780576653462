import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocaleStore from 'i18n/src/LocaleStore';
import AuthenticationStore from 'security/src/AuthenticationStore';
import BioCatchActions from 'security/src/BioCatchActions';
import { Provider } from 'mobx-react';
import Fetch from 'fetch';

import { Loadable } from 'loading';
import { Analytics } from 'common-ui/src/components/module';
import AppLoading from './loading/AppLoading';
import AppConfigStore from './app-config/AppConfigStore';
import './deps/usp_lib';
import './deps/auth';
import AppHelmet from './AppHelmet';
import NavigationStore from './navigation/stores/NavigationStore';
import AppLoaderErrorBoundary from './AppLoaderErrorBoundary';
import UrlSanitizer from './UrlSanitizer';
import CONSTANTS from './Constants';

class AppLoaderComponent extends Component {
    static defaultProps = {};

    static propTypes = {
        appType: PropTypes.string,
    };

    static appDisabledHandler() {
        const path = window.location.pathname;
        let contextPath = process.env.REACT_APP_CONTEXT;

        if (contextPath && contextPath.endsWith('/')) {
            contextPath = contextPath.substring(0, contextPath.length - 1);
        }

        if (path.indexOf(contextPath) === 0) {
            let uspContextPath = '/usp-core';

            if (path.indexOf('/live') !== -1) {
                uspContextPath = '/tob/live/usp-core';
            } else if (path.indexOf('/beta') !== -1) {
                uspContextPath = '/tob/beta/usp-core';
            }
            const routerPath = path.substring(
                path.indexOf(contextPath) + contextPath.length,
            );

            let finalPath = `${uspContextPath}${routerPath}`;

            if (window.location.href.indexOf('localhost') !== -1) {
                finalPath = `https://www.uspdev1.com${finalPath}`;
            }
            window.location.href = finalPath;
        }
    }

    constructor() {
        super();
        Fetch.setErrorCodeHandler(
            CONSTANTS.ERROR_CODES.APP_DISABLED,
            AppLoaderComponent.appDisabledHandler,
        );

        this.loadablePromise = LocaleStore.loadLocale()
            .then((res) => {
                const { locale } = res ?? {};

                if (locale) {
                    const { appType } = this.props;

                    if (appType !== 'cloudflare') {
                        import('moment').then((moment) => {
                            const momentLocale = locale
                                .toLowerCase()
                                .replace('_', '-');
                            moment.locale(momentLocale);
                        });
                    }
                }

                return Promise.all([
                    AppConfigStore.loadAppConfig(),
                    AuthenticationStore.loadCurrentUser(),
                ]);
            })
            .then(() => {
                const { appConfig } = AppConfigStore;
                const promises = [];

                if (appConfig.cdiFingerprintEnabled) {
                    promises.push(import('./deps/fingerprint2'));
                }

                if (promises.length) {
                    return Promise.all(promises);
                }

                return Promise.resolve();
            })
            .then(() => {
                const { appType } = this.props;

                if (appType === 'cloudflare') {
                    return import('./cloudflare/CloudFlareApp');
                }

                return import('./App');
            });
    }

    state = {};

    render() {
        return (
            <Provider
                authenticationStore={AuthenticationStore}
                appConfigStore={AppConfigStore}
                localeStore={LocaleStore}
                navigationStore={NavigationStore}
                bioCatchActions={BioCatchActions}
            >
                <Analytics key="analytics" />
                <React.Fragment>
                    <AppHelmet
                        appConfigStore={AppConfigStore}
                        localeStore={LocaleStore}
                    />
                    <AppLoaderErrorBoundary>
                        <UrlSanitizer>{this.getAppComponent()}</UrlSanitizer>
                    </AppLoaderErrorBoundary>
                </React.Fragment>
            </Provider>
        );
    }

    getAppComponent() {
        return (
            <Loadable
                loader={() => this.loadablePromise}
                loadingComponent={AppLoading}
            />
        );
    }
}

export default AppLoaderComponent;
