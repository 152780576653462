import { action } from 'mobx';
import BioCatchService from './services/BioCatchService';

class BioCatchActions {
    @action
    // eslint-disable-next-line class-methods-use-this
    init() {
        return BioCatchService.postAction('init', 'LOGIN').catch(
            () => undefined, // ignore errors
        );
    }

    @action
    // eslint-disable-next-line class-methods-use-this
    getScore(activity) {
        return BioCatchService.postAction('getScore', activity).catch(
            () => undefined, // ignore errors
        );
    }
}

export default new BioCatchActions();
