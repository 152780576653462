/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Search extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M6.6,1.8c2.8,0,5,2.3,5,5c0,2.8-2.3,5-5,5c-2.8,0-5-2.3-5-5C1.7,3.9,3.8,1.8,6.6,1.8z M6.6,13.4c1.4,0,2.7-0.4,3.7-1.1
                c0,0,0,0.1,0.1,0.1l5.2,5.2c0.3,0.3,0.6,0.3,1,0.3c0.3,0,0.7-0.2,1-0.3c0.5-0.5,0.5-1.3,0-1.8l-5.2-5.2c0,0,0,0-0.1-0.1
                c0.7-1,1.1-2.4,1.1-3.7C13.4,3,10.4,0,6.7,0C3,0,0,3.1,0,6.7C-0.1,10.5,2.9,13.4,6.6,13.4z"
                />
            </svg>
        );
    }
}
