/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Edit extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path d="M17.6,4.1L15.8,5.9,12.1,2.2l1.8-1.8a1,1,0,0,1,1.39,0L17.6,2.71a0.94,0.94,0,0,1,.28.69A0.94,0.94,0,0,1,17.6,4.1ZM0.13,14.18L11,3.27,14.73,7,3.82,17.87H0.13v-3.7Z" />
            </svg>
        );
    }
}
