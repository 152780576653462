import Fetch from 'fetch';
import queryString from 'query-string';
import { getADRequestHeaders } from 'common-ui/src/anomaly-detection';

const handleLoginError = (response) => {
    if (response && response.targetData) {
        return Promise.resolve(response);
    }

    return Promise.reject(response);
};

export default {
    getLoginPageProperties(queryParams, typeOfPage) {
        const queryURL = queryString.stringify({ ...queryParams, typeOfPage });
        const configUrl = `/auth-olb/v1/login/initial/config?${queryURL}`;

        return Fetch.get(configUrl);
    },

    getCurrentUser() {
        return Fetch.get('/auth-olb/v1/user/authtoken');
    },

    login(data) {
        const urlParams = new URLSearchParams(window.location.search);
        const consentParam = urlParams.get('consent');

        let loginPath = '/auth-olb/v1/login';

        if (consentParam === 'true') {
            loginPath += window.location.search;
        }

        return Fetch.postFormData(loginPath, data, getADRequestHeaders()).catch(
            handleLoginError,
        );
    },

    externalLogin(data) {
        return Fetch.postFormData(
            '/auth-olb/v1/external-login',
            data,
            getADRequestHeaders(),
        ).catch(handleLoginError);
    },

    loginRedirect(unAuthenticatedAllowedUser, mfaString) {
        return Fetch.post(
            `/auth-olb/v1/login/redirect?mfaLSO=${mfaString}${
                unAuthenticatedAllowedUser ? '&ui=new' : ''
            }`,
            {},
            getADRequestHeaders(),
        ).catch(handleLoginError);
    },

    logout(reason) {
        return Fetch.post(`/auth-olb/v1/logout?reason=${reason}`);
    },

    getLogoutPageConfig(reason, locale, ibGuid) {
        return Fetch.post(
            `/auth-olb/v1/logout/config?reason=${reason}&locale=${locale}&ibGuid=${ibGuid}`,
        );
    },

    getServiceStoppedConfig(business, close) {
        return Fetch.get(
            `/auth-olb/v1/serviceStopped?business=${business}&close=${close}`,
        );
    },

    getLoginConfig(excludeTheme) {
        const url = `/site-olb/v1/configurations/pre-login?channel=WEB${
            excludeTheme ? '&pageType=LOGIN_PAGE&filter=PAGE' : ''
        }`;

        return Fetch.get(url);
    },

    getLoginConfigMessages(params) {
        const url = '/auth-olb/v1/login/get-login-config-messages';

        return Fetch.get(url, params);
    },
};
