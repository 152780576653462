import { LAYOUT_BREAKPOINTS } from '../constants/layout-breakpoints';

/**
 *
 * Method returns mui media breakpoint for current viewport
 * @param {theme} mui theme
 * @returns
 */
export function getBreakpoint(theme) {
    const { breakpoints } = theme;
    const { values } = breakpoints;
    const { documentElement } = document;
    const body = document.getElementsByTagName('body')[0];
    const width =
        window.innerWidth || documentElement.clientWidth || body.clientWidth;

    let breakpoint;

    if (width >= values.lg) {
        breakpoint = LAYOUT_BREAKPOINTS.LG;
    } else if (width < values.lg && width >= values.md) {
        breakpoint = LAYOUT_BREAKPOINTS.MD;
    } else if (width < values.md && width >= values.sm) {
        breakpoint = LAYOUT_BREAKPOINTS.SM;
    } else {
        breakpoint = LAYOUT_BREAKPOINTS.XS;
    }

    return breakpoint;
}
