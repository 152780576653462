export default function TextField() {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root': {
                        textTransform: 'capitalize',
                    },
                },
            },
        },
    };
}
