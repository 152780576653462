/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class CheckImage extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M0,3.3v11.4h18V3.3H0z M16.8,12.3H9v-1.2h7.8V12.3z M16.8,9.6H1.2V8.4h15.6L16.8,9.6L16.8,9.6z M16.8,6.9H1.2V5.7h15.6
                        L16.8,6.9L16.8,6.9z"
                    />
                </g>
            </svg>
        );
    }
}
