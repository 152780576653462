/* eslint-disable import/prefer-default-export,import/no-mutable-exports,no-var,prefer-const,react/destructuring-assignment,react/prop-types,no-underscore-dangle,padding-line-between-statements,prefer-destructuring,object-shorthand,react/no-access-state-in-setstate */
import React, { Component } from 'react';
import FormattedMoney from '../../../util/FormattedMoney';

const { convertNumberToUSD } = FormattedMoney;

export var MoneyInputFieldHOC = (ComposedComponent) =>
    class extends Component {
        static displayName = 'MoneyInputFieldHOC';

        constructor(props) {
            super(props);

            let initialValue = this.props.value;

            this.state = {
                value: (initialValue && initialValue.toString()) || '',
                isFocused: false,
            };

            this._onChange = this._onChange.bind(this);
            this._onBlur = this._onBlur.bind(this);
            this._onFocus = this._onFocus.bind(this);
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.value === 0 && !this.state.isFocused) {
                this.setState({ value: '' });
            }
        }

        render() {
            let {
                value,
                onBlur,
                onChange,
                onFocus,
                onMoneyChange,
                ...childProps
            } = this.props;
            if (this.state.value !== '.') {
                let stateValueAsNumber = parseFloat(this.state.value || 0);
                value =
                    (value === null && stateValueAsNumber === 0) ||
                    value === stateValueAsNumber ||
                    value === undefined
                        ? this.state.value
                        : value;
                value = this.state.isFocused
                    ? value
                    : value && convertNumberToUSD(value, false);
            } else {
                value = this.state.value;
            }
            return (
                <ComposedComponent
                    ref={(composedComponent) => {
                        this.composedComponent = composedComponent;
                    }}
                    value={value}
                    onBlur={this._onBlur}
                    onChange={this._onChange}
                    onFocus={this._onFocus}
                    {...childProps}
                />
            );
        }

        _onChange(evt) {
            let value = evt.target.value;

            if (value) {
                if (this._isValid(value)) {
                    this.setState({ value: value });
                    if (this.props.onChange) {
                        this.props.onChange(
                            evt,
                            value === '.'
                                ? null
                                : parseFloat(value || 0) || null,
                        );
                    }
                }
            } else {
                this.setState({ value: '' });
                if (this.props.onChange) {
                    this.props.onChange(evt, null);
                }
            }
        }

        _onBlur(evt) {
            let value = this.state.value;
            value = value === '.' ? '' : parseFloat(value || 0);
            let valueAsNumber = parseFloat(value);
            this.setState({
                isFocused: false,
                value: (value && value.toString()) || '',
            });
            if (valueAsNumber) {
                if (this.props.onMoneyChange) {
                    this.props.onMoneyChange(
                        valueAsNumber,
                        convertNumberToUSD(valueAsNumber, false),
                    );
                }

                if (this.props.onBlur) {
                    this.props.onBlur(evt, valueAsNumber);
                }
            } else {
                if (this.props.onMoneyChange) {
                    this.props.onMoneyChange(null, null);
                }

                if (this.props.onBlur) {
                    this.props.onBlur(evt, null);
                }
            }
        }

        _onFocus(evt) {
            this.setState({ isFocused: true });
            if (this.props.onFocus) {
                this.props.onFocus(evt);
            }
        }

        _isValid(value) {
            // Any number of digits, followed by an optional decimal point, and if there is a decimal point, 1 or 2 digits
            // after the decimal point
            let regex = /^\d*(\.)?(\d{1,2})?$/;

            return (
                value != null &&
                (value === '.' ||
                    (regex.test(value.toString()) &&
                        (!this.props.maxLength ||
                            value.toString().length <= this.props.maxLength) &&
                        (!this.props.maxValue ||
                            parseFloat(value) <= this.props.maxValue)))
            );
        }
    };
