import AnalyticsLogger from 'common-ui/src/util/AnalyticsLogger';
import AnalyticsLoggerHelper from './AnalyticsLoggerHelper';

export default {
    log(navData) {
        // const {
        // navigationStore,
        // appConfigStore,
        // } = AnalyticsLoggerHelper.config;
        const globalDI = AnalyticsLoggerHelper.dIEnvConfig;
        // const { currentLocation: location } = navigationStore;
        const { diId } = globalDI;
        const { csrfToken } = navData;
        const { lang } = navData;

        if (lang === 'en_US') {
            const analyticsData = {
                diId,
                csrfToken,
                button_name: navData.item.label.en_US.toString(),
            };

            const eventObject = {
                props: {
                    prop12: 'navbar',
                    eVar12: 'navbar',
                    events: 'event22',
                    pageName: navData.item.label.en_US,
                },
                site: 'sdp',
                action: 'navbar',
                category: navData.item.label.en_US,
            };

            AnalyticsLogger.trackEvent(eventObject, analyticsData);
        }
    },
};
