/* eslint-disable import/order,no-useless-constructor,prefer-const,no-underscore-dangle,no-else-return,prefer-destructuring,react/destructuring-assignment,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from '../images/SVG';
import { Modal } from 'react-bootstrap';
import styles from '../../styles/loader/LoaderModal.scss';

export default class LoaderModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { text, contained, ...rest } = this.props;
        let backdropStyle = { backgroundColor: '#FFFFFF' };

        if (contained) {
            return (
                <div className={styles['contained-modal-container']}>
                    <Modal
                        backdropStyle={backdropStyle}
                        className={styles['modal-container']}
                        dialogClassName={styles['loader-modal']}
                        container={this}
                        {...rest}
                    >
                        {this._renderModalBody()}
                    </Modal>
                </div>
            );
        } else {
            return (
                <Modal
                    backdropStyle={backdropStyle}
                    className={styles['modal-container']}
                    dialogClassName={styles['loader-modal']}
                    {...rest}
                >
                    {this._renderModalBody()}
                </Modal>
            );
        }
    }

    _renderModalBody() {
        let text = this.props.text;

        return (
            <Modal.Body className={styles['loader-modal-body']}>
                <SVG name="PageLoader" width="67px" height="67px" />
                {text ? (
                    <div
                        className={styles['loader-text']}
                        role="alert"
                        aria-atomic="true"
                        aria-live="assertive"
                    >
                        {text}
                    </div>
                ) : null}
            </Modal.Body>
        );
    }
}

LoaderModal.propTypes = {
    /**
     * Text that will appear beneath the spinner.
     */
    text: PropTypes.string,

    /**
     * When true, the modal will be rendered inside the node that contains it, otherwise the modal will be rendered
     * directly into the body of the document.
     */
    contained: PropTypes.bool,
};
