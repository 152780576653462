/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Email extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M1,2.9l8,6.7l8-6.7H1z M18,3.9l-9,8.4L0,4v10.5l3.7-4c0,0,0.4-0.3,0.8,0c0.4,0.3,0.2,0.8,0.2,0.8l-3.5,3.7h15.6l-3.5-3.7
						c0,0-0.2-0.4,0.2-0.8c0.3-0.3,0.8,0,0.8,0l3.7,4L18,3.9z"
                    />
                </g>
            </svg>
        );
    }
}
