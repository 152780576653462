/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class SmallLoader extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                className="rotate"
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    fill="#ccc"
                    d="M13.43,1.17l-1.74,3a5.5,5.5,0,1,1-5.4,0l-1.77-3A9,9,0,1,0,13.43,1.17Z"
                />
            </svg>
        );
    }
}
