/* eslint-disable spaced-comment,no-useless-constructor,one-var,prefer-const,react/no-find-dom-node,no-underscore-dangle,func-names,no-var,vars-on-top,padding-line-between-statements,consistent-return,react/sort-comp,react/destructuring-assignment */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

//@Reference: https://github.com/boblauer/react-onclickout

export default class ClickOutHandler extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let self = this,
            el = ReactDOM.findDOMNode(this);

        self.__windowListener = function (e) {
            if (e.domArray != null && e.domArray.indexOf(el) !== -1) {
                return;
            }
            var clickOutHandler = self.onClickOut || self.props.onClickOut;
            if (!clickOutHandler) {
                return console.warn('onClickOut is not defined.');
            }
            clickOutHandler.call(self, e);
        };

        self.__elementListener = function (e) {
            if (e.domArray == null) {
                e.domArray = [];
            }
            e.domArray.push(el);
        };

        setTimeout(function () {
            if (self.__unmounted) {
                return;
            }
            window.addEventListener('click', self.__windowListener);
            el.addEventListener('click', self.__elementListener);
        }, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.__windowListener);
        ReactDOM.findDOMNode(this).removeEventListener(
            'click',
            this.__elementListener,
        );
        this.__unmounted = true;
    }

    render() {
        return Array.isArray(this.props.children) ? (
            <div>{this.props.children}</div>
        ) : (
            React.Children.only(this.props.children)
        );
    }
}
