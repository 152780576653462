import { useState } from 'react';

import { useTheme } from '@mui/material/styles';

import { useWindowResize } from './useWindowResize';
import { getBreakpoint } from '../utils/breakpoint-util';

export const useLayoutBreakpoint = () => {
    const theme = useTheme();
    const [layoutBreakpoint, setLayoutBreakpoint] = useState(
        getBreakpoint(theme),
    );

    useWindowResize(() => {
        const breakpoint = getBreakpoint(theme);
        setLayoutBreakpoint(() => breakpoint);
    });

    return layoutBreakpoint;
};
