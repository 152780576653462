/* eslint-disable one-var,prefer-const,react/destructuring-assignment,react/prop-types,padding-line-between-statements,no-lonely-if,no-underscore-dangle,prefer-template,vars-on-top,no-var,no-plusplus,no-param-reassign,class-methods-use-this */
import React, { Component } from 'react';
import Moment from 'moment';

export default class SendDeliverByDateDayComponent extends Component {
    render() {
        let date = Moment(this.props.date),
            dayComponentOpts = {};
        let parsedDate = Moment(this.props.selectedDate);
        let parsedDateNextPrev;
        if (this.props.direction === 'forward') {
            if (this.props.deliverByDate) {
                parsedDateNextPrev = Moment(this.props.deliverByDate);
            }
        } else {
            if (this.props.sendDate) {
                parsedDateNextPrev = Moment(this.props.sendDate);
            }
        }

        if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
            dayComponentOpts.onClick = this._onClickHandler;
            dayComponentOpts.className = 'di-calendar-disable-holidays';
        }

        if (this.props.direction === 'forward') {
            if (date.isBetween(parsedDate, parsedDateNextPrev)) {
                dayComponentOpts = {
                    className: 'di-calendar-select-send-date',
                };
            } else if (date.isSame(parsedDateNextPrev)) {
                dayComponentOpts.className = 'di-calendar-select-send-date-end';
            }
        } else {
            if (date.isBetween(parsedDateNextPrev, parsedDate)) {
                dayComponentOpts = {
                    className: 'di-calendar-select-send-date',
                };
            } else if (date.isSame(this.props.selectedDate)) {
                dayComponentOpts.className = 'di-calendar-select-send-date-end';
            } else if (date.isSame(parsedDateNextPrev)) {
                dayComponentOpts.className =
                    'di-calendar-select-deliver-by-date-start';
            }
        }

        if (this.props.disableHolidays) {
            this._disableHolidays(date, dayComponentOpts);
        }

        if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
            dayComponentOpts.onClick = this._onClickHandler;
            dayComponentOpts.className =
                'di-calendar-disable-holidays d' + date.isoWeekday();
        }

        return <div {...dayComponentOpts}>{this.props.label}</div>;
    }

    _disableHolidays(date, dayComponentOpts) {
        for (var i = 0; i < this.props.disableHolidays.length; i++) {
            let holidayDate = this.props.disableHolidays[i];
            let parsedDate;
            if (typeof holidayDate === 'string') {
                parsedDate = Moment(holidayDate, this.props.format);
            } else {
                parsedDate = holidayDate;
            }

            if (parsedDate.isSame(date)) {
                dayComponentOpts.onClick = this._onClickHandler;
                dayComponentOpts.className = 'di-calendar-disable-holidays';
                break;
            }
        }
    }

    _onClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
    }
}
