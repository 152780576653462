/* eslint-disable react/prefer-stateless-function,no-useless-constructor */
import React, { Component } from 'react';

export default class Missing extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <span>Invalid or Name not provided</span>;
    }
}
