/* eslint-disable import/no-useless-path-segments,class-methods-use-this */
import AltInstance from 'common-ui/src/alt/AltInstance';
import appRouterHistory from 'app-router-history';
import MfaService from './../services/MfaService';

class MfaActions {
    constructor() {
        this.generateActions(
            'setCSRFToken',
            'setErrorCodes',
            'setDestinations',
            'selectContactMethod',
            'cancelContactMethod',
            'setCode',
            'submitOtp',
        );
    }

    mfaLog(message, admin, token, totp, csrfToken) {
        MfaService.mfaLog(message, admin, token, totp, csrfToken);
    }

    cancel() {
        this.dispatch();
        appRouterHistory().replace('/app/logout?reason=mfaCancel');
    }
}

export default AltInstance.createActions(MfaActions);
