/* eslint-disable import/order,react/destructuring-assignment,prefer-const,prefer-template,react/prop-types,react/jsx-no-bind,no-underscore-dangle,object-shorthand,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import AlmanacPicker from './AlmanacPicker';
import PropTypes from 'prop-types';
import SlideTransitionItem from './SlideTransitionItem';
import Moment from 'moment';
import styles from '../../styles/SlideTransitionDatePicker.scss';
import SVG from '../images/SVG';

export default class SlideTransitionDatePicker extends Component {
    render() {
        let defaultValue = this.props.defaultValue
            ? this.props.defaultValue
            : new Date();
        let format = this.props.format ? this.props.format : 'MMM DD YYYY';

        defaultValue =
            this.state && this.state.selectedDate
                ? this.state.selectedDate
                : defaultValue;

        let dateValue = !this.props?.navFormDateSelected
            ? Moment(defaultValue).format(format)
            : '';

        return (
            <div className={styles['slide-transition-datepicker']}>
                <div>
                    <div
                        className={
                            'hidden-xs ' +
                            styles['slide-transition-datepicker-hidden-xs']
                        }
                    >
                        <AlmanacPicker
                            role={this.props.role}
                            ariaLabel={this.props.ariaLabel}
                            prefixlabel={this.props.label}
                            {...this.props}
                            defaultValue={defaultValue}
                            onDateChange={this._handleOnDateChange.bind(this)}
                            useOverlay={this.props.useOverlay}
                            manualEntryEnabled={this.props.manualEntryEnabled}
                            svgRole={this.props.svgRole}
                            svgName={this.props.svgName}
                            style={this.props.style}
                            iconFocussable={this.props.iconFocussable}
                        />
                        {this.props.showError ? (
                            <div
                                className={
                                    'text-danger ' +
                                    styles[
                                        'slide-transition-datepicker-error-div'
                                    ]
                                }
                            >
                                <SVG name="AlertError" />{' '}
                                {this.props.errorMessage}
                            </div>
                        ) : null}
                    </div>
                    <div className="visible-xs-block">
                        <SlideTransitionItem
                            label={this.props.label}
                            disabled={this.props.disable}
                            value={dateValue}
                            navDown={this.props.navDown}
                            labelClassName={this.props.labelClassName}
                            errorMessage={this.props.errorMessage}
                            showError={this.props.showError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    _handleOnDateChange(selectedDate, formatedDate) {
        if (this.props.onDateChange) {
            this.props.onDateChange(selectedDate, formatedDate);
        }
        this.setState({ selectedDate: selectedDate });
    }
}

SlideTransitionDatePicker.propTypes = {
    defaultValue: PropTypes.object,
    format: PropTypes.string,
    label: PropTypes.string,
    navDown: PropTypes.func,
    showError: PropTypes.bool,
    errorMessage: PropTypes.string,
    useOverlay: PropTypes.bool,
    manualEntryEnabled: PropTypes.bool,
};
