export default Object.freeze({
    HTTP_STATUS_OK: 200,

    DEFAULT_TOKEN_LIFE: 600000,
    INITIAL_TIMER_START_DELAY: 5000,
    TIMEOUT_CHECK_FREQUENCY: 30000,
    WARNING_TIME: 60000,
    MILLI_SECONDS_PER_SECOND: 1000,

    TAB_ELEMENT_SLECETORS_FOR_POPUP:
        '[tabindex="0"],a[href],input,button,textarea',
    TAB_ELEMENT_SLECETORS_FOR_DROPDOWN: 'a[href]',

    SERVICE_STOP_ERROR_CODE: '27118',

    // Common url's without root context
    HOME_PAGE_URL: '/app/home',
    EMAIL_UPDATE_CONFIG: '/usr-setting-olb/v1/login/email/config',
    EMAIL_UPDATE_URL: '/usr-setting-olb/v1/login/email/update',
    EMAIL_UPDATE_COMPLETE_URL: '/usr-setting-olb/v1/login/email/complete',
    AUTHENTICATOR_PROMPT_COMPLETE_URL: '/app/authenticatorPromptComplete',
    LOCKOUT_URL: '/app/logout?reason=lockout',
    SERVICE_STOPPED_URL: '/app/logout?reason=serviceStopped',
    STEP_UP_AUTH_URL: '/auth-olb/v1/step-up-authentication/submit',
    MFA_SUBMIT_URL: '/auth-olb/v1/mfa/submit',
    MFA_LOGIN_URL: '/auth-olb/v1/mfa/initial/login',
    MOBILE_CALLBACK_SUCCESS_URL: 'cma://?response=success',
    MOBILE_CALLBACK_FAILURE_URL: 'cma://?response=failure',
    MOBILE_CALLBACK_STOPPED_URL: 'cma://?response=stopped',
    MOBILE_CALLBACK_LOCKOUT_URL: 'cma://?response=lockout',
    MOBILE_CALLBACK_USER_HOLD_URL: 'cma://?response=userHold',
    MOBILE_DISCLOSURE_DECLINE_URL: 'cma://usp-adaptive-conversion/timeout',
    MOBILE_GENERIC_DISCLOSURE_ACCEPT_URL:
        'cma://?response=success&action=accepted',
    MOBILE_GENERIC_DISCLOSURE_DECLINE_URL:
        'cma://?response=success&action=declined',
    MOBILE_LOAD_GOOGLE_AUTHENTICATOR_URL:
        'cma://?response=loadGoogleAuthenticator',
    MOBILE_LOAD_MICROSOFT_AUTHENTICATOR_URL:
        'cma://?response=loadMicrosoftAuthenticator',
    FORGOT_PASSWORD_URL: 'app/authUpdate',
    INITIAL_LOGIN_URL: '/app/initialLogin',
    POST_LOGIN_URL: '/app/postLogin',
    LOGOUT_URL: '/app/logout',
    UPDATE_PIN_URL: '/usr-setting-olb/v1/pin/update',
    FEEDBACK_URL: '/v1/user/feedback',
    LOCALIZATION_URL: '/v1/localization',
    UI_NEW_QUERY_PARAM: '?ui=new',
    LOG_MARKETING_ERROR_URL: '/site-olb/v1/logForMAISNavigationAppNotFound',
    IS_USERNAME_AVAILABLE_URL: '/usr-engage-olb/v1/user/usernameAvailability',
    ADAPTIVE_CONVERSION_URL: '/usr-engage-olb/v1/user/provision',
    MOBILE_ADAPTIVE_CONVERSION_SUCCESS_URL:
        'cma://usp-adaptive-conversion/success',
    MOBILE_ADAPTIVE_CONVERSION_TIMEOUT_URL:
        'cma://usp-adaptive-conversion/timeout',
    CHANGE_PASSWORD_URL: '/usr-setting-olb/v1/user/settings/password/change',
    PASSWORD_EXPIRED_URL: '/app/logout?reason=passwordExpired',

    // Error codes returned by controller
    ERROR_CODE_SERVICE_STOPPED: 'serviceStopped',
    ERROR_CODE_PARTIAL_UPDATE_FAILED: 'partialUpdateFailed',
    ERROR_CODE_LOCKED_USER: 'lockedUser',
    ERROR_CODE_INVALID_CURRENT_PASSWORD: 'invalidCurrentPassword',
    CONSENT_APPROVE_URL: '/app/consent/approve',
    ACTIVE: 'ACTIVE',
    PENDING: 'REGISTERED',
    DECLINE: 'FI_REJECTED',
    APPROVAL_PENDING: 'Approval Pending',
    APPROVAL_DECLINE: 'Declined',
    TEMP_PASSWORD_EXPIRED: 'tempPasswordExpired',
    TEMP_PASSWORD_LOCKED: 'tempPasswordLocked',

    /* Other application URLS   */

    // AJAX request states
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',

    // Header constant
    FEATURED_NAV: 'featuredNav',
    MOBILE_FEATURED_NAV: 'mobileNavfeaturedNav',

    // Error messages
    INVALID_SPECIAL_CHARACTER_MSG:
        'A special character any one of the following: \n ! @ # $ % ^ & * _ + - = ( ) [ ] { } | : ; ` , . / ?',
});
