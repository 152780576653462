/* eslint-disable no-unused-vars,import/order,react/prefer-stateless-function,prefer-const,react/destructuring-assignment,react/static-property-placement */
import React, { Component } from 'react';
import Collapse from 'react-collapse';
import PropTypes from 'prop-types';
import ExpandCollapse from '../../common/components/ExpandCollapse';
import { SVG, Alert, IFrame } from 'common-ui/src/components/module';

export default class MfaDisclosure extends Component {
    render() {
        let isEarlyIE = navigator.userAgent.indexOf('MSIE') !== -1;
        let iframeOptions = {
            checkOrigin: false,
            inPageLinks: true,
            heightCalculationMethod: isEarlyIE ? 'max' : 'lowestElement',
        };

        return (
            <div className="mfa-disclosure" id="mfaDisclosure">
                <div className="data-rates">{this.props.disclosure}</div>
                <ExpandCollapse
                    title={this.props.title}
                    expandedTitle={this.props.expandedTitle}
                >
                    <IFrame
                        src={this.props.link}
                        resizerOptions={iframeOptions}
                        allowDomains={['localhost']}
                    />
                </ExpandCollapse>
            </div>
        );
    }
}

MfaDisclosure.propTypes = {
    disclosure: PropTypes.string,
    title: PropTypes.string,
    expandedTitle: PropTypes.string,
    link: PropTypes.string,
};
