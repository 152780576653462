import { green } from '@mui/material/colors';
import { createGradient } from '../utils/theme';

const backgroundPalette = {
    brand: '#1B365D',
    default: '#F0F2F4',
};

export const commonPalette = {
    white: '#FFF',
    black: '#000',
};

const primaryPalette = {
    light: '#136EF6',
    lighter: '#C7D2E1',
    main: '#1B365D',
    dark: '#182C48',
    darker: '#0D1D33',
    contrastText: commonPalette.white,
};

const secondaryPalette = {
    light: createGradient(
        0,
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 0, 0.1)',
        '#FFC845',
    ),
    lighter: '#FFE8B1',
    main: '#FFC845',
    dark: createGradient(
        0,
        'rgba(0, 0, 0, 0.6)',
        'rgba(0, 0, 0, 0.6)',
        '#FFC845',
    ),
    darker: '#382A09',
    contrastText: primaryPalette.main,
};

const errorPalette = {
    light: '#F44336',
    lighter: '#FDECEA',
    main: '#E91607',
    dark: '#C81002',
    darker: `${createGradient(0, '#F44336', '#F44336')},${createGradient(
        0,
        'rgba(0, 0, 0, 0.6)',
        'rgba(0, 0, 0, 0.6)',
    )}`,
    contrastText: commonPalette.white,
};

const infoPalette = {
    light: '#9EA3FE',
    lighter: '#E7E8FF',
    main: '#5364FD',
    dark: '#062EC9',
    darker: `${createGradient(0, '#5364FD', '#5364FD')},${createGradient(
        0,
        'rgba(0, 0, 0, 0.6)',
        'rgba(0, 0, 0, 0.6)',
    )}`,
    contrastText: commonPalette.white,
};

const warningPalette = {
    light: '#FFB547',
    lighter: '#FFE3B9',
    main: '#FFA929',
    dark: '#A86500',
    darker: '#6D4200',
    contrastText: 'rgba(0, 0, 0, 0.87)',
};

const successPalette = {
    light: '#43A847',
    lighter: '#DAECDB',
    main: '#2D8630',
    dark: '#1B791F',
    darker: '#125815',
    contrastText: commonPalette.white,
    hover: green[500],
};

const actionPalette = {
    active: 'rgba(0, 0, 0, 0.6)',
    hover: 'rgba(0, 0, 0, 0.03)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.36)',
    disabledBackground: 'rgba(0, 0, 0, 0.08)',
    focus: 'rgba(0, 0, 0, 0.08)',
};

const greyPalette = {
    20: '#F2F2F2',
    40: '#D3D3D3',
    60: '#8A8A8A',
};

const callToActionPalette = {
    main: '#31A9E0',
    contrastText: commonPalette.white,
};

export const palette = {
    palette: {
        action: actionPalette,
        primary: primaryPalette,
        secondary: secondaryPalette,
        error: errorPalette,
        info: infoPalette,
        warning: warningPalette,
        success: successPalette,
        // default: defaultPalette,
        background: backgroundPalette,
        grey: greyPalette,
        callToAction: callToActionPalette,
    },
};
