export default function SvgIcon() {
    return {
        MuiSvgIcon: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    // fontSize: 'inherit',
                    ...(ownerState.navIcon && {
                        color: ownerState.primary
                            ? theme.palette.grey[700]
                            : theme.palette.grey[500],
                    }),
                }),
            },
        },
    };
}
