/* eslint-disable strict,lines-around-directive,prefer-const,prefer-destructuring,padding-line-between-statements,spaced-comment,lines-between-class-members */
'use strict';
/**
 * Class containing helper functions for browser detection.
 */
export default class BrowserDetect {
    /**
     * Checks whether the current user is in a mobile browser. "Mobi" is checked
     * in the user agent string since this is what's present in mobile browser
     * user agent strings.
     * https://developer.mozilla.org/en-US/docs/Browser_detection_using_the_user_agent
     */
    static isMobile() {
        return /Mobi/.test(navigator.userAgent);
    }

    /**
     * Checks whether the current user is in a Safari browser.
     */
    static isSafari() {
        let userAgent = navigator.userAgent;
        if (
            userAgent &&
            userAgent.indexOf('Safari') > -1 &&
            userAgent.indexOf('Chrome') === -1 &&
            userAgent.indexOf('Chromium') === -1
        ) {
            return true;
        }
        return false;
    }

    static isAndroid() {
        //https://davidwalsh.name/detect-android
        let ua = navigator.userAgent.toLowerCase();
        let isAndroid = ua.indexOf('android') > -1;
        return isAndroid;
    }

    static isIE11() {
        let userAgentIE = !!(
            navigator.userAgent.match(/Trident/) &&
            navigator.userAgent.match(/rv[ :]11/)
        );
        return userAgentIE;
    }
    static isIOS() {
        //http://stackoverflow.com/questions/9038625/detect-if-device-is-ios
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    static isChrome() {
        //https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
        // Edge will return true for Safari and Chrome, so adding a check for !(Edge) will make this only return true for users on Chrome
        return (
            navigator.userAgent.toLowerCase().indexOf('chrome') &&
            !(navigator.userAgent.indexOf('Edge') > -1)
        );
    }
}
