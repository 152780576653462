export default function Link() {
    return {
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.level > 1 && { width: '100%' }),
                }),
                button: {
                    height: 'initial',
                },
            },
        },
    };
}
