/* eslint-disable react/prefer-stateless-function,no-useless-constructor,no-var */
import React, { Component } from 'react';
import SVG from '../images/SVG';

// This component should be shown whenever something is loading.
export default class PageLoader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var loadingTextStyles = {
            position: 'relative',
            top: '-25px',
            left: '10px',
        };

        var containerStyle = {
            lineHeight: '200px',
        };

        return (
            <div className="first text-center" style={containerStyle}>
                <span style={loadingTextStyles}>
                    <SVG name="PageLoader" size="lg" height={75} width={75} />
                </span>
            </div>
        );
    }
}
