import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export const Portal = ({ wrapperId, children }) => {
    const [wrapperElement, setWrapperElement] = useState(null);

    useEffect(() => {
        const element = document.getElementById(wrapperId);

        if (element) {
            setWrapperElement(element);
        }
    });

    return wrapperElement
        ? ReactDOM.createPortal(children, wrapperElement)
        : null;
};

Portal.propTypes = {
    wrapperId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
