/* eslint-disable no-underscore-dangle,class-methods-use-this,react/prop-types */
import React, { Component } from 'react';
import connectToStores from 'common-ui/src/alt/connectToStores';
import StepUpAuthenticationActions from '../actions/StepUpAuthenticationActions';
import StepUpAuthenticationStore from '../stores/StepUpAuthenticationStore';
import SVG from 'common-ui/src/components/images/SVG';

class AuthenticatorInput extends Component {
    constructor(props) {
        super(props);

        this._renderAuthenticatorInput = this._renderAuthenticatorInput.bind(
            this,
        );
        this._validate = this._validate.bind(this);
        this._onInputKeyDown = this._onInputKeyDown.bind(this);
    }

    static getStores() {
        return [StepUpAuthenticationStore];
    }

    static getPropsFromStores() {
        return StepUpAuthenticationStore.getState();
    }

    render() {
        return <div>{this._renderAuthenticatorInput()}</div>;
    }

    _renderAuthenticatorInput() {
        return (
            <div className="row">
                <label htmlFor="authenticator">
                    {this.props.securityOptionAuthenticator.title}
                </label>
                {this.props.submittingAuthenticator ? (
                    <SVG name="SmallLoader" size="sm" />
                ) : null}
                <div
                    className={this.props.validAuthenticator ? '' : 'has-error'}
                >
                    <span className="row col-sm-6 col-xs-12">
                        <input
                            type="text"
                            id="authenticator"
                            name="authenticator"
                            className="form-control"
                            maxLength={
                                this.props.securityOptionAuthenticator
                                    .maxLengthOTP
                            }
                            value={this.props.authenticator}
                            onChange={this._validate}
                            aria-describedby={
                                this.props.securityOptionAuthenticator.title
                            }
                            onBlur={this._validate}
                            onKeyDown={this._onInputKeyDown}
                            placeholder={
                                this.props.securityOptionAuthenticator
                                    .placeholder
                            }
                            readOnly={false}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                        />

                        <div
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <p className="help-block">
                                <span
                                    id="accessibleAlertMessageAuthenticator"
                                    className="sr-only"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    {!this.props.validAuthenticator
                                        ? this.props.securityOptionAuthenticator
                                              .error
                                        : null}
                                </span>
                                <span
                                    className={
                                        this.props.validAuthenticator
                                            ? 'hide'
                                            : 'di-icon-danger'
                                    }
                                    aria-hidden="true"
                                />
                                <span
                                    className={
                                        this.props.validAuthenticator
                                            ? 'hide'
                                            : 'show-inline'
                                    }
                                >
                                    {' '}
                                    {
                                        this.props.securityOptionAuthenticator
                                            .error
                                    }
                                </span>
                            </p>
                        </div>
                    </span>

                    <span
                        id="authenticatorButton"
                        className="row col-sm-6 col-xs-12 button-container"
                    >
                        <button
                            type="button"
                            id="authenticatorSubmit"
                            className="btn btn-primary step-up-button"
                            disabled={
                                this.props.submittingAuthenticator ||
                                this.props.submittingEmail ||
                                this.props.submittingPhone ||
                                this.props.submittingToken
                            }
                            onClick={
                                StepUpAuthenticationActions.submitAuthenticator
                            }
                        >
                            {this.props.securityOptionAuthenticator.submitBtn}
                        </button>
                    </span>
                </div>
            </div>
        );
    }

    _validate(evt) {
        let authenticator = evt.target.value;

        if (authenticator) {
            authenticator = authenticator.replace(/[^0-9]/g, '');
        }
        StepUpAuthenticationActions.setAuthenticator(authenticator);
        const valid =
            authenticator.length <=
            this.props.securityOptionAuthenticator.maxLengthOTP;
        StepUpAuthenticationActions.setValidAuthenticator(valid);
    }

    _onInputKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            StepUpAuthenticationActions.submitAuthenticator();
        }
    }
}

export default connectToStores(AuthenticatorInput);
