/* eslint-disable prefer-const,no-underscore-dangle,react/destructuring-assignment,spaced-comment,prefer-template,padding-line-between-statements,object-shorthand,eqeqeq,react/button-has-type,jsx-a11y/no-noninteractive-element-interactions,react/no-array-index-key,jsx-a11y/no-noninteractive-tabindex,no-unused-vars,react/no-access-state-in-setstate,func-names,react/static-property-placement,react/boolean-prop-naming */
/**
 * Created by vt250055 on 6/22/16.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from '../images/SVG';
import ClickOutHandler from '../../util/clickout/ClickOutHandler';
import HelperFunctions from '../../util/HelperFunctions';
import splitButtonStyles from '../../styles/SplitButton.scss';

export default class SplitButton extends Component {
    constructor(props) {
        super(props);

        let defaultConfig = {
            clicked: false,
            isOpen: false,
        };

        this.state = defaultConfig;
        this._onClickOutSide = this._onClickOutSide.bind(this);
        this._dropdownToggleButtonClick = this._dropdownToggleButtonClick.bind(
            this,
        );
        this._getButtonFunction = this._getButtonFunction.bind(this);
    }

    render() {
        let variationType = this.props.variationType || 'primary'; //{primary| secondary | tertiary}
        let id = this.props.id || '';
        let menuPositionStyle = '';
        let list = {};
        let optionsStyle = {};
        let dropDownButtonStyle = {};
        let dropDownStyle = {};
        let splitButtonGroupStyle =
            'di dropdown sm' + (this.state.isOpen ? ' open' : '');
        let mainButtonStyle =
            'btn btn-' + variationType + ' sm splitbutton main-button';

        if (variationType === 'primary' || variationType === 'secondary') {
            dropDownButtonStyle = splitButtonStyles['split-button-dropdown'];
        } else if (variationType === 'tertiary') {
            dropDownButtonStyle =
                splitButtonStyles['split-button-dropdown-tertiary'];
        }

        if (this.props.items) {
            list = this._renderListItem(this.props);
        }

        if (!this.props.menuExtendsOnRight && !this.props.menuExtendsOnLeft) {
            let overflowY = 'hidden';
            if (this.props.showScrollbar) {
                overflowY = 'scroll';
            }
            optionsStyle = {
                width:
                    HelperFunctions.getWidthOfText(
                        this.props.buttonLabel,
                        'Arial',
                        14,
                    ) +
                    42 /* space around the text inside the button */ +
                    44 /* size of the button containing the arrow */ +
                    'px',
                overflowY: overflowY,
                overflowX: 'hidden',
            };
        }
        if (this.props.listHeight != undefined) {
            optionsStyle.maxHeight = this.props.listHeight + 'px';
        }
        if (this.props.menuExtendsOnRight) {
            menuPositionStyle = 'dropdown-menu-left';
        } else if (this.props.menuExtendsOnLeft) {
            menuPositionStyle = 'dropdown-menu-right';
        }
        dropDownStyle = 'dropdown-menu';
        if (menuPositionStyle !== undefined) {
            dropDownStyle += ' ' + menuPositionStyle;
            dropDownStyle += ' ' + splitButtonStyles['split-button-extended'];
        }

        return (
            <ClickOutHandler onClickOut={this._onClickOutSide}>
                <div
                    className={splitButtonGroupStyle}
                    id={id !== '' ? 'dd-' + id : ''}
                >
                    <div>
                        <button
                            className={mainButtonStyle}
                            onClick={this._getButtonFunction}
                            title={this.props.buttonLabel}
                        >
                            {this.props.buttonLabel}
                        </button>
                        <button
                            type="button"
                            className={dropDownButtonStyle}
                            aria-haspopup="true"
                            aria-expanded={this.state.isOpen}
                            onClick={this._dropdownToggleButtonClick}
                            aria-label="dropdown menu"
                        >
                            <SVG name="Sort" width="16px" height="16px" />
                        </button>
                    </div>
                    <ul
                        className={dropDownStyle}
                        role="menu"
                        style={optionsStyle}
                        id="dropdownMenuId"
                        ref={(dropdownMenuId) => {
                            this.dropdownMenuId = dropdownMenuId;
                        }}
                    >
                        {list}
                    </ul>
                </div>
            </ClickOutHandler>
        );
    }

    _getItemFunction(item, e) {
        this.props.onItemSelect(item, e);
        this.setState({ isOpen: false });
    }

    _handleEnterKeyGetItemFunction(item, e) {
        if (e.key === 'Enter' && e.keyCode === 13) {
            this._getItemFunction(item, e);
        }
    }

    _renderListItem(data) {
        let listItems = [];
        let Wrapper = this.props.itemWrapper;

        if (Wrapper && data.items) {
            data.items.forEach((item, i) => {
                let itemWrapped = new Wrapper(item);
                let title = itemWrapped.getTitle();
                let displayTitle = itemWrapped.getTitle();
                let cellLayout = <span>{displayTitle}</span>;

                listItems.push(
                    <li
                        key={i}
                        onClick={this._getItemFunction.bind(this, item)}
                        title={title}
                        onKeyDown={this._handleEnterKeyGetItemFunction.bind(
                            this,
                            item,
                        )}
                        tabIndex="0"
                    >
                        {cellLayout}
                    </li>,
                );
            });
        }
        return listItems;
    }

    _dropdownToggleButtonClick(event) {
        this.setState({ isOpen: !this.state.isOpen });
        setTimeout(function () {
            let ele = this.dropdownMenuId.childNodes[0];
            if (ele) {
                ele.focus();
            }
        }, 200);
    }

    _onClickOutSide(event) {
        this.setState({ isOpen: false });
    }

    _getButtonFunction(event) {
        this.props.onButtonSelect(this.props.buttonLabel, event);
        this.setState({ isOpen: false });
    }
}

SplitButton.propTypes = {
    /**
     * String of text to add to left button.
     */
    buttonLabel: PropTypes.string,
    /**
     * Sets color of left button to dark grey(primary), light grey(secondary), or white(tertiary).Default Value: primary
     */
    variationType: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    /**
     * String to set the id attribute for the div containing the split button.
     */
    id: PropTypes.string,
    /**
     * Adds a max height to the dropdown list in pixels.
     */
    listHeight: PropTypes.number,
    /**
     * Custom function for wrapping each item in this.props.items.
     */
    itemWrapper: PropTypes.func.isRequired,
    /**
     * Array of objects to be rendered in dropdown.
     */
    items: PropTypes.array.isRequired,
    /**
     * Custom function to be executed when the user click the left button.
     */
    onButtonSelect: PropTypes.func.isRequired,
    /**
     * Custom function to be executed when an item is selected from the dropdown list.
     */
    onItemSelect: PropTypes.func.isRequired,
    /**
     * If true dropdown menu extends to the right.
     */
    menuExtendsOnRight: PropTypes.bool,
    /**
     * If true dropdown menu extends to the left.
     */
    menuExtendsOnLeft: PropTypes.bool,
    /**
     * Removes the srollbar from the dropdown.
     */
    showScrollbar: PropTypes.bool,
};
