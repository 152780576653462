import { action, observable } from 'mobx';
import AppConfigService from './AppConfigService';

class AppConfigStore {
    @observable appConfig = null;

    async loadAppConfig() {
        const appConfig = await AppConfigService.getAppConfig();
        this.setAppConfig(appConfig);
    }

    @action
    setAppConfig(appConfig) {
        this.appConfig = appConfig;
    }

    setDBKSession(dbkSession) {
        this.dbkSession = dbkSession;
    }

    getDBKSession() {
        return this.dbkSession;
    }
}
export default new AppConfigStore();
