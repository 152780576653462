import { defaultsDeep } from 'lodash';

import { palette } from './palette';
import { typography } from './typography';
import { zIndex } from './zIndex';
import { getComponentOverrides } from './component-overrides';

export const getMergedTheme = (experienceTheme, ncrTheme) => {
    const dbkTheme = defaultsDeep(
        {},
        palette,
        typography,
        getComponentOverrides(ncrTheme),
        zIndex,
        experienceTheme,
        ncrTheme,
    );

    return dbkTheme;
};
