/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class TransferMoney extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path d="M0.1,3.5v3.4h11.6v2.3l6.2-3.7l-6.2-4.3v2.3L0.1,3.5z M18,11.1H6.2V8.8l-6.2,4.3l6.2,3.7v-2.3h11.6v-3.4H18z" />
            </svg>
        );
    }
}
