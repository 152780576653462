/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class AlertOutline extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M8.3,11.7h1.4L10,5H8L8.3,11.7z M9,14c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S9.6,14,9,14z M17.6,14.7L10.7,1.4
                C10.2,0.5,9.6,0,9,0S7.8,0.5,7.3,1.4L0.4,14.7c-0.5,0.9-0.5,1.8-0.2,2.4C0.6,17.7,1.3,18,2.3,18h13.3c1,0,1.8-0.3,2.1-0.9
                C18.1,16.5,18.1,15.6,17.6,14.7z M16.3,16.2C16,16.7,15.4,17,14.5,17h-11c-0.8,0-1.5-0.3-1.8-0.8c-0.3-0.5-0.2-1.2,0.2-2l5.7-11
                C8,2.4,8.5,2,9,2s1,0.4,1.4,1.1l5.7,11C16.5,15,16.6,15.7,16.3,16.2z"
                />
            </svg>
        );
    }
}
