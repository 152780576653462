// eslint-disable-next-line filenames/match-exported
import IconButton from './IconButton';
import Button from './Button';
import Toolbar from './Toolbar';
import Stack from './Stack';
import Link from './Link';
import SvgIcon from './SvgIcon';
import Drawer from './Drawer';
import Switch from './Switch';
import OutlinedInput from './OutlinedInput';
import Textfield from './TextField';
import Paper from './Paper';
// import Radio from './Radio';

export const getComponentOverrides = (theme) => {
    return {
        components: {
            ...IconButton(theme),
            ...Button(theme),
            ...Toolbar(theme),
            ...Stack(theme),
            ...Link(theme),
            ...SvgIcon(theme),
            ...Drawer(theme),
            ...Switch(theme),
            ...OutlinedInput(theme),
            ...Textfield(theme),
            ...Paper(theme),
            // ...Radio(theme),
        },
    };
};
