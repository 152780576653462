/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Scheduled extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g fill="#2e6f03">
                    <path d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S14,0,9,0z M9,17c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S13.4,17,9,17z" />
                    <polygon points="13.6,12.1 9.5,8.8 9.5,2.6 8.3,2.6 8.3,9.2 8.3,9.2 13,13 	" />
                </g>
            </svg>
        );
    }
}
