/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Help extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M11.7,0.4C7-1.1,1.9,1.5,0.4,6.3c-1.5,4.7,1.1,9.8,5.9,11.3c4.7,1.5,9.8-1.1,11.3-5.9C19.1,6.9,16.5,1.9,11.7,0.4z
                         M10.3,15.3H8v-2.4h2.3V15.3z M13.2,7.4c-0.1,0.4-0.3,0.7-0.5,0.9c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.1-0.5,0.4-0.8,0.6
                        c-0.3,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.3,0.5-0.3,0.9v0.6H8v-0.8c0-0.5,0.1-0.9,0.3-1.2C8.5,9.3,8.6,9,8.8,8.8
                        C9,8.6,9.2,8.4,9.4,8.2C9.6,8,9.8,7.9,10,7.7c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.2,0.2-0.5,0.2-0.8c0-0.5-0.1-0.9-0.4-1.2
                        C10,4.8,9.7,4.7,9.2,4.7c-0.3,0-0.6,0.1-0.8,0.2C8.2,5,8,5.2,7.9,5.4C7.8,5.6,7.7,5.8,7.6,6.1C7.5,6.4,7.5,6.7,7.5,7H5
                        c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-1,0.8-1.3c0.4-0.4,0.8-0.7,1.3-0.9s1.1-0.3,1.7-0.3c0.8,0,1.5,0.1,2,0.3c0.5,0.2,1,0.5,1.3,0.8
                        C12.7,4.2,13,4.6,13.1,5c0.1,0.4,0.2,0.7,0.2,1.1C13.4,6.6,13.3,7.1,13.2,7.4z"
                    />
                </g>
            </svg>
        );
    }
}
