/* eslint-disable no-underscore-dangle,react/destructuring-assignment,react/prop-types,no-empty,react/sort-comp,no-var,vars-on-top,prefer-template,prefer-const,jsx-a11y/label-has-for,padding-line-between-statements,react/static-property-placement,react/no-typos */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from '../images/SVG';
import InputStyles from '../../styles/Input.scss';

export default class InputField extends Component {
    constructor(props) {
        super(props);

        this._onFocus = this._onFocus.bind(this);
        this.getInputRef = this.getInputRef.bind(this);
    }

    componentDidMount() {
        if (this.props.focusOnInit) {
            try {
                setTimeout(() => {
                    this.inputElement.focus();
                    this.inputElement.select();
                });
            } catch (e) {}
        }
    }

    getInputRef() {
        return this.inputElement;
    }

    render() {
        var inputClassName = this.props.isValid ? '' : 'has-error ';
        inputClassName += this.props.className ? this.props.className : '';
        inputClassName += this.props.svgName ? ' has-feedback' : '';

        var errorIconClass = this.props.isValid ? 'hide' : 'di-icon-danger';
        var errorMessageClass = this.props.isValid
            ? 'hide'
            : 'show-inline ' + InputStyles['error-message'];

        let textStartPosition =
            'di-input ' +
            // Defining callsign keystroke field using css class
            // https://docs.callsign.com/docs/web-intel-sdk-registered/287a7228a1bcb-setting-up-the-web-sdk#defining-the-keystroke-fields
            'cs-username ' +
            (this.props.svgLeft
                ? InputStyles['input-text-left-padding'] + ' form-control'
                : 'form-control');
        let iconStyle =
            InputStyles['input-addon-icon-top'] + ' form-control-feedback ';
        iconStyle += this.props.svgLeft
            ? InputStyles['input-addon-icon-left-position']
            : '';

        /*  Add props className instead of hard code (for label etc.). Also generify htmlFor.   */
        return (
            <div className="di-input-container">
                {!this.props.labelNotRequired ? (
                    <label
                        htmlFor={this.props.id}
                        id={(this.props.id || '') + 'label'}
                        className={this.props.labelClassName}
                    >
                        {this.props.label}
                    </label>
                ) : (
                    <br />
                )}
                <div className={inputClassName}>
                    <input
                        type={this.props.type}
                        id={this.props.id}
                        name={this.props.name}
                        ref={(input) => {
                            this.inputElement = input;
                        }}
                        role={this.props.ariaRole ? this.props.ariaRole : null}
                        aria-label={
                            this.props.ariaLabel
                                ? this.props.ariaLabel
                                : this.props.label
                        }
                        className={textStartPosition}
                        maxLength={this.props.maxLength}
                        value={this.props.value}
                        autoCorrect={this.props.autoCorrect}
                        autoCapitalize={this.props.autoCapitalize}
                        autoComplete={this.props.autoComplete}
                        defaultValue={this.props.defaultValue}
                        aria-describedby={
                            this.props.ariaDescribedBy
                                ? this.props.ariaDescribedBy
                                : (this.props.id ? this.props.id : '') +
                                  'ErrorMsg'
                        }
                        onBlur={this.props.onBlur}
                        onFocus={this._onFocus}
                        onKeyDown={this.props.onKeyDown}
                        onKeyUp={this.props.onKeyUp}
                        placeholder={this.props.placeholder}
                        pattern={this.props.pattern}
                        onChange={this.props.onChange}
                        onInput={this.props.onInput}
                    />
                    {this.props.svgName ? (
                        <span className={iconStyle}>
                            <SVG name={this.props.svgName} />
                        </span>
                    ) : null}
                    <p
                        id={(this.props.id || '') + 'ErrorMsg'}
                        className="help-block"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <span
                            className="sr-only"
                            role="alert"
                            aria-live="assertive"
                        >
                            {!this.props.isValid
                                ? this.props.validationError
                                : null}
                        </span>
                        <span className={errorIconClass} aria-hidden="true" />
                        <span
                            className={errorMessageClass}
                            id={(this.props.id || '') + 'ErrorMsg1'}
                        >
                            {!this.props.isValid
                                ? this.props.validationError
                                : null}
                        </span>
                    </p>
                </div>
            </div>
        );
    }

    _onFocus(evt) {
        // Select all current text in the input when focused. This makes it easier to edit.
        this.inputElement.select();
        if (this.props.onFocus) {
            this.props.onFocus(evt);
        }
    }
}

InputField.defaultProps = {
    autoCorrect: 'off',
    autoCapitalize: 'off',
    autoComplete: 'off',
};

InputField.PropTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    pattern: PropTypes.string,
    maxLength: PropTypes.number,
    ariaLabel: PropTypes.string,
    labelClassName: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    svgName: PropTypes.string,
    svgLeft: PropTypes.bool,
    isValid: PropTypes.bool,
    validationError: PropTypes.string,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};
