import { useState } from 'react';

/**
 * Custom hook to copy text to clipboard and manage the copied state.
 *
 * @param {string} text - The text to be copied to the clipboard
 * @return {Array} An array containing the copied state, copy function, and reset function
 */
export const useCopyToClipboard = () => {
    const [copied, setCopied] = useState(false);

    // eslint-disable-next-line consistent-return
    const copy = async (text) => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported');

            return false;
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
        } catch (error) {
            console.warn('Copy failed', error);
            setCopied(false);
        }
    };

    const reset = () => setCopied(false);

    return [copied, copy, reset];
};
