/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Notifications extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path d="M9,0C8.7,0,7.8,0.1,7.8,1.5c0.7,0,1.1,0,1.2,0s0.5,0,1.2,0C10.2,0.1,9.3,0,9,0z" />
                    <path
                        d="M15.3,12.1c-1.2-1-1.7-3.3-1.8-6.3c0-3.1-2.6-3.7-3.3-3.7c-0.3,0-1.1,0-1.2,0l0,0c-0.1,0-0.9,0-1.2,0
                        c-0.7,0-3.3,0.5-3.3,3.7c-0.1,3-0.6,5.3-1.8,6.3s-2,1.3-2,2.1s1.1,1.7,3.3,1.7c2.1,0,4.8,0,5,0l0,0c0.2,0,2.8,0,5,0
                        s3.3-0.8,3.3-1.7C17.3,13.4,16.6,13.1,15.3,12.1z"
                    />
                    <path d="M9,16.5H7.2c0,0,0,1.5,1.8,1.5s1.8-1.5,1.8-1.5H9z" />
                </g>
            </svg>
        );
    }
}
