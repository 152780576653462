/* eslint-disable no-underscore-dangle,class-methods-use-this,react/prop-types */
import React, { Component } from 'react';
import connectToStores from 'common-ui/src/alt/connectToStores';
import { InternationalPhoneNumberInputField } from 'common-ui/src/components/module';
import StepUpAuthenticationActions from '../actions/StepUpAuthenticationActions';
import StepUpAuthenticationStore from '../stores/StepUpAuthenticationStore';
import SVG from 'common-ui/src/components/images/SVG';

class PhoneInput extends Component {
    constructor(props) {
        super(props);

        this._renderPhoneInput = this._renderPhoneInput.bind(this);
        this._validateCountry = this._validateCountry.bind(this);
        this._validate = this._validate.bind(this);
        this._onInputKeyDown = this._onInputKeyDown.bind(this);
    }

    static getStores() {
        return [StepUpAuthenticationStore];
    }

    static getPropsFromStores() {
        return StepUpAuthenticationStore.getState();
    }

    render() {
        return <div>{this._renderPhoneInput()}</div>;
    }

    _renderPhoneInput() {
        return (
            <div className="row">
                <label htmlFor="phoneNumber">
                    {this.props.securityOptionPhone.title}
                </label>
                {this.props.submittingPhone ? (
                    <SVG name="SmallLoader" size="sm" />
                ) : null}
                <div>
                    <span className="row col-sm-6 col-xs-12">
                        <InternationalPhoneNumberInputField
                            key="phone"
                            ariaLabelForInput={
                                this.props.securityOptionPhone.title
                            }
                            className=""
                            selectedPhoneCode={this.props.phoneCountry}
                            phoneNumber={this.props.phoneNumber}
                            phoneRequired={false}
                            onCountryCodeChange={this._validateCountry}
                            onIntlPhNoChange={this._validate}
                            onPhoneValidityCheck={this._validate}
                            onBlur={
                                StepUpAuthenticationActions.setNoPhoneSubmitted
                            }
                            onKeyDown={
                                this.props.securityOptionPhone.isSmsEnabled
                                    ? null
                                    : this._onInputKeyDown
                            }
                            isInternationalPhoneEnabled={
                                this.props.securityOptionPhone
                                    .isInternationalPhoneSupported
                            }
                            defaultFocus={false}
                            focusInputOnInit={false}
                            validateOnInit={true}
                            hasError={this.props.noPhoneSubmitted}
                            errorMessage={
                                this.props.i18nStrings[
                                    'phonewidget.common.js.fieldError'
                                ]
                            }
                            countryCodeData={
                                this.props.securityOptionPhone.countryInfoArray
                            }
                            phoneMinLength={
                                this.props.securityOptionPhone.phoneMinLength
                            }
                            phoneMaxLength={
                                this.props.securityOptionPhone.phoneMaxLength
                            }
                            i18nStrings={this.props.i18nStrings}
                            inputFieldId="phoneNumber"
                        />
                    </span>
                    <span
                        id="stepUpPhoneButtons"
                        className="row col-sm-6 col-xs-12 button-container"
                    >
                        {this.props.securityOptionPhone.isSmsEnabled ? (
                            <button
                                type="button"
                                id="textMe"
                                className="btn btn-primary step-up-button step-up-phone"
                                disabled={
                                    this.props.submittingAuthenticator ||
                                    this.props.submittingEmail ||
                                    this.props.submittingPhone ||
                                    this.props.submittingToken
                                }
                                onClick={
                                    StepUpAuthenticationActions.submitPhoneText
                                }
                                title={this.props.securityOptionPhone.submitTextBtn}
                            >
                                {this.props.securityOptionPhone.submitTextBtn}
                            </button>
                        ) : null}
                        {this.props.securityOptionPhone.isVoiceEnabled ? (
                            <button
                                type="button"
                                id="callMe"
                                className="btn btn-primary step-up-button step-up-phone"
                                disabled={
                                    this.props.submittingAuthenticator ||
                                    this.props.submittingEmail ||
                                    this.props.submittingPhone ||
                                    this.props.submittingToken
                                }
                                onClick={
                                    StepUpAuthenticationActions.submitPhoneCall
                                }
                                title={this.props.securityOptionPhone.submitCallBtn}
                            >
                                {this.props.securityOptionPhone.submitCallBtn}
                            </button>
                        ) : null}
                    </span>
                </div>
            </div>
        );
    }

    _validateCountry(evt, phoneCountry) {
        StepUpAuthenticationActions.setPhoneCountry(phoneCountry);
    }

    _validate(phoneCountry, phoneNumber, error) {
        if (
            this.props.noPhoneSubmitted &&
            phoneNumber != null &&
            phoneNumber !== ''
        ) {
            StepUpAuthenticationActions.setNoPhoneSubmitted();
        }
        StepUpAuthenticationActions.setPhoneCountry(phoneCountry);
        StepUpAuthenticationActions.setPhoneNumber(phoneNumber);
        StepUpAuthenticationActions.setValidPhone(!error);
    }

    _onInputKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            StepUpAuthenticationActions.submitPhoneCall();
        }
    }
}

export default connectToStores(PhoneInput);
