/* eslint-disable no-underscore-dangle,class-methods-use-this,react/prop-types */
import React, { Component } from 'react';
import connectToStores from 'common-ui/src/alt/connectToStores';
import StepUpAuthenticationActions from '../actions/StepUpAuthenticationActions';
import StepUpAuthenticationStore from '../stores/StepUpAuthenticationStore';
import SVG from 'common-ui/src/components/images/SVG';

const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2,})$/i;
const EMAIL_PRINTABLE_REGEX = /[^ -~]/;
class EmailInput extends Component {
    constructor(props) {
        super(props);

        this._renderEmailInput = this._renderEmailInput.bind(this);
        this._validate = this._validate.bind(this);
        this._onInputKeyDown = this._onInputKeyDown.bind(this);
    }

    static getStores() {
        return [StepUpAuthenticationStore];
    }

    static getPropsFromStores() {
        return StepUpAuthenticationStore.getState();
    }

    render() {
        return <div>{this._renderEmailInput()}</div>;
    }

    _renderEmailInput() {
        return (
            <div className="row">
                <label htmlFor="email">
                    {this.props.securityOptionEmail.title}
                </label>
                {this.props.submittingEmail ? (
                    <SVG name="SmallLoader" size="sm" />
                ) : null}
                <div className={this.props.validEmail ? '' : 'has-error'}>
                    <span className="row col-sm-6 col-xs-12">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            maxLength="64"
                            value={this.props.email}
                            onChange={this._validate}
                            aria-describedby={
                                this.props.securityOptionEmail.title
                            }
                            onBlur={this._validate}
                            onKeyDown={this._onInputKeyDown}
                            placeholder={
                                this.props.securityOptionEmail.placeholder
                            }
                            readOnly={false}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                        />

                        <div
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <p className="help-block">
                                <span
                                    id="accessibleAlertMessageEmail"
                                    className="sr-only"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    {!this.props.validEmail
                                        ? this.props.securityOptionEmail.error
                                        : null}
                                </span>
                                <span
                                    className={
                                        this.props.validEmail
                                            ? 'hide'
                                            : 'di-icon-danger'
                                    }
                                    aria-hidden="true"
                                />
                                <span
                                    className={
                                        this.props.validEmail
                                            ? 'hide'
                                            : 'show-inline'
                                    }
                                >
                                    {' '}
                                    {this.props.securityOptionEmail.error}
                                </span>
                            </p>
                        </div>
                    </span>

                    <span
                        id="emailButton"
                        className="row col-sm-6 col-xs-12 button-container"
                    >
                        <button
                            type="button"
                            id="emailSubmitButton"
                            className="btn btn-primary step-up-button step-up-email"
                            disabled={
                                this.props.submittingAuthenticator ||
                                this.props.submittingEmail ||
                                this.props.submittingPhone ||
                                this.props.submittingToken
                            }
                            onClick={StepUpAuthenticationActions.submitEmail}
                            title={this.props.securityOptionEmail.submitBtn}
                        >
                            {this.props.securityOptionEmail.submitBtn}
                        </button>
                    </span>
                </div>
            </div>
        );
    }

    _validate(evt) {
        const email = evt.target.value.trim();
        StepUpAuthenticationActions.setEmail(email);
        const valid =
            email.length === 0 ||
            (EMAIL_REGEX.test(email) && !EMAIL_PRINTABLE_REGEX.test(email));
        StepUpAuthenticationActions.setValidEmail(valid);
    }

    _onInputKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            StepUpAuthenticationActions.submitEmail();
        }
    }
}

export default connectToStores(EmailInput);
