/* eslint-disable spaced-comment,prefer-template,no-param-reassign */
//Reference https://plainjs.com/javascript/selecting/select-dom-elements-by-css-selectors-4/

export default class DOMUtils {
    // select a list of matching elements, context is optional
    static select(selector, context) {
        return (context || document).querySelectorAll(selector);
    }

    // select the first match only, context is optional
    static select1(selector, context) {
        return (context || document).querySelector(selector);
    }

    static hasClass(el, className) {
        return el.classList
            ? el.classList.contains(className)
            : new RegExp('\\b' + className + '\\b').test(el.className);
    }

    static addClass(el, className) {
        if (el.classList) {
            el.classList.add(className);
        } else if (!DOMUtils.hasClass(el, className)) {
            el.className += ' ' + className;
        }
    }

    static removeClass(el, className) {
        if (el.classList) {
            el.classList.remove(className);
        } else {
            el.className = el.className.replace(
                new RegExp('\\b' + className + '\\b', 'g'),
                '',
            );
        }
    }
}
