/* eslint-disable import/prefer-default-export */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

const StyledButton = styled(Button)(({ disableHoverBackground }) => ({
    ...(disableHoverBackground && {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }),
}));

export const IconButtonWithLabel = forwardRef(
    (
        {
            id,
            ariaLabel,
            size,
            handler,
            text,
            startIcon,
            endIcon,
            fontWeight = 'medium',
            disableHoverBackground = false,
            ...props
        },
        ref,
    ) => {
        return (
            <StyledButton
                ref={ref}
                id={id}
                aria-label={ariaLabel}
                size={size}
                color="inherit"
                onClick={handler}
                startIcon={startIcon}
                endIcon={endIcon}
                disableHoverBackground={disableHoverBackground}
                {...props}
            >
                <Typography
                    variant="body1"
                    fontWeight={fontWeight}
                    fontSize="inherit"
                    whiteSpace="nowrap"
                >
                    {text}
                </Typography>
            </StyledButton>
        );
    },
);

IconButtonWithLabel.propTypes = {
    id: PropTypes.string,
    ariaLabel: PropTypes.string.isRequired,
    size: PropTypes.string,
    handler: PropTypes.func,
    text: PropTypes.func.isRequired,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    fontWeight: PropTypes.string,
    disableHoverBackground: PropTypes.bool,
};
