import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createTheme } from '@ncr-design-system/shared-frontend';

import { getMergedTheme, GlobalStyles, DEFAULT_SPACING } from './styles';

const defaultThemeType = 'light';

const defaultTheme = {
    type: defaultThemeType,
};

const ThemeContext = createContext(defaultTheme);

export default function ThemeProvider({
    theme = {},
    themeType = defaultThemeType,
    children,
}) {
    const [ncrTheme, setNcrTheme] = useState(
        createTheme({
            palette: { mode: themeType },
            spacing: DEFAULT_SPACING,
        }),
    );

    useEffect(() => {
        if (themeType === ncrTheme.palette.mode) return;
        setNcrTheme(createTheme({ palette: { mode: themeType } }));
    }, [themeType]);

    const mergedTheme = getMergedTheme(theme, ncrTheme);

    return (
        <ThemeContext.Provider value={{ type: themeType }}>
            <MuiThemeProvider theme={mergedTheme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <GlobalStyles theme={mergedTheme} />
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
}

ThemeProvider.propTypes = {
    theme: PropTypes.object,
    themeType: PropTypes.string,
};

export function useTheme() {
    return useContext(ThemeContext);
}
