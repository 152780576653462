import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

const LocaleCodeByLanguageTagMap = {
    en_US: 'en-US',
    es_ES: 'es-ES',
    zh_TW: 'zh-TW',
    fr_CA: 'fr-CA',
};

function getMetaElement(value) {
    const descMeta = document.querySelector(
        "meta[http-equiv='Content-Security-Policy']",
    );

    if (descMeta && descMeta.content === value) return false;

    return true;
}

@inject('localeStore')
@inject('appConfigStore')
@observer
class AppHelmet extends Component {
    static defaultProps = {
        appConfigStore: null,
        localeStore: null,
    };

    static propTypes = {
        appConfigStore: PropTypes.instanceOf(Object),
        localeStore: PropTypes.instanceOf(Object),
    };

    render() {
        const { appConfigStore, localeStore } = this.props;
        const { appConfig } = appConfigStore;
        const { locale } = localeStore;

        if (!appConfig) {
            return false;
        }

        if (
            appConfig.leapfrogBrandingFile &&
            document.getElementById('leapfrogBrandingStyle') === null &&
            document.getElementById('experience-branding-css') === null &&
            window.parent === window
        ) {
            const link = document.createElement('link');
            link.href = appConfig.leapfrogBrandingFile;
            link.rel = 'stylesheet';
            link.id = 'leapfrogBrandingStyle';
            document.body.appendChild(link);
        }

        if (
            appConfig.whiteListDomains &&
            getMetaElement(appConfig.whiteListDomains)
        ) {
            const meta = document.createElement('meta');
            meta.httpEquiv = 'Content-Security-Policy';
            meta.content = appConfig.whiteListDomains;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        return (
            <Helmet
                htmlAttributes={{
                    lang:
                        LocaleCodeByLanguageTagMap[locale] ??
                        LocaleCodeByLanguageTagMap.en_US,
                }}
            />
        );
    }
}

export default AppHelmet;
