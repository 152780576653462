const DEFAULT_FONT_FAMILY = 'Roboto,sans-serif';

export const typography = {
    typography: {
        fontFamily: DEFAULT_FONT_FAMILY,
        h1: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        h2: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        h3: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        h4: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        h5: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        h6: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        subtitle1: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        subtitle2: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        body1: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        body2: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        button: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        caption: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        overline: {
            fontFamily: DEFAULT_FONT_FAMILY,
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '4px',
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem',
        },
        mediumAvatar: {
            width: '36px',
            height: '36px',
            fontSize: '1.2rem',
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem',
        },
    },
};
