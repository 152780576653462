import { createBrowserHistory as createHistory } from 'history';
import Firebase from 'common-ui/src/analytics/vendors/Firebase';

let appContext = process.env.REACT_APP_CONTEXT;
const setAppContext = (ctx) => {
    appContext = ctx;
};

const handleLogout = (url) => {
    if (typeof url === 'string' && url.indexOf('/app/logout') === 0) {
        window.location.href = `${appContext}${url.substring(1)}`;

        Firebase.deleteApp();

        return true;
    }

    return false;
};

const patchHistory = (history) => {
    const { push: originalPush, replace: originalReplace } = history;
    // eslint-disable-next-line no-param-reassign
    history.push = (...args) => {
        const [url, ...rest] = args;

        let patchedURL = url;

        if (typeof url === 'string' && url.indexOf('/frame') === 0) {
            patchedURL = `/app/home${patchedURL}`;
        }

        if (handleLogout(patchedURL)) {
            return;
        }

        originalPush.apply(this, [patchedURL, ...rest]);
    };

    // eslint-disable-next-line no-param-reassign
    history.replace = (...args) => {
        const [url] = args;

        if (handleLogout(url)) {
            return;
        }

        originalReplace.apply(this, [...args]);
    };

    return history;
};

let history;
export default () => {
    if (!history) {
        history = patchHistory(createHistory({ basename: appContext }));
    }

    return history;
};
export { setAppContext };
