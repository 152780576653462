/* eslint-disable one-var,prefer-destructuring,react/destructuring-assignment,react/prop-types,prefer-const,no-underscore-dangle,padding-line-between-statements,class-methods-use-this */
import React, { Component } from 'react';
import Moment from 'moment';

export default class DatePickerDayComponent extends Component {
    render() {
        let date = this.props.date,
            dayComponentOpts = {};

        date = Moment(date);

        if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
            dayComponentOpts.onClick = this._onClickHandler;
            dayComponentOpts.className = 'di-calendar-disable-holidays';
        }
        return (
            <div {...dayComponentOpts} disabled="disabled">
                {this.props.label}
            </div>
        );
    }

    _onClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
    }
}
