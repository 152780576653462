/* eslint-disable no-shadow */
import Fetch from 'fetch';
import Constants from './common/constants/Constants';

/*
 * Uses the new fetch API but returns the data in a format expected by SDP classes
 */
export default {
    get(url, queryString, headers) {
        return Fetch.get(url, queryString, headers).then((data) => {
            return {
                status: Constants.HTTP_STATUS_OK,
                data,
            };
        });
    },
    post(url, data, headers) {
        return Fetch.post(url, data, headers).then((data) => {
            return {
                status: Constants.HTTP_STATUS_OK,
                data,
            };
        });
    },
    postFormData(url, data, headers) {
        return Fetch.postFormData(url, data, headers).then((data) => {
            return {
                status: Constants.HTTP_STATUS_OK,
                data,
            };
        });
    },
    patch(url, data, headers) {
        return Fetch.patch(url, data, headers).then((data) => {
            return {
                status: Constants.HTTP_STATUS_OK,
                data,
            };
        });
    },
    patchFormData(url, data, headers) {
        return Fetch.patchFormData(url, data, headers).then((data) => {
            return {
                status: Constants.HTTP_STATUS_OK,
                data,
            };
        });
    },
    put(url, data, headers) {
        return Fetch.put(url, data, headers).then((data) => {
            return {
                status: Constants.HTTP_STATUS_OK,
                data,
            };
        });
    },

    putFormData(url, data, headers) {
        return Fetch.putFormData(url, data, headers).then((data) => {
            return {
                status: Constants.HTTP_STATUS_OK,
                data,
            };
        });
    },
};
