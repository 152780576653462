/* eslint-disable filenames/match-exported,react/prefer-stateless-function,no-useless-constructor,no-var,prefer-template,react/destructuring-assignment,padding-line-between-statements,react/prop-types,react/no-danger,react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Alert extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var className = 'media message message-' + this.props.type;
        return (
            <div className={className}>
                <div className="media-left" />
                {this.props.dangerouslySetInnerHTML ? (
                    <div
                        className="media-body"
                        dangerouslySetInnerHTML={
                            this.props.dangerouslySetInnerHTML
                        }
                    />
                ) : (
                    <div className="media-body">{this.props.children}</div>
                )}
            </div>
        );
    }
}

Alert.propTypes = {
    /**
     * Selection will change icon of the message.
     */
    type: PropTypes.oneOf(['success', 'info', 'danger', 'warning', 'message']),
};
