/* eslint-disable filenames/match-exported,import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment,react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Calender extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                role={this.props.svgRole}
                aria-label={this.props.svgName}
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M0,5.1V18h18V5.1H0z M1.8,6.6h2.1v2.1H1.8V6.6z M1.8,10.6h2.1v2.1H1.8V10.6z M1.8,14.5h2.1v2.1H1.8V14.5z M5.8,6.6h2.1v2.1
                        H5.8V6.6z M5.8,10.6h2.1v2.1H5.8V10.6z M5.8,14.5h2.1v2.1H5.8V14.5z M9.9,6.6H12v2.1H9.9V6.6z M9.9,10.6H12v2.1H9.9V10.6z
                         M9.9,14.5H12v2.1H9.9V14.5z M13.8,6.6h2.1v2.1h-2.1V6.6z M13.8,10.6h2.1v2.1h-2.1V10.6z M13.8,14.5h2.1v2.1h-2.1V14.5z M0,1.2v2.5
                        h18V1.2h-1.4V0h-2.3v1.2H3.7V0H1.4v1.2H0z"
                    />
                </g>
            </svg>
        );
    }
}
