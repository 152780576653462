// eslint-disable-next-line import/prefer-default-export
export const sxProps = {
    wrapper: { px: { xs: 1, sm: 2, lg: 10 } },
    smPaddingX: { px: { xs: 3 } },
    mdAboveDisplay: { display: { xs: 'none', sm: 'block' } },
    mdAboveDisplayFlex: { display: { xs: 'none', md: 'flex' } },
    mdNoneDisplay: { display: { md: 'none' } },
    dNone: { display: 'none' },
    iconButtonDimensions: {
        height: 36,
        width: 36,
    },
};
