import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import { VStack } from '../Stack';
import { ErrorIllustration } from '../../illustrations';

export const Error = ({ title, description, size, hasMarkup = false, sx }) => {
    return (
        <VStack sx={sx}>
            <ErrorIllustration sx={{ fontSize: size ?? 250 }} />
            {title && (
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    align="center"
                >
                    {hasMarkup ? (
                        <Box dangerouslySetInnerHTML={{ __html: title }} />
                    ) : (
                        title
                    )}
                </Typography>
            )}
            {description && (
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    align="center"
                >
                    {description}
                </Typography>
            )}
        </VStack>
    );
};

Error.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    size: PropTypes.number,
    hasMarkup: PropTypes.bool,
    sx: PropTypes.object,
};
