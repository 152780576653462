/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Settings extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M15.9,10.9l2-0.7C18,9.8,18,9.4,18,9c0-0.5,0-0.9-0.1-1.3L15.8,7c-0.6-0.2-0.8-0.8-0.6-1.4l1-2c-0.5-0.7-1.1-1.3-1.8-1.8
                l-2.1,1c-0.5,0.2-1.2,0-1.4-0.6l-0.8-2.1C9.7,0.1,9.4,0,9,0C8.5,0,8.1,0,7.6,0.1L6.9,2.2C6.7,2.8,6.1,3.1,5.5,2.8l-2-0.9
                C2.8,2.4,2.2,3,1.7,3.7l0.9,1.9C2.9,6.1,2.6,6.8,2,7L0.1,7.7C0,8.1,0,8.6,0,9s0,0.9,0.1,1.3l2.1,0.7c0.6,0.2,0.8,0.8,0.6,1.4l-1,2
                c0.5,0.7,1.1,1.3,1.8,1.8l2.1-1c0.5-0.2,1.2,0,1.4,0.6l0.8,2.2C8.3,17.9,8.6,18,9,18c0.5,0,0.9,0,1.4-0.1l0.7-2.1
                c0.2-0.6,0.8-0.8,1.4-0.6l2,0.9c0.7-0.5,1.3-1.1,1.8-1.8l-0.9-1.9C15.1,11.7,15.3,11.1,15.9,10.9z M8.8,11.9c-1.7,0-3.1-1.3-3.1-3.1
                s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1C11.9,10.6,10.5,11.9,8.8,11.9z"
                />
            </svg>
        );
    }
}
