/* eslint-disable react/no-unknown-property */
import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const NoSearchResultsIllustration = (props) => {
    return (
        <SvgIcon
            viewBox="0 0 266 199"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_3127_18858)">
                <rect x="6" y="22" width="122" height="15" fill="#F0F2F4" />
                <rect x="6" y="45" width="122" height="15" fill="#F0F2F4" />
                <rect x="6" y="67" width="122" height="15" fill="#F0F2F4" />
                <path
                    d="M89.7295 172.989C57.9215 140.623 58.6903 87.8503 77.8546 87.308C97.019 86.7657 113.027 128.259 100.881 175.158L89.7295 172.989V172.989Z"
                    fill="#EFF7EE"
                />
                <path
                    d="M46.3809 133.771C36.4325 141.002 55.1808 169.218 83.76 174.822L90.4526 171.93C79.8621 142.701 56.3294 126.541 46.3719 133.771H46.3809Z"
                    fill="#EFF7EE"
                />
                <path
                    d="M205.647 174.352C187.061 174.189 129.46 173.466 94.5045 171.369C29.6858 167.483 -23.2491 173.9 11.5253 181.239C21.2477 183.291 -6.77988 195.582 61.8284 198.357C122.053 200.797 183.453 195.591 213.163 193.35C242.881 191.109 256.583 187.141 262.95 185.351C268.548 183.779 270.61 174.93 205.638 174.352H205.647Z"
                    fill="#F0F2F4"
                />
                <path
                    d="M62.1807 98.4419C51.8886 86.1411 46.9957 70.5956 48.4157 54.6615C49.8356 38.6912 57.4055 24.2213 69.7506 13.936C82.0596 3.69588 97.5882 -1.1666 113.488 0.2343C146.435 3.16263 170.881 32.3285 167.951 65.2631C166.531 81.2515 158.934 95.7214 146.589 106.016C134.298 116.256 118.778 121.109 102.879 119.708C86.9343 118.289 72.4819 110.743 62.1898 98.4419H62.1807ZM139.39 97.4115C149.456 89.0242 155.615 77.2566 156.773 64.269C159.16 37.4801 139.299 13.7643 112.493 11.3963C99.5598 10.2485 86.9343 14.1981 76.9406 22.5402C66.8836 30.9185 60.7246 42.677 59.567 55.6466C58.4184 68.6163 62.3887 81.2515 70.7816 91.2566C79.1474 101.253 90.9137 107.399 103.865 108.555C116.78 109.703 129.405 105.745 139.39 97.4115Z"
                    fill="#D1DBE0"
                />
                <path
                    opacity="0.2"
                    d="M77.0583 22.6753C87.0158 14.3693 99.5961 10.4287 112.484 11.5675C139.191 13.9264 158.997 37.57 156.61 64.2594C155.461 77.2019 149.32 88.9333 139.29 97.2935C129.333 105.609 116.762 109.522 103.883 108.392C90.9681 107.253 79.256 101.126 70.9083 91.1567C62.5426 81.1877 58.5903 68.5977 59.7389 55.6823C60.8875 42.7398 67.0284 31.0174 77.0583 22.6843V22.6753Z"
                    fill="white"
                />
                <path
                    opacity="0.2"
                    d="M148.406 41.34C140.339 31.7055 129.025 25.7946 116.544 24.6919C104.081 23.5893 91.9171 27.4034 82.2852 35.4291C72.6262 43.4821 66.6933 54.7796 65.5628 67.2522V67.3154C65.1196 72.1689 65.4361 76.9681 66.4581 81.6046C62.3521 73.7324 60.5433 64.8661 61.3482 55.81V55.7467C62.4878 43.2651 68.4297 31.9585 78.0978 23.9147C87.7117 15.8979 99.885 12.0838 112.339 13.1865C124.828 14.2891 136.134 20.2 144.201 29.8346C149.139 35.7184 152.494 42.5602 154.086 49.8449C152.521 46.8442 150.631 43.9972 148.406 41.34V41.34Z"
                    fill="white"
                />
                <path
                    opacity="0.31"
                    d="M159.549 64.5123C162.072 36.196 141.072 11.1245 112.746 8.62995C99.0714 7.42789 85.7314 11.6035 75.168 20.4065C64.5412 29.2638 58.0295 41.6912 56.7995 55.4019L51.7891 54.959C51.7891 54.959 51.7981 54.8867 51.7981 54.8506C53.1547 39.8203 60.2995 26.218 71.9302 16.5382C83.5156 6.86753 98.176 2.28523 113.171 3.61383C144.256 6.37043 167.309 33.8823 164.55 64.9551L159.531 64.5213L159.549 64.5123Z"
                    fill="#F0F2F4"
                />
                <path
                    d="M147.493 118.715C144.527 115.262 141.551 111.819 138.576 108.348C137.228 106.739 136.622 104.733 136.812 102.826C138.287 101.823 139.716 100.738 141.099 99.5811C142.537 98.3791 143.912 97.1138 145.205 95.7852C147.105 95.9478 148.986 96.8788 150.333 98.4875C153.318 101.94 156.302 105.42 159.305 108.899C162.118 112.243 160.752 115.768 157.379 118.516L157.361 118.534C154.014 121.318 150.27 122.05 147.502 118.715H147.493Z"
                    fill="#D1DBE0"
                />
                <path
                    d="M162.217 105.385L143.425 121.109L196.389 184.322L215.18 168.597L162.217 105.385Z"
                    fill="#CEE9FD"
                />
                <path
                    d="M147.195 125.619L165.989 109.893L162.208 105.383L143.424 121.109L147.195 125.619Z"
                    fill="#D1DBE0"
                />
                <path
                    d="M154.339 113.724L146.037 120.668L148.632 123.766L156.933 116.822L154.339 113.724Z"
                    fill="#CDD5D7"
                />
                <path
                    d="M200.166 188.822C209.689 189.671 218.1 182.631 218.95 173.095C217.693 171.595 216.436 170.095 215.179 168.604C208.912 173.846 202.662 179.079 196.395 184.33C197.652 185.83 198.909 187.33 200.175 188.831L200.166 188.822Z"
                    fill="#D1DBE0"
                />
                <path
                    d="M206.911 176.471L198.617 183.41L201.857 187.277L210.15 180.337L206.911 176.471Z"
                    fill="#CDD5D7"
                />
            </g>
            <defs>
                <clipPath id="clip0_3127_18858">
                    <rect
                        width="265"
                        height="199"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
