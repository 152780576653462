/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Info extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M0,9c0,4.9,4.1,9,9,9s9-4.1,9-9s-4.1-9-9-9S0,4.1,0,9z M6.1,12.6h1.8V9.1H7.1c-0.3,0-0.7-0.4-0.7-0.6V7.8
                        c0-0.3,0.4-0.6,0.6-0.6h3.6v5.4h1.8v1.9H6.1V12.6z M7.7,4.1c0-0.8,0.7-1.4,1.5-1.4c0.7,0,1.4,0.6,1.4,1.4S10,5.4,9.3,5.4
                        S7.7,4.8,7.7,4.1z"
                    />
                </g>
            </svg>
        );
    }
}
