/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import { Box } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ScreenSettingsItemContainer from '../ScreenSettingsItemContainer';

export default class DragDropContainer extends Component {
    render() {
        return (
            <Box m="21px 24px">
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="accountDropable">
                        {(provided) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {this.props.data.map((data, index) => {
                                    return (
                                        <ScreenSettingsItemContainer
                                            title={data.name}
                                            subtitle={data.subText}
                                            index={index}
                                            otherData={data}
                                        />
                                    );
                                })}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        );
    }

    onDragEnd = () => {};
}
