/* eslint-disable react/prefer-stateless-function,prefer-const,react/destructuring-assignment,react/prop-types,prefer-destructuring,padding-line-between-statements,jsx-a11y/no-noninteractive-element-interactions,prefer-template,jsx-a11y/no-noninteractive-tabindex,no-nested-ternary */
import React, { Component } from 'react';

export default class DropdownItem extends Component {
    render() {
        let cssClassName = this.props.className ? this.props.className : '';
        let cssStyle = this.props.style ? this.props.style : null;
        let title = this.props.title ? this.props.title : '';
        let skipAnchorWrapping = this.props.skipAnchorWrapping
            ? this.props.skipAnchorWrapping
            : false;
        let body;

        if (skipAnchorWrapping) {
            body = <span>{this.props.children}</span>;
        } else if (this.props.child) {
            body = (
                <span className="btn btn-tertiary">{this.props.children}</span>
            );
        } else {
            body = (
                <span role={!this.props.noRole ? 'menuitem' : null}>
                    {this.props.children}
                </span>
            );
        }

        // Override the default Dropdown parent onClick if customOnClick is defined on this component.
        let onClick = this.props.onClick;
        if (this.props.customOnClick) {
            onClick = this.props.customOnClick;
        }

        return (
            <li
                className={
                    'dropdown-item ' +
                    (this.props.active ? 'active ' : '') +
                    cssClassName
                }
                onClick={onClick}
                title={title}
                aria-label={title}
                style={cssStyle}
                onKeyDown={this.props.onKeyDown}
                tabIndex="0"
                role={
                    !this.props.noRole
                        ? this.props.role
                            ? this.props.role
                            : 'menuitem'
                        : null
                }
            >
                {body}
            </li>
        );
    }
}
