import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { TextField, Grid, Box } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

export const DateRange = ({
    fromLabel,
    toLabel,
    defaultStartDate,
    defaultEndDate,
    minDate,
    maxDate,
    calendars,
    handleChange,
    isMobileView,
}) => {
    const [dateRangeValue, setDateRangeValue] = useState([
        defaultStartDate,
        defaultEndDate,
    ]);

    const mawAllowedDate = maxDate ?? moment();

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            localeText={{ start: fromLabel, end: toLabel }}
        >
            <Box sx={{ width: '100%' }}>
                <DateRangePicker
                    value={dateRangeValue}
                    onChange={(newValue) => {
                        setDateRangeValue(newValue);
                        handleChange(newValue);
                    }}
                    minDate={minDate}
                    maxDate={mawAllowedDate}
                    calendars={calendars}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={isMobileView ? 12 : 6}>
                                    <TextField
                                        id="from-date"
                                        {...startProps}
                                        sx={{
                                            mr: (theme) => theme.spacing(1.5),
                                            ml: (theme) => theme.spacing(0.2),
                                            backgroundColor: (theme) =>
                                                theme.palette.common.white,
                                            minWidth: '100%',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={isMobileView ? 12 : 6}>
                                    <TextField
                                        id="to-date"
                                        {...endProps}
                                        sx={{
                                            backgroundColor: (theme) =>
                                                theme.palette.common.white,
                                            minWidth: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                />
            </Box>
        </LocalizationProvider>
    );
};

DateRange.propTypes = {
    fromLabel: PropTypes.string,
    toLabel: PropTypes.string,
    defaultStartDate: PropTypes.object,
    defaultEndDate: PropTypes.object,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    calendars: PropTypes.number,
    handleChange: PropTypes.func,
    isMobileView: PropTypes.bool,
};
