/* eslint-disable no-underscore-dangle,react/boolean-prop-naming,spaced-comment,react/destructuring-assignment,react/no-unused-state,prefer-template,prefer-const,react/prop-types,react/sort-comp,react/no-string-refs,eqeqeq,prefer-destructuring,padding-line-between-statements,jsx-a11y/label-has-for,chai-friendly/no-unused-expressions,jsx-a11y/no-static-element-interactions,react/jsx-curly-brace-presence,no-return-assign,react/jsx-boolean-value,no-unused-vars,one-var,object-shorthand,no-nested-ternary,consistent-return,no-plusplus,class-methods-use-this,react/button-has-type,prefer-rest-params,react/no-access-state-in-setstate,func-names,no-lonely-if,no-unneeded-ternary,react/no-find-dom-node */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import moment from 'moment';
import SVG from '../images/SVG';
import Almanac from '../calendar/Almanac';
import ClickOutHandler from '../../util/clickout/ClickOutHandler';
import Localizer from '../../util/Localizer';
import styles from '../../styles/AlmanacPicker.scss';
import Constants from '../../constants/Constants';
import AlmanacPickerDefaultErrors from './AlmanacPickerDefaultErrors.json';

const _i18nDefaultStrings = {
    'comp.calendar.label.save': 'Save',
    'comp.calendar.label.headertop': 'Select Date',
};

export default class AlmanacPicker extends Component {
    static propTypes = {
        /**
         * A date object representing the data for the current date in the component.Default Value: Current Date
         */
        defaultValue: PropTypes.object,
        /**
         * A boolean for hiding the close button.
         */
        hideCloseButton: PropTypes.bool,
        /**
         * A boolean for hiding the save button.
         */
        hideSaveButton: PropTypes.bool,
        /**
         * A function to be run when the almanac is opened.
         */
        onOpen: PropTypes.func,
        /**
         * A function to be run when there is a change to the date.
         */
        onChange: PropTypes.func,
        /**
         * Custom function to be run when the save button is pressed. Takes in default value as an input and should return true if you want the calendar to close when the save button is pressed.
         */
        onOK: PropTypes.func,
        /**
         * A function to be run when the window is closed.
         */
        onCancel: PropTypes.func,
        /**
         * A string that determines the format of the date.
         */
        dateFormat: PropTypes.string,
        /**
         * A boolean that will let you click to select rather than use the save button.
         */
        closeOnSelect: PropTypes.bool,
        /**
         * A function to be run when a date is selected.
         */
        onDateSelect: PropTypes.func,
        /**
         * A boolean to disable the almanac picker.
         */
        disable: PropTypes.bool,
        /**
         * A header element to add to the almanac.
         */
        header: PropTypes.element,
        /**
         * A footer element to add to the almanac.
         */
        footer: PropTypes.element,
        /**
         * Label to add above the prefix label.
         */
        prefixlabel: PropTypes.string,
        //::.. Calendar/ Almanac Properties ..::
        /**
         * Internationalization strings for using the almanac picker in other languages.
         */
        i18nStrings: PropTypes.object,
        /**
         * Formatting for the month in the header of the almanac.
         */
        headerMonthFormat: PropTypes.string,
        /**
         * Holidays to block out for selection.
         */
        holidays: PropTypes.array,
        /**
         * A boolean to make weekends unselectable.
         */
        disableWeekends: PropTypes.bool,
        /**
         * An object representing the selectable date range for the almanac picker and accompanying almanac.
         */
        selectRange: PropTypes.object,
        //onSelect: PropTypes.func,
        /**
         * A function to be run when the user changes the month.
         */
        onMonthChange: PropTypes.func,
        /**
         * A date object that represents the first selectable day on your almanac.
         */
        maxStartDate: PropTypes.object,
        /**
         * A date object that represents the last selectable day on your almanac.
         */
        maxEndDate: PropTypes.object,
        /**
         * If true will make the dropdown sit on top of anything it covers, if false, opening dropdown shifts content down to make space for the dropdown.
         */
        useOverlay: PropTypes.bool,
        /**
         * A prop to show loading image when almanac isn't fully loaded.
         */
        loading: PropTypes.bool,
        /**
         * Loading Text for the almanac.
         */
        loadingText: PropTypes.string,
        /**
         * Boolean to enable manual entry
         */
        manualEntryEnabled: PropTypes.bool,
        /**
         * String of the error should the user type in an incorrect format
         */
        formatError: PropTypes.string,
        /**
         * String of the error should the user type in a date that is on a designated holiday
         */
        holidaysError: PropTypes.string,
        /**
         * String of the error should the user type in a date that is on a weekend when they are disabled
         */
        disableWeekendsError: PropTypes.string,
        /**
         * String of the error should the user type in a date that is before the max start date
         */
        maxStartDateError: PropTypes.string,
        /**
         * String of the error should the user type in a date that is after the max end date
         */
        maxEndDateError: PropTypes.string,
        /**
         * String containing the locale to get the correct static labels.
         */
        locale: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this._handleOnChange = this._handleOnChange.bind(this);
        this._onInputKeyDown = this._onInputKeyDown.bind(this);
        this._getCalenderBottom = this._getCalenderBottom.bind(this);
        this._getCalenderTop = this._getCalenderTop.bind(this);
        this._openCalendar = this._openCalendar.bind(this);
        this._closeCalendar = this._closeCalendar.bind(this);
        this._onClickOutSide = this._onClickOutSide.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onMonthChange = this._onMonthChange.bind(this);
        this._onDateSelect = this._onDateSelect.bind(this);
        this._onDateFocus = this._onDateFocus.bind(this);
        this._onClickSave = this._onClickSave.bind(this);
        this._onClickCancel = this._onClickCancel.bind(this);
        this._onKeyDownCancel = this._onKeyDownCancel.bind(this);
        this._getString = this._getString.bind(this);
        this._focusLastElement = this._focusLastElement.bind(this);
        this._focusFirstElement = this._focusFirstElement.bind(this);
        this._getFirstAndLastFocusableElements = this._getFirstAndLastFocusableElements.bind(
            this,
        );
        this._onResetFocus = this._onResetFocus.bind(this);
        this._onInputFocus = this._onInputFocus.bind(this);
        this._onInputBlur = this._onInputBlur.bind(this);
        this._toggleCalendar = this._toggleCalendar.bind(this);
        this._setAlmanacPickerDate = this._setAlmanacPickerDate.bind(this);
        this._addErrorMessage = this._addErrorMessage.bind(this);
        this._isValidDate = this._isValidDate.bind(this);
        this._isDateEqual = this._isDateEqual.bind(this);
        this._onInputPaste = this._onInputPaste.bind(this);
        this.resetError = this.resetError.bind(this);
        this._onDateKeyDown = this._onDateKeyDown.bind(this);
        this._focusManagementForAlmanacPreviousMonthKeyDown = this._focusManagementForAlmanacPreviousMonthKeyDown.bind(
            this,
        );
        this._onDateIconFocussable = this._onDateIconFocussable.bind(this);
        this._createRemoveSpan = this._createRemoveSpan.bind(this);
        this.state = {
            isOpen: false,
            isDisplay: this.props?.suppressDefaultValue ? false : true,
            defaultValue: this.props.defaultValue
                ? moment(this.props.defaultValue)
                : moment(),
            currentMonth: this.props.defaultValue
                ? moment(this.props.defaultValue).startOf('month')
                : moment().startOf('month'),
            firstFocusableElement: null,
            lastFocusableElement: null,
            initialValue: this.props.defaultValue
                ? moment(this.props.defaultValue)
                : moment(),
            initialMonth: moment().startOf('month'),
            ariaLabelChanged: '',
            calendarStatus: '',
            prevFocussableElement: '',
            labelText: '',
            uniqueDateTimeValue:
                Date.now() + '-' + Math.floor(Math.random() * 100),
        };

        let locale = Localizer.getLocale(this.props.locale);
        let defaultErrors = AlmanacPickerDefaultErrors[locale];
        this.errors = {
            formatError: this.props.formatError
                ? this.props.formatError
                : defaultErrors.formatError,
            invalidDateError: this.props.invalidDateError
                ? this.props.invalidDateError
                : defaultErrors.invalidDateError,
            holidaysError: this.props.holidaysError
                ? this.props.holidaysError
                : defaultErrors.holidaysError,
            disabledWeekendsError: this.props.disableWeekendsError
                ? this.props.disableWeekendsError
                : defaultErrors.disableWeekendsError,
            maxStartDateError: this.props.maxStartDateError
                ? this.props.maxStartDateError
                : defaultErrors.maxStartDateError,
            maxEndDateError: this.props.maxEndDateError
                ? this.props.maxEndDateError
                : defaultErrors.maxEndDateError,
        };
    }

    componentDidMount() {
        if (this.props.manualEntryEnabled) {
            this._setAlmanacPickerDate();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !prevState.isOpen &&
            this.state.isOpen &&
            this.refs &&
            this.refs.calendar
        ) {
            this._getFirstAndLastFocusableElements(this.refs.calendar);
        }

        if (
            prevState.firstFocusableElement !== this.state.firstFocusableElement
        ) {
            if (prevState.firstFocusableElement) {
                prevState.firstFocusableElement.removeEventListener(
                    'keydown',
                    this._focusLastElement,
                );
            }

            if (this.state.firstFocusableElement) {
                this.state.firstFocusableElement.addEventListener(
                    'keydown',
                    this._focusLastElement,
                );
            }
        }

        if (
            prevState.lastFocusableElement !== this.state.lastFocusableElement
        ) {
            if (prevState.lastFocusableElement) {
                prevState.lastFocusableElement.removeEventListener(
                    'keydown',
                    this._focusFirstElement,
                );
            }

            if (this.state.lastFocusableElement) {
                this.state.lastFocusableElement.addEventListener(
                    'keydown',
                    this._focusFirstElement,
                );
            }
        }

        if (
            this.props.manualEntryEnabled &&
            prevState.defaultValue != this.state.defaultValue
        ) {
            this._setAlmanacPickerDate();
        }

        if (this.state.isOpen) {
            this._createRemoveSpan();
        }

        if (prevState.isOpen && !this.state.isOpen) {
            this._createRemoveSpan();
        }
    }

    _onDateKeyDown(event) {
        let key = event.key;
        event.stopPropagation();
        if (key === 'Enter' || key === 'ArrowDown') {
            this._toggleCalendar();
        }
    }

    _setAlmanacPickerDate() {
        // Only called if manual entry is enabled
        let format = this.props.format
            ? this.props.format
            : Constants.MANUAL_ENTRY_DATE_FORMAT;
        let formattedDefaultValue = this.state.defaultValue.format(format);
        let almanacPickerInput = this.diAlmanacPickerInput;

        if (almanacPickerInput) {
            almanacPickerInput.value = formattedDefaultValue;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.defaultValue !== nextProps.defaultValue) {
            this.setState({
                defaultValue: nextProps.defaultValue
                    ? moment(nextProps.defaultValue)
                    : moment(),
                initialValue: nextProps.defaultValue
                    ? moment(nextProps.defaultValue)
                    : moment(),
                currentMonth: nextProps.defaultValue
                    ? moment(nextProps.defaultValue).startOf('month')
                    : moment().startOf('month'),
            });
        }
    }

    render() {
        let isOpen = this.state.isOpen ? ' open' : '';
        let useOverlay = this.props.useOverlay ? ' almanac-overlay' : '';
        let format = this.props.dateFormat
            ? this.props.dateFormat
            : Constants.MANUAL_ENTRY_DATE_FORMAT;
        let error = this.state.error;
        let label = this.props.prefixlabel ? (
            <label
                className={styles['input-label']}
                htmlFor={this.props.id ? this.props.id : null}
            >
                {this.props.prefixlabel}
            </label>
        ) : undefined;

        let {
            i18nStrings,
            headerMonthFormat,
            holidays,
            disableWeekends,
            selectRange,
            maxStartDate,
            maxEndDate,
            dateFilter,
            loading,
            loadingText,
        } = this.props;

        let almanacProps = {
            i18nStrings,
            headerMonthFormat,
            holidays,
            disableWeekends,
            selectRange,
            onMonthChange: this._onMonthChange,
            maxStartDate,
            maxEndDate,
            dateFilter,
            loading,
            loadingText,
            currentMonth: this.state.currentMonth,
        };

        this.state.labelText = this.state.ariaLabelChanged
            ? this.state.ariaLabelChanged
            : 'DatePicker ' +
              this.props.ariaLabel +
              ' ' +
              this.state.defaultValue.format(format);
        let inputAttributes = {
            type: 'text',
            // role: !BrowserDetect.isIE11() ? 'application' : null,
            className:
                'form-control ' +
                (this.props.disable ? '' : styles['date-input'] + ' '),
            'aria-label': this.state.ariaLabelChanged
                ? this.state.ariaLabelChanged
                : 'DatePicker ' +
                  this.props.ariaLabel +
                  ' ' +
                  this.state.defaultValue.format(format),
            onChange: this._handleOnChange,
            onKeyDown: this._onInputKeyDown,
            disabled: this.props.disable,
        };

        let inputContainerDivAttributes = {};

        let calendarIconContainerAttributes = {
            className:
                'form-control-feedback ' +
                (this.props.prefixlabel ? styles['has-label'] + ' ' : ''),
        };

        if (this.props.manualEntryEnabled) {
            inputAttributes.onFocus = this._onInputFocus;
            inputAttributes.onBlur = this._onInputBlur;
            inputAttributes.onPaste = this._onInputPaste;
            inputAttributes.id = this.props.id ? this.props.id : null;
            inputAttributes.maxLength = '10';
            calendarIconContainerAttributes.onClick = this._toggleCalendar;
            calendarIconContainerAttributes.onKeyDown = this._onDateKeyDown;
            calendarIconContainerAttributes.role = !this.props
                .noCalendarIconAttributesRole
                ? 'application'
                : null;
            calendarIconContainerAttributes.className +=
                styles['manual-entry-enabled-calendar-icon-container'];
            calendarIconContainerAttributes.tabIndex = '0';
            if (error) {
                inputContainerDivAttributes.className = 'has-error';
            }
        } else {
            inputAttributes.value = this.state.isDisplay
                ? this.state.defaultValue.format(format)
                : '';
            inputAttributes.readOnly = true;
            // inputAttributes.role = "application"
            inputContainerDivAttributes.onClick = this._toggleCalendar;
            inputAttributes.id = this.props.id ? this.props.id : null;
        }
        if (this.state.labelText) {
            !this.props.disable
                ? (calendarIconContainerAttributes.tabIndex = '0')
                : '-1';
            calendarIconContainerAttributes.id = `dateIcon_${this.state.uniqueDateTimeValue}`;
            calendarIconContainerAttributes.role = !this.props
                .noCalendarIconAttributesRole
                ? 'application'
                : null;
        }

        return (
            <ClickOutHandler onClickOut={this._onClickOutSide}>
                <div
                    className="di-almanac-picker form-group"
                    ref="diAlmanacPicker"
                    onKeyDown={this._onKeyDown}
                >
                    <div {...inputContainerDivAttributes}>
                        <div className="has-feedback">
                            <div className={'input-group'}>
                                {label}
                                <input
                                    ref={(input) => {
                                        this.diAlmanacPickerInput = input;
                                    }}
                                    {...inputAttributes}
                                />
                                <div
                                    aria-label={this.state.labelText}
                                    {...calendarIconContainerAttributes}
                                    style={this.props.style}
                                    ref={(icon) => (this.calendarIcon = icon)}
                                >
                                    <SVG
                                        role={this.props.role}
                                        ariaLabel={this.props.ariaLabel}
                                        name="Calendar"
                                        svgRole={this.props.svgRole}
                                        svgName={this.props.svgName}
                                        width="20"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        ref="calendar"
                        className={'calendar' + isOpen + useOverlay}
                    >
                        {this._getCalenderTop()}
                        <Almanac
                            defaultValue={this.state.defaultValue}
                            focusSelectedDateOnMount={true}
                            footer={<div />}
                            onSelect={this._onDateSelect}
                            onDateFocus={this._onDateFocus}
                            currentMonth={this.state.currentMonth}
                            onResetFocus={this._onResetFocus}
                            initialValue={this.state.initialValue}
                            manualEntryEnabled={this.props.manualEntryEnabled}
                            focusManagementForAlmanacPreviousMonthKeyDown={
                                this
                                    ._focusManagementForAlmanacPreviousMonthKeyDown
                            }
                            onDateIconFocussable={this._onDateIconFocussable}
                            iconFocussable={this.props.iconFocussable}
                            {...almanacProps}
                        />
                        {this._getCalenderBottom()}
                        <div
                            className={
                                'footer' + (this.props.footer ? '' : ' empty')
                            }
                        >
                            {this.props.footer}
                        </div>
                    </div>
                    {this._addErrorMessage()}
                </div>
            </ClickOutHandler>
        );
    }

    _onInputBlur(event) {
        let errorInfo = {
            error: false,
            errorMessage: null,
        };

        if (this.props.manualEntryEnabled) {
            let value = this.diAlmanacPickerInput.value;
            let month, day, year, dateString, date, defaultValue, currentMonth;
            if (value.split('/').length === 3) {
                dateString = value.split('/');
                month = dateString[0];
                day = dateString[1];
                year = dateString[2];
            } else if (value.split('-').length === 3) {
                dateString = value.split('-');
                month = dateString[0];
                day = dateString[1];
                year = dateString[2];
            } else if (
                value.length === 6 ||
                (value.length === 8 &&
                    !value.includes('/') &&
                    !value.includes('-'))
            ) {
                month = value.substring(0, 2);
                day = value.substring(2, 4);
                year = value.substring(4);
            } else {
                errorInfo.error = true;
                errorInfo.errorMessage = this.errors.formatError;
            }

            date = month + '/' + day + '/' + year;
            // if format is correct, but an invalid date is entered
            if (!errorInfo.error) {
                if (!moment(date)._isValid) {
                    if (this.diAlmanacPickerInput) {
                        setTimeout(() => {
                            this.diAlmanacPickerInput.value = date;
                        });
                    }
                    errorInfo.error = true;
                    errorInfo.errorMessage = this.errors.invalidDateError;
                }
            }
            defaultValue = moment(date);
            currentMonth = moment(date).startOf('month');
            if (!errorInfo.error) {
                errorInfo = this._isValidDate(defaultValue);
                errorInfo.defaultValue = defaultValue;
            }
            if (errorInfo.error) {
                errorInfo.isOpen = false;
                this.setState(errorInfo);
                this.setState({ ariaLabelChanged: 'Please enter valid date' });
            } else {
                if (this.props.onDateSelect) {
                    this.props.onDateSelect(defaultValue);
                }
                this.setState({
                    defaultValue: defaultValue,
                    currentMonth: currentMonth,
                    initialValue: defaultValue,
                    initialMonth: currentMonth,
                    error: false,
                    isOpen: false,
                    ariaLabelChanged: '',
                });
            }
        }
    }

    _createRemoveSpan() {
        let node = document.createElement('span');
        let textnodeClass = document.createAttribute('class');
        let textnodeRole = document.createAttribute('role');
        let textnodeAriaLive = document.createAttribute('aria-live');
        let textnodeAriaAtomic = document.createAttribute('aria-atomic');
        let textnodeId = document.createAttribute('id');
        textnodeClass.value = 'sr-only';
        textnodeRole.value = 'alert';
        textnodeId.value = 'calendarOpenClose';
        textnodeAriaLive.value = 'assertive';
        textnodeAriaAtomic.value = 'true';
        node.setAttributeNode(textnodeClass);
        node.setAttributeNode(textnodeRole);
        node.setAttributeNode(textnodeId);
        node.setAttributeNode(textnodeAriaLive);
        node.setAttributeNode(textnodeAriaAtomic);
        let flagDatePickerEnter = false;
        let activeDomElement = document.activeElement;
        if (activeDomElement === this.calendarIcon) {
            flagDatePickerEnter = true;
        }
        node.innerHTML = flagDatePickerEnter
            ? this.state.isOpen
                ? 'Calendar Open'
                : this.state.labelText + 'Calendar Close'
            : '';
        setTimeout(() => {
            document.body.appendChild(node);
            setTimeout(() => {
                let hiddenSpanElement = document.getElementById(
                    'calendarOpenClose',
                );
                if (hiddenSpanElement) {
                    hiddenSpanElement.remove();
                }
            }, 1000);
        }, 500);
    }

    _onInputFocus() {
        if (this.props.manualEntryEnabled) {
            let diAlmanacPickerInput = this.diAlmanacPickerInput;
            if (
                diAlmanacPickerInput &&
                diAlmanacPickerInput.setSelectionRange
            ) {
                diAlmanacPickerInput.setSelectionRange(0, 9999);
                this.setState({
                    error: false,
                    isOpen: false,
                });
            }
        }
    }

    _toggleCalendar() {
        if (this.state.isOpen) {
            this._onClickOutSide();
        } else {
            this._openCalendar();
        }
    }

    _addErrorMessage() {
        if (
            this.state.error &&
            this.state.errorMessage &&
            this.props.manualEntryEnabled
        ) {
            return (
                <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    className="has-error"
                >
                    <p className={'help-block ' + styles['error-container']}>
                        <SVG name="AlertError" />
                        <div
                            className={
                                styles['error-message-container'] +
                                ' text-danger'
                            }
                        >
                            {this.state.errorMessage}
                        </div>
                    </p>
                </div>
            );
        }
    }

    _isValidDate(currentDate) {
        let isBeforeMaxStartDate = false;
        let isAfterMaxEndDate = false;
        let isWeekend = false;
        let isHoliday = false;
        let errorMessage = null;
        let errorObject = {
            error: false,
            errorMessage: null,
        };
        let format = Constants.MANUAL_ENTRY_DATE_FORMAT;

        if (this.props.holidays && this.props.holidays.length > 0) {
            for (let i = 0; i < this.props.holidays.length; i++) {
                isHoliday = this._isDateEqual(
                    moment(this.props.holidays[i]),
                    currentDate,
                );
                if (isHoliday) {
                    errorMessage = this.errors.holidaysError;
                }
            }
        }
        if (this.props.disableWeekends) {
            let dayOfWeek = currentDate.day();
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                isWeekend = true;
                errorMessage = this.errors.disabledWeekendsError;
            }
        }
        if (this.props.maxStartDate) {
            let maxSDate = moment(this.props.maxStartDate);
            isBeforeMaxStartDate =
                !this._isDateEqual(maxSDate, currentDate) &&
                maxSDate.valueOf() > currentDate.valueOf();
            if (isBeforeMaxStartDate) {
                let selectAfterDate = maxSDate
                    .clone()
                    .subtract(1, 'day')
                    .format(format);
                let maxStartDateError = this.errors.maxStartDateError;
                if (
                    maxStartDateError.includes(
                        Constants.MANUAL_ENTRY_MAX_START_DATE_KEY,
                    )
                ) {
                    maxStartDateError = maxStartDateError.replace(
                        Constants.MANUAL_ENTRY_MAX_START_DATE_KEY,
                        selectAfterDate,
                    );
                }
                errorMessage = maxStartDateError;
            }
        }
        if (this.props.maxEndDate) {
            let maxEDate = moment(this.props.maxEndDate);
            isAfterMaxEndDate =
                !this._isDateEqual(maxEDate, currentDate) &&
                maxEDate.valueOf() < currentDate.valueOf();
            if (isAfterMaxEndDate) {
                let selectBeforeDate = maxEDate
                    .clone()
                    .add(1, 'day')
                    .format(format);
                let maxEndDateError = this.errors.maxEndDateError;
                if (
                    maxEndDateError.includes(
                        Constants.MANUAL_ENTRY_MAX_END_DATE_KEY,
                    )
                ) {
                    maxEndDateError = maxEndDateError.replace(
                        Constants.MANUAL_ENTRY_MAX_END_DATE_KEY,
                        selectBeforeDate,
                    );
                }
                errorMessage = maxEndDateError;
            }
        }
        if (
            isHoliday ||
            isWeekend ||
            isBeforeMaxStartDate ||
            isAfterMaxEndDate
        ) {
            errorObject.error = true;
            errorObject.errorMessage = errorMessage;
        }

        return errorObject;
    }

    _isDateEqual(d1, d2) {
        return d1.format('YYYY MM DD') === d2.format('YYYY MM DD');
    }

    _getCalenderBottom() {
        return this.props.hideSaveButton ? (
            <div />
        ) : (
            <div
                className={
                    'bottom text-center' +
                    (this.props.hideSaveButton ? ' hidden' : '')
                }
            >
                <button
                    className={'btn btn-tertiary'}
                    onClick={this._onClickSave}
                >
                    {this._getString('label.save')}
                </button>
            </div>
        );
    }

    _onInputPaste(event) {
        let pastedString = event.clipboardData.getData('text');
        let allowedCharacters = Constants.MANUAL_ENTRY_ALLOWED_CHARACTERS;
        let isValid = true;
        for (let i = 0; i < pastedString.length; i++) {
            let character = pastedString[i];
            if (!allowedCharacters.includes(character)) {
                isValid = false;
                break;
            }
        }

        if (!isValid) {
            event.preventDefault();
        }
    }

    _getCalenderTop() {
        let topLabel = this._getString('label.headertop');

        return this.props.header ? (
            this.props.header
        ) : (
            <div className={'top'}>
                <div className={'section heading'}>
                    <h3>{topLabel}</h3>
                </div>
                <div
                    tabIndex="0"
                    className={
                        'section close' +
                        (this.props.hideCloseButton ? ' hidden' : '')
                    }
                    onClick={this._onClickCancel}
                    aria-label="close"
                    onKeyDown={this._onKeyDownCancel}
                    role="button"
                >
                    <SVG name="Close" width="15" />
                </div>
            </div>
        );
    }

    _onMonthChange(month) {
        if (this.refs && this.refs.calendar) {
            this._getFirstAndLastFocusableElements(this.refs.calendar);
        }

        this.setState({
            currentMonth: month.clone(),
        });

        if (this.props.onMonthChange) {
            this.props.onMonthChange(...arguments);
        }
    }

    _onDateSelect(date) {
        let state = {
            defaultValue: date,
            isOpen: this.props.closeOnSelect ? false : this.state.isOpen,
            ariaLabelChanged: '',
            isDisplay: true,
        };

        state.currentMonth = date.clone().startOf('month');

        if (this.props.closeOnSelect) {
            if (this.diAlmanacPickerInput) {
                this.diAlmanacPickerInput.focus();
            }
            state.initialValue = date.clone();
            state.initialMonth = date.clone().startOf('month');
        }
        if (this.props.onDateSelectDisplay) {
            this.props.onDateSelectDisplay();
        }
        this.setState(state);
        if (this.props.onDateSelect) {
            this.props.onDateSelect(date);
        }
    }

    _onDateFocus(date) {
        this._getFirstAndLastFocusableElements(this.refs.calendar);
    }

    _onDateIconFocussable() {
        if (this.calendarIcon) {
            this.calendarIcon.focus();
        }
    }

    _onClickCancel() {
        let initialMonth = this.state.initialMonth.clone();
        let initialValue = this.state.initialValue.clone();
        this.setState({ defaultValue: initialValue });
        let success = true;

        if (this.props.onCancel) {
            success = this.props.onCancel(this.state.defaultValue);
        }

        if (success) {
            this._closeCalendar();
        }
        setTimeout(() => {
            if (this.props.iconFocussable) {
                this.calendarIcon.focus();
            } else if (this.diAlmanacPickerInput) {
                this.diAlmanacPickerInput.focus();
            }
        });
        this.setState({ currentMonth: initialMonth, isOpen: false });
    }

    _onKeyDownCancel(event) {
        if (event.key === 'Enter' || event.key === ' ') {
            this._onClickCancel();
        }
    }

    _onClickSave() {
        let currentMonth = this.state.currentMonth.clone();
        let defaultValue = this.state.defaultValue.clone();
        this.setState({
            initialValue: defaultValue,
            initialMonth: currentMonth,
        });
        let success = true;

        if (this.props.onOK) {
            success = this.props.onOK(this.state.defaultValue);
        }

        if (success) {
            this._closeCalendar();
        }
    }

    _onClickOutSide() {
        if (this.state.isOpen) {
            let initialValue = this.state.initialValue.clone();
            let initialMonth = this.state.initialMonth.clone();
            if (!this.props.manualEntryEnabled) {
                this.setState({
                    currentMonth: initialMonth,
                    defaultValue: initialValue,
                });
            }
            if (this.props.onCancel) {
                this.props.onCancel(this.state.defaultValue);
            }

            this._closeCalendar();
        }
    }

    _onKeyDown(event) {
        let key = event.key;
        if (event.key === 'Escape') {
            this.setState({ isOpen: false });
            if (this.diAlmanacPickerInput) {
                this.diAlmanacPickerInput.focus();
            }
        }
        if (event.key === 'Enter') {
            if (!this.state.isOpen) {
                event.preventDefault();
                this._openCalendar();
                setTimeout(function () {
                    if (this.props.iconFocussable) {
                        if (this.calendarIcon) {
                            this.calendarIcon.focus();
                        }
                    }
                }, 500);
            }
        }
        if (key === 'Escape') {
            if (this.props.iconFocussable) {
                if (this.state.isOpen) {
                    this._closeCalendar();
                }
                if (this.calendarIcon) {
                    this.calendarIcon.focus();
                }
            }
        }
    }

    _openCalendar() {
        if (!this.props.disable) {
            if (this.props.onOpen) {
                this.props.onOpen();
            }
            this.setState({
                isOpen: true,
                calendarStatus: 'Calendar Open',
            });
        }
    }

    _closeCalendar() {
        this.setState({
            isOpen: false,
            currentMonth: this.state.defaultValue.clone().startOf('month'),
            calendarStatus: 'Calendar Closed',
        });
    }

    _handleOnChange(control) {
        if (this.props.onChange) {
            this.props.onChange(control);
        }
    }

    _onInputKeyDown(event) {
        let key = event.key;
        event.stopPropagation();
        if (this.props.manualEntryEnabled) {
            let acceptableValues = '0123456789/';
            let acceptableKeys = [
                'ArrowLeft',
                'ArrowRight',
                'Backspace',
                'Delete',
                'Tab',
                'ArrowDown',
                'ArrowUp',
            ];
            if (
                !acceptableValues.includes(key) &&
                !acceptableKeys.includes(key)
            ) {
                event.preventDefault();
            }
        } else {
            if (key === 'Enter' || key === 'ArrowDown') {
                event.preventDefault();
                this._openCalendar();
                if (this.props.iconFocussable) {
                    if (this.calendarIcon) {
                        this.calendarIcon.focus();
                    }
                }
            }
        }
        if (this.props.iconFocussable) {
            if (key === 'Escape') {
                if (this.state.isOpen) {
                    this._closeCalendar();
                }
                if (this.calendarIcon) {
                    this.calendarIcon.focus();
                }
            }
        }
    }

    _getString(key) {
        //let lang = data.lang;
        let i18nStrings = this.props.i18nStrings || _i18nDefaultStrings;
        let value = i18nStrings['comp.calendar.' + key];

        // If value for Key is not found, true the complete key.
        return value ? value : 'comp.calendar.' + key;
    }

    _focusLastElement(event) {
        if (
            event.shiftKey === true &&
            event.keyCode === 9 &&
            this.state.lastFocusableElement
        ) {
            this.state.lastFocusableElement.focus();
            event.preventDefault();
        }
    }

    _focusFirstElement(event) {
        if (
            event.shiftKey === false &&
            event.keyCode === 9 &&
            this.state.firstFocusableElement
        ) {
            this.state.firstFocusableElement.focus();
            event.preventDefault();
        }
    }

    _getFirstAndLastFocusableElements(ref) {
        if (ref) {
            let elements = ReactDOM.findDOMNode(ref).querySelectorAll(
                '[tabindex="0"],a[href],input,button,textarea',
            );
            let focusableElements = [];

            for (let i = 0; i < elements.length; i++) {
                let formElement = elements[i];
                if (
                    formElement &&
                    formElement.getAttribute('type') != 'hidden' &&
                    formElement.getAttribute('tabindex') != '-1'
                ) {
                    focusableElements.push(formElement);
                }
            }

            if (focusableElements.length > 0) {
                let firstElement = focusableElements[0];
                let previousElement =
                    focusableElements[focusableElements.length - 2];
                let lastElement =
                    focusableElements[focusableElements.length - 1];

                this.setState({
                    firstFocusableElement: firstElement,
                    prevFocussableElement: previousElement,
                    lastFocusableElement: lastElement,
                });
            }
        }
    }

    _onResetFocus() {
        let initialMonth = this.state.initialMonth.clone();
        let initialValue = this.state.initialValue.clone();
        this.setState({
            currentMonth: initialMonth,
            defaultValue: initialValue,
        });
        this._closeCalendar();
        if (this.props.iconFocussable) {
            if (this.calendarIcon) {
                this.calendarIcon.focus();
            }
        }
        if (this.props.manualEntryEnabled) {
            if (this.calendarIcon) {
                this.calendarIcon.focus();
            }
        }
    }

    resetError(date) {
        if (date) {
            let resetDate = moment(date).clone();
            this.setState({
                error: false,
                defaultValue: resetDate,
            });
        }
    }

    _focusManagementForAlmanacPreviousMonthKeyDown() {
        this._closeCalendar();
        if (this.props.manualEntryEnabled && this.calendarIcon) {
            this.calendarIcon.focus();
        } else {
            if (this.diAlmanacPickerInput) {
                this.diAlmanacPickerInput.focus();
            }
        }
    }
}
