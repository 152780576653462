/* eslint-disable */
var ifs = ifs || {};

ifs.redirToLogin = function() {
    var context = ifs.env && ifs.env.context ? ifs.env.context : '';
    var logout =
        ifs.env && ifs.env.logoutUrl ? ifs.env.logoutUrl : '/app/logout';
    window.top.location.href = context + logout + '?reason=403';
};

ifs.parseMessageParams = function(str) {
    var urlParams = {};
    var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g,
        d = function(s) {
            return decodeURIComponent(s.replace(a, ' '));
        };

    while ((e = r.exec(str))) {
        urlParams[d(e[1])] = d(e[2]);
    }
    return urlParams;
};

ifs.showLightbox = function(element) {
    var e = Ext.get(element);
    if (e) {
        e.show();
        if (!e.hasCls('usp-close-handler-installed')) {
            e.on('keyup', ifs.closeLightboxOnESC, e); // passing lightbox el as scope
            e.addCls('usp-close-handler-installed');
        }
        var first = e.select('.usp-focus-on-open').first();
        if (first) {
            first.focus();
        } else {
            // fallback by looking for anything focusable
            first = e.select('[tabindex],a[href],input,button').first();
            if (first) {
                first.focus();
            }
        }
    }
};

ifs.hideLightbox = function(element) {
    var e = Ext.get(element);
    if (e) {
        e.hide();
    }
};

ifs.closeLightboxOnESC = function(evt, el) {
    if (evt.keyCode === 27) {
        ifs.hideLightbox(this); // close the lightbox NOT the event target
        return false;
    }
};

ifs.isPrintable = function(pString) {
    var re = /[^ -~]/;
    return !re.test(pString);
};

ifs.testPassword = function(pPassword) {
    if (!ifs.isPrintable(pPassword)) return false;
    var i = 0;
    var specialCharacters = pPassword.replace(/[a-z0-9]/gi, '');
    if (/[A-Za-z]/.test(pPassword)) i++;
    if (/[0-9]/.test(pPassword)) i++;
    if (
        specialCharacters &&
        specialCharacters.length > 0 &&
        ifs.isPrintable(specialCharacters)
    )
        i++;
    return i > 1;
};

/**
 * Shows or hides a DOM element.
 *
 * @param id ID or object reference of element to show or hide
 * @param shouldShow true if the element should be shown. Otherwise, false.
 * @param displayMode (optional) if the element is to be shown, the display mode (block, inline-block, etc.) to use. Default: block
 */
ifs.showMe = function(id, shouldShow, displayMode) {
    if (!displayMode) {
        displayMode = 'block';
    }

    var el = Ext.getDom(id);
    if (el) {
        el.style.display = shouldShow ? displayMode : 'none';
    }
};

// Make an Ext.Ajax request with the given request parameters and call the given callback when it is complete.
// Essentially makes Ext.Ajax synchronous.
ifs.waitForAjax = function(request, callback) {
    var context = {
        done: false,
        success: false,
        result: null,
    };

    function watchAjax() {
        if (!context.done) {
            setTimeout(watchAjax, 1000);
            return;
        }

        callback(context.success, context.result);
    }

    Ext.Ajax.request({
        url: request.url,
        method: request.method,
        params: request.params,
        success: function(subresult, subrequest) {
            context.done = true;
            context.success = true;
            context.result = subresult;
            if (typeof request.success === 'function') {
                request.success(subresult, subrequest);
            }
        },
        failure: function(subresult, subrequest) {
            context.done = true;
            context.success = false;
            context.result = subresult;
            if (typeof request.failure === 'function') {
                request.failure(subresult, subrequest);
            }
        },
    });

    watchAjax();
};

/*  A polyfill for the trim function missing in IE8 */
if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
    };
}
