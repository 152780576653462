/* eslint-disable no-unused-vars */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Drawer as MuiDrawer } from '@mui/material';

export const DrawerSize = {
    ExtraSmall: 'extra small',
    Small: 'small',
    Medium: 'medium',
    Large: 'large',
    XLarge: 'xlarge',
    ExtraLarge: 'extra large',
    Full: 'full',
    TwoThirds: 'two thirds',
};

export const DrawerVariants = {
    Permanent: 'permanent',
    Persistent: 'persistent',
    Temporary: 'temporary',
};

export const DrawerWidthBySize = {
    [DrawerSize.ExtraSmall]: 1 / 6,
    [DrawerSize.Small]: 1 / 5,
    [DrawerSize.Medium]: 1 / 4,
    [DrawerSize.Large]: 1 / 3,
    [DrawerSize.XLarge]: 2 / 5,
    [DrawerSize.ExtraLarge]: 1 / 2,
    [DrawerSize.TwoThirds]: 2 / 3,
    [DrawerSize.Full]: '100vw',
};

export const Drawer = forwardRef(
    (
        {
            open,
            size = DrawerSize.Small,
            variant = DrawerVariants.Temporary,
            children,
            sx,
            ...props
        },
        ref,
    ) => {
        return (
            <MuiDrawer
                ref={ref}
                open={open}
                variant={variant}
                sx={{
                    width: DrawerWidthBySize[size],
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: DrawerWidthBySize[size],
                        ...sx,
                    },
                }}
                {...props}
            >
                {children}
            </MuiDrawer>
        );
    },
);

Drawer.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    open: PropTypes.bool.isRequired,
    size: PropTypes.instanceOf(DrawerSize).isRequired,
    variant: PropTypes.string,
    sx: PropTypes.object,
};
