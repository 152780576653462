// eslint-disable-next-line filenames/match-exported
import React, { useState, useCallback, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
    modernBrandingEnabled: false,
    toggleMuiBranding: () => undefined,
};

export const ModernBrandingContext = createContext(initialValue);

export function ModernBrandingProvider({ brandingEnabled, children }) {
    const [modernBrandingEnabled, SetMuiBrandingEnabled] = useState(
        brandingEnabled,
    );

    const setLocalStorage = useCallback((enabled) => {
        localStorage.setItem('modernBrandingEnabled', enabled);
    }, []);

    const toggleMuiBranding = () => {
        SetMuiBrandingEnabled(!modernBrandingEnabled);
        setLocalStorage(!modernBrandingEnabled);
    };

    return (
        <ModernBrandingContext.Provider
            value={{
                modernBrandingEnabled,
                toggleMuiBranding,
            }}
        >
            {children}
        </ModernBrandingContext.Provider>
    );
}

ModernBrandingProvider.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    brandingEnabled: PropTypes.bool,
};

export const useModernBranding = () => {
    return useContext(ModernBrandingContext);
};
