import { action, observable } from 'mobx';
import LoginService from './services/LoginService';

class AuthenticationStore {
    @observable user = null;

    @observable initialized = undefined;

    @observable isOauth2Flow = false;

    @observable clientId = null;

    @observable state = null;

    @observable redirectUri = null;

    landingPageLocation = null;

    @action
    loadCurrentUser() {
        return LoginService.getCurrentUser().then((res) => {
            this.user = res;

            if (this.initialized === undefined) {
                this.initialized = true;
            }
        });
    }

    @action
    login(data) {
        const a = this; // eslint-disable-line

        return LoginService.login(data);
    }

    @action
    externalLogin(data) {
        const a = this; // eslint-disable-line

        return LoginService.externalLogin(data);
    }

    @action
    loginRedirect = (unAuthenticatedAllowedUser) =>
        new Promise((resolve) => {
            let mfaString = '';

            // Check localStorage for MFA cookie
            if (window.localStorage) {
                Object.keys(localStorage).forEach((key) => {
                    if (new RegExp('MAF_IB_*', 'g').test(key)) {
                        mfaString += `${key}=${localStorage.getItem(key)};`;
                    }
                });
            }

            resolve(mfaString);
        }).then((mfaString) =>
            LoginService.loginRedirect(unAuthenticatedAllowedUser, mfaString),
        );

    @action
    logout(reason) {
        return LoginService.logout(reason).then((response) => {
            this.user = null;

            return response;
        });
    }

    // eslint-disable-next-line
    getLogoutConfig(reason, locale, ibGuid) {
        return LoginService.getLogoutPageConfig(reason, locale, ibGuid);
    }

    // eslint-disable-next-line
    getServiceStoppedConfig(business, close) {
        return LoginService.getServiceStoppedConfig(business, close);
    }
}
export default new AuthenticationStore();
