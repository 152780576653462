/* eslint-disable import/order,no-underscore-dangle,react/destructuring-assignment,react/no-string-refs,prefer-template,react/no-find-dom-node,prefer-const,padding-line-between-statements,eqeqeq,no-useless-return,react/no-access-state-in-setstate,prefer-destructuring,class-methods-use-this,react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import calcStyles from '../../styles/Calculator.scss';

import calcLabels from './Labels.json';

import classNames from 'classnames';

const OPERATORS = Object.freeze({
    PLUS_MINUS: 'plus_minus',
    ADD: '+',
    SUBTRACT: '-',
    DIVIDE: '/',
    MULTIPLY: '*',
    PERCENTAGE: '%',
});

const MAX_INPUT_LENGTH = 18;

export default class Calculator extends Component {
    static defaultProps = {
        locale: 'en_US',
    };

    constructor(props) {
        super(props);
        this.state = {
            inputValue: 0,
            operator: null,
            currentValue: null,
            prevValue: null,
        };

        this._renderKeyPad = this._renderKeyPad.bind(this);
        this._setValue = this._setValue.bind(this);
        this._setOperator = this._setOperator.bind(this);
        this._clear = this._clear.bind(this);
        this._calculate = this._calculate.bind(this);
        this._result = this._result.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._displayValue = this._displayValue.bind(this);
        this.labels = this.props.labels
            ? this.props.labels
            : calcLabels[this.props.locale];
    }

    render() {
        return (
            <div>
                <div className={calcStyles['input-text-area']}>
                    <input
                        type="text"
                        className={classNames(
                            calcStyles['input-text'],
                            'calculator-input',
                        )}
                        value={this.state.inputValue}
                        aria-live="polite"
                        ref="calculatorInput"
                        aria-label={
                            this.labels.calculator_label_for_display_result
                        }
                        onKeyPress={this._onKeyPress}
                        onKeyDown={this._onKeyDown}
                        readOnly
                    />
                </div>
                <div className={calcStyles['key-pad-area']}>
                    {this._renderKeyPad()}
                </div>
            </div>
        );
    }

    _renderKeyPad() {
        return (
            <div>
                <div className={calcStyles['key-row']}>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._clear}
                            aria-label={this.labels.calculator_label_for_clear}
                            type="button"
                        >
                            C
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setOperator}
                            value={OPERATORS.PLUS_MINUS}
                            aria-label={
                                '± ' +
                                this.labels.calculator_label_for_sum_minus
                            }
                            type="button"
                        >
                            &plusmn;
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            id="percentageId"
                            onClick={this._setOperator}
                            value={OPERATORS.PERCENTAGE}
                            aria-describedby="percentageId"
                            type="button"
                        >
                            %
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setOperator}
                            value={OPERATORS.DIVIDE}
                            aria-label={
                                '÷ ' + this.labels.calculator_label_for_divide
                            }
                            type="button"
                        >
                            &divide;
                        </button>
                    </div>
                </div>
                <div className={calcStyles['key-row']}>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="7"
                            type="button"
                        >
                            7
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="8"
                            type="button"
                        >
                            8
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="9"
                            type="button"
                        >
                            9
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setOperator}
                            value={OPERATORS.MULTIPLY}
                            aria-label={
                                '× ' + this.labels.calculator_label_for_multiply
                            }
                            type="button"
                        >
                            &times;
                        </button>
                    </div>
                </div>
                <div className={calcStyles['key-row']}>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="4"
                            type="button"
                        >
                            4
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="5"
                            type="button"
                        >
                            5
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="6"
                            type="button"
                        >
                            6
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setOperator}
                            value={OPERATORS.SUBTRACT}
                            aria-describedby="minusId"
                            type="button"
                        >
                            -
                        </button>
                        <div className="sr-only" tabIndex="-1" id="minusId">
                            {this.labels.calculator_label_for_minus}
                        </div>
                    </div>
                </div>
                <div className={calcStyles['key-row']}>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="1"
                            type="button"
                        >
                            1
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="2"
                            type="button"
                        >
                            2
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="3"
                            type="button"
                        >
                            3
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            id="plusId"
                            onClick={this._setOperator}
                            value={OPERATORS.ADD}
                            aria-describedby="plusId"
                            type="button"
                        >
                            +
                        </button>
                    </div>
                </div>
                <div className={calcStyles['key-row']}>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            onClick={this._setValue}
                            value="0"
                            type="button"
                        >
                            0
                        </button>
                    </div>
                    <div className={calcStyles['key-col']} />
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            id="dotId"
                            onClick={this._setValue}
                            value="."
                            aria-describedby="dotId"
                            type="button"
                        >
                            .
                        </button>
                    </div>
                    <div className={calcStyles['key-col']}>
                        <button
                            className={calcStyles['key-btn']}
                            id="equalId"
                            onClick={this._calculate}
                            aria-describedby="equalId"
                            type="button"
                        >
                            =
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    _setValue(e) {
        ReactDOM.findDOMNode(this.refs.calculatorInput).focus();
        let val = this.state.currentValue ? this.state.currentValue : 0;
        let selectedNumber = e.target.value;
        let calcValue = '';
        val = val.toString();
        if (val === '0' && selectedNumber != '.') {
            calcValue = selectedNumber;
        } else if (val.indexOf('.') !== -1 && selectedNumber === '.') {
            return;
        } else {
            calcValue = val + selectedNumber;
        }
        if (calcValue.length <= MAX_INPUT_LENGTH) {
            this.setState({ inputValue: calcValue, currentValue: calcValue });
        }
    }

    _setOperator(e) {
        ReactDOM.findDOMNode(this.refs.calculatorInput).focus();
        if (this.state.currentValue == null && this.state.operator == null) {
            return;
        } else if (e.target.value === OPERATORS.PLUS_MINUS) {
            this.setState({
                inputValue: Number(this.state.inputValue) * -1,
                currentValue: 0,
            });
        } else if (
            e.target.value === OPERATORS.PERCENTAGE &&
            this.state.operator != null &&
            this.state.prevValue != null &&
            this.state.currentValue != null
        ) {
            this.setState({
                inputValue: this._displayValue(
                    (Number(this.state.prevValue) *
                        Number(this.state.inputValue)) /
                        100,
                ),
            });
        } else if (e.target.value === OPERATORS.PERCENTAGE) {
            this.setState({
                inputValue: this._displayValue(
                    Number(this.state.inputValue) / 100,
                ),
            });
            if (this.state.operator) {
                this.setState({ currentValue: null });
            } else {
                this.setState({ currentValue: 0 });
            }
        } else if (this.state.operator == null) {
            this.setState({
                prevValue: this._displayValue(Number(this.state.inputValue)),
                inputValue: this._displayValue(Number(this.state.inputValue)),
                operator: e.target.value,
                currentValue: null,
            });
        } else {
            if (this.state.currentValue != null) {
                this._result(Number(this.state.inputValue));
            }
            this.setState({ operator: e.target.value, currentValue: null });
        }
    }

    _clear() {
        ReactDOM.findDOMNode(this.refs.calculatorInput).focus();
        this.setState({
            inputValue: 0,
            currentValue: null,
            operator: null,
            prevValue: null,
        });
    }

    _calculate() {
        if (this.state.operator) {
            this._result(Number(this.state.inputValue));
            this.setState({ operator: null, currentValue: 0 });
        }
        ReactDOM.findDOMNode(this.refs.calculatorInput).focus();
    }

    _onKeyPress(e) {
        if (e.preventDefault) {
            e.preventDefault();
        }
        let keyCode = e.keyCode || e.which;
        if ((keyCode >= 48 && keyCode <= 57) || keyCode == 46) {
            let key = e.key;
            if (keyCode == 46) {
                key = '.';
            }
            if (!this.state.currentValue) {
                this.setState({ inputValue: key, currentValue: key });
            } else {
                if (keyCode == 46 && this.state.inputValue.indexOf('.') != -1) {
                    return;
                }
                let val = this.state.inputValue + key;
                if (val.length <= MAX_INPUT_LENGTH) {
                    this.setState({ inputValue: val, currentValue: val });
                }
            }
        } else if (keyCode == 43) {
            this._setOperator({ target: { value: '+' } });
        } else if (keyCode == 45) {
            this._setOperator({ target: { value: '-' } });
        } else if (keyCode == 47) {
            this._setOperator({ target: { value: '/' } });
        } else if (keyCode == 42) {
            this._setOperator({ target: { value: '*' } });
        } else if (keyCode == 37) {
            this._setOperator({ target: { value: '%' } });
        } else if (keyCode == 13 || keyCode == 61) {
            this._calculate();
        }
    }

    _onKeyDown(e) {
        let keyCode = e.keyCode || e.which;
        let value = this.state.inputValue;
        if (keyCode == 8) {
            e.preventDefault();
            if (value) {
                if (value.length > 1) {
                    value = value.substring(0, value.length - 1);
                } else {
                    value = 0;
                }
                this.setState({ inputValue: value, currentValue: value });
            }
        } else if (keyCode == 27 || keyCode == 46) {
            this._clear();
        } else if (keyCode == 13) {
            this._calculate();
        }
    }

    _result(currentValue) {
        let prevValue = Number(this.state.prevValue);
        let calcValue = 0;
        if (this.state.operator) {
            switch (this.state.operator) {
                case OPERATORS.ADD:
                    calcValue = this._displayValue(prevValue + currentValue);
                    break;
                case OPERATORS.SUBTRACT:
                    calcValue = this._displayValue(prevValue - currentValue);
                    break;
                case OPERATORS.MULTIPLY:
                    calcValue = this._displayValue(prevValue * currentValue);
                    break;
                case OPERATORS.DIVIDE:
                    calcValue = this._displayValue(prevValue / currentValue);
                    break;
                default:
                // do nothing
            }
            this.setState({
                currentValue: 0,
                inputValue: calcValue,
                prevValue: calcValue,
            });
        }
    }

    _displayValue(value) {
        return parseFloat(
            parseFloat(value.toPrecision(10)).toString().substring(0, 18),
        );
    }
}

Calculator.propTypes = {
    /**
     * Carries dynamic labels that need to used in the component, if not provided will use static labels.
     */
    labels: PropTypes.array,

    /**
     * Carries locale that need to be used, to find the right static labels.Default Value: 'en_US'
     */
    locale: PropTypes.string,
};
