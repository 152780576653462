/* eslint-disable prefer-const,eqeqeq */
export default class Localizer {
    static getLocale(locale) {
        let locales = {
            englishLocale: 'en_US',
            spanishLocale: 'es_ES',
            chineseLocale: 'zh_TW',
            frenchLocale: 'fr_CA',
        };

        let returnedLocale = locales.englishLocale;

        if (
            locale === 'es' ||
            locale === 'es_ES' ||
            locale === 'es_es' ||
            locale === 'ES_ES'
        ) {
            returnedLocale = locales.spanishLocale;
        } else if (
            locale === 'zh' ||
            locale === 'zh_TW' ||
            locale === 'zh_tw' ||
            locale === 'ZH_TW'
        ) {
            returnedLocale = locales.chineseLocale;
        } else if (
            locale === 'fr' ||
            locale === 'fr_CA' ||
            locale === 'fr_ca' ||
            locale === 'FR_CA'
        ) {
            returnedLocale = locales.frenchLocale;
        }

        return returnedLocale;
    }
}
