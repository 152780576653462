/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Success extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    fill="#2e6f03"
                    d="M3,7.3l4.2,3.8L15.1,0c0,0,1.7-0.1,2.4,0.7C18.2,1.6,18,3.5,18,3.5L7.3,18L0,10.8c0,0-0.2-1.9,0.6-2.8S3,7.3,3,7.3z"
                />
            </svg>
        );
    }
}
