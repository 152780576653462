/* eslint-disable react/prefer-stateless-function,no-var,padding-line-between-statements,react/destructuring-assignment,react/prop-types,eqeqeq,react/no-danger */
import React, { Component } from 'react';

export default class MfaHeader extends Component {
    render() {
        var icon = null;
        if (this.props.icon == 'Call') {
            icon = (
                <span
                    className="di-icon-call fi-link-color mfa-header-icon"
                    aria-hidden="true"
                />
            );
        } else if (this.props.icon == 'Email') {
            icon = (
                <span
                    className="di-icon-email fi-link-color mfa-header-icon mfa-header-icon-small"
                    aria-hidden="true"
                />
            );
        } else if (this.props.icon == 'Info') {
            icon = (
                <span className="di-icon-info fi-link-color mfa-header-icon mfa-header-icon-large" />
            );
        }

        return (
            <div>
                <div className="mfa-enter-code-heading mfa-header-with-icon">
                    {icon}
                    <h2
                        className="mfa-phone-heading"
                        dangerouslySetInnerHTML={{
                            __html: this.props.children,
                        }}
                        id={this.props.headerId}
                    />
                </div>
            </div>
        );
    }
}
