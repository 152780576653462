/**
 * This component is used only for debugging as placeholder UI container.
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Paper as MuiPaper } from '@mui/material';

const StyledPaper = styled(MuiPaper)(({ theme, padding }) => ({
    padding: theme.spacing(padding),
}));

export const Paper = forwardRef(
    ({ children, padding = 1, sx, ...props }, ref) => {
        return (
            <StyledPaper ref={ref} padding={padding} sx={sx} {...props}>
                {children}
            </StyledPaper>
        );
    },
);

Paper.propTypes = {
    padding: PropTypes.number,
    sx: PropTypes.object,
};
