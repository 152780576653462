/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class PageLoader extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                className="rotate"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g fill="none" stroke="#bbb" strokeMiterlimit="10">
                    <circle
                        cx="9"
                        cy="9"
                        r="7.9"
                        opacity=".5"
                        strokeWidth="1"
                    />
                    <path
                        d="M1.1 9A7.9 7.9 0 0 1 9 1.1"
                        strokeLinecap="square"
                    />
                </g>
            </svg>
        );
    }
}
