/* eslint-disable react/no-unknown-property */
import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export const ErrorIllustration = (props) => {
    return (
        <SvgIcon
            viewBox="0 0 264 168"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M199.051 150.588C241.513 109.328 240.486 42.0515 214.903 41.3602C189.32 40.6689 167.95 93.5661 184.164 153.354L199.051 150.588V150.588Z"
                fill="#EFF7EE"
            />
            <path
                d="M256.795 100.428C269.991 109.75 245.123 146.129 207.215 153.353L198.338 149.625C212.386 111.94 243.6 91.1059 256.807 100.428H256.795Z"
                fill="#EFF7EE"
            />
            <path
                d="M204.275 142.933C185.785 142.768 128.48 142.032 93.7041 139.899C29.2184 135.946 -23.4445 142.474 11.1513 149.939C20.8237 152.026 -7.05985 164.529 61.1958 167.352C121.111 169.834 182.195 164.539 211.752 162.259C241.319 159.978 254.95 155.942 261.284 154.122C266.854 152.522 268.905 143.522 204.266 142.933H204.275Z"
                fill="#F0F2F4"
            />
            <path
                d="M51.8937 138.364C48.0548 145.03 52.8666 153.354 60.5596 153.354H202.579C210.272 153.354 215.084 145.03 211.245 138.364L140.235 15.0491C136.389 8.36934 126.75 8.36935 122.904 15.0491L51.8937 138.364ZM139.597 129.14H123.541V112.998H139.597V129.14ZM139.597 96.8551H123.541V64.57H139.597V96.8551Z"
                fill="#FFC1BC"
            />
        </SvgIcon>
    );
};
