/* eslint-disable no-restricted-globals */
import React, { Component, Fragment } from 'react';
import Fetch from 'fetch';
import { Helmet } from 'react-helmet';
import StepUpAuthenticationApp from 'legacy-usp/src/step_up/StepUpAuthenticationApp';
import 'legacy-usp/scss/step_up_authentication.scss';
import AltInstance from 'common-ui/src/alt/AltInstance';
import StepUpAuthenticationStore from 'legacy-usp/src/step_up/stores/StepUpAuthenticationStore';
import PropTypes from 'prop-types';
import PageLoader from 'common-ui/src/components/loader/PageLoader';
import {
    JourneyType,
    getADRequestHeaders,
    getActiveJourneyType,
} from 'common-ui/src/anomaly-detection';

const getAnomalyDetectionIdfromQuery = () => {
    // SSO will sent the journeyId as a query string and to propogate jpurneyId to "/setp-up-authentication/submit",storing it on window.dbk
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    let adJourneyId = null;
    let adSessionId = null;

    if (params.has('journeyId')) {
        adJourneyId = params.get('journeyId');
    }

    if (params.has('sessionId')) {
        adSessionId = params.get('sessionId');
    }

    return {
        ...(adSessionId && { adSessionId }),
        ...(adJourneyId && { adJourneyId }),
    };
};

class StepUpAuthentication extends Component {
    static defaultProps = {
        history: {},
    };

    static propTypes = {
        history: PropTypes.object,
        config: PropTypes.object,
        successCallback: PropTypes.func,
        cancelCallback: PropTypes.func,
        feature: PropTypes.string,
        repeatableChallenge: PropTypes.bool,
        externalUse: PropTypes.bool,
        reason: PropTypes.string,
    };

    state = {};

    render() {
        let stepUpAuthenticationConfig = false;

        if (this.props.config) {
            stepUpAuthenticationConfig = this.props.config;
        } else if (this.state.stepUpAuthenticationConfig) {
            stepUpAuthenticationConfig = this.state.stepUpAuthenticationConfig;
        }
        const { history } = this.props;

        if (stepUpAuthenticationConfig) {
            return (
                <Fragment>
                    <Helmet>
                        <title>
                            {stepUpAuthenticationConfig.browserTabTitle}
                        </title>
                    </Helmet>
                    <StepUpAuthenticationApp
                        config={stepUpAuthenticationConfig}
                        history={history}
                        successCallback={this.props.successCallback}
                        cancelCallback={this.props.cancelCallback}
                        feature={this.props.feature}
                        repeatableChallenge={this.props.repeatableChallenge}
                    />
                </Fragment>
            );
        }

        return <PageLoader />;
    }

    async componentDidMount() {
        const externalUse = !!this.props.externalUse;

        if (!externalUse && window.self !== window.top) {
            window.top.location = window.self.location;
        } else if (!this.props.config) {
            let configUrl = `/auth-olb/v1/step-up-authentication/config${location.search}`;
            let context = '';
            let anomalyDetectionIds = {};

            if (Object.keys(getAnomalyDetectionIdfromQuery()).length !== 0) {
                anomalyDetectionIds = Object.assign(
                    {},
                    getAnomalyDetectionIdfromQuery(),
                );

                if (anomalyDetectionIds.adJourneyId) {
                    sessionStorage.setItem(
                        'adJourneyId',
                        anomalyDetectionIds?.adJourneyId,
                    );
                }

                if (anomalyDetectionIds.adSessionId) {
                    sessionStorage.setItem(
                        'sessionId',
                        anomalyDetectionIds?.adSessionId,
                    );
                }
            } else if (
                getActiveJourneyType() &&
                Object.values(JourneyType).includes(getActiveJourneyType())
            ) {
                anomalyDetectionIds = getADRequestHeaders();
            }

            if (window.parent.location.pathname.indexOf('/live') !== -1) {
                context = 'auth-olb/live';
            } else if (
                window.parent.location.pathname.indexOf('/beta') !== -1
            ) {
                context = 'auth-olb/beta';
            }

            if (context !== '') {
                configUrl = configUrl.replace('auth-olb', context);
            }

            let feature = '';

            if (this.props.feature !== undefined) {
                feature = this.props.feature;
            }
            let stepUpParams = {};

            if (this.props.reason !== undefined) {
                stepUpParams = {
                    feature,
                    repeatableChallenge: !!this.props.repeatableChallenge,
                    reason: this.props.reason,
                    journeyId: anomalyDetectionIds?.adJourneyId,
                    sessionId: anomalyDetectionIds?.adSessionId,
                };
            } else {
                stepUpParams = {
                    feature,
                    repeatableChallenge: !!this.props.repeatableChallenge,
                    journeyId: anomalyDetectionIds?.adJourneyId,
                    sessionId: anomalyDetectionIds?.adSessionId,
                };
            }

            const stepUpAuthenticationConfig = await Fetch.get(
                configUrl,
                stepUpParams,
                {},
            );
            const { targetView, targetData } = stepUpAuthenticationConfig;

            if (targetView === 'redirect') {
                const { history } = this.props;
                history.replace(targetData);
            } else if (targetView === 'step_up') {
                const stepUpAuthentication = {};

                stepUpAuthentication.i18nStrings = targetData.i18nStrings;
                stepUpAuthentication.forAppCode = targetData.forAppCode;
                stepUpAuthentication.actionId = targetData.actionId;
                stepUpAuthentication.dataRates = targetData.dataRates;
                stepUpAuthentication.isMobileFlow = targetData.isMobileFlow;
                stepUpAuthentication.isMobileResponse =
                    targetData.isMobileResponse;
                stepUpAuthentication.securityOptions =
                    targetData.securityOptions;
                stepUpAuthentication.stepUpStatus = targetData.stepUpStatus;
                stepUpAuthentication.authenticatedUrl =
                    targetData.pageProps.authenticatedUrl;
                stepUpAuthentication.CSRFToken = targetData.pageProps.CSRFToken;
                stepUpAuthentication.browserTabTitle =
                    targetData.pageProps.browserTabTitle;

                if (this.props.reason !== undefined) {
                    stepUpAuthentication.reason = this.props.reason;
                } else {
                    stepUpAuthentication.reason = targetData.reason;
                }

                this.setState({
                    stepUpAuthenticationConfig: stepUpAuthentication,
                });
            }
        }
    }

    componentWillUnmount() {
        if (AltInstance) {
            AltInstance.recycle(StepUpAuthenticationStore);
        }
    }
}

export default StepUpAuthentication;
