/* eslint-disable react/default-props-match-prop-types,react/prefer-stateless-function,react/prop-types,react/static-property-placement */
import React from 'react';
import classNames from 'classnames';
import { Button, SafeAnchor } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bsClass as setBsClass } from 'react-bootstrap/lib/utils/bootstrapUtils';

const propTypes = {
    /**
     * Hides the caret that normally appears on the right side of the toggle.
     */
    noCaret: PropTypes.bool,

    /**
     * Used for applying the correct accessibility attributes to the toggle. This should match the value of open on
     * the menu.
     */
    open: PropTypes.bool,

    /**
     * Renders the toggle as an anchor instead of a button.
     */
    useAnchor: PropTypes.bool,

    /**
     * Changes the color of the toggle to white instead of gray (only applies to buttons).
     */
    onGray: PropTypes.bool,
};

const defaultProps = {
    open: false,
    useAnchor: false,
    bsRole: 'toggle',
};

class SelectionDropdownToggle extends React.Component {
    render() {
        const {
            noCaret,
            open,
            useAnchor,
            bsClass,
            className,
            children,
            onGray,
            ariaLabel,
            ariaLabelValueAccountType,
            ...props
        } = this.props;

        delete props.bsRole;

        const Component = useAnchor ? SafeAnchor : Button;
        const useCaret = !noCaret;

        // This intentionally forwards bsSize and bsStyle (if set) to the
        // underlying component, to allow it to render size and style variants.
        return (
            <Component
                {...props}
                role="button"
                className={classNames(className, bsClass, {
                    'on-gray': onGray,
                })}
                aria-haspopup
                aria-expanded={open}
                aria-label={
                    ariaLabel === 'Account type' || ariaLabel === 'action'
                        ? ariaLabel + ariaLabelValueAccountType
                        : ariaLabel
                }
            >
                <span className="dropdown-toggle-content">
                    <span className="dropdown-toggle-text">{children}</span>
                    {useCaret && (
                        <span className="dropdown-toggle-caret-spacer" />
                    )}
                    {useCaret && <span className="caret" />}
                </span>
            </Component>
        );
    }
}

SelectionDropdownToggle.propTypes = propTypes;
SelectionDropdownToggle.defaultProps = defaultProps;

export default setBsClass('dropdown-toggle', SelectionDropdownToggle);
