/* eslint-disable react/prefer-stateless-function,react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StepUpAuthenticationContainer from './components/StepUpAuthenticationContainer';

export default class StepUpAuthenticationApp extends Component {
    render() {
        return (
            <div className="fade-in">
                <StepUpAuthenticationContainer
                    i18nStrings={this.props.config.i18nStrings}
                    dataRates={this.props.config.dataRates}
                    forAppCode={this.props.config.forAppCode}
                    actionId={this.props.config.actionId}
                    authenticatedUrl={this.props.config.authenticatedUrl}
                    stepUpStatus={this.props.config.stepUpStatus}
                    isMobileFlow={this.props.config.isMobileFlow}
                    isMobileResponse={this.props.config.isMobileResponse}
                    securityOptions={this.props.config.securityOptions}
                    xt={this.props.config.CSRFToken}
                    successCallback={this.props.successCallback}
                    cancelCallback={this.props.cancelCallback}
                    feature={this.props.feature}
                    repeatableChallenge={this.props.repeatableChallenge}
                    reason={this.props.config.reason}
                />
            </div>
        );
    }
}

StepUpAuthenticationApp.propTypes = {
    config: PropTypes.object,
    successCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
};
