/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class TextMobileAlertsOutline extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M11.7,1C7,0,1.9,1.9,0.4,5.2c-1.1,2.4-0.1,5,2.3,6.6c-0.5,2.8-1.4,5.4-1.4,5.4l5.6-3.8c4.5,0.8,9.2-1.1,10.7-4.3
                C19.1,5.7,16.4,2.1,11.7,1z M15.7,8.9c-1.2,2.4-5.3,3.9-9.1,3.1l-2.8,1.7c0,0,0.5-1.4,0.7-2.6c-1.8-1.3-3.3-3-2.3-5.2
                c1.2-2.6,5.1-4,9.1-3.2C15.3,3.6,16.9,6.4,15.7,8.9z"
                />
            </svg>
        );
    }
}
