import AnalyticsLogger from 'common-ui/src/util/AnalyticsLogger';

export default {
    log: (option, destination) => {
        const { protocol } = option;
        const { contactInfo } = destination;
        const analyticsData = { channel: protocol, contactInfo };
        const eventObject = {
            props: {
                prop12: 'Login MFA',
                eVar12: 'Login MFA',
                events: 'event22',
                pageName: 'Login',
            },
            site: 'sdp',
            action: 'login_mfa',
            category: 'Login',
        };

        if (AnalyticsLogger) {
            AnalyticsLogger.trackEvent(eventObject, analyticsData);
        }
    },
};
