/* eslint-disable no-underscore-dangle,prefer-const,react/destructuring-assignment,react/prop-types,dot-notation,prefer-destructuring,padding-line-between-statements,prefer-template,jsx-a11y/no-redundant-roles,jsx-a11y/label-has-for,react/no-danger,no-else-return,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from '../../styles/RadioButtonAndCheckbox.scss';

export default class RadioButton extends Component {
    constructor(props) {
        super(props);
        this._getLabel = this._getLabel.bind(this);
    }

    render() {
        let className = this.props.className ? this.props.className : null;
        let small = this.props.isSmall ? Styles['sm'] : null;
        let name = this.props.name ? this.props.name : null;
        let id = this.props.id;
        let disabled = this.props.disabled ? Styles['disabled'] : null;
        let labelContainerId = null;
        if (id) {
            labelContainerId = id + 'LabelContainer';
        }

        let componentLabelContainerClassNames = classNames({
            'di-radio': true,
            'di-input': true,
            [`${Styles['component-label-container']}`]: true,
            [`${className}`]: this.props.className,
            [`${disabled}`]: this.props.disabled,
        });

        let clickSpaceClassNames = classNames({
            'rbcb-clickspace-container': true,
        });

        let radioButtonClickSpaceSpanClassNames = classNames({
            [`${Styles['radio-lbl']}`]: true,
            'rbcb-lbl': true,
            [`${small}`]: this.props.isSmall,
            [`${disabled}`]: this.props.disabled,
        });

        let radioButtonLabelContainerClassNames = classNames({
            [`${Styles['label-container']}`]: true,
            [`${small}`]: this.props.isSmall,
        });

        return (
            <div className={componentLabelContainerClassNames}>
                <div className={clickSpaceClassNames}>
                    <input
                        type="radio"
                        name={name}
                        role="radio"
                        aria-describedby={
                            this.props.ariaDescribedBy
                                ? this.props.ariaDescribedBy
                                : id
                        }
                        aria-checked={
                            this.props.ariaChecked
                                ? this.props.ariaChecked
                                : null
                        }
                        onChange={this.props.onChange}
                        checked={this.props.isChecked}
                        defaultChecked={this.props.checked}
                        disabled={this.props.disabled}
                        id={id}
                    />
                    <span className={radioButtonClickSpaceSpanClassNames} />
                </div>
                <label
                    id={labelContainerId}
                    className={radioButtonLabelContainerClassNames}
                    htmlFor={id}
                >
                    {this._getLabel()}
                </label>
            </div>
        );
    }

    _getLabel() {
        if (this.props.label) {
            return <span>{this.props.label}</span>;
        } else if (this.props.children) {
            return <span>{this.props.children}</span>;
        } else if (this.props.dangerouslySetLabelInnerHtml) {
            return (
                <span
                    dangerouslySetInnerHTML={
                        this.props.dangerouslySetLabelInnerHtml
                    }
                />
            );
        } else {
            return null;
        }
    }
}

RadioButton.propTypes = {
    /**
     * If true, the radio button is small.
     */
    isSmall: PropTypes.bool,
    /**
     * If true, the radio button is disabled.
     */
    disabled: PropTypes.bool,
    /**
     * If true, the radio button is selected.
     */
    isChecked: PropTypes.bool,
    /**
     * If true, the radio button defaults to selected when the page loads.
     */
    checked: PropTypes.bool,
    /**
     * Add a name attribute to the radio button.
     */
    name: PropTypes.string,
    /**
     * Add an id to the label surrounding the input.
     */
    id: PropTypes.string.isRequired,
    /**
     * Function to be executed when there is a change to the input.
     */
    onChange: PropTypes.func,
    /**
     * If you have a string of html that is absolutely necessary in the label, this prop can be used. The value of this prop is an object containing the key '__html' whose value is the string of html meant to be placed in the label.
     */
    dangerouslySetLabelInnerHtml: PropTypes.object,
};
