/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Rename extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 20 16.9"
                enableBackground="new 0 0 20 16.9"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path d="M8.7,0L14,15h-3.2l-1-3.3H4.3L3.1,15H0L5.4,0C5.4,0,8.7,0,8.7,0z M8.9,9.2L7,3.7l0,0L5.1,9.2C5.1,9.2,8.9,9.2,8.9,9.2z" />
                <rect x="15" width="2" height="8" />
                <rect x="12" y="3" width="8" height="2" />
            </svg>
        );
    }
}
