/* eslint-disable import/no-useless-path-segments,no-var,react/destructuring-assignment,react/prop-types,eqeqeq,padding-line-between-statements,react/jsx-no-bind,no-underscore-dangle */
import React, { Component } from 'react';
import MfaHeader from './MfaHeader';
import MfaTextWithLink from './MfaTextWithLink';
import MfaActions from './../actions/MfaActions';

export default class MfaEnterCodeHeading extends Component {
    render() {
        var icon = null;
        var text;

        if (
            this.props.contactInfo.protocol === 'SMS' ||
            this.props.contactInfo.protocol === 'VOICE'
        ) {
            icon = 'Call';
            text = this.props.config.labels.enterCodeHeading.replace(
                '{0}',
                this.props.contactInfo.contactInfo,
            );
        } else if (this.props.contactInfo.protocol === 'EMAIL') {
            icon = 'Email';
            text = this.props.config.labels.enterCodeHeading.replace(
                '{0}',
                this.props.contactInfo.contactInfo,
            );
        } else if (this.props.contactInfo.protocol === 'TOKEN') {
            text = this.props.config.labels.securityTokenHeading;
        } else if (this.props.contactInfo.protocol === 'TOTP') {
            text = this.props.config.labels.totpHeading;
        } else {
            text = this.props.config.labels.adminGeneratedHeading;
        }
        return (
            <div>
                <MfaHeader icon={icon} headerId="mfaEnterCodeHeading">
                    {text}
                </MfaHeader>
                {this.props.contactInfo.protocol === 'EMAIL' &&
                this.props.isMobileFlow !== true ? (
                    <div className="mfa-indent mfa-new-tab-link">
                        <MfaTextWithLink
                            text={this.props.config.labels.newTabInfoLabel}
                            link={this.props.config.labels.newTabInfoLink}
                            onClick={this._openNewTab.bind(this)}
                        />
                        <br />
                        <br />
                    </div>
                ) : null}
            </div>
        );
    }

    _openNewTab() {
        MfaActions.mfaLog(
            'Event=MfaNewTabUrl Message=Opening new tab to access email otp Conversion=Mfa',
            false,
            false,
            false,
            this.props.config.CSRFToken,
        );
        window.open(this.props.config.newTabEmailUrl, '_blank');
    }
}
