/* eslint-disable import/order,spaced-comment,one-var,prefer-const,react/destructuring-assignment,react/prop-types,no-unneeded-ternary,object-shorthand,no-restricted-syntax,padding-line-between-statements,no-underscore-dangle,react/no-string-refs,react/jsx-no-bind,jsx-a11y/no-noninteractive-tabindex,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/sort-comp,lines-between-class-members,react/no-find-dom-node,class-methods-use-this,vars-on-top,no-var,no-plusplus,no-param-reassign,prefer-template,react/prefer-es6-class,prefer-destructuring */
import CalendarWidget from 'react-widgets/lib/Calendar';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import SVG from '../images/SVG';
import 'react-widgets/lib/scss/react-widgets.scss';
import '../../styles/Calendar.css';
import Moment from 'moment';
import momentLocalizer from 'react-widgets/lib/localizers/moment';
import DOMUtils from '../utils/DOMUtils';
import ARIAUtils from '../utils/ARIAUtils';

//@deprecated, Refer to Almanac Component
export default class Calendar extends Component {
    constructor(props) {
        super(props);

        let dateToShow = this.props.defaultValue
                ? this.props.defaultValue
                : null,
            monthInView = Moment(dateToShow ? dateToShow : new Date());

        this.state = {
            dateToShow: dateToShow,
            format: this.props.format ? this.props.format : 'MMM DD YYYY',
            dateFormat: this.props.dateFormat ? this.props.dateFormat : 'D',
            monthInView: monthInView,
        };

        if (this.props.footerMessages) {
            for (let messageobj of this.props.footerMessages) {
                if (typeof messageobj.date === 'string') {
                    messageobj.date = new Date(messageobj.date);
                }
                let smoment = Moment(messageobj.date);
                if (
                    smoment.isSame(monthInView, 'year') &&
                    smoment.isSame(monthInView, 'month')
                ) {
                    this.state.canShowMessage = true;
                    this.state.message = messageobj.message;
                    this.state.messageComponent = messageobj.messageComponent ? (
                        messageobj.messageComponent
                    ) : (
                        <SVG name="TurboTax" />
                    );
                    break;
                }
            }
        }

        if (this.props.locale) {
            Moment.locale(this.props.locale);
        } else {
            Moment.locale('en-US', {
                weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            });
        }

        momentLocalizer(Moment);
    }

    render() {
        let calendarOpts = {};
        if (this.props.disableHolidays) {
            calendarOpts.dayComponent = this._disableHolidays(
                this.props.disableHolidays,
                this.state.format,
                this.props.disableSelection,
                this.props.disableWeekends,
            );
        } else if (!this.props.dayComponent && this.props.disableWeekends) {
            calendarOpts.dayComponent = this._disableWeekends(
                this.props.disableSelection,
            );
        } else if (this.props.disableSelection) {
            calendarOpts.dayComponent = this._disableSelection(
                this.props.disableSelection,
            );
        }

        let calendarMessage;
        if (this.props.footerMessages && this.state.canShowMessage) {
            calendarMessage = this.state.message;
            calendarMessage = calendarMessage.replace(
                '{y}',
                this.state.monthInView.year(),
            );
        }

        if (this.state.dateToShow) {
            calendarOpts.defaultValue = this.state.dateToShow;
        }

        // Remove the disableSelection prop which is not a prop of the CalendarWidget component.
        let { disableSelection, ...copyProps } = this.props;

        return (
            <div className="di-container di-calendar" ref="diCalendar">
                <h2 className={this.props.titleClassName}>
                    {this.props.title}
                </h2>
                <CalendarWidget
                    tabIndex="-1"
                    {...calendarOpts}
                    {...copyProps}
                    aria-label={this.props.title}
                    dayFormat={this.state.dayFormat}
                    dateFormat={this.state.dateFormat}
                    onNavigate={this._handleOnNavigate.bind(this)}
                    ref="diCalendarWidget"
                />

                {this.props.footerMessages && this.state.canShowMessage ? (
                    <div className="media" tabIndex="0">
                        <div className="media-left">
                            {this.state.messageComponent}
                        </div>
                        <div
                            className="media-body brand-link"
                            onClick={this._handleFooterMessageClick.bind(this)}
                        >
                            {calendarMessage}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    _handleOnNavigate(date, direction, view) {
        let monthInView = Moment(date);
        let canShowMessage = false;
        let message;
        let messageComponent;
        let calendarMessage;

        if (this.props.footerMessages) {
            for (let messageobj of this.props.footerMessages) {
                if (typeof messageobj.date === 'string') {
                    messageobj.date = new Date(messageobj.date);
                }
                let smoment = Moment(messageobj.date);
                if (
                    smoment.isSame(monthInView, 'year') &&
                    smoment.isSame(monthInView, 'month')
                ) {
                    canShowMessage = true;
                    message = messageobj.message;
                    messageComponent = messageobj.messageComponent ? (
                        messageobj.messageComponent
                    ) : (
                        <SVG name="TurboTax" />
                    );
                    break;
                }
            }
        }

        if (this.props.footerMessages && canShowMessage) {
            calendarMessage = message;
            calendarMessage = calendarMessage.replace(
                '{y}',
                monthInView.year(),
            );
        }

        this.setState({
            canShowMessage: canShowMessage,
            message: calendarMessage,
            messageComponent: messageComponent,
        });
        if (this.props.onNavigate) {
            this.props.onNavigate(date, direction, view);
        }
        if (this.ariaTimer) {
            window.clearTimeout(this.ariaTimer);
            this.ariaTimer = null;
        }

        this.ariaTimer = setTimeout(() => {
            let headers = this.getTableHeader();
            this._updateAriaAttribute(headers);
            this.ariaTimer = null;
        }, 1000);
    }
    getTableHeader() {
        let calendarWidgetElement = ReactDOM.findDOMNode(
            this.refs.diCalendarWidget,
        );
        let tableHeader = DOMUtils.select(
            '.rw-calendar-grid th',
            calendarWidgetElement,
        );
        return tableHeader;
    }
    componentDidMount() {
        let calendarWidgetEl = ReactDOM.findDOMNode(this.refs.diCalendarWidget);

        let caretLeftEl = DOMUtils.select1(
            '.rw-i-caret-left',
            calendarWidgetEl,
        );
        let caretRightEl = DOMUtils.select1(
            '.rw-i-caret-right',
            calendarWidgetEl,
        );
        let buttonLeftEl = DOMUtils.select1('.rw-btn-left', calendarWidgetEl);
        let buttonRightEl = DOMUtils.select1('.rw-btn-right', calendarWidgetEl);

        let calendarEl = this.refs.diCalendar;
        let mediaBodyEl = DOMUtils.select1('.media-body', calendarEl);
        let rwBtnEl = DOMUtils.select(
            '.rw-calendar-grid td .rw-btn',
            calendarEl,
        );
        let disableHolidaysEl = DOMUtils.select(
            '.di-calendar-disable-holidays',
            calendarEl,
        );
        let disableWeekendsEl = DOMUtils.select(
            '.di-calendar-disable-weekends',
            calendarEl,
        );
        let disableWeekends6El = DOMUtils.select(
            '.di-calendar-disable-holidays.d6',
            calendarEl,
        );
        let disableWeekends7El = DOMUtils.select(
            '.di-calendar-disable-holidays.d7',
            calendarEl,
        );
        let rwBtnViewEl = DOMUtils.select1(
            '.rw-header .rw-btn-view',
            calendarEl,
        );

        DOMUtils.removeClass(caretLeftEl, 'rw-i');
        DOMUtils.removeClass(caretLeftEl, 'rw-i-caret-left');
        DOMUtils.addClass(caretLeftEl, 'di-icon-back');

        DOMUtils.removeClass(caretRightEl, 'rw-i');
        DOMUtils.removeClass(caretRightEl, 'rw-i-caret-right');
        DOMUtils.addClass(caretRightEl, 'di-icon-forward');

        DOMUtils.addClass(rwBtnViewEl, 'brand-link');

        DOMUtils.removeClass(buttonLeftEl, 'rw-btn');
        DOMUtils.addClass(buttonLeftEl, 'di-rw-btn');
        DOMUtils.addClass(buttonLeftEl, 'btn-link');

        DOMUtils.removeClass(buttonRightEl, 'rw-btn');
        DOMUtils.addClass(buttonRightEl, 'di-rw-btn');
        DOMUtils.addClass(buttonRightEl, 'btn-link');

        if (mediaBodyEl) {
            DOMUtils.removeClass(mediaBodyEl, 'media-body');
            DOMUtils.addClass(mediaBodyEl, 'di-media-body');
            DOMUtils.addClass(mediaBodyEl, 'btn-link');
        }
        let headers = this.getTableHeader();
        this._updateAriaAttribute(headers);
        this._doSomeDOMUpdates(
            rwBtnEl,
            disableHolidaysEl,
            disableWeekendsEl,
            disableWeekends6El,
            disableWeekends7El,
        );
    }

    componentDidUpdate() {
        let calendarEl = this.refs.diCalendar;
        let calendarWidgetElement = ReactDOM.findDOMNode(this.refs.calendarEl);
        let rwBtnEl = DOMUtils.select(
            '.rw-calendar-grid td .rw-btn',
            calendarEl,
        );
        let disableHolidaysEl = DOMUtils.select(
            '.di-calendar-disable-holidays',
            calendarEl,
        );
        let disableWeekendsEl = DOMUtils.select(
            '.di-calendar-disable-weekends',
            calendarEl,
        );
        let disableWeekends6El = DOMUtils.select(
            '.di-calendar-disable-holidays.d6',
            calendarEl,
        );
        let disableWeekends7El = DOMUtils.select(
            '.di-calendar-disable-holidays.d7',
            calendarEl,
        );
        let calenderLeftButton = DOMUtils.select1(
            '.rw-btn-left',
            calendarWidgetElement,
        );
        let calenderRightButton = DOMUtils.select1(
            '.rw-btn-right',
            calendarWidgetElement,
        );
        if (calenderLeftButton) {
            calenderLeftButton.setAttribute('tabIndex', '0');
        }
        if (calenderRightButton) {
            calenderRightButton.setAttribute('tabIndex', '0');
        }
        this._doSomeDOMUpdates(
            rwBtnEl,
            disableHolidaysEl,
            disableWeekendsEl,
            disableWeekends6El,
            disableWeekends7El,
        );
    }

    _updateAriaAttribute(tableHeader) {
        let WeekDaysArray = Moment.weekdays(true);
        let weekdaysMin = Moment.weekdaysMin(true);
        for (var i = 0; i < tableHeader.length; i++) {
            tableHeader[i].setAttribute('scope', 'col');
            tableHeader[i].setAttribute('role', 'columnheader');
            tableHeader[i].innerHTML = ' ';
            ARIAUtils.appendHiddenElementWithFullWeekName(
                tableHeader[i],
                WeekDaysArray[i],
            );
            ARIAUtils.appendHiddenElement(tableHeader[i], weekdaysMin[i]);
        }
    }

    _doSomeDOMUpdates(
        rwBtnEl,
        disableHolidaysEl,
        disableWeekendsEl,
        disableWeekends6El,
        disableWeekends7El,
    ) {
        let calendarEl = this.refs.diCalendar;
        let rwBtnViewEl = DOMUtils.select1(
            '.rw-header .rw-btn-view',
            calendarEl,
        );
        let rwNowEl = DOMUtils.select1(
            '.rw-calendar-grid td .rw-now',
            calendarEl,
        );

        if (this.props.disableSelection) {
            //for(let rwBtnElItem of rwBtnEl) {
            for (let i = 0; i < rwBtnEl.length; i++) {
                let rwBtnElItem = rwBtnEl[i];
                DOMUtils.addClass(rwBtnElItem, 'di-calendar-disable-selection');
            }
        }

        if (disableHolidaysEl) {
            //for(let disableHolidaysElItem of disableHolidaysEl) {
            for (let i = 0; i < disableHolidaysEl.length; i++) {
                let disableHolidaysElItem = disableHolidaysEl[i];
                DOMUtils.addClass(
                    disableHolidaysElItem.parentNode,
                    'di-calendar-disable-selection',
                );
                DOMUtils.addClass(
                    disableHolidaysElItem.parentNode,
                    'di-calendar-remove-hover-styles',
                );
            }
        }

        if (this.props.disableWeekends) {
            //for(let disableWeekendsElItem of disableWeekendsEl) {
            for (let i = 0; i < disableWeekendsEl.length; i++) {
                let disableWeekendsElItem = disableWeekendsEl[i];
                DOMUtils.addClass(
                    disableWeekendsElItem.parentNode,
                    'di-calendar-disable-selection',
                );
                DOMUtils.addClass(
                    disableWeekendsElItem.parentNode.parentNode,
                    'di-calendar-disable-weekends',
                );
            }

            for (let i = 0; i < disableWeekends6El.length; i++) {
                let disableWeekendsElItem = disableWeekends6El[i];
                DOMUtils.addClass(
                    disableWeekendsElItem.parentNode.parentNode,
                    'd6',
                );
            }
            for (let i = 0; i < disableWeekends7El.length; i++) {
                let disableWeekendsElItem = disableWeekends7El[i];
                DOMUtils.addClass(
                    disableWeekendsElItem.parentNode.parentNode,
                    'd7',
                );
            }
        }

        if (rwBtnViewEl && rwNowEl) {
            let rwNowColor = window.getComputedStyle(rwBtnViewEl).color;
            rwNowEl.setAttribute(
                'style',
                'border-color: ' + rwNowColor + ' !important',
            );
        }
    }

    _disableHolidays(
        disableHolidays,
        format,
        disableSelection,
        disableWeekends,
    ) {
        let DayComponent = createReactClass({
            render() {
                let date = this.props.date,
                    dayComponentOpts = {};

                for (let formatedDate of disableHolidays) {
                    let parsedDate = Moment(formatedDate, format);
                    if (parsedDate.isSame(date)) {
                        dayComponentOpts.onClick = this._onClickHandler;
                        dayComponentOpts.className =
                            'di-calendar-disable-holidays';
                        break;
                    }
                }

                date = Moment(date);

                if (
                    disableWeekends &&
                    (date.isoWeekday() === 6 || date.isoWeekday() === 7)
                ) {
                    dayComponentOpts.onClick = this._onClickHandler;
                    dayComponentOpts.className = 'di-calendar-disable-weekends';
                }

                if (disableSelection) {
                    dayComponentOpts.onClick = this._onClickHandler;
                }

                return (
                    <div {...dayComponentOpts} disabled="disabled">
                        {this.props.label}
                    </div>
                );
            },
            _onClickHandler(e) {
                e.preventDefault();
                e.stopPropagation();
            },
        });
        return DayComponent;
    }

    _disableWeekends(disableSelection) {
        let DayComponent = createReactClass({
            render() {
                let date = this.props.date,
                    dayComponentOpts = {};

                date = Moment(date);

                if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
                    dayComponentOpts.onClick = this._onClickHandler;
                    dayComponentOpts.className = 'di-calendar-disable-weekends';
                }

                if (disableSelection) {
                    dayComponentOpts.onClick = this._onClickHandler;
                }

                return (
                    <div {...dayComponentOpts} disabled="disabled">
                        {this.props.label}
                    </div>
                );
            },
            _onClickHandler(e) {
                e.preventDefault();
                e.stopPropagation();
            },
        });
        return DayComponent;
    }

    _disableSelection(disableSelection) {
        let DayComponent = createReactClass({
            render() {
                let dayComponentOpts = {};

                if (disableSelection) {
                    dayComponentOpts.onClick = this._onClickHandler;
                }

                return <div {...dayComponentOpts}>{this.props.label}</div>;
            },
            _onClickHandler(e) {
                e.preventDefault();
                e.stopPropagation();
            },
        });
        return DayComponent;
    }

    _handleFooterMessageClick(e) {
        if (this.props.onFooterMessageClick) {
            this.props.onFooterMessageClick(e);
        }
    }
}
