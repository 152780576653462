import { action, observable } from 'mobx';

class NavigationStore {
    @observable navigationData = null;

    @observable isAwaitingBranding = false;

    @observable isLoadingBranding = false;

    currentLocation = null;

    allowedDomains = null;

    @action
    setNavigationData(navigationData) {
        this.navigationData = navigationData;
    }

    @action
    setAwaitingBrandingFlag = (value) => {
        this.isAwaitingBranding = value;
    };

    @action
    setLoadingBranding = (value) => {
        this.isLoadingBranding = value;
    };

    setCurrentLocation(currentLocation) {
        this.currentLocation = currentLocation;
    }

    isAllowedDomain(domain) {
        return this.getAllowedDomains().indexOf(domain) !== -1;
    }

    getAllowedDomains() {
        const { navigationData, allowedDomains } = this;

        if (!this.navigationData) {
            return false;
        }

        if (!allowedDomains) {
            if (navigationData.integratedAppDomainsList) {
                const domainList = navigationData.integratedAppDomainsList
                    .replace(/\s/g, '')
                    .replace('\\n', '');
                this.allowedDomains = domainList.split(',').concat('localhost');
            } else {
                this.allowedDomains = ['localhost'];
            }
        }

        return this.allowedDomains;
    }
}

export default new NavigationStore();
