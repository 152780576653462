/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { IconBox } from 'dbk-common-ui/src/components';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0px',
    height: '35%',
    boxShadow: '0px 0px 0px 1px #e0e0e0',
    borderRadius: '8px',
}));

const StyledContentSection = styled(Box)(() => ({
    margin: '10px 0px',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
}));

const StyledTitleSection = styled(Box)(() => ({
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '500',
}));

const StyledSubtitleSection = styled(Box)(({ theme }) => ({
    color: theme?.palette?.action?.active,
}));

export function HomeScreenSettingsItemContainer({
    icon,
    title,
    subtitle,
    ...props
}) {
    return (
        <StyledRoot {...props}>
            <IconBox>{icon}</IconBox>
            <StyledContentSection>
                <StyledTitleSection typography="subtitle1">
                    {title}
                </StyledTitleSection>
                <StyledSubtitleSection mt="9px" typography="body2">
                    {subtitle}
                </StyledSubtitleSection>
            </StyledContentSection>
            {props.children}
        </StyledRoot>
    );
}

HomeScreenSettingsItemContainer.propTypes = {
    icon: PropTypes.elementType,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};
