// eslint-disable-next-line no-unused-vars
export default function IconButton({ palette }) {
    return {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&.Mui-focusVisible': {
                        backgroundColor: palette.action.focus,
                    },
                },
            },
        },
    };
}
