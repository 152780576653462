import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

const UrlSanitizer = (props) => {
    const [pageContent, setPageContent] = useState();

    const href = window.top?.href || window.location?.href;

    const checkUrl = () => {
        const currentUrl = decodeURIComponent(href);
        const sanitizedUrl = DOMPurify.sanitize(currentUrl);

        if (
            sanitizedUrl?.includes('javascript') ||
            currentUrl !== sanitizedUrl
        ) {
            throw new Error('Invalid URL');
        } else {
            const { children } = props;
            setPageContent(children);
        }
    };

    useEffect(checkUrl, [href]);

    return <React.Fragment>{pageContent}</React.Fragment>;
};

export default UrlSanitizer;
