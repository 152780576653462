/* eslint-disable import/newline-after-import,react/prefer-stateless-function,react/no-unused-prop-types,no-useless-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Generic extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <svg
                x="0px"
                y="0px"
                viewBox="-275 367 60 60"
                enableBackground="new -275 367 60 60"
            >
                <g>
                    <path d="M-245,427l-30-30l30-30h30v30L-245,427z M-230,375c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S-226.1,375-230,375z" />
                </g>
            </svg>
        );
    }
}
