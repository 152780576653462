/* eslint-disable */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'formdata-polyfill';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import 'common-ui/src/css/bootstrap/3.3.7/css/bootstrap.css';
import 'common-ui/src/css/di-bootstrap-override.css';
import 'common-ui/src/css/diFonts/style.css';
import 'common-ui/src/css/di-base.css';
import 'common-ui/src/css/di-animation.css';
import 'common-ui/src/css/di-svg-icon.css';

import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/zh-tw';
import 'moment/locale/fr-ca';
import AltInstance from 'common-ui/src/alt/AltInstance';
import connectToStores from 'alt-utils/lib/connectToStores';
import * as Utilities from 'common-ui/src/util/module';
import * as Components from 'common-ui/src/components/module';
import * as ReactRouter from 'react-router';
import axios from 'axios';
import Alt from 'alt';
import * as ReactBootstrap from 'react-bootstrap';
import { setupMUILicense } from 'dbk-dynamic-branding/src/license';
import { setRegionPrefixToFetch } from './utils/FetchUtils';
import registerServiceWorker from './registerServiceWorker';
import AppLoaderComponent from './AppLoaderComponent';
import StepUp from './step-up/StepUp';

const isDev = () => {
    return process && process.env && process.env.NODE_ENV === 'development';
};

if (!isDev()) {
    __webpack_public_path__ = process.env.REACT_APP_CONTEXT; // eslint-disable-line
    setRegionPrefixToFetch();
}

// IE11 polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

window.React = React;
window.React.PropTypes = PropTypes;

if (window.DI == null) {
    window.DI = {};
}

window.DI.AltInstance = AltInstance;
window.DI.Utilities = Utilities;
window.DI.Components = Components;
window.DI.StepUp = StepUp;
window.axios = axios;
window.Alt = Alt;
window.Alt.connectToStores = connectToStores;
window.ReactBootstrap = ReactBootstrap;
window.ReactDOM = ReactDOM;
window.Moment = moment;
window.ReactRouter = ReactRouter;

// Setup MUI license
setupMUILicense();

ReactDOM.render(<AppLoaderComponent />, document.getElementById('root'));
registerServiceWorker();

if (process.env.NODE_ENV === 'development') {
    const script = document.createElement('script');
    script.setAttribute(
        'src',
        'https://github.com/Khan/tota11y/releases/download/0.1.3/tota11y.min.js',
    );
    document.getElementsByTagName('head')[0].appendChild(script);
}

if (process.env.NODE_ENV === 'production') {
    try {
        const dateStr = new Date(
            new Number(process.env.REACT_APP_BUILD_DATE_TIME),
        );
        console.log(
            `Online Banking App(${process.env.REACT_APP_BUILD_VERSION}) built on ${dateStr}`,
        );
    } catch {} // eslint-disable-line
}
