/* eslint-disable import/order,no-underscore-dangle,react/sort-comp,react/destructuring-assignment,prefer-destructuring,react/prop-types,padding-line-between-statements,jsx-a11y/no-static-element-interactions,jsx-a11y/no-noninteractive-tabindex,react/no-access-state-in-setstate,eqeqeq,react/no-typos */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-collapse';
import styles from '../../../scss/expand_collapse.scss';
import CommonUtils from '../utils/CommonUtils';
import { BrowserDetect } from 'common-ui/src/util/module';

export default class ExpandCollapse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };

        this._handleClick = this._handleClick.bind(this);
        this._handleOnKey = this._handleOnKey.bind(this);
    }

    componentDidMount() {
        CommonUtils.resizeIframe();
    }

    componentDidUpdate() {
        setTimeout(() => {
            CommonUtils.resizeIframe();
        }, 500);
    }

    render() {
        const classNameStr = this.state.expanded
            ? `${styles['selector-icon']} ${styles['selector-icon-opened']}`
            : styles['selector-icon'];

        let title = this.props.title;

        if (this.props.expandedTitle && this.state.expanded) {
            title = this.props.expandedTitle;
        }

        let titleId = this.props.id;
        if (titleId) {
            titleId += 'Title';
        }

        let contentId = this.props.id;
        if (contentId) {
            contentId += 'Content';
        }

        const fixedHeight = this.props.fixedHeight;
        return (
            <div className={styles['expand-collapse']} id={this.props.id}>
                <div
                    className={`${styles.selector} brand-link`}
                    onClick={this._handleClick}
                    onKeyDown={this._handleOnKey}
                >
                    <span className={classNameStr} />
                    <span
                        className={styles['selector-label']}
                        tabIndex="0"
                        role={BrowserDetect.isIOS() ? 'button' : 'link'}
                        aria-label={
                            BrowserDetect.isIOS() ? `Link ${title}` : title
                        }
                        aria-expanded={this.state.expanded}
                        id={titleId}
                    >
                        {title}
                    </span>
                </div>
                <div aria-live="assertive" aria-atomic="true">
                    <Collapse
                        isOpened={this.state.expanded}
                        springConfig={this.animationConfig}
                        id={contentId}
                        fixedHeight={fixedHeight}
                    >
                        <div
                            className={styles.content}
                            id="expandCollapseSection"
                        >
                            {this.props.children}
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }

    _handleClick() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    _handleOnKey(event) {
        if (event.keyCode == 13) {
            this.setState({
                expanded: !this.state.expanded,
            });
        }
    }
}

ExpandCollapse.PropTypes = {
    title: PropTypes.string.isRequired,
    expandedTitle: PropTypes.string,
    children: PropTypes.element.isRequired,
    id: PropTypes.string,
};
