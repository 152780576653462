export function getCSSVar(name) {
    return getComputedStyle(document.documentElement)
        ?.getPropertyValue(name)
        ?.trim();
}

export function createGradient(deg, ...colors) {
    // eslint-disable-next-line no-param-reassign
    colors = colors.filter(Boolean);

    return `linear-gradient(${deg}deg, ${colors.join(', ')})`;
}
