/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import { Box } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Draggable } from 'react-beautiful-dnd';
import ErrorIcon from '@mui/icons-material/Error';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const StyledRoot = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'center',
    justifyContent: 'center',
    margin: '10px 0px',
    height: '35%',
    boxShadow: '0px 0px 0px 1px #e0e0e0',
    borderRadius: '8px',
}));

const StyledContentSection = styled(Box)(() => ({
    margin: '10px 0px',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
}));

const StyledTitleSection = styled(Box)(() => ({
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '500',
}));

const StyledSubtitleSection = styled(Box)(({ theme }) => ({
    color: theme?.palette?.action?.active,
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#2eb82e',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(255,255,255,.35)',
        boxSizing: 'border-box',
    },
}));

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledIconBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
}));

export default class ScreenSettingsItemContainer extends Component {
    render() {
        const { props } = this;
        const { otherData } = props;
        const { icon, isFavEnabled } = otherData;

        return (
            <Draggable
                draggableId={this.props.title + this.props.index}
                index={this.props.index}
            >
                {(provided) => (
                    <StyledRoot
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                    >
                        {icon && (
                            <StyledIconBox>
                                <ErrorIcon fontSize="large" />
                            </StyledIconBox>
                        )}
                        <StyledContentSection>
                            <StyledTitleSection typography="subtitle1">
                                {this.props.title}
                            </StyledTitleSection>
                            <StyledSubtitleSection mt="9px" typography="body2">
                                {this.props.subtitle}
                            </StyledSubtitleSection>
                        </StyledContentSection>
                        <StyledBox>
                            {isFavEnabled && (
                                <StarBorderIcon fontSize="small" />
                            )}
                            <AntSwitch
                                defaultChecked
                                inputProps={{ 'aria-label': 'ant design' }}
                            />
                            <div {...provided.dragHandleProps}>
                                <DragIndicatorIcon />
                            </div>
                        </StyledBox>
                    </StyledRoot>
                )}
            </Draggable>
        );
    }
}
