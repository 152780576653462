/**
 * For this to work, parent element wrapping this component
 * should be block or inline-block element
 * and should have width provided
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

export function TruncatedTypography({ id, variant, title }) {
    return (
        <Typography id={id} variant={variant} title={title} noWrap>
            {title}
        </Typography>
    );
}

TruncatedTypography.propTypes = {
    id: PropTypes.string,
    variant: PropTypes.string,
    title: PropTypes.string,
};

export default {};
