import { LAYOUT_BREAKPOINTS } from '../constants';
import { useLayoutBreakpoint } from './useLayoutBreakpoint';

export const useSmallViewport = () => {
    const layoutBreakpoint = useLayoutBreakpoint();

    console.log(layoutBreakpoint);

    const isSmallViewport =
        layoutBreakpoint === LAYOUT_BREAKPOINTS.SM ||
        layoutBreakpoint === LAYOUT_BREAKPOINTS.XS;

    return isSmallViewport;
};
