/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class HelpOutline extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M9.8,14.6H8.3v-2h1.5V14.6z M6.1,6.1C6.2,5.7,6.4,5.3,6.7,5c0.3-0.3,0.6-0.5,1-0.7C8.1,4.1,8.5,4.1,9,4.1
						c0.6,0,1.1,0.1,1.5,0.3c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.4,0.5,0.5,0.8c0.1,0.3,0.2,0.6,0.2,0.8c0,0.4-0.1,0.8-0.2,1.1
						s-0.2,0.5-0.4,0.7S11.3,8.8,11.1,9c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.3,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.7v0.5l-1.6,0v-0.6
						c0-0.4,0.1-0.7,0.2-1C8.5,9.2,8.6,9,8.7,8.8C8.9,8.6,9,8.5,9.2,8.3C9.4,8.2,9.5,8,9.7,7.9C9.8,7.8,9.9,7.6,10,7.5s0.1-0.4,0.1-0.6
						c0-0.4-0.1-0.7-0.3-0.9C9.7,5.7,9.4,5.6,9.1,5.6c-0.2,0-0.4,0-0.6,0.1C8.3,5.9,8.2,6,8,6.2C7.9,6.3,7.9,6.5,7.8,6.7
						C7.8,6.9,7.7,6.9,7.7,7.1H5.9C5.9,6.6,5.9,6.5,6.1,6.1z"
                    />
                    <path
                        d="M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S14,0,9,0z M9,17.1c-4.5,0-8.1-3.6-8.1-8.1S4.5,0.9,9,0.9s8.1,3.6,8.1,8.1
						S13.5,17.1,9,17.1z"
                    />
                </g>
            </svg>
        );
    }
}
