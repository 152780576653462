export default function Stack() {
    return {
        MuiStack: {
            defaultProps: {
                justifyContent: 'center',
                alignItems: 'center',
                spacing: 0.5,
            },
        },
    };
}
