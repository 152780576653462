import React from 'react';
import PropTypes from 'prop-types';

import { Switch } from '../Switch';

import { useLayout } from '../../contexts';
import { useResponsive } from '../../hooks/useResponsive';

export const LayoutSwitch = ({ label }) => {
    const { isVerticalLayout, toggleLayout } = useLayout();

    const isDesktop = useResponsive('up', 'md');

    const switcher = isDesktop && (
        <Switch
            checked={isVerticalLayout}
            onChange={toggleLayout}
            label={label}
        />
    );

    return switcher;
};

LayoutSwitch.propTypes = {
    label: PropTypes.string.isRequired,
};

export default {};
