/* eslint-disable import/order,spaced-comment,react/no-deprecated,prefer-const,no-underscore-dangle,react/destructuring-assignment,react/prop-types,react/no-access-state-in-setstate,padding-line-between-statements,no-lone-blocks,dot-notation,prefer-template,react/button-has-type,react/jsx-no-bind,no-nested-ternary,react/no-danger,react/jsx-indent,jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control,one-var,prefer-destructuring,no-lonely-if,class-methods-use-this,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import Calendar from './Calendar';
import Moment from 'moment';
import SVG from '../images/SVG';
import DatePickerDayComponent from './DatePickerDayComponent';
import SendDeliverByDateDayComponent from './SendDeliverByDateDayComponent';
import PropTypes from 'prop-types';

//@deprecated, Refer to Almanac Component
export default class DatePicker extends Component {
    constructor(props) {
        super(props);

        let _strings = {
            'di.datepicker.close': 'Close',
            'di.datepicker.save': 'Save',
            'di.datepicker.cancel': 'Cancel',
            'di.datepicker.selectDate': 'Select Date',
            'di.datapicker.from': 'From',
            'di.datepicker.to': 'to',
            'di.datepicker.go': 'Go',
        };

        this.state = {
            showCalendar: false,
            format: this.props.format ? this.props.format : 'MMM DD YYYY',
            defaultValue: this.props.defaultValue
                ? this.props.defaultValue
                : null,
            selectedDateFormated: null,
            selectedDate: this.props.defaultValue
                ? this.props.defaultValue
                : null,
            i18nStrings: this.props.i18nStrings
                ? this.props.i18nStrings
                : _strings,
            fromDefaultValue: this.props.fromDefaultValue
                ? this.props.fromDefaultValue
                : null,
            toDefaultValue: this.props.toDefaultValue
                ? this.props.toDefaultValue
                : null,
        };

        if (this.props.XS) {
            this.state.defaultValue = new Date();
        }

        if (this.state.defaultValue) {
            this.state.selectedDateFormated = Moment(
                this.state.defaultValue,
            ).format(this.state.format);
        }

        if (this.props.dateRange) {
            this.state.selectedFromDateFormated = Moment(
                this.state.fromDefaultValue,
            ).format(this.state.format);
            this.state.selectedToDateFormated = Moment(
                this.state.toDefaultValue,
            ).format(this.state.format);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.fromDefaultValue !== nextProps.fromDefaultValue ||
            this.props.toDefaultValue !== nextProps.toDefaultValue
        ) {
            this.setState({
                fromDefaultValue: nextProps.fromDefaultValue,
                toDefaultValue: nextProps.toDefaultValue,
                selectedFromDateFormated: Moment(
                    nextProps.fromDefaultValue,
                ).format(this.state.format),
                selectedToDateFormated: Moment(nextProps.toDefaultValue).format(
                    this.state.format,
                ),
            });
        }
    }

    render() {
        let cal = null;
        let ip = null;
        if (this.props.dateRange) {
            cal = this._renderFromToInputCalendar();
        } else {
            //if( !this.props.XS && !this.props.selectSendDate &&  !this.props.selectDeliverByDate )
            {
                ip = this._renderInput();
            }
            if (
                this.state.showCalendar ||
                this.props.XS
                //||  this.props.selectSendDate || this.props.selectDeliverByDate
            ) {
                cal = this._renderCalendar();
            }
        }

        return (
            <div className="di-datepicker">
                {ip} {cal}
            </div>
        );
    }

    _renderCalendar(calendarIDStr) {
        let calendarOpts = {};
        if (calendarIDStr) {
            if (calendarIDStr === 'toCalendar') {
                calendarOpts['min'] = this.state.fromDefaultValue;
            } else if (calendarIDStr === 'fromCalendar') {
                calendarOpts['max'] = this.state.toDefaultValue;
            }
        }

        if (this.props.selectSendDate) {
            calendarOpts['dayComponent'] = this._sendDeliverByDateDayComponent(
                this.state.selectedDate,
                'forward',
                this.props.onSendDateChange,
                this.props.onDeliverByDateChange,
                this.props.disableHolidays,
                this.state.format,
            );
        }
        if (this.props.selectDeliverByDate) {
            calendarOpts['dayComponent'] = this._sendDeliverByDateDayComponent(
                this.state.selectedDate,
                'back',
                this.props.onSendDateChange,
                this.props.onDeliverByDateChange,
                this.props.disableHolidays,
                this.state.format,
            );
        }

        return (
            <div
                className={
                    'di-calendar-wrapper' +
                    (this.props.popover && !this.props.XS
                        ? ' di-calendar-wrapper-popover'
                        : '') +
                    (this.props.XS ? ' di-calendar-xs' : '')
                }
            >
                {!this.props.selectSendDate &&
                !this.props.selectDeliverByDate ? (
                    <div>
                        {!this.props.XS ? (
                            <div className="clearfix">
                                <span className="sr-only">
                                    {
                                        this.state.i18nStrings[
                                            'di.datepicker.close'
                                        ]
                                    }
                                </span>
                                <button
                                    className="pull-right btn btn-tertiary di-datepicker-close-btn"
                                    onClick={this._handleCancel.bind(this)}
                                >
                                    <span className="di-icon-close" />
                                </button>
                            </div>
                        ) : (
                            <h2>
                                {
                                    this.state.i18nStrings[
                                        'di.datepicker.selectDate'
                                    ]
                                }
                            </h2>
                        )}
                    </div>
                ) : null}

                <Calendar
                    onChange={this._handleOnChange.bind(this)}
                    defaultValue={
                        calendarIDStr
                            ? calendarIDStr === 'fromCalendar'
                                ? this.state.fromDefaultValue
                                : this.state.toDefaultValue
                            : this.state.defaultValue
                    }
                    {...calendarOpts}
                    {...this.props}
                />
                {this.props.calendarFooter ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: this.props.calendarFooter(),
                        }}
                    />
                ) : null}
                {this.props.enableButtons ||
                (!this.props.selectSendDate &&
                    !this.props.selectDeliverByDate) ? (
                    <div className="text-center">
                        <button
                            className={
                                'btn' +
                                (this.props.XS || this.props.enableButtons
                                    ? ' btn-primary btn-block'
                                    : ' btn-tertiary')
                            }
                            onClick={
                                this.props.XS
                                    ? this._handleSaveXS.bind(this)
                                    : this._handleSave.bind(this, calendarIDStr)
                            }
                        >
                            {this.state.i18nStrings['di.datepicker.save']}
                        </button>
                        {this.props.XS || this.props.enableButtons ? (
                            <button
                                className="btn btn-secondary btn-block"
                                onClick={this._handleCancel.bind(this)}
                            >
                                {this.state.i18nStrings['di.datepicker.cancel']}
                            </button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    }

    _renderInput() {
        let disabledInput = this.props.disabled ? 'disabled' : '';
        let readonlyInput = this.props.readonly ? 'true' : '';

        let errorClass = '';

        if (this.props.showError) {
            errorClass = 'di-datepicker-error';
        }

        return (
            <div className="form-group  has-feedback hidden-xs">
                {this.props.labelText ? (
                    <div className={errorClass + ' input-group'}>
                        <label className="input-group-addon">
                            {this.props.labelText}
                        </label>

                        <input
                            onFocus={this._handleOnFocus.bind(this)}
                            type="text"
                            className="form-control"
                            value={this.state.selectedDateFormated}
                            onChange={this._handleInputChange.bind(this)}
                            disabled={disabledInput}
                            readOnly={readonlyInput}
                        />
                    </div>
                ) : (
                    <input
                        onFocus={this._handleOnFocus.bind(this)}
                        type="text"
                        className="form-control"
                        value={this.state.selectedDateFormated}
                        onChange={this._handleInputChange.bind(this)}
                        disabled={disabledInput}
                        readOnly={readonlyInput}
                    />
                )}
                <span className="form-control-feedback">
                    <SVG name="Calendar" size="md" />
                </span>
            </div>
        );
    }

    _renderFromToInputCalendar() {
        return (
            <div className="form-inline">
                <div
                    className={
                        this.state.showFromCalendar || this.state.showToCalendar
                            ? 'pull-left di-calendar-space'
                            : 'pull-left'
                    }
                >
                    <div className="form-group has-feedback">
                        <label className="di-calendar-from-date-label">
                            {this.state.i18nStrings['di.datapicker.from']}
                        </label>
                        <div className="di-calendar-from-to-input-wrapper">
                            <input
                                type="text"
                                className="form-control"
                                onFocus={this._handleOnFocus.bind(
                                    this,
                                    'fromCalendar',
                                )}
                                value={this.state.selectedFromDateFormated}
                                onChange={this._handleFromToInputChange.bind(
                                    this,
                                    'fromCalendarInput',
                                )}
                            />
                            <span className="form-control-feedback">
                                <SVG name="Calendar" size="md" />
                            </span>
                        </div>
                    </div>
                    {this.state.showFromCalendar ? (
                        <div className="di-from-to-calendar-wrapper">
                            {this._renderCalendar('fromCalendar')}
                        </div>
                    ) : null}
                </div>
                <div className="pull-left">
                    <div className="form-group has-feedback">
                        <label className="di-calendar-to-date-label">
                            {this.state.i18nStrings['di.datepicker.to']}
                        </label>
                        <div className="di-calendar-from-to-input-wrapper">
                            <input
                                type="text"
                                className="form-control"
                                onFocus={this._handleOnFocus.bind(
                                    this,
                                    'toCalendar',
                                )}
                                value={this.state.selectedToDateFormated}
                                onChange={this._handleFromToInputChange.bind(
                                    this,
                                    'toCalendarInput',
                                )}
                            />
                            <span className="form-control-feedback">
                                <SVG name="Calendar" size="md" />
                            </span>
                        </div>
                    </div>
                    {this.state.showToCalendar ? (
                        <div className="di-from-to-calendar-wrapper">
                            {this._renderCalendar('toCalendar')}
                        </div>
                    ) : null}
                </div>
                <button
                    type="submit"
                    className="btn btn-primary pull-left di-calendar-go-button"
                    onClick={this._handleGoButtonClick.bind(this)}
                >
                    {this.state.i18nStrings['di.datepicker.go']}
                </button>
            </div>
        );
    }

    _handleOnFocus(whichCalendar) {
        if (typeof whichCalendar === 'string') {
            if (whichCalendar === 'fromCalendar') {
                this.setState({
                    showFromCalendar: true,
                    showToCalendar: false,
                });
            } else {
                this.setState({
                    showToCalendar: true,
                    showFromCalendar: false,
                });
            }
        } else {
            this.setState({ showCalendar: true });
        }
    }

    _handleOnChange(date) {
        this.setState({ selectedDate: date });
        if (this.props.onChange) {
            this.props.onChange(date);
        }
    }

    _handleCancel() {
        if (!this.props.XS) {
            this.setState({
                showCalendar: false,
                showFromCalendar: false,
                showToCalendar: false,
            });
        }
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    _handleSave(calendarIDStr) {
        let formatedDate = Moment(this.state.selectedDate).format(
            this.state.format,
        );

        if (typeof calendarIDStr === 'string') {
            if (calendarIDStr === 'fromCalendar') {
                this.setState({
                    showFromCalendar: false,
                    showToCalendar: false,
                    fromDefaultValue: this.state.selectedDate,
                    selectedFromDateFormated: formatedDate,
                });
            } else {
                this.setState({
                    showFromCalendar: false,
                    showToCalendar: false,
                    toDefaultValue: this.state.selectedDate,
                    selectedToDateFormated: formatedDate,
                });
            }
        } else {
            this.setState({
                showCalendar: false,
                defaultValue: this.state.selectedDate,
                selectedDateFormated: formatedDate,
            });
        }

        if (this.props.onDateChange) {
            this.props.onDateChange(this.state.selectedDate, formatedDate);
        }
    }

    _handleSaveXS() {
        let formatedDate,
            selectedDate = this.state.selectedDate;
        if (!selectedDate) {
            selectedDate = this.state.defaultValue;
        }
        formatedDate = Moment(selectedDate).format(this.state.format);
        if (this.props.onDateChange) {
            this.props.onDateChange(selectedDate, formatedDate);
        }
    }

    _handleInputChange(e) {
        let formatedDate = e.target.value,
            inputDate = Moment(formatedDate, this.state.format);
        if (inputDate.isValid()) {
            inputDate = inputDate.toDate();
            this.setState({
                selectedDateFormated: formatedDate,
                defaultValue: inputDate,
            });

            if (this.props.onDateChange) {
                this.props.onDateChange(inputDate, formatedDate);
            }
        } else {
            this.setState({ selectedDateFormated: formatedDate });
        }
    }

    _handleFromToInputChange(calendarInputStr, e) {
        let formatedDate = e.target.value,
            inputDate = Moment(formatedDate, this.state.format);
        if (inputDate.isValid()) {
            inputDate = inputDate.toDate();
            if (calendarInputStr === 'fromCalendarInput') {
                this.setState({
                    selectedFromDateFormated: formatedDate,
                    fromDefaultValue: inputDate,
                });
            } else {
                this.setState({
                    selectedToDateFormated: formatedDate,
                    toDefaultValue: inputDate,
                });
            }
        } else {
            if (calendarInputStr === 'fromCalendarInput') {
                this.setState({ selectedFromDateFormated: formatedDate });
            } else {
                this.setState({ selectedToDateFormated: formatedDate });
            }
        }
    }

    _handleGoButtonClick() {
        if (this.props.onFromToDateChange) {
            this.props.onFromToDateChange(
                this.state.selectedFromDateFormated,
                this.state.fromDefaultValue,
                this.state.selectedToDateFormated,
                this.state.toDefaultValue,
            );
        }
    }

    _sendDeliverByDateDayComponent(
        selectedDate,
        direction,
        onSendDateChange,
        onDeliverByDateChange,
        disableHolidays,
        format,
    ) {
        if (!selectedDate) {
            return DatePickerDayComponent;
        }
        let sendDate = null;
        let deliverByDate = null;
        if (onDeliverByDateChange) {
            sendDate = onDeliverByDateChange(selectedDate);
        }
        if (onSendDateChange) {
            deliverByDate = onSendDateChange(selectedDate);
        }

        return React.createClass({
            render() {
                return (
                    <SendDeliverByDateDayComponent
                        direction={direction}
                        sendDate={sendDate}
                        deliverByDate={deliverByDate}
                        selectedDate={selectedDate}
                        disableHolidays={disableHolidays}
                        format={format}
                        {...this.props}
                    />
                );
            },
        });
    }
}

DatePicker.propTypes = {
    popover: PropTypes.bool,
    XS: PropTypes.bool,
    labelText: PropTypes.string,
    /**
     * A boolean to set the date picker as disabled
     */
    disabled: PropTypes.bool,
    /**
     * A boolean that determines if the date picker is read only or not
     */
    readonly: PropTypes.bool,
    dateRange: PropTypes.bool,
    selectSendDate: PropTypes.bool,
    enableButtons: PropTypes.bool,
    selectDeliverByDate: PropTypes.bool,
    /**
     * A function to be called when the user changes the send date
     */
    onSendDateChange: PropTypes.func,
    /**
     * A function to be called when the user changes the deliver by date
     */
    onDeliverByDateChange: PropTypes.func,
    /**
     * The value of the date you want to show in the selection input
     */
    defaultValue: PropTypes.object,
    fromDefaultValue: PropTypes.object,
    toDefaultValue: PropTypes.object,
    /**
     * Format of date string
     */
    format: PropTypes.string,
};
