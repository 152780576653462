/* eslint-disable react/prefer-stateless-function,no-useless-constructor */
import React, { Component } from 'react';
import SVG from '../images/SVG';

// This component should be shown whenever something is loading.
export default class Loader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <SVG name="SmallLoader" size="lg" />
            </div>
        );
    }
}
