import React from 'react';
import PropTypes from 'prop-types';

import { Switch as MuiSwitch, Typography } from '@mui/material';

import { HStack } from '../Stack';

export const Switch = ({ label, size = 'small', checked, onChange }) => {
    return (
        <HStack>
            <MuiSwitch
                checked={checked}
                onChange={onChange}
                name={`${label}_switch`}
                color="success"
                size={size}
            />
            <Typography variant="body1" fontWeight="medium">
                {label}
            </Typography>
        </HStack>
    );
};

Switch.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default {};
