import Fetch from 'fetch';
import { BioCatchSDKManager } from 'common-ui/src/util/module';

export default class BioCatchService {
    static postAction(action, activity) {
        return Fetch.post(`/accts-olb/v1/epoxy/biocatch?action=${action}`, {
            customerSessionId: BioCatchSDKManager.sessionId,
            activityType: activity,
            activityName: activity,
            vendorId: BioCatchSDKManager.config.vendorId,
            deviceModel: 'OnlineBanking',
        });
    }
}
