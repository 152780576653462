/* eslint-disable import/order,prefer-const,no-underscore-dangle,react/sort-comp,padding-line-between-statements,react/no-access-state-in-setstate,react/destructuring-assignment,no-plusplus,react/no-string-refs,class-methods-use-this,react/prop-types,no-lonely-if,react/no-find-dom-node,prefer-destructuring,object-shorthand,prefer-template,react/no-array-index-key,react/jsx-no-bind,eqeqeq,no-unused-vars,spaced-comment,dot-notation,jsx-a11y/no-static-element-interactions,no-nested-ternary,func-names,react/no-unused-state,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from '../images/SVG';
import ReactDOM from 'react-dom';
import keyCode from 'keycode';
import DropdownItem from './DropdownItem';
import EventManager from '../../util/EventManager';
import styles from '../../styles/Dropdown.scss';

import ClickOutHandler from '../../util/clickout/ClickOutHandler';

/**
 * @deprecated Since 1.8.0; Use SelectionDropdown instead
 */
export default class Dropdown extends Component {
    constructor(props) {
        super(props);

        let defaultConfig = {
            clicked: false,
            selectedItem: null,
            selectedIndex: null,
            isOpen: false,
        };

        this.state = defaultConfig;

        this._dropdownToggleButtonClick = this._dropdownToggleButtonClick.bind(
            this,
        );
        this._closeDropdown = this._closeDropdown.bind(this);
        this._dropdownToggleButtonKeyPress = this._dropdownToggleButtonKeyPress.bind(
            this,
        );
        this._focusOnNextElement = this._focusOnNextElement.bind(this);
        this._focusOnPreviousElement = this._focusOnPreviousElement.bind(this);
        this._focusOnDropdownButton = this._focusOnDropdownButton.bind(this);
        this._closeDropdownOnEsc = this._closeDropdownOnEsc.bind(this);
        this._getFocusableListItems = this._getFocusableListItems.bind(this);
        this._getFocusableListItem = this._getFocusableListItem.bind(this);
        this._focusSelectedItem = this._focusSelectedItem.bind(this);
        this.closeDropdownWithBlur = this.closeDropdownWithBlur.bind(this);
        this.resetDropdown = this.resetDropdown.bind(this);

        this.focusPosition = 0;
    }

    componentDidMount() {
        EventManager.listen(
            'hideOtherDropdowns',
            this._dropdownToggleButtonClick,
        );
        EventManager.listen('closeDropdown', this._closeDropdown);
    }

    componentWillUnmount() {
        EventManager.unlisten(
            'hideOtherDropdowns',
            this._dropdownToggleButtonClick,
        );
        EventManager.unlisten('closeDropdown', this._closeDropdown);
    }

    _resetFocusPosition() {
        this.focusPosition = 0;
    }

    _listItemClick(item, index, event) {
        let remainOpen = false;
        if (item.props && item.props.remainOpen) {
            remainOpen = item.props.remainOpen;
        }
        if (!remainOpen) {
            this.setState({
                selectedItem: item,
                selectedIndex: index,
                isOpen: !this.state.isOpen,
            });
            if (this.props.onSelect) {
                this.props.onSelect(item, event);
            }
            this._focusOnDropdownButton();
        }
        this._resetFocusPosition();
    }

    _focusOnNextElement() {
        let dropdownItems = this._getFocusableListItems();
        if (dropdownItems[this.focusPosition + 1]) {
            this.focusPosition++;
            dropdownItems[this.focusPosition].focus();
        }
    }

    _focusOnPreviousElement() {
        let dropdownItems = this._getFocusableListItems();
        if (dropdownItems[this.focusPosition - 1]) {
            this.focusPosition--;
            dropdownItems[this.focusPosition].focus();
        }
    }

    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    _focusOnDropdownButton() {
        setTimeout(() => {
            if (this.refs.dropdownToggle) {
                if (this.refs.dropdownToggle.querySelector('button')) {
                    this.refs.dropdownToggle.querySelector('button').focus();
                } else {
                    this.refs.dropdownToggle.focus();
                }
            }
        }, 200);
    }

    _setFocusOnTarget(targetElement) {
        if (targetElement) {
            setTimeout(() => {
                targetElement.focus();
            });
        }
    }

    _closeDropdownOnEsc() {
        this._resetFocusPosition();
        this._focusOnDropdownButton();
        this.setState({ isOpen: false });
    }

    _handleKeyDown(item, event) {
        let dropdownItems = this._getFocusableListItems();

        switch (event.keyCode) {
            case keyCode.codes.down:
                this._focusOnNextElement();
                event.preventDefault();
                break;
            case keyCode.codes.up:
                this._focusOnPreviousElement();
                event.preventDefault();
                break;
            case keyCode.codes.enter:
                if (item.key && this.props.link) {
                    this._selectItem(item, event);
                    this.context.router.push(this.props.link);
                } else {
                    this._selectItem(item, event);
                }
                break;
            case keyCode.codes.space:
                this._selectItem(item, event);
                break;
            case keyCode.codes.esc:
                this._closeDropdownOnEsc();
                break;
            case keyCode.codes.tab:
                if (dropdownItems[this.focusPosition - 1] && event.shiftKey) {
                    event.preventDefault();
                    this._focusOnPreviousElement();
                } else if (dropdownItems[this.focusPosition + 1]) {
                    event.preventDefault();
                    this._focusOnNextElement();
                } else {
                    if (this.props.preventCloseOnTabOut) {
                        break;
                    } else {
                        event.preventDefault();
                        this.setState({
                            isOpen: false,
                        });
                        this._focusOnDropdownButton();
                    }
                }
                break;
            default:
        }
    }

    _selectItem(item, event) {
        this._listItemClick(item, this.focusPosition, event);
        this._focusOnDropdownButton();
        event.preventDefault();
    }

    _getFocusableListItems() {
        return ReactDOM.findDOMNode(this.refs.dropdownList).querySelectorAll(
            'li.dropdown-item',
        );
    }

    _getFocusableListItem(index) {
        return this._getFocusableListItems().item(index);
    }

    _renderListItem(items, trimValueOn, minWidth) {
        let listItems = [];
        let selectedItem;

        if (this.state) {
            selectedItem = this.state.selectedItem;
        }
        if (items) {
            items.forEach((item, index) => {
                let title = item.title;
                let displayTitle = item.title;
                let labelStyle = { minWidth: minWidth };
                let active = '';

                if (trimValueOn > 0 && title.length >= trimValueOn) {
                    displayTitle = title.substring(0, title.length - 4) + '...';
                }

                if (selectedItem) {
                    active = selectedItem.title === item.title ? 'active' : '';
                }
                listItems.push(
                    <DropdownItem
                        key={index}
                        onClick={this._listItemClick.bind(this, item, index)}
                        className={active}
                        title={title}
                        style={labelStyle}
                        onKeyDown={this._handleKeyDown.bind(this, item)}
                    >
                        {displayTitle}
                    </DropdownItem>,
                );
            });
        }

        return listItems;
    }

    _dropdownToggleButtonKeyPress(event) {
        if (event.keyCode == 40 && this.props.disabled !== true) {
            event.stopPropagation();
            event.preventDefault();
            this.setState({ isOpen: !this.state.isOpen });

            this._focusSelectedItem();
        }
    }

    _dropdownToggleButtonClick(event) {
        if (this.props.disabled === true) {
            return;
        }

        if (
            event.nativeEvent ||
            event.currentTarget === this.refs.dropdownToggle
        ) {
            event.stopPropagation();
            this.setState({ isOpen: !this.state.isOpen });

            this._focusSelectedItem();

            EventManager.dispatch('hideOtherDropdowns', {
                srcElement: this.refs.dropdownToggle,
            });
        } else {
            if (event.srcElement === this.refs.dropdownToggle) {
                return;
            }
            this.setState({ isOpen: false });
        }
    }

    _focusSelectedItem() {
        let selectedIndex = this.state.selectedIndex;
        if (this.props.selectedIndex) {
            selectedIndex = this.props.selectedIndex;
        }
        let selectedItem = this._getFocusableListItem(selectedIndex);
        let firstItem = this._getFocusableListItem(0);
        if (selectedItem) {
            this.focusPosition = selectedIndex;
            this._setFocusOnTarget(selectedItem);
        } else if (firstItem) {
            this._setFocusOnTarget(firstItem);
        }
    }

    _closeDropdown(event) {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        }
    }

    closeDropdownWithBlur() {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        }
        this._focusOnDropdownButton();
    }

    render() {
        let trimValueOn = this.props.trimValueOn ? this.props.trimValueOn : -1;
        let minWidth = this.props.minWidth
            ? this.props.minWidth + 'px'
            : 100 + '%';
        let variationType = this.props.variationType
            ? ' ' + this.props.variationType
            : ''; //{on-gray | link | fi link}
        let size = this.props.small ? ' sm' : ''; //{sm}
        let id = this.props.id || this.props.title || new Date().getTime();
        let defaultLabel = this.props.defaultLabel
            ? this.props.defaultLabel
            : 'Select One...';
        let disabled = this.props.disabled ? ' disabled' : '';
        let selectedItem = this.state.selectedItem;
        let showError = this.props.showError ? ' error' : '';

        let optionsStyle = {};

        let selectedIndex = this.state.selectedIndex;

        if (this.props.selectedIndex !== undefined) {
            selectedIndex = this.props.selectedIndex;
        }

        if (selectedItem && selectedItem.title) {
            defaultLabel = selectedItem.title;
        }

        if (this.props.height && this.props.height > 0) {
            optionsStyle = {
                maxHeight: this.props.height + 'px',
                overflowY: 'auto',
                overflowX: 'hidden',
            };
        }

        let listClasses = ['dropdown-menu'];
        if (this.props.showBorders) {
            listClasses.push(styles['bordered-list']);
        }

        return (
            <ClickOutHandler onClickOut={this._dropdownToggleButtonClick}>
                <div
                    className={
                        'di dropdown ' +
                        styles['base_dropdown'] +
                        ' ' +
                        size +
                        variationType +
                        disabled +
                        (this.state.isOpen ? ' open' : '')
                    }
                >
                    {this.props.selectedItem ? (
                        <div
                            ref="dropdownToggle"
                            id={'dLabel' + id}
                            aria-haspopup="true"
                            title={
                                this.props.titleText
                                    ? this.props.titleText
                                    : defaultLabel
                            }
                            onClick={this._dropdownToggleButtonClick.bind(this)}
                            role={
                                this.props.ariaRole
                                    ? this.props.ariaRole
                                    : 'button'
                            }
                            onKeyDown={this._dropdownToggleButtonKeyPress}
                        >
                            {this.props.selectedItem}
                        </div>
                    ) : (
                        <button
                            ref="dropdownToggle"
                            id={'dLabel' + id}
                            type="button"
                            title={defaultLabel}
                            aria-label={
                                this.props.ariaLabel
                                    ? this.props.ariaLabel
                                    : this.props.label
                                    ? this.props.label
                                    : ''
                            }
                            onClick={this._dropdownToggleButtonClick.bind(this)}
                            className={'toggle' + disabled + showError}
                            onKeyUp={(e) => {
                                e.preventDefault();
                            }}
                            onKeyDown={this._dropdownToggleButtonKeyPress}
                            disabled={this.props.disabled}
                        >
                            <span className="ddlabel">{defaultLabel}</span>
                            <SVG name="Sort" width="16px" height="16px" />
                        </button>
                    )}

                    {this.props.items ? (
                        <ul
                            className={listClasses.join(' ')}
                            role={!this.props.noRole ? 'menu' : null}
                            style={optionsStyle}
                            ref="dropdownList"
                        >
                            {this._renderListItem(
                                this.props.items,
                                trimValueOn,
                                minWidth,
                            )}
                        </ul>
                    ) : (
                        <ul
                            className={listClasses.join(' ')}
                            role={!this.props.noRole ? 'menu' : null}
                            style={optionsStyle}
                            ref="dropdownList"
                        >
                            {React.Children.map(
                                this.props.children,
                                function (item, index) {
                                    return React.cloneElement(item, {
                                        onClick: this._listItemClick.bind(
                                            this,
                                            (item.props &&
                                                item.props.dataOnSelect) ||
                                                item,
                                            index,
                                        ),
                                        onKeyDown: this._handleKeyDown.bind(
                                            this,
                                            (item.props &&
                                                item.props.dataOnSelect) ||
                                                item,
                                        ),
                                        active: index === selectedIndex,
                                    });
                                },
                                this,
                            )}
                        </ul>
                    )}
                </div>
            </ClickOutHandler>
        );
    }

    resetDropdown() {
        this.setState({
            clicked: false,
            selectedItem: null,
            selectedIndex: null,
            isOpen: false,
        });
    }
}

Dropdown.propTypes = {
    /**
     * Number representing the index at which the title of each item should be trimmed and ellipses added
     */
    trimValueOn: PropTypes.number,
    /**
     * The minimum width of the dropdown in pixels
     */
    minWidth: PropTypes.number,
    /**
     * A string to be added to the classname of the dropdown container div
     */
    variationType: PropTypes.string,
    /**
     * If true shrinks the dropdown menu
     */
    small: PropTypes.bool,
    /**
     * Custom id to add to the
     */
    id: PropTypes.string,
    /**
     * Label for the dropdown button
     */
    defaultLabel: PropTypes.string,
    /**
     * If true disables the dropdown
     */
    disabled: PropTypes.bool,
    /**
     * Function to be executed when a selection is made from the dropdown
     */
    onSelect: PropTypes.func,
    /**
     * Height of the dropdown in pixels
     */
    height: PropTypes.number,
    /**
     * The element representing the selected item
     */
    selectedItem: PropTypes.element,
    /**
     * The index of the selected element
     */
    selectedIndex: PropTypes.number,
    /**
     * Default to false. If true borders will be added to each element in the dropdown list
     */
    showBorders: PropTypes.bool,
    /**
     * Either a single element or an array of elements that are children of the dropdown component
     */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    /**
     * Array of objects, each object should have a title and value property
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The name of a list item.
             */
            title: PropTypes.string,
            /**
             * The value of a list item (ALTHOUGH THIS DOESNT SEEM TO DO ANYTHING).
             */
            value: PropTypes.string,
        }),
    ),
    /**
     * If true adds red border to dropdown button to indicate an error
     */
    showError: PropTypes.bool,
};
