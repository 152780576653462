/* eslint-disable react/prefer-stateless-function,no-undef,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';

export default class BillPay extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M9.4,13.5H8.6L0,9v8.1h18V9L9.4,13.5z M3.4,9l7.2-6.8L9.7,1.4C9.3,0.8,8.8,0.9,8.8,0.9c-0.4,0-0.9,0.4-0.9,0.4L2.5,8.6
						C2.9,9.1,3.4,9,3.4,9z M9,12.8l8.5-5.4c0,0,0.7-1,0-1.8S13,2,13,2s-0.3-0.1-0.9,0.5S4,9.7,4,9.7L9,12.8z"
                    />
                </g>
            </svg>
        );
    }
}
