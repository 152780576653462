/* eslint-disable lines-between-class-members */
export default class PerformanceLogger {
    static BOOMR = window.top.BOOMR;
    static isHooked = false;

    static startTimer(timerName, startTime) {
        if (this.BOOMR && this.BOOMR.plugins && this.BOOMR.plugins.RT) {
            this.BOOMR.plugins.RT.clearTimer(timerName);
            this.BOOMR.plugins.RT.startTimer(timerName, startTime);
        }
    }

    static endTimer(timerName, endTime) {
        if (this.BOOMR && this.BOOMR.plugins && this.BOOMR.plugins.RT) {
            this.BOOMR.plugins.RT.endTimer(timerName, endTime);
        }
    }

    static hook(history, hadRouteChange, options) {
        if (
            !this.isHooked &&
            this.BOOMR &&
            this.BOOMR.plugins &&
            this.BOOMR.plugins.History &&
            this.BOOMR.plugins.SPA
        ) {
            // Boomerang listens to the wrong events, so we need to detect route changes manually
            history.listen(() => {
                this.BOOMR.plugins.SPA.route_change();
            });

            this.BOOMR.plugins.History.hook(history, hadRouteChange, options);

            this.isHooked = true;
        }
    }
}
