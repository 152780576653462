import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import PageLoader from 'common-ui/src/components/loader/PageLoader';

@observer
class AppLoading extends Component {
    static defaultProps = {
        error: null,
        shouldShowSupportContact: false,
    };

    static propTypes = {
        error: PropTypes.string,
        shouldShowSupportContact: PropTypes.bool,
    };

    state = {};

    render() {
        const { error, shouldShowSupportContact } = this.props;
        let component = <PageLoader />;

        if (error) {
            let message = '';
            const { errorCode } = error;

            if (shouldShowSupportContact) {
                // This is for Mobile
                //     #getLocalizedHtml1("login.serviceunavailable.error", "We are temporarily experiencing technical difficulties; try again later. If the problem persists contact us at {0}", $
                //     !customerSupportContact
                // )
            } else {
                message = `This page is temporarily unavailable. Please try again later. If the problem persists, contact customer support.${
                    errorCode ? ` (Error Code: ${errorCode}).` : ''
                }`;
            }
            component = (
                <React.Fragment>
                    <Helmet>
                        <title>Error</title>
                        <link
                            rel="stylesheet"
                            href={`${process.env.REACT_APP_CONTEXT}loading/main.css`}
                        />
                    </Helmet>
                    <div id="container">
                        <div id="main" className="wrapper">
                            <div className="formLayout">
                                <div id="errorInfo">{message}</div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return component;
    }
}
export default AppLoading;
