/* eslint-disable no-useless-constructor,prefer-const,react/destructuring-assignment,prefer-template,padding-line-between-statements,react/prop-types,import/no-dynamic-require,global-require,no-underscore-dangle,class-methods-use-this,react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SVG extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let iconName = this.props.name ? this.props.name : 'missing';
        let className = this.props.size ? ' ' + this.props.size : '';

        const compProps = {};
        if (this.props.title) {
            compProps.title = this.props.title;
        }
        if (this.props.desc) {
            compProps.desc = this.props.desc;
        }
        if (this.props.svgName) {
            compProps.svgName = this.props.svgName;
        }
        if (this.props.svgRole) {
            compProps.svgRole = this.props.svgRole;
        }

        let svgImage;
        try {
            svgImage = require(`./svg/${iconName}`);
        } catch (e) {
            console.error(`Invalid SVG name "${iconName}"`);
            return null;
        }
        const svgElement = React.createElement(
            svgImage.default || svgImage,
            compProps,
        );

        // Use the style object specified in props if it's provided.
        const style = this.props.style ? this.props.style : {};
        style.fill = 'currentColor';
        style.display = 'inline-block';

        // Append the width as a style if specified as a prop.
        if (this.props.width) {
            if (typeof this.props.width === 'number') {
                style.width = `${this.props.width}px`;
            } else {
                style.width = this.props.width;
            }
        }

        // Append the height as a style if specified as a prop.
        if (this.props.height) {
            if (typeof this.props.height === 'number') {
                style.height = `${this.props.height}px`;
            } else {
                style.height = this.props.height;
            }
        }

        // Append the custom className if specified as a prop.
        if (this.props.className) {
            className = `${className} ${this.props.className}`;
        }

        return (
            <span
                role={this.props.role}
                aria-label={this.props.ariaLabel}
                className={'di-svg' + className}
                style={style}
            >
                {svgElement}
            </span>
        );
    }

    _camelToDash(str) {
        return str.replace(/\W+/g, '-').replace(/([a-z\d])([A-Z])/g, '$1-$2');
    }
}

SVG.propTypes = {
    /**
     * Name of the icon (examples: PageLoader, Delete, Info).
     */
    name: PropTypes.string.isRequired,
    /**
     * Add string size of to the SVG container.
     */
    size: PropTypes.string,
    /**
     * Width of SVG container.
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Height of SVG container.
     */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Custom class name to add to the SVG container.
     */
    className: PropTypes.string,
    /**
     * String for SVG title attribute to improve accessibility.
     */
    title: PropTypes.string,
    /**
     * String for SVG description attribute to improve accessibility.
     */
    desc: PropTypes.string,
    /**
     * Object of CSS styles for SVG container.
     */
    style: PropTypes.object,
};
