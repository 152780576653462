/* eslint-disable spaced-comment,class-methods-use-this,no-var,padding-line-between-statements,lines-between-class-members */
//added accessibility related functions for calender
class ARIAUtils {
    appendHiddenElement(context, messageText) {
        var spanElement = document.createElement('span');
        if (spanElement) {
            spanElement.innerHTML = ' ';
            spanElement.setAttribute('aria-hidden', 'true');
            spanElement.innerHTML = messageText;
            context.appendChild(spanElement);
        }
    }
    appendHiddenElementWithFullWeekName(context, messageText) {
        var spanElement = document.createElement('span');
        if (spanElement) {
            spanElement.className = 'sr-only';
            spanElement.innerHTML = ' ';
            spanElement.innerHTML = messageText;
            context.appendChild(spanElement);
        }
    }
}
export default new ARIAUtils();
