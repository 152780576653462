/* eslint-disable import/prefer-default-export,import/no-mutable-exports,no-var,no-underscore-dangle,prefer-const,react/prop-types,padding-line-between-statements,react/destructuring-assignment,prefer-destructuring,eqeqeq,no-param-reassign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Higher order component to handle InputField interactions with type="number".
 * This HOC should be wrapped around a component that renders InputField.
 * Usage:
        class MyInput extends Component {
            constructor(props) {
                super(props);
            }

            render() {

                return (
                    <InputField id="myInput"
                                type="number"
                                maxLength={this.props.maxLength}
                                focusOnInit={true}
                                onChange={this.props.onChange}
                                onKeyDown={this.props.onKeyDown}
                                defaultValue={this.props.value}
                                pattern="[0-9]*"
                                isValid={false}/>
                )
            }
        }

        export default NumberInputFieldHOC(MyInput);
 */
export var NumberInputFieldHOC = (ComposedComponent) =>
    class extends Component {
        static displayName = 'NumberInputFieldHOC';

        static propTypes = {
            maxLength: PropTypes.number,
            validationRegex: PropTypes.string,
        };

        constructor(props) {
            super(props);

            this.state = {
                previousValue: null,
            };

            this._onChange = this._onChange.bind(this);
            this._onKeyDown = this._onKeyDown.bind(this);
        }

        render() {
            let { onChange, onKeyDown, ...childProps } = this.props;

            return (
                <ComposedComponent
                    onChange={this._onChange}
                    onKeyDown={this._onKeyDown}
                    {...childProps}
                />
            );
        }

        _onKeyDown(evt) {
            // Input type="number" allows "e", ".", and "-" based on the HTML5 spec and we want to
            // prevent their input from registering a change.
            // keyCode 69 = "e", keyCode 189 = "-", keyCode 190 = "."
            if (
                evt.keyCode === 69 ||
                evt.keyCode === 189 ||
                evt.keyCode === 190
            ) {
                evt.preventDefault();
            } else {
                // Keep track of the previously entered value so we can set it inside the input if needed.
                // The target value here is actually the previous value prior to being changed when a new
                // key is pressed.
                this.setState({ previousValue: evt.target.value });
                if (this.props.onKeyDown) {
                    this.props.onKeyDown(evt);
                }
            }
        }

        _onChange(evt) {
            let value = evt.target.value;

            // If the value length is 0, which is the case when non-numeric characters are input, set the
            // input value back to the empty string.
            if (value.length == 0) {
                evt.target.value = '';
            } else {
                // If the input exceeds the max length prop (if set) or the input does not validate against
                // the regex, set the input value back to the previous input.
                let regexString = this.props.validationRegex
                    ? this.props.validationRegex
                    : '[0-9]*';
                let regex = new RegExp(regexString);
                if (
                    (this.props.maxLength != null &&
                        value.length > this.props.maxLength) ||
                    !regex.test(value)
                ) {
                    evt.target.value = this.state.previousValue;
                    return;
                }
            }

            if (this.props.onChange) {
                this.props.onChange(evt);
            }
        }
    };
