/* eslint-disable react/default-props-match-prop-types,react/prefer-stateless-function,react/prop-types,react/static-property-placement */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

import { SafeAnchor } from 'react-bootstrap';
import {
    bsClass as setBsClass,
    splitBsProps,
} from 'react-bootstrap/lib/utils/bootstrapUtils';

const propTypes = {
    /**
     * HTML `href` attribute corresponding to `a.href`.
     */
    href: PropTypes.string,

    /**
     * Callback fired when the menu item is clicked.
     */
    onClick: PropTypes.func,
};

const defaultProps = {
    bsRole: 'footer',
};

class MenuFooter extends React.Component {
    render() {
        const { bsClass, className, style, ...props } = this.props;

        const [, elementProps] = splitBsProps(props);

        return (
            <SafeAnchor
                {...elementProps}
                className={classNames(bsClass, className)}
                style={style}
                tabIndex="-1"
            />
        );
    }
}

MenuFooter.propTypes = propTypes;
MenuFooter.defaultProps = defaultProps;

export default setBsClass('menu-footer', MenuFooter);
