/* eslint-disable no-use-before-define */
import firebase from 'firebase/app';
import 'firebase/analytics';
import Fetch from 'fetch';

const UserType = {
    Retail: 'RETAIL',
    Business: 'BUSINESS',
};

let appInitializationFailed = false;
let isEveryRequiredPropertySet = false;
let loggingInProgress = false;
let initialData = [];
let firebaseApp;
let userProperties;

const initializeOLBSessionId = () => {
    const sessionId = sessionStorage.getItem('OLB_SESSIONID');

    if (sessionId) {
        return sessionId;
    }

    const newSessionId = Date.now();
    sessionStorage.setItem('OLB_SESSIONID', newSessionId);

    return newSessionId;
};

const removeSessionId = () => {
    sessionStorage.removeItem('OLB_SESSIONID');
};

const preserveAnalytics = (eventObject, analyticsData) => {
    initialData.push({ eventObject, analyticsData });
};

const resetAnalytics = () => {
    initialData = [];
};

function setUserProperties() {
    if (!window.di?.env) return;
    const { diId: FIID, guid: USER_GUID } = window.di.env;

    if (!FIID || !USER_GUID) return;

    if (!window.dbk?.sessionInfo) return;
    const { isBBUser } = window.dbk.sessionInfo();

    if (isBBUser === undefined) return;

    const USER_TYPE = isBBUser ? UserType.Business : UserType.Retail;
    userProperties = { FIID, USER_GUID, USER_TYPE, OLB_SESSIONID };
    // Set user properties
    firebaseApp.analytics().setUserProperties(userProperties);
    isEveryRequiredPropertySet = true;
}

const logPreExistingAnalytics = () => {
    if (initialData.length && !loggingInProgress) {
        loggingInProgress = true;
        initialData.forEach((data) =>
            Firebase.handleEvent(data.eventObject, data.analyticsData),
        );
        resetAnalytics();
    }
};

const cleanseAnalyticsData = (data) => {
    const { fiId, diId, guid, userGuid, isBBUser, ...analyticsData } = data;

    return analyticsData;
};

const logAnalytics = (eventObject, analyticsData) => {
    const analytics = firebaseApp.analytics();

    if (analyticsData) {
        // eslint-disable-next-line no-param-reassign
        analyticsData = cleanseAnalyticsData(analyticsData);
        analytics.logEvent(eventObject.action, {
            ...analyticsData,
            ...userProperties,
        });
    } else {
        analytics.logEvent(eventObject.category, {
            action: eventObject.action,
            ...userProperties,
        });
    }
};

const OLB_SESSIONID = initializeOLBSessionId();

export default class Firebase {
    static init() {
        const url = `/site-olb/v1/analytics/firebase/config`;
        Fetch.get(url)
            .then((firebaseConfig) => {
                if (firebaseConfig && Object.keys(firebaseConfig).length > 0) {
                    firebaseApp = firebase.initializeApp(firebaseConfig);
                    console.log('[Firebase] OLB_SESSIONID', OLB_SESSIONID);
                }
            })
            .catch((error) => {
                appInitializationFailed = true;
                console.log(error);
            });
    }

    static handleEvent(eventObject, analyticsData) {
        if (appInitializationFailed) return;

        if (!firebaseApp) {
            preserveAnalytics(eventObject, analyticsData);

            return;
        }

        setUserProperties();

        if (!isEveryRequiredPropertySet) {
            preserveAnalytics(eventObject, analyticsData);

            return;
        }

        logPreExistingAnalytics();
        logAnalytics(eventObject, analyticsData);
    }

    static deleteApp() {
        removeSessionId();

        if (firebaseApp) {
            firebaseApp.delete();
        }
    }
}
