/* eslint-disable react/destructuring-assignment,no-underscore-dangle,prefer-const,dot-notation,react/prop-types,padding-line-between-statements,prefer-template,jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for,react/no-access-state-in-setstate,spaced-comment,react/no-danger,no-else-return,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from '../../styles/RadioButtonAndCheckbox.scss';
import BrowserDetect from '../../util/BrowserDetect';

export default class CheckBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked ? this.props.checked : false,
        };
        this._onClick = this._onClick.bind(this);
        this._getLabel = this._getLabel.bind(this);
        this._onChange = this._onChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // With the changes to the checkbox using state,
        // this allows developers to set the checked attribute with props as well.
        if (this.props.checked !== nextProps.checked) {
            this.setState({
                checked: nextProps.checked,
            });
        }
    }

    render() {
        let small = this.props.isSmall ? Styles['sm'] : null;
        let name = this.props.name ? this.props.name : null;
        let disabled = this.props.disabled ? Styles['disabled'] : null;
        let id = this.props.id ? this.props.id : null;
        let labelContainerId = null;
        let roleCheck = this.props.role ? this.props.role : null;
        if (id) {
            labelContainerId = id + 'LabelContainer';
        }
        let componentLabelContainerClassNames = classNames({
            'di-checkbox': true,
            'di-input': true,
            [`${Styles['component-label-container']}`]: true,
            [`${disabled}`]: this.props.disabled,
        });

        let clickSpaceClassNames = classNames({
            'rbcb-clickspace-container': true,
        });

        let checkboxClickSpaceSpanClassNames = classNames({
            [`${Styles['checkbox-lbl']}`]: true,
            'rbcb-lbl': true,
            [`${small}`]: this.props.isSmall,
            [`${disabled}`]: this.props.disabled,
        });

        let checkboxLabelContainerClassNames = classNames({
            [`${Styles['label-container']}`]: true,
            [`${small}`]: this.props.isSmall,
        });

        return (
            <label id={id} className={componentLabelContainerClassNames}>
                <div className={clickSpaceClassNames}>
                    <input
                        ref={(checkbox) => {
                            this.checkbox = checkbox;
                        }}
                        type="checkbox"
                        name={name}
                        onClick={this._onClick}
                        onChange={this._onChange}
                        checked={this.state.checked}
                        aria-checked={this.state.checked}
                        aria-label={
                            this.props.ariaLabel ? this.props.ariaLabel : ''
                        }
                        disabled={this.props.disabled}
                        role={roleCheck}
                    />
                    <span className={checkboxClickSpaceSpanClassNames} />
                </div>
                <div
                    id={labelContainerId}
                    className={checkboxLabelContainerClassNames}
                >
                    {this._getLabel()}
                </div>
            </label>
        );
    }

    _onClick(e) {
        if (!this.props.disabled) {
            this.setState({
                checked: !this.state.checked,
            });

            if (this.props.onClick) {
                this.props.onClick(e);
            }
        }
    }

    _onChange(e) {
        if (this.props.disabled && BrowserDetect.isIE11()) {
            //IE 11 and React an issue where if a checkbox is disabled and double clicked the onChange is called. https://github.com/facebook/react/issues/4457
            e.preventDefault();
        } else if (this.props.onChange && !this.props.disabled) {
            this.props.onChange(e);
        }

        if (this.checkbox) {
            if (document.activeElement !== this.checkbox) {
                setTimeout(() => {
                    this.checkbox.focus();
                });
            }
        }
    }

    _getLabel() {
        if (this.props.label) {
            return <span>{this.props.label}</span>;
        } else if (this.props.children) {
            return <span>{this.props.children}</span>;
        } else if (this.props.dangerouslySetLabelInnerHtml) {
            return (
                <span
                    dangerouslySetInnerHTML={
                        this.props.dangerouslySetLabelInnerHtml
                    }
                />
            );
        } else {
            return null;
        }
    }
}

CheckBox.propTypes = {
    /**
     * if true creates a small checkbox.
     */
    isSmall: PropTypes.bool,
    /**
     * if true disables the checkbox.
     */
    disabled: PropTypes.bool,
    /**
     * if true the checkbox is checked.Default Value: false
     */
    checked: PropTypes.bool,
    /**
     * Add an id to the label surrounding the input.
     */
    id: PropTypes.string,
    /**
     * A function to be executed whenever there is a change to the checkbox.
     */
    onChange: PropTypes.func,
    /**
     * If you have a string of html that is absolutely necessary in the label, this prop can be used. The value of this prop is an object containing the key '__html' who's value is the string of html meant to be placed in the label.
     */
    dangerouslySetLabelInnerHtml: PropTypes.object,
};
