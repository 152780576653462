/* eslint-disable import/order,react/destructuring-assignment,no-return-assign,react/prop-types,prefer-template,react/static-property-placement,react/boolean-prop-naming,react/no-unused-prop-types */
import React, { Component } from 'react';
import InputStyles from '../../styles/Input.scss';
import InputFieldGroupAddon from './InputFieldGroupAddon';
import { MoneyInputFieldHOC } from './hoc/MoneyInputFieldHOC';
import PropTypes from 'prop-types';

class InputFieldMoney extends Component {
    render() {
        return (
            <InputFieldGroupAddon
                id={this.props.id}
                ref={(moneyField) => (this.moneyField = moneyField)}
                name={this.props.name}
                className={
                    this.props.className +
                    ' ' +
                    InputStyles['input-field-money-form-group']
                }
                labelClassName={this.props.labelClassName}
                label={this.props.label}
                labelOnSameLine={this.props.labelOnSameLine}
                addonLabel={this.props.addonLabel}
                svgName={this.props.svgName}
                changeXsHeight={this.props.changeXsHeight}
                value={this.props.value}
                isValid={this.props.isValid}
                validationError={this.props.validationError}
                maxLength={this.props.maxLength}
                focusOnInit={this.props.focusOnInit}
                type="text"
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                ariaLabel={this.props.placeholder}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
            >
                {this.props.children}
            </InputFieldGroupAddon>
        );
    }
}

InputFieldMoney.propTypes = {
    focusOnInit: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    labelOnSameLine: PropTypes.bool,
    disabled: PropTypes.bool,
    addonLabel: PropTypes.string,
    svgName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string]),
    isValid: PropTypes.bool,
    changeXsHeight: PropTypes.bool,
    id: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    onMoneyChange: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    validationError: PropTypes.string,
};

export default MoneyInputFieldHOC(InputFieldMoney);
