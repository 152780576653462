/* eslint-disable strict,lines-around-directive,prefer-destructuring,prefer-const,react/destructuring-assignment,padding-line-between-statements,prefer-template,react/sort-comp,react/static-property-placement */
'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventManager from '../../util/EventManager';

/**
 * This component serves as a container to allow content created by other frameworks
 * such as jQuery and Angular to exist within the React component tree. This component
 * should always be a leaf child node in the tree. It should NEVER have React children
 * since those React components would never update.
 */
export default class NonReactComponent extends Component {
    constructor(props) {
        super(props);

        // Fire warning message in case no ID is given since it's required.
        let id = this.props.id;
        if (!id) {
            console.error(
                'NonReactComponent does not have an ID and will not load properly.',
            );
        }
    }

    shouldComponentUpdate() {
        // This should always be false so React itself does not interact with the content
        // that is injected/created by other frameworks into the div container in render.
        return false;
    }

    componentDidMount() {
        // Fire an event for other framework code to listen to when this component is mounted to the DOM.
        if (this.props.id) {
            EventManager.dispatch(this.props.id + '-mount');
        }
    }

    componentWillUnmount() {
        // Fire an event for other framework code to listen to when this component is unmounted from the DOM.
        if (this.props.id) {
            EventManager.dispatch(this.props.id + '-unmount');
        }
    }

    render() {
        // Default the element to div if not specified as a prop.
        let type = this.props.elementType ? this.props.elementType : 'div';
        // Pass through any props attached to the component.
        let { elementType, children, ...passThroughProps } = this.props;

        return React.createElement(type, passThroughProps, this.props.children);
    }
}

NonReactComponent.propTypes = {
    /**
     * id attribute for the component for mounting and unmounting the component.
     */
    id: PropTypes.string.isRequired,
    /**
     * Lists the type of element you are creating.Defaults Value: div
     */
    elementType: PropTypes.string,
    /**
     * children of the component - this is what will be displayed inside the component.
     */
    children: PropTypes.node,
};
