export default function OutlinedInput({ palette }) {
    return {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: palette?.grey[300],
                    },
                    '&:hover $notchedOutline': {
                        borderColor: palette?.grey[60],
                    },
                },
                input: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    padding: '10px 0',
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
            },
        },
    };
}
