/* eslint-disable filenames/match-exported,import/order,no-underscore-dangle,react/sort-comp,react/destructuring-assignment,react/no-find-dom-node,prefer-const,prefer-destructuring,react/prop-types,react/static-property-placement */
import classNames from 'classnames';
import activeElement from 'dom-helpers/activeElement';
import contains from 'dom-helpers/query/contains';
import keycode from 'keycode';
import React, { cloneElement } from 'react';
import ReactDOM from 'react-dom';
import all from 'react-prop-types/lib/all';
import elementType from 'react-prop-types/lib/elementType';
import isRequiredForA11y from 'react-prop-types/lib/isRequiredForA11y';
import { uncontrollable } from 'uncontrollable';
import warning from 'warning';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';
import SelectionDropdownMenu from './SelectionDropdownMenu';
import SelectionDropdownToggle from './SelectionDropdownToggle';
import {
    bsClass as setBsClass,
    prefix,
} from 'react-bootstrap/lib/utils/bootstrapUtils';
import createChainedFunction from 'react-bootstrap/lib/utils/createChainedFunction';
import {
    exclusiveRoles,
    requiredRoles,
} from 'react-bootstrap/lib/utils/PropTypes';
import ValidComponentChildren from 'react-bootstrap/lib/utils/ValidComponentChildren';

const TOGGLE_ROLE = SelectionDropdownToggle.defaultProps.bsRole;
const MENU_ROLE = SelectionDropdownMenu.defaultProps.bsRole;

const propTypes = {
    /**
     * Displays the menu above the toggle, instead of below it.
     */
    dropup: PropTypes.bool,

    /**
     * An html id attribute, necessary for assistive technologies, such as screen readers.
     */
    id: isRequiredForA11y(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),

    /**
     * The component class that's used as the wrapper for the toggle and the menu.
     */
    componentClass: elementType,

    /**
     * The children of a Dropdown may be a <Dropdown.Toggle> or a <Dropdown.Menu>.
     */
    children: all(
        requiredRoles(TOGGLE_ROLE, MENU_ROLE),
        exclusiveRoles(MENU_ROLE),
    ),

    /**
     * Disables the toggle.
     */
    disabled: PropTypes.bool,

    /**
     * Right aligns the menu and the toggle.
     */
    pullRight: PropTypes.bool,

    /**
     * Displays the menu.
     */
    open: PropTypes.bool,

    /**
     * Called when the menu is closed. (event: object) => any
     */
    onClose: PropTypes.func,

    /**
     * Called when the menu wishes to change visibility. Takes the new value of open as input. (open: bool) => any
     */
    onToggle: PropTypes.func,

    /**
     * Called when a menu item is selected. (eventKey: any, event: Object) => any
     */
    onSelect: PropTypes.func,

    /**
     * Determines what kind of event will cause the dropdown to close.
     */
    rootCloseEvent: PropTypes.oneOf(['click', 'mousedown']),
};

const defaultProps = {
    componentClass: ButtonGroup,
};

class SelectionDropdown extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleClick = this.handleClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this._focusInDropdown = false;
    }

    componentDidMount() {
        this.focusNextOnOpen();
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (!nextProps.open && this.props.open) {
            this._focusInDropdown = contains(
                ReactDOM.findDOMNode(this.menu),
                activeElement(document),
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { open } = this.props;
        const prevOpen = prevProps.open;

        if (open && !prevOpen) {
            this.focusNextOnOpen();
        }

        if (!open && prevOpen) {
            // if focus hasn't already moved from the menu lets return it
            // to the toggle
            if (this._focusInDropdown) {
                this._focusInDropdown = false;
                this.focus();
            }
        }
    }

    handleClick() {
        if (this.closingOnMouseDown) {
            this.closingOnMouseDown = false;

            return;
        }

        if (this.props.disabled) {
            return;
        }

        if (this.props.open) {
            this.focus();
        }

        this.toggleOpen();
    }

    handleKeyDown(event) {
        if (this.props.disabled) {
            return;
        }

        switch (event.keyCode) {
            case keycode.codes.down:
            case keycode.codes.enter:
            case keycode.codes.space:
                if (!this.props.open) {
                    this.toggleOpen();
                } else if (this.menu.focusNext) {
                    this.menu.focusNext();
                }
                event.preventDefault();
                break;
            case keycode.codes.esc:
            case keycode.codes.tab:
                this.handleClose(event);
                break;
            default:
        }
    }

    toggleOpen() {
        let open = !this.props.open;

        if (this.props.onToggle) {
            this.props.onToggle(open);
        }
    }

    handleClose(e) {
        if (!this.props.open) {
            return;
        }

        if (e && e.type === 'mousedown') {
            const currentNode = ReactDOM.findDOMNode(this);

            if (currentNode) {
                if (contains(currentNode.parentNode, e.target)) {
                    this.closingOnMouseDown = true;
                }
            }
        }

        this.toggleOpen();
    }

    focusNextOnOpen() {
        const menu = this.menu;

        if (menu.focusNextOnOpen) {
            menu.focusNextOnOpen();
        }
    }

    focus() {
        const toggle = ReactDOM.findDOMNode(this.toggle);

        if (toggle && toggle.focus) {
            toggle.focus();
        }
    }

    renderToggle(child, props) {
        let ref = (c) => {
            this.toggle = c;
        };

        if (typeof child.ref === 'string') {
            warning(
                false,
                'String refs are not supported on `<SelectionDropdown.Toggle>` components. ' +
                    'To apply a ref to the component use the callback signature:\n\n ' +
                    'https://facebook.github.io/react/docs/more-about-refs.html#the-ref-callback-attribute',
            );
        } else {
            ref = createChainedFunction(child.ref, ref);
        }

        return cloneElement(child, {
            ...props,
            ref,
            bsClass: prefix(props, 'toggle'),
            onClick: createChainedFunction(
                child.props.onClick,
                this.handleClick,
            ),
            onKeyDown: createChainedFunction(
                child.props.onKeyDown,
                this.handleKeyDown,
            ),
        });
    }

    renderMenu(child, { id, onClose, onSelect, rootCloseEvent, ...props }) {
        let ref = (c) => {
            this.menu = c;
        };

        if (typeof child.ref === 'string') {
            warning(
                false,
                'String refs are not supported on `<SelectionDropdown.Menu>` components. ' +
                    'To apply a ref to the component use the callback signature:\n\n ' +
                    'https://facebook.github.io/react/docs/more-about-refs.html#the-ref-callback-attribute',
            );
        } else {
            ref = createChainedFunction(child.ref, ref);
        }

        return cloneElement(child, {
            ...props,
            ref,
            labelledBy: id,
            bsClass: prefix(props, 'menu'),
            onClose: createChainedFunction(
                child.props.onClose,
                onClose,
                this.handleClose,
            ),
            onSelect: createChainedFunction(
                child.props.onSelect,
                onSelect,
                this.handleClose,
            ),
            rootCloseEvent,
        });
    }

    render() {
        const {
            componentClass: Component,
            id,
            dropup,
            disabled,
            pullRight,
            open,
            onClose,
            onSelect,
            bsClass,
            className,
            rootCloseEvent,
            children,
            ...props
        } = this.props;

        delete props.onToggle;

        const classes = {
            selection: true,
            [bsClass]: true,
            open,
            disabled,
        };

        if (dropup) {
            classes[bsClass] = false;
            classes.dropup = true;
        }

        // This intentionally forwards bsSize and bsStyle (if set) to the
        // underlying component, to allow it to render size and style variants.

        return (
            <Component {...props} className={classNames(className, classes)}>
                {ValidComponentChildren.map(children, (child) => {
                    switch (child.props.bsRole) {
                        case TOGGLE_ROLE:
                            return this.renderToggle(child, {
                                id,
                                disabled,
                                open,
                                bsClass,
                            });
                        case MENU_ROLE:
                            return this.renderMenu(child, {
                                id,
                                open,
                                pullRight,
                                bsClass,
                                onClose,
                                onSelect,
                                rootCloseEvent,
                            });
                        default:
                            return child;
                    }
                })}
            </Component>
        );
    }
}

SelectionDropdown.propTypes = propTypes;
SelectionDropdown.defaultProps = defaultProps;

setBsClass('dropdown', SelectionDropdown);

const UncontrolledSelectionDropdown = uncontrollable(SelectionDropdown, {
    open: 'onToggle',
});

UncontrolledSelectionDropdown.Toggle = SelectionDropdownToggle;
UncontrolledSelectionDropdown.Menu = SelectionDropdownMenu;

// react-docgen needs this export to read component information properly
export { SelectionDropdown };

export default UncontrolledSelectionDropdown;
