/* eslint-disable import/order,no-var,eqeqeq,class-methods-use-this,prefer-template,lines-between-class-members,prefer-const,no-else-return,padding-line-between-statements,spaced-comment,radix,vars-on-top,no-param-reassign,no-unneeded-ternary,operator-assignment,no-restricted-globals,no-unused-vars,func-names,no-shadow,no-undef,no-restricted-syntax,guard-for-in,no-new-object,no-unreachable */
import Constants from '../constants/Constants';
import appRouterHistory from 'app-router-history';

class CommonUtils {
    areCookiesEnabled() {
        // Set a test cookie
        var expires = new Date();
        expires.setTime(expires.getTime() + 1800000);
        this.setCookie('testCookie', 'cookie', expires, false, false, false);

        if (document.cookie.indexOf('testCookie') == -1) {
            return false;
        }

        // Remove the test cookie
        expires = new Date();
        expires.setTime(expires.getTime() - 1800000);
        this.setCookie('testCookie', 'cookie', expires, false, false, false);

        return true;
    }

    setCookie(name, value, expires, path, domain, secure) {
        document.cookie =
            name +
            '=' +
            value +
            (expires ? '; expires=' + expires.toGMTString() : '') +
            (path ? '; path=' + path : '') +
            (domain ? '; domain=' + domain : '') +
            (secure ? '; secure' : '');
    }
    /*
     * Convert String Date to MM, dd format.
     *
     * #Parameters;
     * - date (required, Type: String): "02/26/2017
     *
     * #Example;
     * - toDateMMdd("02/26/2017");
     * */
    toDateMMdd(_date, checkforToday, dateLabel) {
        let format = 'MM dd';
        let ipDate = this.stringToDate(_date, 'mm/dd/yyyy', '/');

        if (
            (checkforToday && ipDate && this.isTodaysDate(ipDate)) ||
            _date === 'Today'
        ) {
            if (dateLabel == null) {
                return 'Today';
            } else {
                return dateLabel;
            }
        }

        return this.formattedDate(format, ipDate);
    }
    toDateMMMdd(_date, checkforToday, dateLabel) {
        let format = 'MMM dd';
        let ipDate = this.stringToDate(_date, 'mm/dd/yyyy', '/');

        if (
            (checkforToday && ipDate && this.isTodaysDate(ipDate)) ||
            _date === 'Today'
        ) {
            if (dateLabel == null) {
                return 'Today';
            } else {
                return dateLabel;
            }
        }
        return this.formattedDate(format, ipDate);
    }
    /**
     * Check if Date is of today;
     *
     */
    isTodaysDate(date) {
        let todaysDate = new Date();
        return date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
    }
    /*
     * Convert String Date to Date Object.
     *
     * # Parameters;
     * - date (required, type: string) : "17/9/2014"
     * - format (required, type: string) : "dd/MM/yyyy"
     * - delimiter (required, type: string) : "/"
     *
     *
     * Example :
     * - stringToDate("17/9/2014","dd/MM/yyyy","/");
     * - stringToDate("9/17/2014","mm/dd/yyyy","/")
     * - stringToDate("9-17-2014","mm-dd-yyyy","-")
     */
    stringToDate(_date, _format, _delimiter) {
        //console.log(">> _stringToDate : " + _date);
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf('mm');
        var dayIndex = formatItems.indexOf('dd');
        var yearIndex = formatItems.indexOf('yyyy');
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var date = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);

        return date.toString() === 'Invalid Date' ? undefined : date;
    }
    /*
     * Map the date into provided format, not currently have support of Time.
     *
     * #Parameters;
     * - pattern (required) :
     *  -- yy -> Short Year Name
     *  -- yyyy -> Full Year Name
     *  -- MM -> Short Month Name
     *  -- MMM -> Full Month Name
     *  -- dd -> Day Numeric
     * - date (optional, default Today) : JS Date Object
     * - locale (optional, default 'en')
     *
     * Example:
     * - formattedDate ('MM, dd', new Date(), 'en')
     * - formattedDate ('MMM, dd')
     * */
    formattedDate(pattern, date, locale) {
        let localeMonName = {
            en: {
                month_names: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                month_names_short: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
            },
        };
        locale = locale ? locale : 'en';

        date = date ? date : new Date();
        let formattedDate = '';

        if (pattern.indexOf('yyyy') > -1)
            formattedDate = pattern.replace(
                'yyyy',
                date.getFullYear().toString(),
            );
        else if (pattern.indexOf('yy') > -1)
            formattedDate = pattern.replace(
                'yy',
                date.getFullYear().toString(),
            );
        else formattedDate = pattern;

        let mm = date.getMonth().toString(); // getMonth() is zero-based
        if (formattedDate.indexOf('mm') > -1) {
            mm = mm + 1;
            mm = mm.length > 1 ? mm : '0' + mm;
            formattedDate = formattedDate.replace('mm', mm);
        } else if (formattedDate.indexOf('MMM') > -1) {
            formattedDate = formattedDate.replace(
                'MMM',
                localeMonName[locale].month_names[mm],
            );
        } else if (formattedDate.indexOf('MM') > -1) {
            formattedDate = formattedDate.replace(
                'MM',
                localeMonName[locale].month_names_short[mm],
            );
        }

        let dd = date.getDate().toString();
        dd = dd.length > 1 ? dd : '0' + dd;
        formattedDate = formattedDate.replace('dd', dd);
        return formattedDate;
    }

    resizeIframe() {
        var node = document.getElementById('mysettingsBody');
        if (node && node.clientHeight > 0) {
            var sizingInfo = {
                action: 'sizing',
                currentHeight: node.clientHeight,
                currentWidth: document.body.offsetWidth,
                minWidth: 1,
            };
            top.postMessage(
                JSON.stringify(sizingInfo),
                window.location.protocol + '//' + window.location.hostname,
            );
        }
    }

    getFingerprint() {
        //Disable all options. You really want to get each item one at a time.
        //Note: 24700f9f1986800ab4fcc880530dd0ed is the fingerprint of no fingerprint (all options excluded)
        var options = {
            excludeUserAgent: true,
            excludeLanguage: true,
            excludeColorDepth: true,
            excludeScreenResolution: true,
            detectScreenOrientation: true,
            excludeTimezoneOffset: true,
            excludeSessionStorage: true,
            excludeIndexedDB: true,
            excludeAddBehavior: true,
            excludeOpenDatabase: true,
            excludeCpuClass: true,
            excludePlatform: true,
            excludeDoNotTrack: true,
            excludeCanvas: true,
            excludeWebGL: true,
            excludeFlashFonts: true,
            excludeJsFonts: true,
            excludepluginsKey: true,
        };

        var i = 0;

        var CDI = function (options, structFingerprint, option) {
            //use to track how long it will take for the fingerprint function to run
            var dateStart = new Date();

            var fp = new Fingerprint2(options, structFingerprint);
            fp.get(function (fingerprintHash) {
                var dateEnd = new Date();

                //Fills out the struction with all the information we want to save in our database
                structFingerprint.testDate = dateStart;
                structFingerprint.hash = fingerprintHash;
                structFingerprint.option = option.replace('exclude', '');
                structFingerprint.executionTime = dateEnd - dateStart;
            });
        };

        for (var option in options) {
            //Clone the object options to modify it for each request by enabling one option at a time
            var structOptions = JSON.parse(JSON.stringify(options));
            structOptions[option] = false;
            var structFingerprint = new Object();

            if (option === 'excludepluginsKey') {
                CDI(structOptions, structFingerprint, option);
                this.refs.fingerprintPluginsKey.value = structFingerprint.hash;
            } else if (option === 'excludeJsFonts') {
                CDI(structOptions, structFingerprint, option);
                this.refs.fingerprintJsFonts.value = structFingerprint.hash;
            } else if (option === 'excludeLanguage') {
                CDI(structOptions, structFingerprint, option);
                this.refs.fingerprintLanguage.value = structFingerprint.hash;
            } else if (option === 'excludeTimezoneOffset') {
                CDI(structOptions, structFingerprint, option);
                this.refs.fingerprintTimezoneOffset.value =
                    structFingerprint.hash;
            } else if (option === 'excludeCpuClass') {
                CDI(structOptions, structFingerprint, option);
                this.refs.fingerprintCpuClass.value = structFingerprint.hash;
            }
        }
    }

    strHasUpperCasePolicy(str, minPolicy) {
        return this.strHasPolicy(str, new RegExp('[A-Z]', 'g'), minPolicy);
    }

    strHasLowerCasePolicy(str, minPolicy) {
        return this.strHasPolicy(str, new RegExp('[a-z]', 'g'), minPolicy);
    }

    strHasLetterPolicy(str, minPolicy) {
        return this.strHasPolicy(str, new RegExp('[a-zA-Z]', 'g'), minPolicy);
    }

    strHasNumberPolicy(str, minPolicy) {
        return this.strHasPolicy(str, new RegExp('[0-9]', 'g'), minPolicy);
    }

    isPrintable(string) {
        return !/[^ -~]/.test(string);
    }

    strHasSpecialCharPolicy(str, minPolicy) {
        if (minPolicy === 0) {
            return {
                hasValidSpecialChars: true,
                hasUnsupportedChars: !this.isPrintable(str),
            };
        }
        const pattern = '(.*[!-/|:-@|\\[-`|{-~]){' + minPolicy + '}.*';
        const hasValidSpecialChars = str.match(pattern);
        return {
            hasValidSpecialChars: !!hasValidSpecialChars,
            hasUnsupportedChars: !this.isPrintable(str),
        };
    }

    strHasPolicy(str, pattern, min) {
        if (min === 0) {
            return true;
        } else {
            var minMatch = str.match(pattern);
            if (minMatch) {
                return min > minMatch.length ? false : true;
            }
        }
        return false;
    }

    /*
     * handle forgot password link URL inside the iframe like My Settings pages.
     * This would force to logout and redirect to /app/authUpdate.
     */
    handleForgotPasswordLinkURL(context, forgotPasswordLinkId) {
        let forgotPasswordLink = document.getElementById(forgotPasswordLinkId);
        if (forgotPasswordLink) {
            forgotPasswordLink.addEventListener('click', function (event) {
                event.preventDefault();
                appRouterHistory().replace(Constants.PASSWORD_EXPIRED_URL);
                return false;
            });
        }
    }
}

export default new CommonUtils();
