/* eslint-disable react/prop-types */
import React from 'react';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export function GlobalStyles({ theme }) {
    return (
        <GlobalThemeStyles
            styles={{
                '*': {
                    margin: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                },
                html: {
                    width: '100%',
                    height: '100%',
                    WebkitOverflowScrolling: 'touch',
                    fontSize: 16,
                },
                body: {
                    width: '100%',
                    height: '100%',
                    backgroundColor: `${theme.palette.background.default} !important`,
                },
                '#root': {
                    width: '100%',
                    height: '100%',
                },
            }}
        />
    );
}
