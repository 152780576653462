/* eslint-disable strict,lines-around-directive,eqeqeq,no-underscore-dangle,react/destructuring-assignment,no-var,vars-on-top,padding-line-between-statements,prefer-const,no-shadow,no-plusplus,react/prop-types,react/sort-comp,no-else-return,prefer-template,react/jsx-boolean-value,no-unused-vars,no-unneeded-ternary,no-param-reassign,react/static-property-placement,react/boolean-prop-naming */
'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loader as MuiLoader } from 'dbk-common-ui';

import IFrame from '../iframe/IFrame';
import Loader from '../loader/Loader';
import PageLoader from '../loader/PageLoader';
import Constants from '../../constants/Constants';
import ErrorCode from '../../constants/ErrorCode';
import Alert from '../alert/Alert';
import HelperFunctions from '../../util/HelperFunctions';
import styles from './CustomComponent.scss';

/* global $LAB:false */

/**
 * This component is responsible for loading custom FI content. Different configurations
 * are required as props depending on the integration type.
 * More information can be found in the feature wiki:
 * http://mediawiki.dcb.diginsite.net/csi/index.php/Custom_Components_(SAML_SSO)
 */
export default class CustomComponent extends Component {
    constructor(props) {
        super(props);

        if (typeof $LAB == 'undefined') {
            console.error(
                'Warning: Custom.js requires $LABJS to be present in the browser in order to load ' +
                    " custom written content. Make sure it's included in the SPA parent container.",
            );
        }

        this._renderComponent = this._renderComponent.bind(this);
        this._renderUncontrolledIFrame = this._renderUncontrolledIFrame.bind(
            this,
        );
        this._renderControlledIFrame = this._renderControlledIFrame.bind(this);
        this._renderError = this._renderError.bind(this);
        this._clearIntervalTimer = this._clearIntervalTimer.bind(this);

        // Internal state specifically for tracking the loading of dependencies for the
        // "component" type only. The other types use iframes so they don't need this state.
        this.state = {
            status: Constants.LOADING,
        };
        // setInterval reference so we can clear it when needed.
        this.setIntervalRef = null;
    }

    componentDidMount() {
        // Dynamically load the JS and CSS dependencies for the "component" type.
        // This is done in didMount because we need to execute an asynchronous function
        // that will eventually set state to signal content is loaded, which shouldn't
        // be done in render() or the constructor();
        if (this.props.config.type === Constants.CUSTOM_TYPE_COMPONENT) {
            try {
                if (this.props.config.css != null) {
                    var cssIncludes = this.props.config.css;
                    if (Array.isArray(cssIncludes) && cssIncludes.length > 0) {
                        cssIncludes.forEach((href) => {
                            HelperFunctions.loadCSSResource(
                                this._appendResourceVersion(href),
                            );
                        });
                    }
                }

                if (this.props.config.js != null) {
                    var jsIncludes = this.props.config.js;
                    if (Array.isArray(jsIncludes) && jsIncludes.length > 0) {
                        // For each element in the jsInclude array, append a resource version.
                        let resources = [];
                        jsIncludes.forEach((include) => {
                            resources.push(
                                this._appendResourceVersion(include),
                            );
                        });

                        $LAB.setOptions({ AlwaysPreserveOrder: true })
                            .script(resources)
                            .wait(() => {
                                if (this.state.status === Constants.LOADING) {
                                    // Set state to success once all JS dependencies are loaded, but only if we're
                                    // in a loading state
                                    this.setState({
                                        status: Constants.SUCCESS,
                                    });
                                }
                            });

                        // Add a check interval timer to see if we need to display an error.
                        // This is needed in case resources 404 or are not returned properly.
                        let resourceLoadTimeout = this.props.resourceLoadTimeout
                            ? this.props.resourceLoadTimeout
                            : Constants.DEFAULT_RESOURCE_LOAD_TIMEOUT;

                        // Clear the current setInterval if it exists.
                        this._clearIntervalTimer();

                        let counter = 0;
                        // Each 1 second interval up to the max configuration, check whether we can
                        // access the component via global var componentName or the status is SUCCESS.
                        // If we can't do this by the end of the max interval, display an error.
                        this.setIntervalRef = setInterval(() => {
                            if (counter < resourceLoadTimeout) {
                                var Component = HelperFunctions.getObjectByDotNotation(
                                    window,
                                    this.props.config.componentName,
                                );
                                if (
                                    this.state.status == Constants.SUCCESS &&
                                    Component
                                ) {
                                    // Break the interval chain since we have a success.
                                    this._clearIntervalTimer();
                                } else {
                                    counter++;
                                }
                            } else {
                                this._clearIntervalTimer();
                                // Set the state to ERROR only if it's not SUCCESS.
                                if (this.state.status != Constants.SUCCESS) {
                                    this.setState({ status: Constants.ERROR });
                                }
                            }
                        }, 1000);
                    }
                }

                if (this.props.loaderConfig) {
                    this.props.loaderConfig.then((comp) => {
                        if (this.state.status === Constants.LOADING) {
                            // Set state to success once all JS dependencies are loaded, but only if we're
                            // in a loading state
                            this.setState({
                                status: Constants.SUCCESS,
                                dynamicComponent: comp.default,
                            });
                        }
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    componentWillUnmount() {
        // Clear the current setInterval if it exists.
        this._clearIntervalTimer();
    }

    render() {
        // Render the component based on the type prop passed in.
        if (this.props.config.type === Constants.CUSTOM_TYPE_COMPONENT) {
            return this._renderComponent();
        } else if (
            this.props.config.type === Constants.CUSTOM_TYPE_CONTROLLED_IFRAME
        ) {
            return this._renderControlledIFrame();
        } else if (
            this.props.config.type === Constants.CUSTOM_TYPE_UNCONTROLLED_IFRAME
        ) {
            return this._renderUncontrolledIFrame();
        } else {
            console.error(
                'Warning: type prop contained an unknown type - ' +
                    this.props.config.type,
            );
            return this._renderError(
                ErrorCode.INVALID_COMPONENT_TYPE_CONFIGURATION,
            );
        }
    }

    _clearIntervalTimer() {
        if (this.setIntervalRef) {
            clearInterval(this.setIntervalRef);
            this.intervalRef = null;
        }
    }

    _renderError(errorCode) {
        let pageLevelContainer = this.props.isPageLevel ? 'container' : '';
        let className = 'di-container';
        className += this.props.isPageLevel ? ' first' : '';

        return (
            <div className={pageLevelContainer}>
                <div className={className}>
                    <Alert type="danger" showIcon={true}>
                        <div>
                            We are currently experiencing technical
                            difficulties. Please try again later.
                        </div>
                        <div className={styles['error-code']}>
                            (Error Code: {errorCode})
                        </div>
                    </Alert>
                </div>
            </div>
        );
    }

    /**
     * Render a React component who's JS and CSS dependencies are dynamically loaded.
     */
    _renderComponent() {
        // Display loader prior to loading all the resource dependencies.
        if (this.state.status === Constants.LOADING) {
            if (this.props.isPageLevel) {
                return this.props.modernBrandingEnabled ? (
                    <MuiLoader sx={{ height: 230 }} />
                ) : (
                    <PageLoader />
                );
            } else {
                return (
                    <div className="di-container">
                        <Loader />
                    </div>
                );
            }
        } else if (this.state.status == Constants.SUCCESS) {
            try {
                // Retrieve the component by the configured component name. The component
                // definition will be available on the window object.
                let Component;
                if (this.state.dynamicComponent) {
                    Component = this.state.dynamicComponent;
                } else {
                    Component = HelperFunctions.getObjectByDotNotation(
                        window,
                        this.props.config.componentName,
                    );
                }

                if (Component != null) {
                    return <Component {...this.props} />;
                } else {
                    // The resources were downloaded but the component is invalid. Log a message in console.
                    let includes = '';
                    this.props.config.js.forEach((include, i) => {
                        includes += '\n' + include;
                    });

                    console.error(
                        this.props.config.componentName +
                            ' is not a valid React component.' +
                            ' Check the following resources:' +
                            includes,
                    );
                }
            } catch (e) {
                console.error(e);
            }
            return this._renderError(ErrorCode.FAILED_TO_RENDER_COMPONENT);
        } else {
            // Status is set to ERROR only if the component dependency resources fail to download.
            // The timeout time for this is currently defaulted to 10s.
            return this._renderError(ErrorCode.FAILED_TO_DOWNLOAD_RESOURCES);
        }
    }

    /**
     * Render an uncontrolled iframe which is a standard iframe with a specified url.
     */
    _renderUncontrolledIFrame() {
        // Enable scrolling on the IFrame by default unless set explicitly to false.
        let enableIFrameScrolling =
            this.props.enableIFrameScrolling == false ? false : true;
        let iframeClassName = 'di-container ' + styles['custom-component'];

        return (
            <IFrame
                className={iframeClassName}
                src={this.props.config.url}
                title={this.props.title}
                id={
                    this.props.iframeId ||
                    (this.props.id ? this.props.id + 'IFrame' : null)
                }
                scrolling={enableIFrameScrolling}
                height={this.props.iframeHeight}
                allowDomains={this.props.allowDomains}
                resizerOptions={this.props.resizerOptions}
                onLoad={this.props.onIframeLoad}
            />
        );
    }

    /**
     * Render a controlled iframe which injects css and js content into a same domain iframe.
     */
    _renderControlledIFrame() {
        try {
            var cssIncludes = this.props.config.css;
            var jsIncludes = this.props.config.js;
            // Requires LABjs to dynamically load JS resources.
            if (this.props.pageConfigs.LABjsUrl == null) {
                console.error(
                    'Error: LABjsUrl prop is required for controlled-iframe components.',
                );
            }
            // Requires the iframe client sizer to resize content.
            if (this.props.pageConfigs.iframeSizerUrl == null) {
                console.error(
                    'Error: iframeSizerUrl prop is required for controlled-iframe components.',
                );
            }
            // Enable scrolling on the IFrame by default unless set explicitly to false.
            let enableIFrameScrolling =
                this.props.enableIFrameScrolling == false ? false : true;
            let iframeClassName = 'di-container ' + styles['custom-component'];

            return (
                <IFrame
                    className={iframeClassName}
                    height={this.props.iframeHeight}
                    title={this.props.title}
                    id={
                        this.props.iframeId ||
                        (this.props.id ? this.props.id + 'IFrame' : null)
                    }
                    jsIncludes={jsIncludes}
                    cssIncludes={cssIncludes}
                    resizer={this.props.pageConfigs.iframeSizerUrl}
                    labJS={this.props.pageConfigs.LABjsUrl}
                    scrolling={enableIFrameScrolling}
                    allowDomains={this.props.allowDomains}
                    resizerOptions={this.props.resizerOptions}
                    onLoad={this.props.onIframeLoad}
                >
                    <div id="contentContainer">
                        <div id="iframeContent" />
                    </div>
                </IFrame>
            );
        } catch (e) {
            console.error('Error: ' + e);
            return null;
        }
    }

    /**
     * Helper function to append the resourceVersion specified in props to the static resource
     * request.
     */
    _appendResourceVersion(resourceUrl) {
        if (
            this.props.resourceVersion != null &&
            this.props.resourceVersion.length > 0 &&
            resourceUrl != null &&
            resourceUrl.length > 0
        ) {
            // Resource url already contains a query param, so append the resource version
            // as an additional parameter
            if (resourceUrl.indexOf('?') > -1) {
                resourceUrl += '&v=' + this.props.resourceVersion;
            } else {
                resourceUrl += '?v=' + this.props.resourceVersion;
            }
        }
        return resourceUrl;
    }
}

CustomComponent.propTypes = {
    /**
     * The configuration object for the custom component.
     */
    config: PropTypes.shape({
        /**
         * Which of the 3 configurations for the custom component you will be using.
         */
        type: PropTypes.oneOf([
            Constants.CUSTOM_TYPE_COMPONENT,
            Constants.CUSTOM_TYPE_CONTROLLED_IFRAME,
            Constants.CUSTOM_TYPE_UNCONTROLLED_IFRAME,
        ]).isRequired,
        /**
         * Array of filepath strings to js files that the developer wants to include.
         */
        js: PropTypes.arrayOf(PropTypes.string),
        /**
         * Array of filepath strings to css files that the developer wants to include.
         */
        css: PropTypes.arrayOf(PropTypes.string),
        /**
         * Url to content the developer wants to use for the uncontrolled-iframe configuration.
         */
        url: PropTypes.string,
        /**
         * The name of the component as defined by JSON dot notation.
         */
        componentName: PropTypes.string,
    }),
    /**
     * The page configuration object that loads scripting and styling scripts for the component.
     */
    pageConfigs: PropTypes.shape({
        /**
         * Url string to the iFrame Resizer.
         */
        iframeSizerUrl: PropTypes.string.isRequired,
        /**
         * Url string to the iFrame Resizer.
         */
        LABjsUrl: PropTypes.string.isRequired,
    }),
    /**
     * The allowable domains for the file to access.
     */
    allowDomains: PropTypes.arrayOf(PropTypes.string),
    /**
     * The current language.
     */
    language: PropTypes.string,
    /**
     * If true, makes the component use the whole page.
     */
    isPageLevel: PropTypes.bool,
    /**
     * Add a check interval timer to display error if request gets an unusable response.
     */
    resourceLoadTimeout: PropTypes.number,
    /**
     * Boolean to make the iFrame scrollable.
     */
    enableIFrameScrolling: PropTypes.bool,
    /**
     * Resource version to append to the static resource request.
     */
    resourceVersion: PropTypes.string,
    /**
     * Height parameter for the iFrame.
     */
    iframeHeight: PropTypes.number,
    /**
     * Add an id to the iFrame.
     */
    id: PropTypes.string,
    /**
     * Used for determining if the id should be null or show the 'id'.
     */
    iframeId: PropTypes.string,
    /**
     * Options to add to the iFrame resizer.
     */
    resizerOptions: PropTypes.object,
    /**
     * Function to be executed when iFrame loads.
     */
    onIframeLoad: PropTypes.func,
    /**
     * String to set title attribute of the iFrame.
     */
    title: PropTypes.string,
};
