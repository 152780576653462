/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Print extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M17.9,14.2c0,0.2-0.2,0.3-0.3,0.3h-2.4v1.7c0,0.6-0.5,1-1,1H3.8c-0.6,0-1-0.5-1-1v-1.7H0.3c-0.2,0-0.3-0.2-0.3-0.3V9.8
                        c0-1.1,0.9-2.1,2.1-2.1h0.7V1.8c0-0.6,0.5-1,1-1H11c0.6,0,1.4,0.3,1.8,0.7l1.6,1.6c0.4,0.4,0.7,1.2,0.7,1.8v2.8h0.7
                        c1.1,0,2.1,0.9,2.1,2.1V14.2z M13.8,9.1V4.9H12c-0.6,0-1-0.5-1-1V2.2H4.1v6.9H13.8z M13.8,16v-2.8H4.1V16H13.8z M15.8,9.1
                        c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7S16.2,9.1,15.8,9.1z"
                    />
                </g>
            </svg>
        );
    }
}
