/* eslint-disable react/boolean-prop-naming */
/* eslint-disable import/order,no-underscore-dangle,react/destructuring-assignment,react/no-string-refs,prefer-template,react/no-find-dom-node,prefer-const,padding-line-between-statements,eqeqeq,no-useless-return,react/no-access-state-in-setstate,prefer-destructuring,class-methods-use-this,react/static-property-placement,no-useless-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import fdicStyles from '../../styles/FdicCompliance.scss';

export default class FdicComponent extends Component {
    constructor(props) {
        super(props);

        this._displayLogo = this._displayLogo.bind(this);
        this._displayTextValue = this._displayTextValue.bind(this);
    }

    render() {
        const config = this.props.config;
        const isInsured = this.props.isInsured;
        const showFDIC = this.props.showFDIC;

        if (showFDIC) {
            return (
                <div className={fdicStyles['main-area']}>
                    <div className={fdicStyles['logo-area']}>
                        {this._displayLogo(isInsured, config)}
                    </div>
                    <div className={fdicStyles['text-area']}>
                        {this._displayTextValue(isInsured, config)}
                    </div>
                </div>
            );
        }

        return <React.Fragment />;
    }

    _displayLogo(isInsured, config) {
        if (isInsured) {
            return (
                <img
                    className={fdicStyles['fdic-logo-img']}
                    src={config.complianceLogoPath}
                    alt="Complaince Logo"
                />
            );
        }

        return <React.Fragment />;
    }

    _displayTextValue(isInsured, config) {
        if (isInsured) {
            return <div>{config.complianceInsuredStatement}</div>;
        }

        return <div>{config.complianceNonInsuredStatement}</div>;
    }
}

FdicComponent.propTypes = {
    config: PropTypes.array,
    isInsured: PropTypes.bool,
    showFDIC: PropTypes.bool,
};
