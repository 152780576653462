import React from 'react';
import StepUpAuthentication from 'mfa/src/StepUpAuthentication';
import PropTypes from 'prop-types';

import 'common-ui/src/css/bootstrap/3.3.7/css/bootstrap.css';
import 'common-ui/src/css/di-bootstrap-override.css';
import 'common-ui/src/css/diFonts/style.css';
import 'common-ui/src/css/di-base.css';
import 'common-ui/src/css/di-animation.css';
import 'common-ui/src/css/di-svg-icon.css';

const StepUp = (props) => (
    <StepUpAuthentication
        successCallback={props.successCallback}
        cancelCallback={props.cancelCallback}
        feature={props.feature}
        repeatableChallenge={props.repeatableChallenge || props.limitChallenge}
        externalUse={props.externalUse}
    />
);

StepUp.propTypes = {
    successCallback: PropTypes.func,
    cancelCallback: PropTypes.func,
    feature: PropTypes.string,
    /** @deprecated */ limitChallenge: PropTypes.bool,
    repeatableChallenge: PropTypes.bool,
    externalUse: PropTypes.bool,
};

export default StepUp;
