export const zIndex = {
    zIndex: {
        mobileStepper: 10000,
        fab: 10050,
        speedDial: 10050,
        appBar: 10100,
        drawer: 10200,
        modal: 10300,
        snackbar: 10400,
        tooltip: 10500,
    },
};
