/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Security extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M3.9,4.7V8H3.1v10h11.8V8h-0.8V4.6C14.3,2.1,11.3,0,9,0S3.7,2.3,3.9,4.7z M5.4,5.1L5.4,5.1c0.2-1.4,0.8-3.6,3.6-3.6
                s3.5,2.3,3.6,3.2l0,0v3.4H5.4V5.1z M11,16.5H7l1.2-3.2C7.5,13,7,12.3,7,11.5c0-1.1,0.9-2,2-2s2,0.9,2,2c0,0.8-0.5,1.5-1.2,1.8
                L11,16.5z"
                />
            </svg>
        );
    }
}
