/* eslint-disable spaced-comment,import/order,import/no-named-as-default,prefer-const,no-useless-escape,react/sort-comp,react/destructuring-assignment,react/no-unused-state,react/prop-types,padding-line-between-statements,no-underscore-dangle,react/no-find-dom-node,no-empty,eqeqeq,no-restricted-syntax,prefer-template,jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,no-script-url,prefer-destructuring,react/no-array-index-key,react/jsx-no-bind,no-plusplus,object-shorthand,no-unused-vars,react/no-access-state-in-setstate,no-param-reassign,class-methods-use-this,one-var,no-var,vars-on-top,no-else-return,react/static-property-placement,react/boolean-prop-naming */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MenuItem } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import styles from '../../styles/InternationalPhoneNumberInputField.scss';
import SelectionDropdown from '../bootstrap/SelectionDropdown';
import SVG from '../images/SVG';
//can switch this to chinese or spanish by pulling in those JSON files
import CountryCodeData from './mock/countrycodes.json';

let DEFAULT_STRINGS = {
    'phonewidget.common.js.fieldError': 'Please enter a valid phone number.',
    'phonewidget.common.js.unrecognizedCountryCode':
        'Please select a valid country code.',
    'phonewidget.common.js.invalidUsNumber':
        'Please enter a valid phone number.',
    'phonewidget.common.js.tooShort': 'Phone number entered is too short.',
    'phonewidget.common.js.tooLong': 'Phone number entered is too long.',
};

// Regular expressions used to control what input characters are valid.
let REGEX = {
    CHARACTER_BLACKLIST: /[^0-9()\-+\s]+/g,
    DOUBLE_WHITESPACE: /\s{2,}/g,
    DOUBLE_LEFT_PARENTHESIS: /\({2,}/g,
    DOUBLE_RIGHT_PARENTHESIS: /\){2,}/g,
    DOUBLE_HYPHEN: /\-{2,}/g,
    PLUS_AT_INDEX_ZERO: /(?!^)\+/g,
    NUMBER_AFTER_LEFT_PARENTHESIS: /\([^\d]+/g,
};

export default class InternationalPhoneNumberInputField extends Component {
    constructor(props) {
        super(props);

        let initialSelectedItem = null;
        let isNonDefaultSelectedCountryName =
            props.selectedCountryName !==
            InternationalPhoneNumberInputField.defaultProps.selectedCountryName;
        let isNonDefaultSelectedCountryPhoneCode =
            props.selectedPhoneCode !==
            InternationalPhoneNumberInputField.defaultProps.selectedPhoneCode;

        if (isNonDefaultSelectedCountryName) {
            initialSelectedItem = props.countryCodeData.find((country) => {
                return country.name === props.selectedCountryName;
            });
        } else if (isNonDefaultSelectedCountryPhoneCode) {
            initialSelectedItem = props.countryCodeData.find((country) => {
                return country.phoneCode === props.selectedPhoneCode;
            });
        }

        this.state = {
            selectedItem: initialSelectedItem,
            selectedPhoneCode: this.props.isInternationalPhoneEnabled
                ? this.props.selectedPhoneCode
                : '1',
            selectedCountryName: this.props.isInternationalPhoneEnabled
                ? this.props.selectedCountryName
                : 'United States',
            rawvalue: null,
            error: false,
            errorMessage: null,
            cursorPosition: (this.props.value || '').length,
            focused: this.props.defaultFocus || this.props.focusInputOnInit,
            typing: false,
            firstAttempt: true,
            selectedIndex: 0,
        };

        let value = '';
        if (this.props.phoneNumber && this.props.phoneNumber.length > 0) {
            value = this._formatPhoneNumber(
                this.state.selectedPhoneCode,
                this.props.phoneNumber,
            );
        }
        this.state.value = value;

        this._renderErrorMessage = this._renderErrorMessage.bind(this);
        this._renderDeleteButton = this._renderDeleteButton.bind(this);
        this._renderInternationalCountryDropdown = this._renderInternationalCountryDropdown.bind(
            this,
        );

        this._onInput = this._onInput.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onDeleteClick = this._onDeleteClick.bind(this);
        this._checkForPhoneError = this._checkForPhoneError.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onDeleteKeyDown = this._onDeleteKeyDown.bind(this);
        this._setWidthOfDropDownMenu = this._setWidthOfDropDownMenu.bind(this);
        this._countryDropdownClick = this._countryDropdownClick.bind(this);
    }

    static defaultProps = {
        phoneMinLength: 9,
        phoneMaxLength: 16,
        selectedPhoneCode: '1',
        selectedCountryName: 'United States',
        phoneNumber: '',
        isInternationalPhoneEnabled: true,
        inputFieldId: 'phonenumber',
        defaultFocus: false,
        focusInputOnInit: false,
        i18nStrings: DEFAULT_STRINGS,
        countryCodeData: CountryCodeData,
        enableDelete: false,
        enableClearOption: false,
        disableResizeEventListener: false,
        suppressFocusBorder: false,
    };

    componentDidMount() {
        if (this.props.isInternationalPhoneEnabled) {
            this._setWidthOfDropDownMenu();
            if (!this.props.disableResizeEventListener) {
                window.addEventListener('resize', this._setWidthOfDropDownMenu);
            }
        }

        // Focus on the country code dropdown
        if (this.props.defaultFocus) {
            setTimeout(() => {
                if (ReactDOM.findDOMNode(this.dropdownButton)) {
                    ReactDOM.findDOMNode(this.dropdownButton).focus();
                }
            });
        }

        // Focus in the input field when mounted.
        if (!this.props.defaultFocus && this.props.focusInputOnInit) {
            try {
                setTimeout(() => {
                    this.intlPhNoInput.focus();
                });
            } catch (e) {}
        }

        // Validate the pre-populated value if specified.
        if (this.props.validateOnInit) {
            setTimeout(() => {
                this._processInput(this.props.phoneNumber);
            });
        }
    }

    componentWillUnmount() {
        if (!this.props.disableResizeEventListener) {
            window.removeEventListener('resize', this._setWidthOfDropDownMenu);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Set the cursor position in the input field based on what was captured in state
        // during the onChange event.
        if (
            this.state.focused &&
            this.state.value &&
            this.state.value.length > 0 &&
            this.state.typing
        ) {
            let ref = this.intlPhNoInput;
            if (ref && ref.setSelectionRange) {
                ref.setSelectionRange(
                    this.state.cursorPosition,
                    this.state.cursorPosition,
                );
            }
        }

        if (
            prevState.rawvalue === this.state.rawvalue &&
            prevState.selectedPhoneCode === this.state.selectedPhoneCode &&
            prevState.error === this.state.error
        ) {
            return;
        }

        // Capture country code changes and execute the custom onChange prop.
        if (prevState.selectedPhoneCode !== this.state.selectedPhoneCode) {
            if (this.props.onIntlPhNoChange) {
                this.props.onIntlPhNoChange(
                    this.state.selectedPhoneCode,
                    this._stripSpecialCharacters(this.state.value),
                    this.state.error,
                );
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.phoneNumber != newProps.phoneNumber) {
            this.state.value = this._parsePhoneNumber(
                newProps.phoneNumber,
            ).phoneNumber;
        }
    }

    render() {
        let value = '';
        if (this.state.value == null) {
            this.state.value = this._parsePhoneNumber(
                this.props.phoneNumber,
            ).phoneNumber;
        }
        value = this.state.value;

        //reset selected phone code
        if (this.state.selectedPhoneCode == '') {
            this.state.selectedPhoneCode = this.props.selectedPhoneCode;
        }

        //reset the selected menu item in dropdown by matching for phone code
        if (this.state.selectedItem == null) {
            for (let item of this.props.countryCodeData) {
                if (this.state.selectedPhoneCode === item.phoneCode) {
                    this.state.selectedItem = {
                        name: item.name,
                        phoneCode: item.phoneCode,
                        iso2CountryCode: item.iso2CountryCode,
                    };
                    break;
                }
            }
        }

        let className = 'intl-phone-number';
        if (this.state.error || this.props.hasError) {
            className += ' has-error';
        }
        // Add the custom CSS class prop to the top level div if provided.
        if (this.props.className) {
            className += ' ' + this.props.className;
        }

        return (
            <div className={className} id={this.props.intlCompId}>
                <div className="input-parent-container">
                    <div
                        className={
                            styles['intnl-ph-no-fld-form-group'] +
                            ' row form-group form-control'
                        }
                        ref={(formGroup) => {
                            this.fldFormGroup = formGroup;
                        }}
                    >
                        {this._renderInternationalCountryDropdown()}
                        <div className="input-container">
                            <input
                                type="tel"
                                aria-label={
                                    this.props.ariaLabelForInput
                                        ? this.props.ariaLabelForInput +
                                          ' ' +
                                          value
                                        : null
                                }
                                className="di-input form-control"
                                value={value}
                                onInput={this._onInput}
                                placeholder="(xxx) xxx-xxxx"
                                onBlur={this._onBlur}
                                onFocus={this._onFocus}
                                onKeyDown={this.props.onKeyDown}
                                ref={(input) => {
                                    this.intlPhNoInput = input;
                                }}
                                maxLength={this.props.phoneMaxLength}
                                id={this.props.inputFieldId}
                                style={
                                    this.props.suppressFocusBorder
                                        ? { border: 'none', height: 'inherit' }
                                        : {}
                                }
                            />
                            {this.props.enableClearOption &&
                                value &&
                                this._renderClearIcon()}
                        </div>
                    </div>
                    {!this.props.handlePhoneValidation && (
                        <div
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            {this._renderErrorMessage()}
                        </div>
                    )}
                </div>
                {this._renderDeleteButton()}
            </div>
        );
    }

    _renderInternationalCountryDropdown() {
        if (this.props.isInternationalPhoneEnabled) {
            let displayLabel = '+1';
            let selectionDropdownId = '';
            if (this.state && this.state.selectedItem) {
                displayLabel = '+ ' + this.state.selectedItem.phoneCode;
            } else if (this.state.selectedPhoneCode) {
                displayLabel = '+ ' + this.state.selectedPhoneCode;
            }

            if (this.props.intlCompId) {
                selectionDropdownId =
                    this.props.intlCompId + '-phoneCodeDropdown';
            }

            let dropdownStyle = {
                width: this.state.dropdownWidth,
                marginLeft: this.state.dropdownMarginLeft,
            };

            return (
                <SelectionDropdown
                    id={selectionDropdownId}
                    onToggle={this._countryDropdownClick}
                >
                    <SelectionDropdown.Toggle
                        bsSize="sm"
                        ref={(toggle) => {
                            this.dropdownButton = toggle;
                        }}
                    >
                        {displayLabel}
                    </SelectionDropdown.Toggle>
                    <SelectionDropdown.Menu
                        style={dropdownStyle}
                        ref={(menu) => {
                            this.dropdownMenu = menu;
                        }}
                        selectedIndex={this.state.selectedIndex}
                        preventDoubleJump={false}
                    >
                        {this._renderListItems()}
                    </SelectionDropdown.Menu>
                </SelectionDropdown>
            );
        }
        return null;
    }

    _renderErrorMessage() {
        if (this.state.error || this.props.hasError) {
            return (
                <p className={'help-block ' + styles['error-container']}>
                    <SVG name="AlertError" />
                    <span className="show-inline">
                        &nbsp;
                        {this.state.errorMessage || this.props.errorMessage}
                    </span>
                </p>
            );
        }
        return null;
    }

    _renderDeleteButton() {
        // Show the delete button if enabled via props and the current text value length is > 0.
        let showDeleteButton =
            this.props.enableDelete &&
            (this.state.value.length > 0 || this.props.showDeleteIfEmpty);
        let deleteSvgStyle = {
            top: '9px',
            left: '13px',
        };
        if (showDeleteButton) {
            return (
                <div
                    id={this.props.inputFieldId + 'Delete'}
                    className="delete-button-container"
                    onClick={this._onDeleteClick}
                    onKeyDown={this._onDeleteKeyDown}
                >
                    <a
                        className="delete-button-link"
                        href="javascript:void(0)"
                        aria-label="Delete"
                        role="button"
                    >
                        <SVG style={deleteSvgStyle} name="Delete" />
                    </a>
                </div>
            );
        }
        return null;
    }

    _renderClearIcon() {
        const clearIconStyle = {
            position: 'absolute',
            right: '15px',
            top: 0,
            bottom: 0,
            lineHeight: '30px',
            fontSize: '12px',
        };

        return (
            <div
                tabIndex="0"
                onClick={(event) => this._onDeleteClick(event, true)}
                aria-label="close"
                onKeyDown={(event) => this._onDeleteKeyDown(event, true)}
                role="button"
                style={clearIconStyle}
            >
                <SVG name="Close" desc="Clear" />
            </div>
        );
    }

    _renderListItems() {
        let listItems = [];
        let selectedItem;
        let countryCodeActive = false;
        if (this.state.selectedItem) {
            selectedItem = this.state.selectedItem;
        } else {
            selectedItem = this.props.countryCodeData[0];
        }
        this.props.countryCodeData.forEach((item, i) => {
            let className = '';
            let countryIso2CountryCode = item.iso2CountryCode;
            let isActive = false;
            if (
                selectedItem.name === item.name &&
                selectedItem.iso2CountryCode === countryIso2CountryCode &&
                !countryCodeActive
            ) {
                isActive = true;
                className = 'active';
                countryCodeActive = true;
                this.state.selectedIndex = i;
            }

            listItems.push(
                <MenuItem
                    active={isActive}
                    key={i}
                    eventKey={i}
                    onSelect={this._listItemClick.bind(this, item, i)}
                    className={
                        className +
                        ' ' +
                        styles['intnl-ph-no-fld-dropdown-menu-li']
                    }
                >
                    <div className={styles['intnl-ph-no-list-item-div']}>
                        <div
                            className={
                                styles['intnl-ph-no-new-flag'] +
                                ' ' +
                                styles[
                                    'intnl-ph-no-new-flag-' +
                                        countryIso2CountryCode
                                ]
                            }
                        />
                        <div className={styles['intnl-ph-no-name']}>
                            {item.name}
                        </div>
                        <div
                            className={styles['intnl-ph-no-code']}
                            id={'countryCode_' + item.phoneCode + i}
                        >
                            +{item.phoneCode}
                        </div>
                    </div>
                </MenuItem>,
            );
        });
        return listItems;
    }

    _listItemClick(item, index, e) {
        this.setState({
            selectedItem: item,
            selectedPhoneCode: item.phoneCode,
            selectedCountryName: item.name,
            selectedIndex: index,
        });

        setTimeout(() => {
            //format phone number and validate phone after changing country code
            this._processInput();
            // Execute custom onCountryCodeChange handler after a country code selection.
            if (this.props.onCountryCodeChange) {
                this.props.onCountryCodeChange(e, item.phoneCode, item.name);
            }
        });
    }

    _setWidthOfDropDownMenu() {
        let windowWidth = document.body.clientWidth;
        let field = this.fldFormGroup;
        if (field) {
            if (windowWidth <= 767) {
                let dropdownMenuLeft =
                    (field.getBoundingClientRect().left + window.scrollX) * -1;
                let marginLeft = dropdownMenuLeft / 2 - 3.5;
                this.setState({
                    dropdownWidth: (windowWidth - 40).toString() + 'px',
                    dropdownMarginLeft: marginLeft.toString() + 'px',
                });
            } else {
                let fieldOffsetWidth = field.offsetWidth;
                this.setState({
                    dropdownWidth: fieldOffsetWidth.toString() + 'px',
                    dropdownMarginLeft: '-3.5px',
                });
            }
        }
    }

    _countryDropdownClick() {
        this._setWidthOfDropDownMenu();
        this._processInput();
    }

    _onInput(e) {
        e.preventDefault();
        let input = e.target.value; // raw input value
        let cursorPosition = e.target.selectionEnd; // raw input cursor position

        // Filter out all character input not in the regex. Only 0-9,(,),-,+,\s are allowed.
        let filteredValue = input.replace(REGEX.CHARACTER_BLACKLIST, '');

        if (filteredValue.trim().length > 0) {
            // Remove the '+' if it's not the first character.
            filteredValue = filteredValue.replace(REGEX.PLUS_AT_INDEX_ZERO, '');

            // Remove any non-numeric character immediately after the left parenthesis.
            filteredValue = filteredValue.replace(
                REGEX.NUMBER_AFTER_LEFT_PARENTHESIS,
                '(',
            );

            // Remove any double characters: (, ), -, \s
            filteredValue = filteredValue.replace(REGEX.DOUBLE_WHITESPACE, ' ');
            filteredValue = filteredValue.replace(
                REGEX.DOUBLE_LEFT_PARENTHESIS,
                '(',
            );
            filteredValue = filteredValue.replace(
                REGEX.DOUBLE_RIGHT_PARENTHESIS,
                ')',
            );
            filteredValue = filteredValue.replace(REGEX.DOUBLE_HYPHEN, '-');
        }

        // NOTE: This should not be an else statement to the block above because the filter process from
        // above may still create a 0 length string which needs to be set in the target value like below.
        // Replace the input target value with the empty string explicitly, otherwise React will
        // just display the last inputted value which may be incorrect.
        if (filteredValue.trim().length == 0) {
            filteredValue = '';
            e.target.value = filteredValue;
        }

        // If the filtered input is different than the raw input and the cursor position is not at the end,
        // we need to move the position back 1 because the current cursor position is based on the raw input value
        // which is incorrect. The filtered input is the one that will be shown and we want to keep the cursor
        // at its original position.
        if (
            input.length != filteredValue.length &&
            cursorPosition <= filteredValue.length
        ) {
            cursorPosition--;
        }

        if (!this.state.firstAttempt) {
            this._checkPhoneValidity(
                this.state.selectedPhoneCode,
                this._stripSpecialCharacters(input),
            );
        }
        this.setState({
            value: filteredValue,
            cursorPosition: cursorPosition,
            typing: true,
        });
        // Capture input field changes and execute the custom onChange prop.
        if (this.props.onIntlPhNoChange) {
            let filteredPhoneNumber = this._stripSpecialCharacters(
                filteredValue,
            );
            if (input[0] === '+') {
                // If countryCode was entered manually
                if (
                    this.state.selectedPhoneCode ===
                    filteredPhoneNumber.substring(
                        0,
                        this.state.selectedPhoneCode.length,
                    )
                ) {
                    // If the selected country code is the same as the first numbers in the number string
                    filteredPhoneNumber = filteredPhoneNumber.substring(
                        this.state.selectedPhoneCode.length,
                    );
                }
            }
            this.props.onIntlPhNoChange(
                this.state.selectedPhoneCode,
                this._stripSpecialCharacters(filteredPhoneNumber),
                this._checkForPhoneError(
                    this.state.selectedPhoneCode,
                    filteredPhoneNumber,
                ).error,
            );
        }
    }

    _onFocus(e) {
        this.setState({ focused: true });
        //we strip special characters on focus to allow users to edit their number when the number is formatted.
        if (this.state.selectedPhoneCode === '1') {
            let strippedNumber = this._stripSpecialCharacters(this.state.value);
            this.setState({
                value: strippedNumber,
            });
        }
        let ref = this.intlPhNoInput;
        if (ref && ref.setSelectionRange) {
            ref.setSelectionRange(0, 9999);
        }
    }

    _onBlur(e) {
        let focusedElement;
        let dropdownButton = ReactDOM.findDOMNode(this.dropdownButton);
        let phoneNumberInputField = this.intlPhNoInput;
        let dropdownList = ReactDOM.findDOMNode(this.dropdownMenu);
        setTimeout(() => {
            focusedElement = document.activeElement;
            if (
                focusedElement !== dropdownButton &&
                focusedElement !== phoneNumberInputField &&
                (focusedElement.parentNode === null ||
                    focusedElement.parentNode.parentNode !== dropdownList)
            ) {
                this.setState({
                    focused: false,
                    typing: false,
                    firstAttempt: false,
                });
                let value = this._processInput();
                if (value.length === 0) {
                    phoneNumberInputField.value = '';
                    if (this.props.enableClearOption) {
                        this._onDeleteClick(e, true);
                    }
                }
                if (this.props.onBlur) {
                    this.props.onBlur(e);
                }
            }
        });
    }

    _processInput(input, e) {
        let value = '';
        if (typeof input !== 'string' || input == null) {
            input = this.state.value;
        }
        let parsedNumber = this._parsePhoneNumber(input);
        let selectedPhoneCode = parsedNumber.countryCode
            ? parsedNumber.countryCode
            : this.state.selectedPhoneCode;
        if (
            this._checkPhoneValidity(
                selectedPhoneCode,
                parsedNumber.phoneNumber,
            )
        ) {
            value = input;
            if (this.intlPhNoInput && this.intlPhNoInput.value) {
                this.setState({
                    rawvalue: this._stripSpecialCharacters(
                        this.intlPhNoInput.value,
                    ),
                });
            }
        } else {
            let formattedInput = this._formatPhoneNumber(
                this.state.selectedPhoneCode,
                parsedNumber.phoneNumber,
            );
            value = formattedInput;
            this.setState({
                value: formattedInput,
                rawvalue: this._stripSpecialCharacters(formattedInput),
            });
        }
        return value;
    }

    _stripSpecialCharacters(num) {
        num = num.replace(/[^0-9]/g, '');
        return num;
    }

    _formatPhoneNumber(countryCode, phoneNumber) {
        let me = this;
        if (phoneNumber === null) {
            return '';
        }
        phoneNumber = this._stripSpecialCharacters(phoneNumber);
        if (
            (countryCode === '1' || me.hideDropdown) &&
            phoneNumber.length == 10
        ) {
            return (
                '(' +
                phoneNumber.substring(0, 3) +
                ') ' +
                phoneNumber.substring(3, 6) +
                '-' +
                phoneNumber.substring(6, 10)
            );
        }
        return phoneNumber;
    }

    _checkForPhoneError(countryCode, phoneNumber) {
        let errorMessage,
            error = false;
        if (phoneNumber.trim() === '') {
            if (this.props.phoneRequired) {
                errorMessage = this._i18nStrings(
                    'phonewidget.common.js.fieldError',
                );
                error = true;
            }
        } else {
            if (phoneNumber.indexOf('+') === 0) {
                if (this.props.isInternationalPhoneEnabled) {
                    errorMessage = this._i18nStrings(
                        'phonewidget.common.js.unrecognizedCountryCode',
                    );
                } else {
                    errorMessage = this._i18nStrings(
                        'phonewidget.common.js.invalidUsNumber',
                    );
                }
                error = true;
            } else if (countryCode === '1') {
                //Validation for US numbers
                phoneNumber = this._stripSpecialCharacters(phoneNumber);
                if (phoneNumber.length < 10) {
                    errorMessage = this._i18nStrings(
                        'phonewidget.common.js.tooShort',
                    );
                    error = true;
                } else if (phoneNumber.length > 10) {
                    errorMessage = this._i18nStrings(
                        'phonewidget.common.js.tooLong',
                    );
                    error = true;
                }
            } else if (countryCode !== '1') {
                //Validation for non-US numbers
                phoneNumber = this._stripSpecialCharacters(phoneNumber);
                if (
                    countryCode.length + phoneNumber.length <
                    this.props.phoneMinLength
                ) {
                    errorMessage = this._i18nStrings(
                        'phonewidget.common.js.tooShort',
                    );
                    error = true;
                } else if (
                    countryCode.length + phoneNumber.length >
                    this.props.phoneMaxLength
                ) {
                    errorMessage = this._i18nStrings(
                        'phonewidget.common.js.tooLong',
                    );
                    error = true;
                }
            }

            // After passing all default validations, go through any custom validators that
            // have been passed in.
            if (
                !error &&
                this.props.customValidators != null &&
                this.props.customValidators.length > 0
            ) {
                let validators = this.props.customValidators;
                for (let i = 0; i < validators.length; i++) {
                    let validate = !validators[i].validator(
                        countryCode,
                        phoneNumber,
                    );
                    if (validate) {
                        error = true;
                        errorMessage = validators[i].errorMessage;
                        break;
                    }
                }
            }
        }
        let returnError = {
            error: error,
            errorMessage: errorMessage,
        };
        return returnError;
    }

    _checkPhoneValidity(countryCode, phoneNumber) {
        let errorInfo = this._checkForPhoneError(countryCode, phoneNumber);

        // Execute the validation handler if provided.
        if (this.props.onPhoneValidityCheck) {
            this.props.onPhoneValidityCheck(
                countryCode,
                phoneNumber,
                errorInfo.error,
            );
        }

        this.setState({
            errorMessage: errorInfo.errorMessage,
            error: errorInfo.error,
        });
        if (this.props.handlePhoneValidation) {
            this.props.handlePhoneValidation(this.state.error);
        }

        return errorInfo.error;
    }

    _parsePhoneNumber(phoneNumber) {
        var number = this._stripSpecialCharacters(phoneNumber);
        var newNumber;
        if (phoneNumber.indexOf('+') === 0) {
            var isValidCountryCode = false;
            var maxCountryCodeToMatch = 5;
            var countryCodeMatchItem;
            var countryCodeMatch;

            if (!this.props.isInternationalPhoneEnabled) {
                maxCountryCodeToMatch = 1;
            }
            //iterate to find the matching country code
            for (var i = maxCountryCodeToMatch; i > 0; i--) {
                var tmpCode = number.substring(0, i);

                for (let item of this.props.countryCodeData) {
                    if (item.phoneCode === tmpCode) {
                        isValidCountryCode = true;
                        newNumber = number.substring(
                            tmpCode.length,
                            number.length,
                        );
                        countryCodeMatchItem = item;
                        countryCodeMatch = tmpCode;
                        break;
                    }
                }

                if (countryCodeMatch) {
                    break;
                }
            }
            if (isValidCountryCode) {
                this.setState({
                    value: newNumber,
                    selectedItem: countryCodeMatchItem,
                    selectedPhoneCode: countryCodeMatch,
                    selectedCountryName: countryCodeMatchItem.name,
                });
            } else {
                newNumber = phoneNumber;
            }

            return {
                phoneNumber: newNumber,
                countryCode: countryCodeMatch,
            };
        } else {
            return {
                phoneNumber: phoneNumber,
            };
        }
    }

    _i18nStrings(key) {
        return this.props.i18nStrings[key];
    }

    _onDeleteClick(evt, inputCleared = false) {
        // Reset the state of this component.
        this.intlPhNoInput.value = '';
        this.setState({
            selectedPhoneCode: '',
            selectedCountryName: '',
            value: '',
            rawvalue: null,
            selectedItem: null,
            error: false,
            errorMessage: null,
        });

        // Execute custom onDelete handler if provided.
        if (this.props.onDelete) {
            this.props.onDelete(
                evt,
                this.state.selectedPhoneCode,
                this._stripSpecialCharacters(this.state.value),
                inputCleared,
            );
        }
    }

    _onDeleteKeyDown(event, inputCleared = false) {
        if (event.keyCode === 32 || event.keyCode === 13) {
            event.preventDefault();
            this._onDeleteClick(event, inputCleared);
        }
    }
}

InternationalPhoneNumberInputField.propTypes = {
    /**
     * Classname to add to the top level div.
     */
    className: PropTypes.string,
    /**
     * Minimum number of digits for the phone number to be valid.Default Value: 9
     */
    phoneMinLength: PropTypes.number,
    /**
     * Maximum number of digits for the phone number to be valid.Default Value: 16
     */
    phoneMaxLength: PropTypes.number,
    /**
     * The international phone code for the selected country.Default Value: '1'
     */
    selectedPhoneCode: PropTypes.string,
    /**
     * The name of the selected country.Default Value: 'United States'
     */
    selectedCountryName: PropTypes.string,
    /**
     * The phone number to show.Default Value: ''
     */
    phoneNumber: PropTypes.string,
    /**
     * Boolean to enable international phone dropdown, if false assumes you are in the United States
     */
    isInternationalPhoneEnabled: PropTypes.bool,
    /**
     * Add an id to the input field if provided.
     */
    inputFieldId: PropTypes.string,
    /**
     * The id to add to the outermost div of the component. When provided, this will also add an accessibility id to the dropdown with the extension '-phoneCodeDropdown'.
     */
    intlCompId: PropTypes.string,
    /**
     * If true sets initial focus to the dropdown button.
     */
    defaultFocus: PropTypes.bool,
    /**
     * Internationalization strings to use the component in other languages.
     */
    i18nStrings: PropTypes.object,
    /**
     * The data for the country code dropdown menu.
     */
    countryCodeData: PropTypes.array,
    /**
     * Function to be executed on changing the number in the input field.
     */
    onIntlPhNoChange: PropTypes.func,
    /**
     * Function to be executed on changing the country code via the drop down.
     */
    onCountryCodeChange: PropTypes.func,
    /**
     * Function to be executed when checking the phone validity.
     */
    onPhoneValidityCheck: PropTypes.func,
    /**
     * Function to be executed when an element loses focus.
     */
    onBlur: PropTypes.func,
    /**
     * Function to be executed when a field value is cleared using the delete button.
     */
    onDelete: PropTypes.func,
    /**
     * Function to be executed when user presses a key.
     */
    onKeyDown: PropTypes.func,
    /**
     * If true will show error if a phone is needed and none has been entered.
     */
    phoneRequired: PropTypes.bool,
    /**
     * Boolean to enable the delete buttons on fields.
     */
    enableDelete: PropTypes.bool,
    /**
     * Boolean to enable the clear icon on fields.
     */
    enableClearOption: PropTypes.bool,
    /**
     * Boolean to show delete buttons even if no fields exist.
     */
    showDeleteIfEmpty: PropTypes.bool,
    /**
     * If true sets the initial focus to the input field.
     */
    focusInputOnInit: PropTypes.bool,
    /**
     * If true will run validation when page loads.
     */
    validateOnInit: PropTypes.bool,
    /**
     * An array of validators that contains the validator and error message.
     */
    customValidators: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * Custom function for validating country code and phone number.
             */
            validator: PropTypes.func.isRequired,
            /**
             * Error message to display should validation fail.
             */
            errorMessage: PropTypes.string.isRequired,
        }),
    ),
    /**
     * Custom accessibility label for the input.
     */
    ariaLabelForInput: PropTypes.string.isRequired,
    /**
     * Boolean value to remove the event listener on resizing the page.Default Value: false
     */
    disableResizeEventListener: PropTypes.bool,
    /**
     * Boolean value to remove the border applied on focus at global level
     */
    suppressFocusBorder: PropTypes.bool,
};
