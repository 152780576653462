import { palette } from '../palette';

const customPalette = palette.palette;

export default function Switch() {
    return {
        MuiSwitch: {
            styleOverrides: {
                colorSuccess: {
                    '&.Mui-checked + .MuiSwitch-track': {
                        color: customPalette.success.hover,
                        backgroundColor: customPalette.success.hover,
                    },
                },
            },
        },
    };
}
