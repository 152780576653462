/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Call extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M7.9,10.3c1.1,1.3,2.7,3.3,3.1,3.4c0.3,0.2,0.2,0.2,0.4,0.1c0.3-0.2,0.5-0.2,0.6-0.7c0-0.2,1.3-1.4,1.3-1.4s0.7-0.5,1-0.2
				s2.9,2.7,2.9,2.7s0.7,0.6,0.2,1.2c-0.5,0.7-1.5,2-2.2,2.3c-0.7,0.3-2.8,0.6-4.1-0.2c-1.1-0.6-4.6-3.5-6.2-5.6
				C3.4,10,1.4,7.3,0.8,6.2c-0.7-1.4-0.3-3.4,0-4.1s1.6-1.6,2.4-2s1.2,0.2,1.2,0.2s2.2,2.8,2.4,3.1s-0.3,1-0.3,1S5.3,5.5,5.2,5.6
				C4.7,5.8,4.7,5.8,4.5,6.1C4.4,6.3,4.4,6.2,4.6,6.6C4.7,7,6.8,9,7.9,10.3z"
                />
            </svg>
        );
    }
}
