import queryString from 'query-string';

const globalHeaders = {};
const errorCodeHandlers = {};
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

let interceptor = null;
let responseInterceptor = null;

const registerInterceptor = (fn) => {
    interceptor = fn;
};

const unregisterInterceptor = () => {
    interceptor = null;
};

const registerResponseInterceptor = (fn) => {
    responseInterceptor = fn;
};

const unregisterResponseInterceptor = () => {
    responseInterceptor = null;
};

const makeRequest = (url, options) => {
    const { method, body, headers = {} } = options;
    let bodyToSend = null;

    if (body instanceof FormData) {
        headers['Content-Type'] = undefined;
        delete headers['Content-Type'];
        bodyToSend = body;
    } else if (body) {
        headers['Content-Type'] = 'application/json; charset=UTF-8';
        bodyToSend = JSON.stringify(body);
    }

    const fetchOptions = {
        mode: 'cors',
        credentials: 'include',
        headers: new Headers({ ...globalHeaders, ...headers }),
        body: bodyToSend,
        method,
    };

    const interceptedConfig =
        (interceptor &&
            interceptor(url, {
                mode: 'cors',
                credentials: 'include',
                method,
                headers: { ...globalHeaders, ...headers },
                body: bodyToSend,
            })) ||
        {};

    const defaultConfig = {
        url: `${process.env.REACT_APP_SERVICES_URL || ''}${
            process.env.REACT_APP_API_CONTEXT || ''
        }${url}`,
        options: fetchOptions,
    };

    const finalPromise = new Promise((resolve, reject) => {
        const promise = fetch(
            interceptedConfig.url || defaultConfig.url,
            interceptedConfig.options || defaultConfig.options,
        );

        promise
            .then((response) => {
                finalPromise.response = response;

                if (response.status === 500) {
                    const contentType = response.headers.get('content-type');
                    let errorPromise;

                    if (
                        contentType &&
                        contentType.indexOf('application/json') !== -1
                    ) {
                        errorPromise = response.json();
                    } else {
                        errorPromise = response.text();
                    }

                    errorPromise.then((jsonResponse) => {
                        reject(jsonResponse);
                    });
                } else {
                    const contentType = response.headers.get('content-type');
                    let convertPromise;

                    if (
                        contentType &&
                        contentType.indexOf('application/json') !== -1
                    ) {
                        convertPromise = response.json();
                    } else {
                        convertPromise = response.text();
                    }
                    convertPromise
                        .then((jsonResponse) => {
                            if (response.status === 429) {
                                // eslint-disable-next-line
                                reject({
                                    response,
                                    responseText: jsonResponse,
                                });
                            } else if (response.status >= 400) {
                                let errorCodeHandler = null;

                                if (jsonResponse && jsonResponse.errorCode) {
                                    ({ errorCodeHandler } = options);

                                    if (!errorCodeHandler) {
                                        errorCodeHandler =
                                            errorCodeHandlers[
                                                jsonResponse.errorCode
                                            ];
                                    }

                                    if (errorCodeHandler) {
                                        errorCodeHandler(
                                            jsonResponse,
                                            response,
                                        );

                                        return;
                                    }
                                }
                                reject(jsonResponse);

                                return;
                            }

                            let interceptorResponse = jsonResponse;

                            if (responseInterceptor) {
                                interceptorResponse = responseInterceptor(
                                    jsonResponse,
                                );

                                if (
                                    typeof interceptorResponse === 'undefined'
                                ) {
                                    interceptorResponse = jsonResponse;
                                }
                            }

                            resolve(interceptorResponse);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    return finalPromise;
};

const handleRequest = async (Fetch, action, url, params, headers, mockLib) => {
    if (process.env.NODE_ENV !== 'production') {
        const tokens = url.split('/');
        const api = tokens.pop();
        const path = tokens.filter((i) => i).join('/');
        const dataPath = `/${path}/${action}-${api}`;

        try {
            const lib = await mockLib();
            const apiConfig = [].concat(lib.default[dataPath]);
            const fn = apiConfig[0];
            const useBackend = apiConfig[1] || false;

            if (!useBackend || process.env.NODE_ENV === 'test') {
                if (fn) {
                    const json = await fn(params);
                    const response = json.default || json;

                    if (response.echoRequest) {
                        return params;
                    }

                    // special handling for pagination
                    const { pageSize = 0, pageNumber = 1 } = params || {};

                    if (pageSize) {
                        const start = (pageNumber - 1) * pageSize;
                        const end = (pageNumber - 1) * pageSize + pageSize;

                        const chunk = [];
                        const { data = [] } = response || {};
                        const { length } = data;

                        for (
                            let idx = Math.min(start, length),
                                len = Math.min(end, length);
                            idx < len;
                            idx += 1
                        ) {
                            chunk.push(data[idx]);
                        }

                        return {
                            ...response,
                            data: chunk,
                            totalPages: Math.ceil(length / pageSize),
                            totalElements: length,
                        }; // copy result into new object
                    }

                    return response;
                }
                console.log(`Unable to find: ${url} - ${dataPath}`);
            }
        } catch (e) {
            console.log(e);
        }
    }

    return Fetch[action](url, params, headers);
};

const Fetch = {
    registerInterceptor(fn) {
        registerInterceptor(fn);
    },

    unregisterInterceptor,
    registerResponseInterceptor,
    unregisterResponseInterceptor,

    get(url, params, headers) {
        const hasParams = url.indexOf('?') !== -1;
        const modifiedUL = params
            ? `${url}${hasParams ? '&' : '?'}${queryString.stringify(params)}`
            : url;

        return makeRequest(modifiedUL, {
            method: 'GET',
            headers,
        });
    },

    delete(url, params, headers) {
        const hasParams = url.indexOf('?') !== -1;
        const modifiedUL = params
            ? `${url}${hasParams ? '&' : '?'}${queryString.stringify(params)}`
            : url;

        return makeRequest(modifiedUL, {
            method: 'DELETE',
            headers,
        });
    },

    post(url, data, headers) {
        return makeRequest(url, {
            method: 'POST',
            body: data,
            headers,
        });
    },

    put(url, data, headers) {
        return makeRequest(url, {
            method: 'PUT',
            body: data,
            headers,
        });
    },

    postFormData(url, data, headers) {
        const formData = new FormData();

        if (data) {
            Object.getOwnPropertyNames(data).forEach((propName) => {
                let value = data[propName];
                const isFile = value instanceof File;

                if (value !== null && typeof value === 'object' && !isFile) {
                    value = JSON.stringify(value);
                }

                if (isFile && isIE11) {
                    formData.append(propName, value, value.name);
                } else {
                    formData.append(propName, value);
                }
            });
        }

        return makeRequest(url, {
            method: 'POST',
            body: formData,
            headers,
        });
    },

    putFormData(url, data, headers) {
        const formData = new FormData();

        if (data) {
            Object.getOwnPropertyNames(data).forEach((propName) => {
                let value = data[propName];
                const isFile = value instanceof File;

                if (value !== null && typeof value === 'object' && !isFile) {
                    value = JSON.stringify(value);
                }
                formData.append(propName, value);
            });
        }

        return makeRequest(url, {
            method: 'PUT',
            body: formData,
            headers,
        });
    },

    patch(url, data, headers) {
        return makeRequest(url, {
            method: 'PATCH',
            body: data,
            headers,
        });
    },

    patchFormData(url, data, headers) {
        const formData = new FormData();

        if (data) {
            Object.getOwnPropertyNames(data).forEach((propName) => {
                let value = data[propName];
                const isFile = value instanceof File;

                if (value !== null && typeof value === 'object' && !isFile) {
                    value = JSON.stringify(value);
                }
                formData.append(propName, value);
            });
        }

        return makeRequest(url, {
            method: 'PATCH',
            body: formData,
            headers,
        });
    },

    setErrorCodeHandler(errorCode, h) {
        errorCodeHandlers[errorCode] = h;
    },

    configureMock(mockPath) {
        return {
            get: (url, params, headers) =>
                handleRequest(Fetch, 'get', url, params, headers, mockPath),
            delete: (url, params, headers) =>
                handleRequest(Fetch, 'delete', url, params, headers, mockPath),
            post: (url, data, headers) =>
                handleRequest(Fetch, 'post', url, data, headers, mockPath),
            put: (url, data, headers) =>
                handleRequest(Fetch, 'put', url, data, headers, mockPath),
            postFormData: (url, data, headers) =>
                handleRequest(
                    Fetch,
                    'postFormData',
                    url,
                    data,
                    headers,
                    mockPath,
                ),
            patch: (url, data, headers) =>
                handleRequest(Fetch, 'patch', url, data, headers, mockPath),
        };
    },
};

export default Fetch;
