import AnalyticsLogger from 'common-ui/src/util/AnalyticsLogger';

export default {
    logContactInfo: (csrf) => {
        const analyticsData = { csrf };
        const eventObject = {
            props: {
                prop12: 'Saved contact',
                eVar12: 'Settings',
                events: 'event22',
                pageName: 'Settings - save contact',
            },
            site: 'sdp',
            action: 'saved_contact',
            category: 'Settings',
        };

        if (AnalyticsLogger) {
            AnalyticsLogger.trackEvent(eventObject, analyticsData);
        }
    },

    logEditContactInfo: (config) => {
        const { userId, userName, csrfToken } = config;
        const analyticsData = {
            userId,
            userName,
            csrfToken,
            timeStamp: new Date().toISOString(),
            pageName: 'Settings - Contact Information',
        };

        const eventObject = {
            props: {
                prop12: 'Edited contact info',
                eVar12: 'Settings',
                events: 'event22',
                pageName: 'Settings',
            },
            site: 'sdp',
            action: 'edited_contact_info',
            category: 'Settings',
        };

        if (AnalyticsLogger) {
            AnalyticsLogger.trackEvent(eventObject, analyticsData);
        }
    },

    logExpandPhoneForText: (config) => {
        const { userId, userName, csrfToken } = config;
        const analyticsData = {
            userId,
            userName,
            csrfToken,
            timeStamp: new Date().toISOString(),
            pageName: 'Settings - Security options',
        };
        const eventObject = {
            props: {
                prop12: 'Initiated phone text',
                eVar12: 'Settings',
                events: 'event22',
                pageName: 'Settings - Security options',
            },
            site: 'sdp',
            action: 'initiated_phone_text',
            category: 'Settings',
        };

        if (AnalyticsLogger) {
            AnalyticsLogger.trackEvent(eventObject, analyticsData);
        }
    },

    logDeletePhoneNo: (csrf) => {
        const analyticsData = { csrf };
        const eventObject = {
            props: {
                prop12: 'Delete number',
                eVar12: 'Settings',
                events: 'event22',
                pageName: 'Settings - delete phone number',
            },
            site: 'sdp',
            action: 'delete_number',
            category: 'Settings',
        };

        if (AnalyticsLogger) {
            AnalyticsLogger.trackEvent(eventObject, analyticsData);
        }
    },
};
