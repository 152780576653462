/* eslint-disable import/order,no-underscore-dangle,spaced-comment,prefer-const,react/destructuring-assignment,react/prop-types,padding-line-between-statements,react/jsx-curly-brace-presence,react/no-string-refs,react/button-has-type,prefer-template,no-unneeded-ternary,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import AlmanacPicker from './AlmanacPicker';
import SlideTransitionItem from './SlideTransitionItem';
import Moment from 'moment';
import PropTypes from 'prop-types';
import '../../styles/SlideTransitionBPAlmanacPicker.scss';
import SVG from '../images/SVG';

const _i18nDefaultStrings = {
    'comp.stbpalmanacpicker.label.sendon': 'Send On:',
    'comp.stbpalmanacpicker.label.deliverby': 'Deliver By:',
    'comp.stbpalmanacpicker.label.save': 'Save',
    'comp.stbpalmanacpicker.label.cancel': 'Cancel',
};

export default class SlideTransitionBPAlmanacPicker extends Component {
    constructor(props) {
        super(props);

        this._handleOnDateChange = this._handleOnDateChange.bind(this);
        this._renderFooter = this._renderFooter.bind(this);
        this._onCancel = this._onCancel.bind(this);
        this._onSave = this._onSave.bind(this);
    }

    render() {
        //let selectedDate = this.props.defaultValue ? this.props.defaultValue : new Date();
        let format = this.props.dateFormat
            ? this.props.dateFormat
            : 'MMM DD, YYYY';
        let sendOnDate =
            this.props.sendOnDate &&
            Moment(this.props.sendOnDate).format(format);
        let deliveredOnDate =
            this.props.deliveredOnDate &&
            Moment(this.props.deliveredOnDate).format(format);

        let pickerProps = {
            maxStartDate: this.props.maxStartDate,
            maxEndDate: this.props.maxEndDate,
            holidays: this.props.holidays,
            disable: this.props.disabled,
            onMonthChange: this.props.onMonthChange,
            dateFormat: this.props.dateFormat,
            disableWeekends: true,
            hideSaveButton: true,
            closeOnSelect: false,
            onDateSelect: this._handleOnDateChange,
            onOpen: this.props.onOpen,
            header: <div />,
            footer: !this.props.loading ? this._renderFooter() : '',
            prefixlabel: this.props.label,
            defaultValue: this.props.sendOnDate || this.props.deliveredOnDate,
            loading: this.props.loading,
            loadingText: this.props.loadingText,
        };

        if (this.props.sendOnDate) {
            pickerProps.selectRange = {
                start: this.props.sendOnDate,
                end: this.props.deliveredOnDate,
            };
        }

        let error;
        if (this.props.showError) {
            error = (
                <div className={'text-danger'}>
                    <SVG name="AlertError" /> {this.props.errorMessage}
                </div>
            );
        }

        return (
            <div className={'bp-date-picker'} ref="bpDatePicker">
                <div className={'hidden-xs'}>
                    <AlmanacPicker {...pickerProps} ref="bpAlmanacPicker" />
                    {error}
                </div>
                <div className="visible-xs">
                    <SlideTransitionItem
                        label={this.props.label}
                        value={sendOnDate || deliveredOnDate}
                        navDown={this.props.navDown}
                        labelClassName={this.props.labelClassName}
                        errorMessage={this.props.errorMessage}
                        showError={this.props.showError}
                    />
                </div>
            </div>
        );
    }

    _handleOnDateChange(selectedDate, formatedDate) {
        if (this.props.onDateChange) {
            this.props.onDateChange(selectedDate, formatedDate);
        }
    }

    _renderFooter() {
        let format = this.props.dateFormat || 'MMM DD, YYYY';
        let sendOnDate =
            this.props.sendOnDate &&
            Moment(this.props.sendOnDate).format(format);
        let deliveredOnDate =
            this.props.deliveredOnDate &&
            Moment(this.props.deliveredOnDate).format(format);

        let deliverOnStyles = {
            color: sendOnDate ? '#d44403' : '#2e6f03',
        };

        return (
            <div>
                <br />
                {sendOnDate ? (
                    <div>
                        <span
                            className="send-on-date-label"
                            style={{ color: '#2e6f03' }}
                        >
                            {this._getString('label.sendon')}
                        </span>{' '}
                        {sendOnDate}
                        <br />
                    </div>
                ) : null}
                <div>
                    <span
                        className="delivered-on-date-label"
                        style={deliverOnStyles}
                    >
                        {this._getString('label.deliverby')}
                    </span>{' '}
                    {deliveredOnDate}
                    <br />
                </div>
                <br />
                <button
                    className="btn btn-primary btn-block"
                    onClick={this._onSave}
                >
                    {this._getString('label.save')}
                </button>
                <button
                    className="btn btn-secondary btn-block"
                    onClick={this._onCancel}
                >
                    {this._getString('label.cancel')}
                </button>
            </div>
        );
    }

    _onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        this.refs.bpAlmanacPicker._closeCalendar();
        if (this.props.onClose) {
            this.props.onClose(this.props.sendOnDate);
        }
    }

    _onSave() {
        if (this.props.onOk) {
            this.props.onOk();
        }
        this.refs.bpAlmanacPicker._closeCalendar();
        if (this.props.onClose) {
            this.props.onClose(this.props.sendOnDate);
        }
    }

    _getString(key) {
        let i18nStrings = this.props.i18nStrings || _i18nDefaultStrings;
        let value = i18nStrings['comp.stbpalmanacpicker.' + key];

        // If value for Key is not found, true the complete key.
        return value ? value : 'comp.stbpalmanacpicker.' + key;
    }
}

SlideTransitionBPAlmanacPicker.propTypes = {
    sendOnDate: PropTypes.object,
    deliveredOnDate: PropTypes.object,
    dateFormat: PropTypes.string,
    label: PropTypes.string,
    footer: PropTypes.func,
    navDown: PropTypes.func,
    showError: PropTypes.bool,
    errorMessage: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onOpen: PropTypes.func,
};
