/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Refresh extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M13.6,10.7h-0.3c-0.7,1.7-2.3,2.9-4.2,2.9c-1.5,0-2.9-0.8-3.7-1.9l2.3-0.6l-5-2.7L0,13.1l2.5-0.7c1.2,2.3,3.7,3.8,6.5,3.8
						s5.6-2,6.8-4.4L13.6,10.7z M4.4,7.4h0.3C5.3,5.7,7,4.5,8.9,4.5c1.5,0,2.9,0.8,3.7,1.9L10.3,7l5,2.7L18,5l-2.5,0.7
						c-1.2-2.3-3.7-3.9-6.5-3.9s-5.6,2-6.8,4.4L4.4,7.4z"
                    />
                </g>
            </svg>
        );
    }
}
