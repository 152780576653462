/* eslint-disable react/prefer-stateless-function,react/destructuring-assignment,react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from '../images/SVG';
import BrowserDetect from '../../util/BrowserDetect';

export default class FieldValidationError extends Component {
    render() {
        return (
            <div
                role={!BrowserDetect.isIE11() ? 'alert' : null}
                aria-live="assertive"
                aria-atomic="true"
            >
                <p
                    className="help-block"
                    role={BrowserDetect.isIE11() ? 'alert' : null}
                >
                    <SVG name="AlertError" />
                    <span id={this.props.id} className="show-inline">
                        {this.props.errorMessage}
                    </span>
                </p>
            </div>
        );
    }
}

FieldValidationError.propTypes = {
    /**
     * Required error message prop received on any field validation error.
     */
    errorMessage: PropTypes.string.isRequired,

    /**
     * A unique id that will be appended to error container whenever error displayed.
     */
    id: PropTypes.string,
};
