/* eslint-disable no-underscore-dangle,prefer-const,react/destructuring-assignment,padding-line-between-statements,prefer-template,no-plusplus,no-else-return,react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/WidgetHeader.scss';
import SVG from '../images/SVG';

export default class WidgetHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._renderLinks = this._renderLinks.bind(this);
    }

    render() {
        let headerType = this.props.isSecondary
            ? ' di-secondary-header brand-type-a-text-style'
            : ' di-primary-header brand-type-a ';
        let noUnderlineClass = '';
        if (this.props.isSecondary && this.props.removeUnderline) {
            noUnderlineClass = ' ' + styles['no-underline'];
        }

        return (
            <div
                className={
                    styles['di-widget-header'] + headerType + noUnderlineClass
                }
            >
                <h1 className={styles['di-widget-header-title']}>
                    {this.props.header}
                </h1>
                {this._renderLinks()}
            </div>
        );
    }

    _renderLinks() {
        let links = this.props.links ? this.props.links : [];

        if (links.length > 0) {
            let displayedLinks = [];
            let maxLinks = this.props.maxNumberOfLinks
                ? this.props.maxNumberOfLinks
                : 3;

            if (links.length < maxLinks) {
                maxLinks = links.length;
            }

            if (maxLinks > 0) {
                for (let i = 0; i < maxLinks; i++) {
                    if (links[i].name) {
                        let linkTitle =
                            links[i].name && links[i].title
                                ? links[i].title
                                : links[i].name;
                        let fullLink = (
                            <a
                                href={links[i].to}
                                className={styles['di-widget-header-link']}
                            >
                                <SVG name={links[i].name} />
                                <p
                                    className={
                                        styles['di-widget-header-link-title']
                                    }
                                >
                                    {linkTitle}
                                </p>
                            </a>
                        );
                        displayedLinks.push(fullLink);
                    }
                }
                return displayedLinks;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

WidgetHeader.propTypes = {
    /**
     * Changes the background color of the header to secondary branding color (default white).Default Value: false
     */
    isSecondary: PropTypes.bool,
    /**
     * Sets the header message.
     */
    header: PropTypes.string.isRequired,
    /**
     * Adds links with an SVG and a title to the right side of the widget header.
     */
    links: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The name of the SVG the user wants to add.
             */
            name: PropTypes.string.isRequired,
            /**
             * A string url of the destination for the link. This is where the user will be taken when the SVG or title underneath are clicked
             */
            to: PropTypes.string.isRequired,
            /**
             * The title to be displayed under the SVG. If none is added the name of the SVG will be used.
             */
            title: PropTypes.string,
        }),
    ),
    /**
     * Removes the line under secondary headers. Has no effect on primary headers.
     */
    removeUnderline: PropTypes.arrayOf(PropTypes.element),
    /**
     * Maximum number of links that can be added to the side of the widget.Default Value: 3
     */
    maxNumberOfLinks: PropTypes.number,
};
