/* eslint-disable react/sort-comp,react/no-find-dom-node,padding-line-between-statements,prefer-destructuring,react/destructuring-assignment,no-underscore-dangle,func-names,no-unused-vars,prefer-const,react/no-array-index-key,react/jsx-no-bind,react/jsx-boolean-value,react/static-property-placement */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from '../../styles/SlideTransitionDropdown.scss';
import DropdownItem from './DropdownItem';

export default class SlideTransitionDropdownChild extends Component {
    componentDidMount() {
        let selectedItem = ReactDOM.findDOMNode(this).querySelectorAll(
            'li.active',
        )[0];
        if (!selectedItem) {
            selectedItem = ReactDOM.findDOMNode(this).querySelectorAll('li')[0];
        }
        if (selectedItem) {
            setTimeout(() => {
                selectedItem.focus();
            }, 301); // This needs to execute right after the slide animation completes
        }
    }

    render() {
        return (
            <div className={styles['slide-transition-dropdown']}>
                <h1>{this.props.label}</h1>
                <ul role="presentation">
                    {this.props.items
                        ? this._renderListItem(this.props.items)
                        : React.Children.map(
                              this.props.children,
                              function (item, index) {
                                  return React.cloneElement(item, {
                                      onClick: this._handleOnClick.bind(
                                          this,
                                          (item.props &&
                                              item.props.dataOnSelect) ||
                                              item,
                                      ),
                                      child: true,
                                  });
                              },
                              this,
                          )}
                </ul>
                <div className="clearfix" />
            </div>
        );
    }

    _renderListItem(items) {
        let listItems = [];
        if (items) {
            items.forEach((item, i) => {
                let displayTitle = item.title;

                let cellLayout = (
                    <div className={styles['di-arrange']}>{displayTitle}</div>
                );

                listItems.push(
                    <DropdownItem
                        key={i}
                        onClick={this._handleOnClick.bind(this, item)}
                        child={true}
                    >
                        {cellLayout}
                    </DropdownItem>,
                );
            });
        }

        return listItems;
    }

    _handleOnClick(item, e) {
        if (this.props.navUp) {
            this.props.navUp(item);
        }
    }
}

SlideTransitionDropdownChild.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    navUp: PropTypes.func,
    label: PropTypes.string,
};
