/* eslint-disable no-underscore-dangle,no-unused-vars,react/sort-comp,no-var,react/destructuring-assignment,padding-line-between-statements,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import SVG from '../images/SVG';
import FullScreenModalStyles from './FullScreenModal.scss';

/**
 * Modal component that will attach itself to the document body.
 */
export default class Modal extends Component {
    constructor(props) {
        super(props);

        this._renderModal = this._renderModal.bind(this);
        this._hide = this._hide.bind(this);
        this._show = this._show.bind(this);
        this._onClose = this._onClose.bind(this);

        this.modal = null;
    }

    componentDidUpdate(prevProps, prevState) {
        this._show();
    }

    componentDidMount() {
        this._show();
    }

    componentWillUnmount() {
        this._onClose();
    }

    render() {
        // Return nothing since the actual dom is rendered elsewhere in the document.
        return null;
    }

    _renderModal() {
        var modal = (
            <div className="modal-open">
                <div className="modal show in" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header fade-in">
                                {this.props.closeButton ? (
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={this._onClose}
                                    >
                                        <span aria-hidden="true">
                                            <SVG name="Close" size="sm" />
                                        </span>
                                    </button>
                                ) : null}
                                <h2
                                    className="modal-title"
                                    aria-label="Heading"
                                >
                                    {this.props.title}
                                </h2>
                            </div>
                            <div className="fade-in">{this.props.children}</div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade in" />
            </div>
        );
        ReactDOM.unstable_renderSubtreeIntoContainer(this, modal, this.modal);
    }

    _onClose() {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this._hide();
    }

    _show() {
        if (this.modal == null && this.props.show) {
            this.modal = document.createElement('div');
            // Apply the customized fullscreen css if it's set to true.
            if (this.props.fullScreen) {
                this.modal.className =
                    FullScreenModalStyles['di-modal-container'];
                // Hide background page scrolling for fullscreen modals.
                document.body.style.overflowY = 'hidden';
            }
            document.body.appendChild(this.modal);
            this._renderModal();
        }
    }

    _hide() {
        if (this.modal) {
            if (this.props.fullScreen) {
                // Add back background page scrolling when fullscreen modal is closed.
                document.body.style.overflowY = '';
            }
            ReactDOM.unmountComponentAtNode(this.modal);
            document.body.removeChild(this.modal);
            this.modal = null;
        }
    }
}

Modal.propTypes = {
    /**
     * If true the modal will cover the full screen.
     */
    fullScreen: PropTypes.bool,
    /**
     * If false will prevent the modal from showing.
     */
    show: PropTypes.bool.isRequired,
    /**
     * Title to add in the modal layover - it will appear in the top left corner and as a h2 element.
     */
    title: PropTypes.string,
    /**
     * If true will add a close button to the top right corner of the modal.
     */
    closeButton: PropTypes.bool,
    /**
     * Custom function to be executed when the modal is closed.
     */
    onClose: PropTypes.func,
};
