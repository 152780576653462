/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class PayPeople extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M16.2,18H1.8c-1,0-1.8-0.8-1.8-1.8V1.8C0,0.8,0.8,0,1.8,0h14.4c1,0,1.8,0.8,1.8,1.8v14.4C18,17.2,17.2,18,16.2,18z M9,2.2
                c-2.7,0-5,2.2-5,4.9v0.1c0,0,0,0,0,0.1v8C4,15.7,4.3,16,4.7,16H6c0.3,0,0.6-0.3,0.6-0.6v-3.9c0.7,0.4,1.6,0.7,2.5,0.7
                c2.7,0,4.9-2.2,4.9-4.9S11.7,2.2,9,2.2z M9,9C8,9,7.2,8.2,7.2,7.2S8,5.4,9,5.4s1.8,0.8,1.8,1.8S10,9,9,9z"
                />
            </svg>
        );
    }
}
