/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class StopPayment extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 20 20"
                enableBackground="new 0 0 20 20"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M14.2,0H5.8L0,5.8v8.3L5.9,20h8.2l5.9-5.9V5.8L14.2,0z M18,13.3L13.2,18H6.7L2,13.3V6.7L6.6,2h6.7L18,6.6V13.3
		z"
                    />
                </g>
                <g>
                    <path d="M12.7,3.5H7.3L3.5,7.3v5.4l3.8,3.8h5.3l3.9-3.9V7.3L12.7,3.5z" />
                </g>
            </svg>
        );
    }
}
