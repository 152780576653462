/* eslint-disable no-underscore-dangle,class-methods-use-this,react/prop-types,react/jsx-boolean-value */
import React, { Component } from 'react';
import connectToStores from 'common-ui/src/alt/connectToStores';
import { InputField } from 'common-ui/src/components/module';
import StepUpAuthenticationActions from '../actions/StepUpAuthenticationActions';
import StepUpAuthenticationStore from '../stores/StepUpAuthenticationStore';
import MfaNoCodeDropdown from './MfaNoCodeDropdown';
import SVG from 'common-ui/src/components/images/SVG';

class OtpInput extends Component {
    constructor(props) {
        super(props);

        this._onInputChange = this._onInputChange.bind(this);
        this._onInputKeyDown = this._onInputKeyDown.bind(this);
        this._getHelpReasons = this._getHelpReasons.bind(this);
    }

    static getStores() {
        return [StepUpAuthenticationStore];
    }

    static getPropsFromStores() {
        return StepUpAuthenticationStore.getState();
    }

    render() {
        return (
            <div className="row">
                <div className="row col-sm-6 col-xs-12 step-up-otp-container">
                    <InputField
                        id="otpCodeInputField"
                        name="otpCodeInputField"
                        focusOnInit={true}
                        title={this.props.securityOptionOtp.title}
                        label={this.props.securityOptionOtp.label}
                        value={this.props.otp}
                        type="text"
                        isValid={this.props.validOtp}
                        validationError={this.props.securityOptionOtp.error}
                        onChange={this._onInputChange}
                        onKeyDown={this._onInputKeyDown}
                        maxLength={this.props.securityOptionOtp.maxLengthOTP}
                    />
                    <MfaNoCodeDropdown
                        labels={this.props.securityOptionOtp.labels}
                        reasons={this._getHelpReasons()}
                        showDifferentMethodLink={
                            this.props.showDifferentMethodLink
                        }
                        forAppCode={this.props.forAppCode}
                        actionId={this.props.actionId}
                        reason={this.props.reason}
                    />
                </div>

                <div
                    id="otpButtons"
                    className="row col-xs-12 button-container step-up-otp-button-container"
                >
                    <button
                        type="button"
                        id="otpSubmitCode"
                        className="btn btn-primary"
                        disabled={this.props.submittingOtp}
                        onClick={this._submitCode}
                        dangerouslySetInnerHTML={{
                            __html: this.props.securityOptionOtp.verifyBtn,
                        }}
                    />
                    <span id="otpLoader" className="step-up-loader">
                        {this.props.submittingOtp ? (
                            <SVG name="SmallLoader" size="lg" />
                        ) : null}
                    </span>
                </div>
            </div>
        );
    }

    _submitCode() {
        StepUpAuthenticationActions.submitOtp();
    }

    _onInputChange(event) {
        let otp = event.target.value;

        if (otp) {
            otp = otp.replace(/[^0-9]/g, '');
        }
        StepUpAuthenticationActions.setOtp(otp);
        const valid = otp.length <= this.props.securityOptionOtp.maxLengthOTP;
        StepUpAuthenticationActions.setValidOtp(valid);
    }

    _onInputKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            StepUpAuthenticationActions.submitOtp(false);
        }
    }

    _getHelpReasons() {
        if (this.props.destinationProtocol === 'VOICE') {
            return this.props.securityOptionOtp.didntGetVoice;
        } else if (this.props.destinationProtocol === 'SMS') {
            return this.props.securityOptionOtp.didntGetText;
        }

        return this.props.securityOptionOtp.didntGetEmail;
    }
}

export default connectToStores(OtpInput);
