/* eslint-disable import/no-useless-path-segments,no-underscore-dangle,react/sort-comp,lines-between-class-members,react/destructuring-assignment,prefer-const,prefer-template,react/prop-types,padding-line-between-statements,jsx-a11y/label-has-for,react/jsx-no-bind,no-return-assign,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDown from './Dropdown';
import SlideTransitionItem from './SlideTransitionItem';
import CommonUtils from './../utils/CommonUtils';
import styles from '../../styles/SlideTransitionDropdown.scss';
import SVG from '../images/SVG';

export default class SlideTransitionDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breakpoint: CommonUtils.getBreakpoint(),
        };
        this.focusSlideTransitionItem = this.focusSlideTransitionItem.bind(
            this,
        );
        this.resetDropdown = this.resetDropdown.bind(this);
        this._updateBreakpoint = this._updateBreakpoint.bind(this);
    }
    /**
     * Set the breakpoint change in state.
     */
    _updateBreakpoint() {
        this.setState({ breakpoint: CommonUtils.getBreakpoint() });
    }

    componentDidMount() {
        // Add a resize listener to handle breakpoint changes.
        window.addEventListener('resize', this._updateBreakpoint);
    }

    componentWillUnmount() {
        // Remove the resize listener when this component is removed from the DOM.
        window.removeEventListener('resize', this._updateBreakpoint);
    }

    render() {
        let defaultLabel = this.props.defaultLabel
            ? this.props.defaultLabel
            : 'Select One ...';
        let slideTransitionDropdownErrorClass = '';
        let selectedItem = this.state ? this.state.selectedItem : null;

        if (this.props.showError) {
            slideTransitionDropdownErrorClass =
                ' ' + styles['slide-transition-dropdown-error'];
        }

        if (selectedItem && selectedItem.title) {
            defaultLabel = selectedItem.title;
        }

        let id = this.props.id ? this.props.id : null;
        return (
            <div className={styles['slide-transition-dropdown']}>
                <div>
                    {this.state.breakpoint !== 'xs' ? (
                        <div
                            className={
                                'hidden-xs ' +
                                styles['slide-transition-dropdown-hidden-xs']
                            }
                        >
                            <div className="form-group  has-feedback">
                                <div
                                    className={
                                        slideTransitionDropdownErrorClass +
                                        ' input-group'
                                    }
                                >
                                    <label
                                        className="input-label"
                                        htmlFor="dLabel"
                                    >
                                        {this.props.label}
                                    </label>
                                    <div className="sr-only">
                                        <input id="dLabel" tabIndex="-1" />
                                    </div>
                                    <DropDown
                                        disabled={this.props.showLoading}
                                        {...this.props}
                                        onSelect={this._handleOnSelect.bind(
                                            this,
                                        )}
                                        ref={(dropdown) =>
                                            (this.dropdown = dropdown)
                                        }
                                    >
                                        {this.props.children}
                                    </DropDown>
                                </div>
                                {this.props.showLoading ? (
                                    <div
                                        className={
                                            styles[
                                                'slide-transiton-dropdown-loading-div'
                                            ]
                                        }
                                    >
                                        <SVG name="SmallLoader" />{' '}
                                        {this.props.loadingMessage}
                                    </div>
                                ) : null}
                                {this.props.showError &&
                                this.props.errorMessage ? (
                                    <div
                                        className={
                                            'text-danger ' +
                                            styles[
                                                'slide-transition-dropdown-error-div'
                                            ]
                                        }
                                        aria-live={
                                            this.props.showError
                                                ? 'assertive'
                                                : ''
                                        }
                                        aria-atomic={
                                            this.props.showError ? 'false' : ''
                                        }
                                    >
                                        <SVG name="AlertFill" />{' '}
                                        {this.props.errorMessage}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div className="visible-xs-block">
                            <SlideTransitionItem
                                id={id}
                                label={this.props.label}
                                value={defaultLabel}
                                showLoading={this.props.showLoading}
                                loadingMessage={this.props.loadingMessage}
                                showError={this.props.showError}
                                errorMessage={this.props.errorMessage}
                                navDown={this.props.navDown}
                                disabled={this.props.disabled}
                                labelClassName={this.props.labelClassName}
                                ref={(slideTransitionItem) => {
                                    this.slideTransitionDropdownItem = slideTransitionItem;
                                }}
                            >
                                {this.props.selectedItem}
                            </SlideTransitionItem>
                        </div>
                    )}
                </div>
                <div className="clearfix" />
            </div>
        );
    }

    _handleOnSelect(item) {
        if (this.props.onSelect) {
            this.props.onSelect(item);
        }
        this.setState({ selectedItem: item });
    }

    focusSlideTransitionItem() {
        let slideTransitionItem = this.slideTransitionDropdownItem;
        if (slideTransitionItem) {
            slideTransitionItem.focusSlideTransitionButton();
        }
    }

    resetDropdown() {
        if (this.dropdown) {
            this.dropdown.resetDropdown();
        }
    }
}

SlideTransitionDropdown.propTypes = {
    defaultLabel: PropTypes.string,
    showError: PropTypes.bool,
    label: PropTypes.string,
    showLoading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    navDown: PropTypes.func,
    selectedItem: PropTypes.element,
    showBorders: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};
