import Fetch from 'fetch';

const addRegionToURL = (url) => {
    const context = process.env.REACT_APP_CONTEXT;
    let newUrl = url;

    if (process && process.env && process.env.NODE_ENV !== 'development') {
        if (url && url.indexOf('http') !== 0 && context) {
            const parts = context.split('/').filter((p) => p);

            if (parts.length > 1) {
                const urlSuffix = parts[1];

                if (newUrl && newUrl.indexOf('/') === 0) {
                    const urlParts = newUrl.split('/').filter((p) => p);

                    if (urlParts[1] !== urlSuffix) {
                        urlParts.splice(1, 0, urlSuffix);
                        newUrl = urlParts.join('/');
                        newUrl = `/${newUrl}`;
                    }
                }
            }
        }
    }

    return newUrl;
};

const setRegionPrefixToFetch = () => {
    if (process && process.env && process.env.NODE_ENV !== 'development') {
        Fetch.registerInterceptor((url, options) => {
            const newUrl = addRegionToURL(url);

            return {
                url: newUrl,
                options,
            };
        });
    }
};

const getFormSubmitURL = (url) => {
    const newUrl = addRegionToURL(url);

    return `${process.env.REACT_APP_SERVICES_URL || ''}${
        process.env.REACT_APP_API_CONTEXT || ''
    }${newUrl}`;
};

export { setRegionPrefixToFetch, addRegionToURL, getFormSubmitURL };
