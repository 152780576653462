/* eslint-disable import/no-useless-path-segments,import/order,no-unused-vars,no-underscore-dangle,react/destructuring-assignment,react/prop-types,react/sort-comp,jsx-a11y/no-static-element-interactions,prefer-template,dot-notation,react/no-string-refs,lines-between-class-members,eqeqeq,react/no-access-state-in-setstate,func-names,no-plusplus,padding-line-between-statements */
import React, { Component } from 'react';
import Collapse from 'react-collapse';
import MfaDropdownActions from './../actions/MfaDropdownActions';
import MfaDrowndownStore from './../stores/MfaDropdownStore';
import styles from '../../../scss/MfaDropdown.scss';
import { BrowserDetect } from 'common-ui/src/util/module';

export default class MfaDropdown extends Component {
    constructor(props) {
        super(props);

        this.animationConfig = { stiffness: 300, damping: 30 };
        this._onStoreChange = this._onStoreChange.bind(this);
        this.id = MfaDropdown._generateId();
        this.accordianGroup = this.props.accordianGroup || `group_${this.id}`;

        this.state = {
            expanded: false,
        };
    }

    componentDidMount() {
        MfaDrowndownStore.listen(this._onStoreChange);
    }

    componentWillUnmount() {
        MfaDrowndownStore.unlisten(this._onStoreChange);
    }

    render() {
        return (
            <div>
                <div
                    className="fi-link-color di-accordion-selector brand-link"
                    onClick={this._handleClick.bind(this)}
                    onKeyDown={this._handleOnKey.bind(this)}
                >
                    <span
                        className={
                            this.state.expanded
                                ? 'di-accordion-selector-icon di-accordion-selector-icon-opened'
                                : 'di-accordion-selector-icon di-accordion-selector-closed'
                        }
                    />
                    <span
                        className={
                            'di-accordion-selector-label' +
                            ' ' +
                            styles['questionTextBorder']
                        }
                        id={`${this.props.id}Title`}
                        tabIndex="0"
                        role="link"
                        aria-expanded={this.state.expanded}
                    >
                        {this.props.title}
                    </span>
                </div>
                <div aria-live="assertive" aria-atomic="true">
                    <Collapse
                        isOpened={this.state.expanded}
                        springConfig={this.animationConfig}
                    >
                        <div id="questionContainer">{this.props.children}</div>
                    </Collapse>
                </div>
            </div>
        );
    }

    _onStoreChange(state) {
        this.setState({
            expanded: state.openDropdowns[this.accordianGroup] === this.id,
        });
    }
    _handleOnKey(event) {
        if (event.keyCode == 13) {
            if (this.accordianGroup) {
                MfaDropdownActions.selectDropdown({
                    groupName: this.accordianGroup,
                    dropdownId: this.id,
                });
            } else {
                this.setState({
                    expanded: !this.state.expanded,
                });
            }
        }
    }
    _handleClick() {
        if (this.accordianGroup) {
            MfaDropdownActions.selectDropdown({
                groupName: this.accordianGroup,
                dropdownId: this.id,
            });
        } else {
            this.setState({
                expanded: !this.state.expanded,
            });
        }
    }
}

MfaDropdown._idIncrement = 0;

MfaDropdown._generateId = function () {
    MfaDropdown._idIncrement++;
    return MfaDropdown._idIncrement;
};
