/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

export const ArrowDropUp = ({ color, ...rest }) => {
    return (
        <SvgIcon
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M0.710051 3.28997L3.30005 0.699971C3.69005 0.309971 4.32005 0.309971 4.71005 0.699971L7.30005 3.28997C7.93005 3.91997 7.48005 4.99997 6.59005 4.99997H1.41005C0.520051 4.99997 0.0800515 3.91997 0.710051 3.28997Z"
                fill={color}
            />
        </SvgIcon>
    );
};

ArrowDropUp.propTypes = {
    color: PropTypes.string,
};
