import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useCopyToClipboard } from '../../hooks';

export const CopyToClipboard = ({ textToCopy, localeText }) => {
    const [copied, copy, reset] = useCopyToClipboard();

    return (
        <Tooltip
            enterTouchDelay={0} // tooltip does not open without the property set to zero.
            describeChild
            title={copied ? localeText.copied : ''}
        >
            <IconButton
                aria-label={copied ? localeText.copied : localeText.copy}
                onClick={() => copy(textToCopy)}
                onMouseOut={reset}
                onTouchStart={() => copy(textToCopy)}
                onTouchEnd={reset}
                sx={{ pl: 1 }}
            >
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );
};

CopyToClipboard.propTypes = {
    textToCopy: PropTypes.string.isRequired,
    localeText: PropTypes.shape({
        copy: PropTypes.string,
        copied: PropTypes.string,
    }).isRequired,
};
