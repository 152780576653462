/* eslint-disable import/order,react/default-props-match-prop-types,object-shorthand,func-names,react/destructuring-assignment,prefer-const,padding-line-between-statements,spaced-comment,react/no-find-dom-node,prefer-destructuring,react/no-access-state-in-setstate,react/sort-comp,react/prop-types,no-unneeded-ternary,no-else-return,react/static-property-placement */
import classNames from 'classnames';
import keycode from 'keycode';
import React from 'react';
import ReactDOM from 'react-dom';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import PropTypes from 'prop-types';

import MenuFooter from './MenuFooter';
import {
    bsClass,
    getClassSet,
    prefix,
    splitBsProps,
} from 'react-bootstrap/lib/utils/bootstrapUtils';
import createChainedFunction from 'react-bootstrap/lib/utils/createChainedFunction';
import ValidComponentChildren from 'react-bootstrap/lib/utils/ValidComponentChildren';

const FOOTER_ROLE = MenuFooter.defaultProps.bsRole;

const propTypes = {
    /**
     * Determines whether or not the menu is shown.
     */
    open: PropTypes.bool,

    /**
     * Right aligns the menu and the toggle.
     */
    pullRight: PropTypes.bool,

    /**
     * Renders borders between each menu item.
     */
    bordered: PropTypes.bool,

    /**
     * Called when the menu is closed. (event: object) => any
     */
    onClose: PropTypes.func,

    /**
     * Sets the aria-labelledby attribute on the menu.
     */
    labelledBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Called when a menu item is selected. (eventKey: any, event: Object) => any
     */
    onSelect: PropTypes.func,

    /**
     * Determines what kind of event will trigger onClose.
     */
    rootCloseEvent: PropTypes.oneOf(['click', 'mousedown']),

    /**
     * Callback that takes a list of menu items and a key code as input and returns an item in the list. By
     * default, matches the first item where the key code matches the first letter of its text content. Pass false to
     * disable hotkey functionality. (items: [node], keyCode: number) => node
     */
    getItemForHotkey: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /**
     * If true, users will not be able to tab out of the dropdown
     */
    preventCloseOnTabOut: PropTypes.bool,
    /**
     * Boolean to prevent issues when jquery is on the same page as the dropdown
     */
    preventDoubleJump: PropTypes.bool,
    /**
     * The index of the selected value
     */
    selectedIndex: PropTypes.number,
};

const defaultProps = {
    bsRole: 'menu',
    pullRight: false,
    rootCloseEvent: 'mousedown',
    getItemForHotkey: function (items, keyCode) {
        return items.find(function (item) {
            return (
                item.textContent &&
                item.textContent[0].toLowerCase() === keycode(keyCode)
            );
        });
    },
};

class SelectionDropdownMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focusIndex: 0,
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.preventDoubleJump) {
            if (this.props.selectedIndex !== prevProps.selectedIndex) {
                this.state.focusIndex = this.props.selectedIndex;
            }
            let items = this.getFocusableMenuItems();
            if (items.length) {
                items[this.state.focusIndex]?.focus();
            }
        }
    }

    handleKeyDown(event) {
        switch (event.keyCode) {
            case keycode.codes.down:
                this.focusNext();
                event.preventDefault();
                break;
            case keycode.codes.up:
                this.focusPrevious();
                event.preventDefault();
                break;
            case keycode.codes.esc:
            case keycode.codes.tab:
                if (this.props.preventCloseOnTabOut) {
                    //this will close the dropdown if the user uses "tab" to get off of the dropdown
                    let menuItems = this.getFocusableMenuItems();
                    if (!menuItems.includes(document.activeElement)) {
                        this.props.onClose(event);
                    }
                } else {
                    this.props.onClose(event);
                }

                break;
            default:
                this.focusHotkeyedItem(event.keyCode);
        }
    }

    getItemsAndActiveIndex() {
        const items = this.getFocusableMenuItems();
        const activeIndex = items.indexOf(document.activeElement);

        return { items, activeIndex };
    }

    getFocusableMenuItems() {
        const node = ReactDOM.findDOMNode(this);
        if (!node) {
            return [];
        }

        return Array.from(node.querySelectorAll('[tabIndex="-1"]'));
    }

    getActiveMenuItem() {
        const node = ReactDOM.findDOMNode(this);
        if (!node) {
            return null;
        }

        return node.querySelector('.active [tabIndex="-1"]');
    }

    focusNextOnOpen() {
        const activeMenuItem = this.getActiveMenuItem();

        if (activeMenuItem) {
            activeMenuItem.focus();
            //reset focus index when open dropdown
            if (this.props.selectedIndex) {
                this.state.focusIndex = this.props.selectedIndex;
            }
        } else {
            this.focusNext();
        }
    }

    focusNext() {
        const { items, activeIndex } = this.getItemsAndActiveIndex();
        if (items.length === 0) {
            return;
        }

        if (this.props.preventDoubleJump) {
            let focusIndex = this.state.focusIndex;

            if (focusIndex === items.length - 1) {
                focusIndex = 0;
            } else {
                focusIndex += 1;
            }
            this.setState({
                focusIndex: focusIndex,
            });
        } else {
            const nextIndex =
                activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            items[nextIndex].focus();
        }
    }

    focusPrevious() {
        const { items, activeIndex } = this.getItemsAndActiveIndex();
        if (items.length === 0) {
            return;
        }

        if (this.props.preventDoubleJump) {
            let focusIndex = this.state.focusIndex;

            if (focusIndex === 0) {
                focusIndex = items.length - 1;
            } else {
                focusIndex -= 1;
            }
            this.setState({
                focusIndex: focusIndex,
            });
        } else {
            const prevIndex =
                activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            items[prevIndex].focus();
        }
    }

    focusHotkeyedItem(keyCode) {
        if (this.props.getItemForHotkey) {
            let item = this.props.getItemForHotkey(
                this.getFocusableMenuItems(),
                keyCode,
            );

            if (item) {
                item.focus();
            }

            if (this.props.preventDoubleJump) {
                let items = this.getFocusableMenuItems();
                let focusedIndex = items.indexOf(document.activeElement);
                this.setState({
                    focusIndex: focusedIndex,
                });
            }
        }
    }

    render() {
        const {
            open,
            pullRight,
            bordered,
            onClose,
            labelledBy,
            onSelect,
            className,
            rootCloseEvent,
            getItemForHotkey,
            children,
            preventDoubleJump,
            selectedIndex,
            ...props
        } = this.props;

        const [bsProps, elementProps] = splitBsProps(props);

        const classes = {
            ...getClassSet(bsProps),
            [prefix(bsProps, 'right')]: pullRight,
        };

        return (
            <RootCloseWrapper
                disabled={!open}
                onRootClose={onClose}
                event={rootCloseEvent}
            >
                <div
                    {...elementProps}
                    className={classNames(className, classes, { bordered })}
                >
                    <ul
                        role="menu"
                        aria-labelledby={labelledBy ? labelledBy : null}
                        className="dropdown-menu-item-list"
                    >
                        {ValidComponentChildren.map(children, (child) => {
                            if (child.props.bsRole === FOOTER_ROLE) {
                                return null;
                            } else {
                                return React.cloneElement(child, {
                                    onKeyDown: createChainedFunction(
                                        child.props.onKeyDown,
                                        this.handleKeyDown,
                                    ),
                                    onSelect: createChainedFunction(
                                        child.props.onSelect,
                                        onSelect,
                                    ),
                                });
                            }
                        })}
                    </ul>
                    {ValidComponentChildren.map(children, (child) => {
                        if (child.props.bsRole === FOOTER_ROLE) {
                            return React.cloneElement(child, {
                                onKeyDown: createChainedFunction(
                                    child.props.onKeyDown,
                                    this.handleKeyDown,
                                ),
                            });
                        } else {
                            return null;
                        }
                    })}
                </div>
            </RootCloseWrapper>
        );
    }
}

SelectionDropdownMenu.propTypes = propTypes;
SelectionDropdownMenu.defaultProps = defaultProps;

export default bsClass('dropdown-menu', SelectionDropdownMenu);
