/* eslint-disable import/no-useless-path-segments,eqeqeq */
import AltInstance from 'common-ui/src/alt/AltInstance';
import MfaDropdownActions from './../actions/MfaDropdownActions';

class MfaDropdownStore {
    constructor() {
        this.openDropdowns = {};
        this.bindActions(MfaDropdownActions);
    }

    onSelectDropdown(info) {
        if (this.openDropdowns[info.groupName] == info.dropdownId) {
            this.openDropdowns[info.groupName] = 0;
        } else {
            this.openDropdowns[info.groupName] = info.dropdownId;
        }
    }

    onClearGroup(groupName) {
        if (this.openDropdowns[groupName] !== undefined) {
            delete this.openDropdowns[groupName];
        }
    }
}

export default AltInstance.createStore(MfaDropdownStore, 'MfaDropdownStore');
