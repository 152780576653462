/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Accounts extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 20 20"
                enableBackground="new 0 0 20 20"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g id="Layer_10">
                    <path
                        d="M10,0C4.477,0,0,4.477,0,10s4.477,10,10,10s10-4.477,10-10S15.523,0,10,0z M13.083,13.04
		c-0.139,0.368-0.345,0.685-0.619,0.952c-0.274,0.268-0.613,0.476-1.019,0.625c-0.14,0.051-0.29,0.091-0.445,0.124v1.453
		c0,0,0.015,0.5-0.5,0.5c-0.515,0-1,0-1,0S9,16.668,9,16.195c0-0.442,0-1.367,0-1.487c-0.072-0.018-0.144-0.036-0.216-0.059
		c-0.405-0.127-0.762-0.326-1.073-0.598c-0.31-0.271-0.557-0.617-0.739-1.038c-0.182-0.42-0.27-0.919-0.262-1.497h1.664
		c0,0.316,0.048,0.583,0.143,0.802c0.095,0.218,0.221,0.396,0.377,0.531c0.157,0.136,0.341,0.237,0.553,0.303
		c0.211,0.065,0.43,0.099,0.657,0.099c0.153,0,0.318-0.015,0.493-0.046c0.175-0.031,0.34-0.09,0.493-0.177
		c0.153-0.087,0.281-0.208,0.383-0.362c0.102-0.153,0.153-0.348,0.153-0.585c0-0.253-0.067-0.459-0.203-0.617
		c-0.135-0.158-0.312-0.289-0.531-0.394c-0.219-0.105-0.467-0.198-0.745-0.276c-0.278-0.08-0.558-0.167-0.843-0.263
		c-0.292-0.087-0.576-0.195-0.854-0.322c-0.278-0.127-0.526-0.291-0.745-0.493C7.487,9.514,7.31,9.263,7.175,8.96
		C7.04,8.658,6.972,8.293,6.972,7.863c0-0.481,0.086-0.899,0.257-1.255c0.172-0.354,0.396-0.649,0.674-0.887
		c0.278-0.236,0.591-0.412,0.942-0.525C8.896,5.179,8.948,5.166,9,5.152V3.695c0,0-0.015-0.5,0.5-0.5c0.515,0,1,0,1,0
		s0.5,0.027,0.5,0.5c0,0.433,0,1.327,0,1.476c0.024,0.007,0.049,0.011,0.073,0.019c0.376,0.109,0.71,0.286,1.002,0.531
		c0.292,0.245,0.523,0.558,0.695,0.939c0.172,0.381,0.257,0.844,0.257,1.387h-1.664c-0.015-0.281-0.064-0.513-0.148-0.697
		c-0.084-0.183-0.195-0.328-0.334-0.434c-0.139-0.105-0.297-0.18-0.476-0.223c-0.179-0.044-0.374-0.065-0.586-0.065
		c-0.139,0-0.277,0.018-0.416,0.053C9.264,6.716,9.139,6.777,9.026,6.864C8.912,6.952,8.819,7.062,8.746,7.193
		c-0.073,0.132-0.11,0.298-0.11,0.499c0,0.185,0.029,0.334,0.088,0.447c0.058,0.114,0.173,0.219,0.345,0.316
		c0.172,0.096,0.408,0.192,0.712,0.289c0.303,0.098,0.699,0.219,1.188,0.368c0.146,0.036,0.349,0.099,0.608,0.191
		c0.259,0.091,0.516,0.239,0.772,0.44c0.255,0.201,0.476,0.471,0.662,0.807c0.186,0.337,0.279,0.77,0.279,1.295
		C13.291,12.274,13.221,12.672,13.083,13.04z"
                    />
                </g>
            </svg>
        );
    }
}
