import React from 'react';
import PropTypes from 'prop-types';

import { Popover } from '@mui/material';

export const MenuPopover = ({ children, paperProps, ...props }) => {
    return (
        <Popover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    mt: 1.5,
                    ml: 0.5,
                    overflow: 'inherit',
                    minWidth: 200,
                    p: (theme) => theme.spacing(2),
                    ...paperProps,
                },
            }}
            {...props}
        >
            {children}
        </Popover>
    );
};

MenuPopover.propTypes = {
    children: PropTypes.node.isRequired,
    paperProps: PropTypes.object,
};
