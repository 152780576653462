/* eslint-disable no-underscore-dangle,react/sort-comp,react/destructuring-assignment,react/prop-types,react/no-string-refs,no-var,prefer-template,dot-notation,react/no-danger,consistent-return,class-methods-use-this,default-case,react/static-property-placement,react/boolean-prop-naming */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Styles from '../../styles/Alert.scss';
import SVG from '../images/SVG';

export default class Alert extends Component {
    constructor(props) {
        super(props);

        this._getSVGName = this._getSVGName.bind(this);
    }

    componentDidMount() {
        if (this.props.scrollToOnMount) {
            this.refs.alertContainer.scrollIntoView();
        }
    }

    render() {
        var className =
            'alert alert-' + this.props.type + ' ' + Styles['container'];

        return (
            <div ref="alertContainer" className={className}>
                {this.props.showIcon ? (
                    <div className={Styles['icon']}>
                        <SVG
                            name={this._getSVGName(this.props.type)}
                            size="sm"
                        />
                    </div>
                ) : null}
                {this.props.dangerouslySetInnerHTML ? (
                    <div
                        dangerouslySetInnerHTML={
                            this.props.dangerouslySetInnerHTML
                        }
                        role="alert"
                        aria-live="assertive"
                    />
                ) : (
                    <div
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        id="alertMessage"
                    >
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }

    _getSVGName(alertType) {
        switch (alertType) {
            case 'success':
                return 'Success';
            case 'info':
                return 'Info';
            case 'danger':
                return 'AlertError';
            case 'warning':
                return 'AlertWarning';
            case 'message':
                return 'AlertFill';
        }
    }
}

Alert.propTypes = {
    /**
     * Selection will change icon and color of the alert.
     */
    type: PropTypes.oneOf(['success', 'info', 'danger', 'warning', 'message'])
        .isRequired,
    /**
     * If showIcon exists (and is not set to false) the icon will show. Color will persist regardless of if this is true or not.
     */
    showIcon: PropTypes.bool,
};
