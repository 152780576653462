import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { ButtonBase, Avatar } from '@mui/material';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    backgroundColor: theme.palette.grey[200],
}));

export function IconButton({ icon, onClick, ariaLabel, sx }) {
    return (
        <ButtonBase aria-label={ariaLabel} onClick={onClick}>
            <StyledAvatar sx={sx}>{icon}</StyledAvatar>
        </ButtonBase>
    );
}

IconButton.propTypes = {
    icon: PropTypes.node,
    onClick: PropTypes.func,
    ariaLabel: PropTypes.string,
    sx: PropTypes.object,
};

export default {};
