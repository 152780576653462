import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import { VStack } from '../Stack';
import { NoSearchResultsIllustration } from '../../illustrations/NoSearchResults';

export const NoResults = ({ title, description }) => {
    return (
        <VStack>
            <NoSearchResultsIllustration sx={{ fontSize: 250 }} />
            {title && (
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    align="center"
                >
                    {title}
                </Typography>
            )}
            {description && (
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    align="center"
                >
                    {description}
                </Typography>
            )}
        </VStack>
    );
};

NoResults.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};
