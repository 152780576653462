/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Recycle extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M3.1,11.8c-0.6,0-1.1-0.2-1.6-0.4c-0.5-0.2-0.7-0.6-0.9-0.9c-0.1-0.2-0.1-0.3-0.2-0.4c0-0.2,0-0.3,0-0.5
						c0-0.3,0-0.6,0.1-0.8s0.2-0.5,0.2-0.7C0.8,7.9,0.9,7.8,1,7.7c0.1-0.1,0.2-0.2,0.2-0.2L0,6.8l4.2-0.2l2,3.7L4.9,9.7
						c0,0-0.1,0.1-0.2,0.2C4.6,9.9,4.6,10,4.5,10.1c-0.1,0.1-0.2,0.2-0.3,0.3C4.1,10.5,4,10.6,4,10.7c-0.1,0.2-0.2,0.2-0.1,0.4
						c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.2,0.2,0.3,0.2L3.1,11.8z M15.1,12.3c0.4,0,0.8-0.1,1.1-0.2c0.3-0.2,0.7-0.3,0.9-0.6
						c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.2,0.2-0.2,0.2-0.3c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.5,0.8c-0.1,0.2-0.2,0.4-0.2,0.5
						c-0.1,0.2-0.2,0.4-0.3,0.7c-0.2,0.6-0.5,1-0.7,1.3c-0.2,0.3-0.3,0.7-0.6,1c-0.2,0.3-0.4,0.6-0.7,0.7c-0.2,0.2-0.4,0.2-0.7,0.2
						c-0.2,0-0.9,0-2.1,0V18l-2.2-3.5L12,11v1.4C14.1,12.3,15.1,12.3,15.1,12.3z M7,0c0.4,0,1.1,0,2.4,0c1.1,0,2,0,2.5,0.1
						c0.6,0.1,0.9,0.2,1.1,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.3,0.7c0.2,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.2,0.2,0.3l1.3-0.8
						l-1.7,3.9L9.2,5.3l1.2-0.7L8.9,1.9c-0.2-0.4-0.5-0.7-0.8-1C7.8,0.7,7.5,0.5,7.2,0.4C6.9,0.3,6.7,0.2,6.4,0.2C6.3,0.2,6.1,0.1,6,0.1
						C6.3,0.1,6.6,0,7,0z M1.5,11.8C1,11.5,0.7,11.3,0.6,11c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.2,0.2,0.4,0.4,0.7s0.3,0.7,0.6,1
						c0.2,0.3,0.3,0.6,0.4,0.7C2.3,13.9,2.3,14,2.3,14c0.2,0.3,0.2,0.6,0.4,0.8c0.2,0.2,0.2,0.5,0.4,0.7c0.2,0.2,0.3,0.5,0.5,0.7
						c0.2,0.2,0.3,0.2,0.5,0.2l4.6-0.1l-0.1-4.5c-2.8,0-4.6,0.1-5.7,0.1C2.4,12,1.9,11.9,1.5,11.8z M14.7,11.6c-0.2-0.3-0.7-1.1-1.4-2.1
						c-0.7-1.1-1.1-1.7-1.2-1.9l3.7-2.2c0,0.1,0.1,0.2,0.2,0.5c0.2,0.2,0.3,0.6,0.5,0.9c0.4,0.7,0.7,1.3,0.9,1.7
						c0.2,0.3,0.3,0.7,0.4,0.7c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.5-0.2,0.7c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.3-0.6,0.6
						s-0.5,0.4-0.9,0.6c-0.3,0.2-0.7,0.2-1.1,0.2C15,12.1,14.9,11.9,14.7,11.6z M6.1,6.7l-3.7-2c0-0.1,0.2-0.3,0.5-0.8
						C3,3.8,3.2,3.5,3.4,3.1s0.4-0.7,0.7-1.1c0.2-0.3,0.3-0.6,0.5-0.7s0.2-0.4,0.2-0.5C4.8,0.7,4.9,0.6,5,0.5s0.4-0.2,0.7-0.2
						c0.1,0,0.2,0,0.5,0c0.2,0,0.5,0.1,0.7,0.2c0.3,0.2,0.7,0.3,0.9,0.6c0.3,0.2,0.6,0.6,0.7,1l0.1,0.1C8.8,2.2,8.9,2.3,9,2.5L6.1,6.7z"
                    />
                </g>
            </svg>
        );
    }
}
