/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class AlertError extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    fill="#c90202"
                    d="M17.6,14.7L10.7,1.4C10.2,0.5,9.6,0,9,0S7.8,0.5,7.3,1.4L0.4,14.7c-0.5,1-0.5,1.8-0.2,2.4S1.3,18,2.3,18h13.4
				c1,0,1.7-0.3,2.1-0.9C18.1,16.5,18.1,15.6,17.6,14.7z M9,16c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S9.6,16,9,16z M9.7,11.7H8.3L8,5h2
				L9.7,11.7z"
                />
            </svg>
        );
    }
}
