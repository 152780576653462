/* eslint-disable */
function verifyId(idResponse) {
    if (idResponse != null && idResponse != '' && idResponse != undefined) {
        try {
            if (Ext != null && Ext != undefined) {
                var idOriginal = Ext.util.Cookies.get('USPIBID');
                if (
                    idOriginal != null &&
                    idOriginal != '' &&
                    idOriginal != undefined
                ) {
                    if (idResponse.indexOf(idOriginal) == -1) {
                        if (
                            ifs != null &&
                            ifs.env != null &&
                            ifs.env.context != null
                        ) {
                            verifyIdLogging(idOriginal, idResponse);
                            window.top.location.href =
                                ifs.env.context + '/app/logout?reason=logout';
                        } else {
                            return false;
                        }
                    }
                }
            }
        } catch (e) {}
    }
    return true;
}

function verifyIdLogging(idOriginalLog, idResponseLog) {
    if (Ext != null && Ext != undefined) {
        Ext.Ajax.request({
            url: ifs.env.context + '/app/idLogging',
            params: {
                idOriginal: idOriginalLog,
                idResponse: idResponseLog,
            },
            method: 'POST',
            success: function(d, e) {},
            failure: function(d, e) {},
        });
    }
}

window.verifyId = verifyId;
window.verifyIdLogging = verifyIdLogging;
