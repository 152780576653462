/* eslint-disable filenames/match-exported,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment,react/no-unknown-property */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Add extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18">
                <title>{title}</title>
                <desc>{desc}</desc>
                <g
                    id="icon-/-subtract"
                    stroke="none"
                    stroke-width="1"
                    fill="currentColor"
                    fill-rule="evenodd"
                >
                    <polygon
                        id="Page-1"
                        fill="currentColor"
                        points="0 7 0 11 18 11 18 7"
                    />
                </g>
            </svg>
        );
    }
}
