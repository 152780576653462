/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class TextMobileAlerts extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                enableBackground="new 0 0 18 18"
                focusable="false"
            >
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    d="M11.6,1.1C7,0.1,2,1.9,0.5,5.3c-1,2.4,0,4.9,2.3,6.6c-0.5,2.8-1.4,5.3-1.4,5.3l5.5-3.7c4.5,0.8,9.1-1,10.5-4.3
				C19,5.8,16.3,2.2,11.6,1.1z"
                />
            </svg>
        );
    }
}
