import React, {
    createContext,
    useContext,
    useReducer,
    useCallback,
} from 'react';

export const LAYOUT_ACTIONS = {
    OPEN_NAVIGATION_PANEL: 'open navigation panel',
    CLOSE_NAVIGATION_PANEL: 'close navigation panel',
    OPEN_RIGHT_PANEL: 'open right panel',
    CLOSE_RIGHT_PANEL: 'close right panel',
    TOGGLE_LAYOUT: 'toggle layout',
};

// eslint-disable-next-line no-unused-vars
const layoutReducer = (state, action) => {
    // switch (action.type) {
    //     // case LAYOUT_ACTIONS.OPEN_NAVIGATION_PANEL:
    //     //     return { ...state, isNavigationPanelOpen: true };
    //     // case LAYOUT_ACTIONS.CLOSE_NAVIGATION_PANEL:
    //     //     return { ...state, isNavigationPanelOpen: false };
    //     // case LAYOUT_ACTIONS.OPEN_RIGHT_PANEL:
    //     //     return { ...state, isRightPanelOpen: true };
    //     // case LAYOUT_ACTIONS.CLOSE_RIGHT_PANEL:
    //     //     return { ...state, isRightPanelOpen: false };
    //     // case LAYOUT_ACTIONS.TOGGLE_LAYOUT:
    //     //     return {
    //     //         ...state,
    //     //         isVerticalLayout: !state.isVerticalLayout,
    //     //         isNavigationPanelOpen: !state.isVerticalLayout,
    //     //     };
    //     default:
    //         return state;
    // }
    return state;
};

const defaultLayout = () => {
    const isVerticalLayout = localStorage?.getItem('isVerticalLayout');

    if (isVerticalLayout !== undefined && isVerticalLayout !== null) {
        return isVerticalLayout === 'true';
    }

    return false;
};

const initialState = {
    isNavigationPanelOpen: defaultLayout(),
    isRightPanelOpen: false,
    isVerticalLayout: defaultLayout(),
};

const defaultValue = {
    ...initialState,
    openNavigationPanel: () => undefined,
    closeNavigationPanel: () => undefined,
    openRightPanel: () => undefined,
    closeRightPanel: () => undefined,
    toggleLayout: () => undefined,
};

export const LayoutContext = createContext(defaultValue);

export function LayoutProvider({ children }) {
    const [state, dispatch] = useReducer(layoutReducer, initialState);

    const openNavigationPanel = () => {
        dispatch({ type: LAYOUT_ACTIONS.OPEN_NAVIGATION_PANEL });
    };

    const closeNavigationPanel = () => {
        dispatch({ type: LAYOUT_ACTIONS.CLOSE_NAVIGATION_PANEL });
    };

    const openRightPanel = () => {
        dispatch({ type: LAYOUT_ACTIONS.OPEN_RIGHT_PANEL });
    };

    const closeRightPanel = () => {
        dispatch({ type: LAYOUT_ACTIONS.CLOSE_RIGHT_PANEL });
    };

    const persistLayoutState = useCallback((enabled) => {
        localStorage.setItem('isVerticalLayout', enabled);
    }, []);

    const toggleLayout = () => {
        persistLayoutState(!state.isVerticalLayout);
        dispatch({ type: LAYOUT_ACTIONS.TOGGLE_LAYOUT });
    };

    return (
        <LayoutContext.Provider
            value={{
                ...state,
                openNavigationPanel,
                closeNavigationPanel,
                openRightPanel,
                closeRightPanel,
                toggleLayout,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
}

export const useNavigationPanel = () => {
    const context = useContext(LayoutContext);

    if (context === undefined) {
        throw new Error(
            'useNavigationPanel must be used within a LayoutContext provider',
        );
    }
    const {
        isNavigationPanelOpen,
        openNavigationPanel,
        closeNavigationPanel,
    } = context;

    return { isNavigationPanelOpen, openNavigationPanel, closeNavigationPanel };
};

export const useRightPanel = () => {
    const context = useContext(LayoutContext);

    if (context === undefined) {
        throw new Error(
            'useRightPanel must be used within a LayoutContext provider.',
        );
    }
    const { isRightPanelOpen, openRightPanel, closeRightPanel } = context;

    return { isRightPanelOpen, openRightPanel, closeRightPanel };
};

export const useLayout = () => {
    const context = useContext(LayoutContext);

    if (context === undefined) {
        throw new Error(
            'useLayout must be used within a LayoutContext provider.',
        );
    }
    const { isVerticalLayout, toggleLayout } = context;

    return { isVerticalLayout, toggleLayout };
};

export default {};
