/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class AlertWarning extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg x="0px" y="0px" viewBox="0 0 18 18" focusable="false">
                <title>{title}</title>
                <desc>{desc}</desc>
                <path
                    fill="#FEC82A"
                    d="M2.3,18c-1,0-1.8-0.3-2.1-0.9c-0.4-0.6-0.3-1.4,0.2-2.4L7.3,1.4C7.8,0.5,8.4,0,9,0s1.2,0.5,1.7,1.4l6.9,13.3
                    c0.5,0.9,0.5,1.8,0.2,2.4c-0.4,0.6-1.1,0.9-2.1,0.9H2.3z"
                />
                <polygon fill="#333333" points="8.3,11.7 8,5 10,5 9.7,11.7 " />
                <circle fill="#333333" cx="9" cy="15" r="1" />
            </svg>
        );
    }
}
