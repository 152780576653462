module.exports = {
    // AJAX request states
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',

    CUSTOM_TYPE_COMPONENT: 'component',
    CUSTOM_TYPE_CONTROLLED_IFRAME: 'controlled-iframe',
    CUSTOM_TYPE_UNCONTROLLED_IFRAME: 'uncontrolled-iframe',

    VERIFY_ID_LOGGING_URL: 'site-olb/v1/idLogging',
    LOGOUT_URL: 'app/logout',

    DEFAULT_RESOURCE_LOAD_TIMEOUT: 10,
    MANUAL_ENTRY_ALLOWED_CHARACTERS: '01234567890/',
    MANUAL_ENTRY_DATE_FORMAT: 'MM/DD/YYYY',
    MANUAL_ENTRY_MAX_START_DATE_KEY: '{MaxStartDate}',
    MANUAL_ENTRY_MAX_END_DATE_KEY: '{MaxEndDate}',

    ENGLISH_LOCALE: 'en_US',
};
