/* eslint-disable no-unused-vars,import/order,padding-line-between-statements */
import { getADRequestHeaders } from 'common-ui/src/anomaly-detection';
import Fetch from '../../USPFetch';

const convertResponseToJSON = (response) => {
    const { data } = response;
    const { result } = data || {};
    try {
        response.data = JSON.parse(result);
    } catch (e) {
        response.data = result;
    }
    return response;
};

export default class MfaService {
    static sendOtp(protocol, destId) {
        const type = protocol.toLowerCase();
        const csrftoken = window.di.mfa.CSRFToken;
        const response = {
            data: 'success',
        };

        const postData = {
            destId,
            csrftoken,
        };

        return Fetch.post(
            `/auth-olb/v1/mfa/select?type=${type}`,
            postData,
            getADRequestHeaders(),
        ).then(convertResponseToJSON);
    }

    static verifyOtp(protocol, destId, code, cookieOptIn) {
        const displayMethod = protocol;
        const type = 'OTP';
        const otp = code;
        const cookieoptin = cookieOptIn;
        const csrftoken = window.di.mfa.CSRFToken;
        const response = {
            data: {
                success: 'success',
                resultURL: '',
            },
        };

        const postData = {
            destId,
            csrftoken,
            otp,
        };
        return Fetch.post(
            `/auth-olb/v1/mfa/submit?displayMethod=${displayMethod}&type=${type}&cookieoptin=${cookieoptin}`,
            postData,
            getADRequestHeaders(),
        ).then(convertResponseToJSON);
    }

    static getQueryString(url) {
        if (!url) {
            return null;
        }

        if (url.indexOf('?') !== -1) {
            url.substring(url.indexOf('?'));
        }

        return null;
    }

    static mfaLog(
        eventMessage,
        adminOTPRequested,
        tokenOTPRequested,
        tOTPRequested,
        csrfToken,
        destId,
    ) {
        const params = { eventMessage };

        if (adminOTPRequested) {
            params.eventType = 'AdminOTPRequested';
        } else if (tokenOTPRequested) {
            params.eventType = 'TokenOTPRequested';
        } else if (tOTPRequested) {
            params.eventType = 'TOTPRequested';
        }

        if (csrfToken) {
            params.CSRFToken = csrfToken;
        }

        if (destId) {
            params.destId = destId;
        }

        return Fetch.postFormData('/auth-olb/v1/mfa/logging', {
            ...params,
        });
    }
}
