/* eslint-disable import/order,no-underscore-dangle,react/destructuring-assignment,react/sort-comp,eqeqeq,react/no-find-dom-node,no-empty,react/no-access-state-in-setstate,padding-line-between-statements,prefer-const,prefer-template,jsx-a11y/label-has-for,no-unneeded-ternary,react/prop-types,jsx-a11y/anchor-is-valid,no-script-url,react/static-property-placement,react/default-props-match-prop-types,react/boolean-prop-naming */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SVG from '../images/SVG';
import InputStyles from '../../styles/Input.scss';
import BrowserDetect from '../../util/BrowserDetect';
import PropTypes from 'prop-types';

export default class InputFieldGroupAddon extends Component {
    constructor(props) {
        super(props);

        this._isSetLinkAsShowDefined = this._isSetLinkAsShowDefined.bind(this);
        this._onClickShowHide = this._onClickShowHide.bind(this);
        this._onChangeText = this._onChangeText.bind(this);
        this._onInputFocus = this._onInputFocus.bind(this);
        this._onMouseDownShowHide = this._onMouseDownShowHide.bind(this);

        this.state = {
            setLinkAsShow: this._isSetLinkAsShowDefined()
                ? this.props.setLinkAsShow
                : true,
        };
        this.showHideLink = null;
    }

    _isSetLinkAsShowDefined() {
        return typeof this.props.setLinkAsShow != 'undefined';
    }

    componentDidMount() {
        if (this.props.focusOnInit) {
            try {
                ReactDOM.findDOMNode(this._inputGroupElement).focus();
                ReactDOM.findDOMNode(this._inputGroupElement).select();
            } catch (e) {}
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.showHideLinks && !prevProps.showHideLinks) {
            // We need to render once to get the width of the show/hide link and then once more to use the width with
            // the input field
            this.forceUpdate();
        }
    }

    _onClickShowHide(evt) {
        this.setState({ setLinkAsShow: !this.state.setLinkAsShow });
        if (this.props.onClickShowHide) {
            this.props.onClickShowHide(evt);
        }
    }

    _onMouseDownShowHide(evt) {
        this.setState({ setLinkAsShow: !this.state.setLinkAsShow });
        if (this.props.onMouseDownShowHide) {
            this.props.onMouseDownShowHide(evt);
        }
    }

    _onChangeText(evt) {
        this.setState({ value: evt.target.value });
        if (this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    render() {
        let ariaLabel = this.props.ariaLabel ? this.props.ariaLabel : '';
        let inputGroupStyle = this.props.className;
        inputGroupStyle += this.props.isValid ? '' : ' has-error';

        // Defining callsign keystroke field using css class
        // https://docs.callsign.com/docs/web-intel-sdk-registered/287a7228a1bcb-setting-up-the-web-sdk#defining-the-keystroke-fields
        let textFieldStyle = 'form-control cs-password';
        let labelClassName =
            this.props.labelClassName || InputStyles['input-group-addon'];
        let insetAddonStyle = InputStyles['di-input-inset-addon-left'];
        let showHideLinksStyle = InputStyles['show-hide-links'];
        let mirrorStyle = 'visible-xs-inline-block';
        // This was to manage an issue created by fixing LEAP-5206 where iOS devices were not picking up the change because there
        let anchorOnClick = { onClick: this._onClickShowHide };
        if (this.props.onMouseDownShowHide) {
            anchorOnClick = { onMouseDown: this._onMouseDownShowHide };
            if (BrowserDetect.isIOS()) {
                anchorOnClick = { onClick: this._onMouseDownShowHide };
            }
        }

        let displayMode = '';
        if (this.props.changeXsHeight) {
            textFieldStyle += ' ' + InputStyles['input-group-height'];
            showHideLinksStyle +=
                ' ' + InputStyles['show-hide-links-change-height-top'];
            if (this.props.labelOnSameLine) {
                labelClassName += ' ' + InputStyles['input-group-height'];
                insetAddonStyle +=
                    ' ' +
                    InputStyles['di-input-inset-addon-same-line-label-top'];
            }
        }
        if (this.props.labelOnSameLine) {
            labelClassName += ' input-label';
            textFieldStyle +=
                ' ' + InputStyles['di-input-inset-addon-label-on-same-line'];
        } else {
            insetAddonStyle +=
                ' ' + InputStyles['di-input-inset-addon-top-label-top'];
        }

        if (this.props.svgName || this.props.addonLabel) {
            textFieldStyle +=
                ' ' + InputStyles['input-group-text-left-padding'];
            inputGroupStyle += ' input-group';
        }

        if (this.props.labelOnSameLine) {
            mirrorStyle += ' ' + InputStyles['mirror-span'];
            textFieldStyle += ' ' + InputStyles['mirror-textbox'];
            insetAddonStyle += ' ' + InputStyles['mirror-inset'];
            displayMode = ' ' + InputStyles['display-mirror'];
        } else {
            mirrorStyle = 'hide';
        }

        let inputStyle = null;

        if (this.props.showHideLinks && this.showHideLink) {
            let showHideLinkWidth = this.showHideLink.offsetWidth;
            let showHideLinkRightMargin = 2;
            inputStyle = {
                paddingRight:
                    showHideLinkWidth + showHideLinkRightMargin + 'px',
            };
        }

        let setLinkAsShow = this._isSetLinkAsShowDefined()
            ? this.props.setLinkAsShow
            : this.state.setLinkAsShow;
        let showHideLabel = setLinkAsShow
            ? this.props.showLabel
            : this.props.hideLabel;

        let inputVal =
            this.props.value !== undefined
                ? this.props.value
                : this.state.value;
        return (
            <div
                className={
                    'form-group ' +
                    InputStyles['input-field-group-addon'] +
                    displayMode
                }
            >
                <div className={inputGroupStyle}>
                    {this.props.labelOnSameLine ? (
                        <label
                            htmlFor={this.props.id}
                            className={labelClassName}
                        >
                            {this.props.label}
                        </label>
                    ) : null}
                    <div className={InputStyles['di-input-inset']}>
                        {!this.props.labelOnSameLine ? (
                            <label
                                htmlFor={this.props.id}
                                className={labelClassName}
                            >
                                {this.props.label}
                            </label>
                        ) : null}

                        <span className={mirrorStyle}>
                            {inputVal ? inputVal : this.props.placeholder}
                        </span>

                        <span className={insetAddonStyle}>
                            {this.props.svgName ? (
                                <span>
                                    <SVG name={this.props.svgName} />
                                </span>
                            ) : (
                                this.props.addonLabel
                            )}
                        </span>
                        <input
                            type={!setLinkAsShow ? 'text' : this.props.type}
                            id={this.props.id}
                            className={textFieldStyle}
                            maxLength={this.props.maxLength}
                            style={inputStyle}
                            value={this.props.value}
                            defaultValue={this.props.defaultValue}
                            aria-label={
                                this.props.value
                                    ? this.props.label
                                    : this.props.label + ' ' + ariaLabel
                            }
                            disabled={this.props.disabled}
                            autoCorrect={this.props.autoCorrect}
                            autoCapitalize={this.props.autoCapitalize}
                            autoComplete={this.props.autoComplete}
                            onChange={this._onChangeText}
                            onInput={this.props.onInput}
                            onBlur={this.props.onBlur}
                            onFocus={this._onInputFocus}
                            aria-invalid={this.props.isValid ? 'false' : 'true'}
                            aria-labelledby={
                                this.props.ariaLabelledby
                                    ? this.props.id +
                                      ' ' +
                                      this.props.ariaLabelledby
                                    : this.props.id
                            }
                            aria-describedby={
                                this.props.ariaDescribedBy
                                    ? this.props.ariaDescribedBy
                                    : this.props.id
                            }
                            ref={(inputGroupElement) => {
                                this._inputGroupElement = inputGroupElement;
                            }}
                            placeholder={
                                this.props.value ? '' : this.props.placeholder
                            }
                        />
                    </div>
                </div>
                {this.props.showHideLinks ? (
                    <a
                        ref={(component) => {
                            this.showHideLink = component;
                        }}
                        href="javascript:void(0)"
                        className={showHideLinksStyle}
                        id={this.props.id + 'ShowHideLink'}
                        {...anchorOnClick}
                    >
                        {showHideLabel}
                    </a>
                ) : null}
                {this.props.children}
                <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    className="has-error"
                >
                    {!this.props.isValid && this.props.validationError ? (
                        <span className="help-block">
                            <SVG name="AlertError" />
                            &nbsp;
                            <span>{this.props.validationError}</span>
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }

    _onInputFocus(evt) {
        let input = this._inputGroupElement;
        if (input && input.setSelectionRange) {
            setTimeout(() => {
                input.setSelectionRange(0, 9999);
            });
        }

        if (this.props.onFocus) {
            this.props.onFocus(evt);
        }
    }
}

InputFieldGroupAddon.defaultProps = {
    autoCorrect: 'off',
    autoCapitalize: 'off',
};

InputFieldGroupAddon.propTypes = {
    focusOnInit: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    labelOnSameLine: PropTypes.bool,
    changeXsHeight: PropTypes.bool,
    svgName: PropTypes.string,
    id: PropTypes.string,
    addonLabel: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isValid: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    validationError: PropTypes.string,
    showHideLinks: PropTypes.bool,
    showLabel: PropTypes.string,
    hideLabel: PropTypes.string,
    setLinkAsShow: PropTypes.bool,
    onClickShowHide: PropTypes.func,
    autoComplete: PropTypes.string,
    onMouseDownShowHide: PropTypes.func,
};
