/* eslint-disable import/newline-after-import,react/prefer-stateless-function,no-useless-constructor,prefer-destructuring,prefer-const,react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class TurboTax extends Component {
    static propTypes = {
        title: PropTypes.string,
        desc: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.title;
        let desc = this.props.desc;

        return (
            <svg x="0px" y="0px" viewBox="0 0 18 18" focusable="false">
                <title>{title}</title>
                <desc>{desc}</desc>
                <g>
                    <path
                        d="M0.5,9A8.5,8.5,0,1,0,9,.5,8.52,8.52,0,0,0,.5,9"
                        fill="#d52b1e"
                    />
                    <path
                        d="M16.12,5.92a10.68,10.68,0,0,0-1.7-2.55,26.06,26.06,0,0,0-6.91,7.54A11.3,11.3,0,0,0,4.22,7.3L2.52,9a18.86,18.86,0,0,1,5.1,5.74,30.73,30.73,0,0,1,8.5-8.82"
                        fill="#fff"
                    />
                </g>
            </svg>
        );
    }
}
